import React, { useEffect, useMemo } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { Card, Divider, Tab, useTheme } from "@mui/material";
import "./leaderboardview.scss";
import Button from "@mui/material/Button";

import Linecharts from "../../components/highcharts/linecharts";
import DonutCharts from "../../components/highcharts/donutcharts";
import SemiPie from "../../components/highcharts/semipie";
import EastIcon from "@mui/icons-material/East";
import { BarChart, CalendarMonth } from "@mui/icons-material";
import Barcharts from "../../components/highcharts/barcharts";
// import TabMenu from "../../components/tabmenu/tabmenu";
import DownIcon from "../../assets/downIcon";
import JustIcon from "../../assets/justIcon";
import UpIcon from "../../assets/upIcon";
import MyMultiSelect from "../../components/dropdown/myMultiSelect";
import MyDateRangePicker from "../../components/datepicker/myDateRangePicker";
import { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveChannels,
  getAllBuHavingPgUnderUser,
  getCustomerEffortChart,
  getCustomerFeedbackChart,
  getHighlevelStats,
} from "../../redux/slices/leaderboardSlice";

function Leaderboardview() {
  //local state
  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();
  const [openDate, setOpenDate] = useState(false);
  const [dateLabel, setDateLabel] = useState();
  const [date, setDate] = useState();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedBu, setSelectedBu] = useState([]);

  //redux
  const {
    active_channels,
    business_units,
    customer_effort_details,
    usagestats_details,
  } = useSelector((state) => state.leaderboardReducer);
  const memoizedActivChannels = useMemo(
    () =>
      active_channels.map((item) => ({
        ...item,
        label: item.name,
        id: item.uuid,
      })),
    [active_channels]
  );
  const memoizedBusinessUnits = useMemo(
    () =>
      business_units.map((item) => ({
        ...item,
        label: item.name,
        id: item.uuid,
      })),
    [business_units]
  );

  //function
  const handleDateChange = (state) => {
    setDateLabel(
      `${moment(state.startDate).format("MM/DD/YYYY")}-${moment(
        state.endDate
      ).format("MM/DD/YYYY")}`
    );
    setDate({
      start_date: moment(state.startDate).format("YYYY-MM-DD"),
      end_date: moment(state.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleChannelChange = (newValue) => {
    setSelectedChannels(newValue.map((channel) => channel.name));
  };

  const handleBuChange = (newValue) => {
    setSelectedBu(newValue.map((bu) => bu.uuid));
  };

  //useEffects
  useEffect(() => {
    dispatch(getActiveChannels());
    dispatch(getAllBuHavingPgUnderUser());
  }, []);

  useEffect(() => {
    setSelectedChannels(active_channels.map((channel) => channel.name));
    setSelectedBu(business_units.map((bu) => bu.uuid));
  }, [active_channels, business_units]);

  useEffect(() => {
    let by_filter = {
      business_unit_uuid: selectedBu,
      end_date: date?.end_date,
      start_date: date?.start_date,
      support_channel: selectedChannels,
    };
    if (
      date?.start_date &&
      date?.end_date &&
      selectedBu.length &&
      selectedChannels.length
    ) {
      dispatch(getHighlevelStats(by_filter));
      dispatch(getCustomerFeedbackChart(by_filter));
      dispatch(getCustomerEffortChart(by_filter));
    }
  }, [selectedBu, selectedChannels, date]);

  return (
    <div
      className="leaderboardWrap"
      style={{ overflow: "scroll", height: "calc(100vh - 70px)" }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Leaderboard
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Track Your Progress and Reclaim the best customer experience!
            </Typography>
          </div>
          <div className="dropdownWrap" style={{ position: "relative" }}>
            <Grid width="185px">
              <MyMultiSelect
                options={memoizedActivChannels}
                label="Channels"
                defaultSelectAll={true}
                handleChange={handleChannelChange}
              />
            </Grid>
            <Grid width="170px">
              <MyMultiSelect
                options={memoizedBusinessUnits}
                label="Business Units"
                defaultSelectAll={true}
                handleChange={handleBuChange}
              />
            </Grid>
            <Grid>
              <Button
                fullWidth
                onClick={() => setOpenDate(true)}
                variant="ascendo"
                endIcon={<CalendarMonth />}
                sx={{
                  textTransform: "none",
                  textAlign: "left",
                  color: "grey",
                  borderColor: "grey",
                  border: "1px solid grey",
                  backgroundColor: "transparent",
                }}
              >
                {dateLabel}
              </Button>
            </Grid>
            <Grid
              display={openDate ? "block" : "none"}
              sx={{ position: "absolute", zIndex: 2, top: 0, right: 0 }}
              onMouseLeave={() => setOpenDate(false)}
            >
              <Card elevation={10}>
                <MyDateRangePicker onStateChange={handleDateChange} />
              </Card>
            </Grid>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid xs={12} md={2} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Typography className="highlightGreen">
                You are getting efficient
              </Typography>
              <Typography className="highlightTitle">Auto Solved</Typography>
              <Divider />
              <div className="innerWrap">
                <div className="highlightNum">10</div>
                <div className="highlightText">
                  Rewards the knowledge creators to make CSAT top notch.
                </div>
              </div>
              <Divider />
              <div className="buttonWrap">
                <Button endIcon={<EastIcon />}>Reveal</Button>
              </div>
            </Card>
          </Grid>

          <Grid xs={12} md={2} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Typography className="highlightGreen">
                You are getting efficient
              </Typography>
              <Typography className="highlightTitle">Auto Solved</Typography>
              <Divider />
              <div className="innerWrap">
                <div className="highlightNum">10</div>
                <div className="highlightText">
                  Rewards the knowledge creators to make CSAT top notch.
                </div>
              </div>
              <Divider />
              <div className="buttonWrap">
                <Button endIcon={<EastIcon />}>Reveal</Button>
              </div>
            </Card>
          </Grid>

          <Grid xs={12} md={2} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Typography className="highlightRed">
                You are getting efficient
              </Typography>
              <Typography className="highlightTitle">Auto Solved</Typography>
              <Divider />
              <div className="innerWrap">
                <div className="highlightNum">10</div>
                <div className="highlightText">
                  Rewards the knowledge creators to make CSAT top notch.
                </div>
              </div>
              <Divider />
              <div className="buttonWrap">
                <Button endIcon={<EastIcon />}>Reveal</Button>
              </div>
            </Card>
          </Grid>

          <Grid xs={12} md={2} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Typography className="highlightGreen">
                You are getting efficient
              </Typography>
              <Typography className="highlightTitle">Auto Solved</Typography>
              <Divider />
              <div className="innerWrap">
                <div className="highlightNum">10</div>
                <div className="highlightText">
                  Rewards the knowledge creators to make CSAT top notch.
                </div>
              </div>
              <Divider />
              <div className="buttonWrap">
                <Button endIcon={<EastIcon />}>Reveal</Button>
              </div>
            </Card>
          </Grid>

          <Grid xs={12} md={2} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Typography className="highlightGreen">
                You are getting efficient
              </Typography>
              <Typography className="highlightTitle">Auto Solved</Typography>
              <Divider />
              <div className="innerWrap">
                <div className="highlightNum">10</div>
                <div className="highlightText">
                  Rewards the knowledge creators to make CSAT top notch.
                </div>
              </div>
              <Divider />
              <div className="buttonWrap">
                <Button endIcon={<EastIcon />}>Reveal</Button>
              </div>
            </Card>
          </Grid>

          <Grid xs={12} md={6} lg={6}>
            <Card className="commonCard chartCard">
              <div className="chartTitle">
                <Typography>Savings</Typography>
              </div>

              <Linecharts />
            </Card>
          </Grid>
          {usagestats_details && (
            <Grid xs={12} md={6} lg={6}>
              <Card className="commonCard chartCard">
                <div className="chartTitle">
                  <Typography>Interactions overtime</Typography>
                </div>

                <Barcharts
                  value={usagestats_details["Interactions Over Time"]}
                />
              </Card>
            </Grid>
          )}

          <Grid xs={12} md={6} lg={6}>
            <Card className="commonCard chartCard">
              <div className="chartTitle">
                <Typography>Customer Satisfaction Score</Typography>
              </div>

              {/* <TabMenu /> */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6} className="chartLeftwrap">
                  <DonutCharts />
                </Grid>
                <Grid item xs={12} md={6} sm={6} className="chartRightwrap">
                  <div className="chartrightInner">
                    <JustIcon />
                    <Typography className="desc">worst - 38</Typography>
                    <DownIcon />
                    <Typography className="percentage redTxt">12%</Typography>
                  </div>
                  <div className="chartrightInner">
                    <JustIcon />
                    <Typography className="desc">Okay - 76</Typography>
                    <UpIcon />
                    <Typography className="percentage greenTxt">12%</Typography>
                  </div>
                  <div className="chartrightInner">
                    <JustIcon />
                    <Typography className="desc">Awesome - 186</Typography>
                    <UpIcon />
                    <Typography className="percentage greenTxt">12%</Typography>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {customer_effort_details && (
            <Grid xs={12} md={6} lg={6}>
              <Card className="commonCard chartCard">
                <div className="chartTitle">
                  <Typography>Customer Effort Score</Typography>
                </div>
                {/* <TabMenu /> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6} className="chartLeftwrap">
                    <SemiPie
                      value={customer_effort_details["Customer Effort Score"]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className="chartRightwrap">
                    <div className="chartrightInner">
                      <JustIcon />
                      <Typography className="desc smallTxt">
                        Low effort (0- 2) 30%
                      </Typography>
                      <DownIcon />
                      <Typography className="percentage redTxt">12%</Typography>
                    </div>
                    <div className="chartrightInner">
                      <JustIcon />
                      <Typography className="desc smallTxt">
                        Medium effort(3- 4) 40%
                      </Typography>
                      <UpIcon />
                      <Typography className="percentage greenTxt">
                        12%
                      </Typography>
                    </div>
                    <div className="chartrightInner">
                      <JustIcon />
                      <Typography className="desc smallTxt">
                        High effort(5- 6) 40%
                      </Typography>
                      <UpIcon />
                      <Typography className="percentage greenTxt">
                        12%
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default Leaderboardview;
