import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";

import Typography from "@mui/material/Typography";
import "./aibotView.scss";
import {
  Button,
  Card,
  TextField,
  Grid,
  IconButton,
  Snackbar,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  debounce,
  Switch,
  tooltip,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  deleteChannelMappingFromTable,
  getAllBots,
  getBotVersions,
  getChannelByName,
  getChannelMappingTable,
  refreshApiKeyForChannel,
  updateBotVersion,
  updateChannelMappingStatus,
  updateSelectedVersion,
} from "../../../redux/slices/supportChannels/supportChannelsSlice";
import { useSelector } from "react-redux";
import SearchNormalIcon from "../../../assets/SearchNormalIcon";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  updateAibotPagination,
  updateIsDelete,
} from "../../../redux/slices/dataTableSlice";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import moment from "moment";
import ResizableTable from "../../../components/dataTable/resizableTable";
import UrlIcon from "../../../assets/UrlIcon";
import ImplementationGuideIcon from "../../../assets/ImplementationGuideIcon";
function AibotTable({ searchProcess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { aibot_table } = useSelector((state) => state.supportChannelReducer);

  const columns = aibot_table?.columns;
  const display_map = aibot_table?.display_map;
  const mappings = aibot_table?.data;
  const { aibot_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  useEffect(() => {
    let by_filters_and_pagination = {
      search: searchProcess ? searchProcess : null,
      page_no: parseInt(aibot_paginationDetails.page) + 1,
      page_size: aibot_paginationDetails.pageSize,
      channel_name: "AI Bot",
    };
    dispatch(getChannelMappingTable(by_filters_and_pagination));
  }, [aibot_paginationDetails, searchProcess]);

  const cols = useMemo(() =>
    columns
      ? columns?.map((column) => {
          if (column === "is_active") {
            return {
              accessorKey: column,
              header: display_map[column],
              Cell: ({ cell }) => {
                const [is_active, setIsActive] = useState(cell.getValue());
                const channel_mapping_uuid = cell.row.original.id;

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Switch
                        size="small"
                        checked={is_active}
                        onChange={() => {
                          let data = {
                            channel_mapping_uuid: channel_mapping_uuid,
                            is_active: !is_active,
                          };
                          setIsActive(!is_active);
                          dispatch(updateChannelMappingStatus(data));
                        }}
                      />
                    </div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {is_active ? "Active" : "Inactive"}
                    </div>
                  </div>
                );
              },
            };
          }
          return {
            accessorKey: column,
            header: display_map[column],
            Cell: ({ cell }) => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {cell.getValue()}
              </div>
            ),
          };
        })
      : []
  );

  const rows = mappings
    ? mappings?.map((dataItem, index) => {
        const row = { id: dataItem.uuid, mapping_uuid: dataItem.mapping_uuid };

        columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateAibotPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {
    {
    }
  };

  return (
    <>
      <>
        <div
          className="listmappingWrapper"
          style={{ overflow: "scroll", height: "calc(100vh - 220px)" }}
        >
          <Grid className="listMappingWrap">
            <ResizableTable
              data={rows}
              columns={cols}
              paginationDetails={aibot_paginationDetails}
              onTablePaginationChange={handlePaginationModelChange}
              onTableRowClick={handleRowClick}
              rowCount={aibot_table?.total || 0}
            />
          </Grid>
        </div>
      </>
    </>
  );
}

function AiBotView() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = theme.palette;
  const [searchProcessChange, setSearchProcessChange] = useState();
  const [searchProcess, setSearchProcess] = useState();
  const [message, setMessage] = useState();


  const [open, setOpen] = useState(false);

  const { channel_details, aibot_table ,bot_versions,selected_version} = useSelector(
    (state) => state.supportChannelReducer
  );
  const { isDeleteVisible, selectionModel } = useSelector(
    (state) => state.dataTableReducer
  );
  const { aibot_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );
  const selectedKeys = Object.keys(selectionModel);
  const mappedData = aibot_table?.data
    ?.filter((item) => selectedKeys?.includes(item?.uuid))
    .map((item) => item.mapping_uuid);

  const [openSnack, setOpenSnack] = useState(false);
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  const handleSearchProcessChange = (event) => {
    const value = event.target.value;
    setSearchProcessChange(value);
    setSearchProcess(value);
  };

  const debounceOnChange = debounce(handleSearchProcessChange, 1000);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchProcess(searchProcessChange);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteMapping = () => {
    setMessage("Mappings deleted successfully.");

    let data = {
      deleteData: {
        filter: {
          channel_uuids: [channel_details.uuid],
          mapping_uuids: mappedData,
        },
      },
      search: searchProcess ? searchProcess : null,
      page_no: parseInt(aibot_paginationDetails.page) + 1,
      page_size: aibot_paginationDetails.pageSize,
      channel_name: "AI Bot",
    };
    dispatch(updateIsDelete(false));
    setOpen(false);
    dispatch(deleteChannelMappingFromTable(data));
    setOpenSnack(true);
  };

  useEffect(() => {
    dispatch(getChannelByName("AI Bot"));
    dispatch(getBotVersions())
    dispatch(getAllBots())
  }, []);

  return (
    <div
      className="aibotWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        paddingTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <div className="titleFlex">
              <Typography variant="h4" sx={{ mb: 2 }}>
                AI Bot
              </Typography>
              {channel_details && channel_details.is_active ? (
                <span className="statusBtn active">Active</span>
              ) : (
                <span className="statusBtn inactive">Inactive</span>
              )}
            </div>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Integrate AI bot and organize data to get predictions with
              Ascendo.ai.
            </Typography>
          </div>
         <div style={{display:'flex'}}>
         <Grid width="130px" display={"flex"} alignItems={"center"}>
            <Autocomplete
              disablePortal
              fullWidth
              disableClearable
              
              options={bot_versions}
              value={selected_version}
              getOptionLabel={(option)=>option.key}
              isOptionEqualToValue={(option,value)=>option.key===value.key}
              onChange={(event,newValue)=>{dispatch(updateSelectedVersion(newValue));dispatch(updateBotVersion(newValue.key));setMessage("Version Updated Successfully!");setOpenSnack(true)}}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Version" />
              )}
            />
          </Grid>
          <div className="rightsideWrap">
         
            <Tooltip title="Click to copy the AI bot url with API key!">
              <IconButton
                onClick={() => {
                  setMessage("Copied the AI Bot URL to clipboard.");

                  navigator.clipboard.writeText(
                    `${
                      JSON.parse(localStorage.getItem("ud")).customer.bot_url
                    }/?api_key=${channel_details?.api_key}`
                  );
                  setOpenSnack(true);
                }}
              >
                <UrlIcon />
              </IconButton>
            </Tooltip>
            <Grid className="implementBtn">
              <Tooltip title="You can find the implementaion guide for AI bot here!">
                <Button
                  onClick={() =>
                    window.open(
                      "https://ascendo.atlassian.net/wiki/spaces/AKB/pages/147324953/AI+Bot+Integration+Guide"
                    )
                  }
                >
                  <ImplementationGuideIcon /> Implementation Guide{" "}
                </Button>
              </Tooltip>
            </Grid>
            <Grid className="regenerateBtn">
              <Tooltip title="Generate an API key for the AI bot here!">
                <Button
                  className="askBtn"
                  onClick={() =>
                    dispatch(refreshApiKeyForChannel(channel_details?.uuid))
                  }
                >
                  {" "}
                  Regenerate API key{" "}
                </Button>
              </Tooltip>
            </Grid>
          </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={3500}
          message={message}
          action={action}
          onClose={handleSnackBarClose}
        />

        <Grid className="cardGrid">
          <Card className="commonCard">
            <Grid>
              <Grid
                display="flex"
                marginLeft="0px"
                marginTop="0px"
                paddingTop="12px"
                justifyContent="space-between"
              >
                <Grid style={{ flex: 1 }} marginLeft="12px" marginRight="12px">
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchNormalIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search for mapping by name"
                    size="small"
                    fullWidth
                    onChange={debounceOnChange}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                {isDeleteVisible && (
                  <Grid item style={{ width: "184px", marginRight: "16px" }}>
                    <Button
                      variant="ascendo"
                      startIcon={
                        <DeleteOutlineIcon
                          sx={{ color: colors.ascendo.darkblue }}
                        />
                      }
                      onClick={handleClickOpen}
                      fullWidth
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the selected mappings?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ pr: 3, pb: 2.5 }}>
                    <Button
                      variant="ascendo"
                      width="75px"
                      sx={{
                        color: "white",
                        backgroundColor: "red",
                        "&:hover": {
                          color: "rgba(7, 25, 66)",
                        },
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="ascendo"
                      width="75px"
                      onClick={handleDeleteMapping}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid>
                <AibotTable searchProcess={searchProcess} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </div>
  );
}

export default AiBotView;
