import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import ArrowLeftIcon from "../../assets/ArrowLeftIcon";
import ArrowRightIcon from "../../assets/ArrowRightIcon";

import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomStepper from "../../components/stepper/customStepper";
import { getmappingSummary } from "../../redux/slices/mapping/mappingsummarySlice";
import { requestData } from "../../redux/slices/mapping/listMappingSlice";
import { getAllUploadedFiles } from "../../redux/slices/mapping/mappingStep2Slice";
import ResizableTable from "../../components/dataTable/simpleTable";
import moment from "moment";
import { updateFilesPagination } from "../../redux/slices/dataTableSlice";
function FilesTable() {
  const dispatch = useDispatch();

  const { filesTableDetails } = useSelector(
    (state) => state.mappingStep2Reducer
  );
  const { files_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );


  

  const cols = useMemo(() =>
    filesTableDetails?.columns
      ? filesTableDetails?.columns?.filter((col)=>col!=="action")?.map((column) => {
          if (column === "created_at") {
            return {
              accessorKey: column,
              header: filesTableDetails?.display_map[column],
              Cell: ({ cell }) => {
                const timestamp = cell.getValue();
                const formattedDate =
                  moment(timestamp).format("MMM DD YYYY HH:mm");

                return (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cell.getValue()?formattedDate:""}
                  </div>
                );
              },
            };
          }
          return {
      
            accessorKey: column,
            header: filesTableDetails?.display_map[column],
            Cell: ({ cell }) => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {cell.getValue()}
              </div>
            ),
         
          };
          
        })
      : []
  );

  const rows = filesTableDetails?.data
    ? filesTableDetails?.data?.map((dataItem) => {
        const row = { id: dataItem.uuid };

        filesTableDetails?.columns?.filter((col)=>col!=="action")?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateFilesPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {
   {}
  };

  return (
    <>
      <>
        <div
          className="listmappingWrapper"
          style={{ overflow: "scroll", height: "calc(100vh - 170px)" }}
        >
          <Grid className="listMappingWrap">
            <ResizableTable
              data={rows}
              columns={cols}
              paginationDetails={files_paginationDetails}
              onTablePaginationChange={handlePaginationModelChange}
              onTableRowClick={handleRowClick}
              rowCount={filesTableDetails?.total??0}
            />
          </Grid>
        </div>
      </>
    </>
  );
}
function MapSummary() {
  const navigate = useNavigate();
  const [customer_uuid, setCustomer_uuid] = useState(
    localStorage.getItem("ci")
  );
  const params = useParams();
  const mapping_uuid = params.mapping_uuid;
  const { mapping_details, field_mapping_details } = useSelector(
    (state) => state.getmappingsummaryReducer
  );
  const { files_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );
  const handleClose = () => {
    navigate(`/${customer_uuid}/mapping`);
  };
  const handlePrevStep = () => {
   

    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/view-mapping`);
  };
  const steps =  [
    "Select Data Source",
    mapping_details?.sub_datasource?.datasource?.name==="pdf"?"Files Uploaded":"Field Mapping",
    "Obfuscation",
    "View Mapping",
    "Summary",
  ]

  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();

  const handleFinish = () => {
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/all-requests`);
   
  };
  useEffect(() => {
    if(mapping_details?.sub_datasource?.datasource?.name==="pdf"){
    let by_filters_and_pagination = {
      mapping_uuid:params.mapping_uuid,
      search:null,
      page_no: parseInt(files_paginationDetails.page) + 1,
      page_size: files_paginationDetails.pageSize,
    };
    dispatch(getAllUploadedFiles(by_filters_and_pagination));
  }
  }, [
  
    files_paginationDetails,mapping_details
  ]);



  useEffect(() => {
    let by_mapping_uuid = {
      customer_uuid: customer_uuid,
      mapping_uuid: mapping_uuid,
    };
    dispatch(getmappingSummary(by_mapping_uuid));
  }, []);

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        zIndex: 9999,
        position: "absolute",
        top: 0,
        left: 0,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {/* Sidebar */}
      <Grid
        width="232px"
        sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
      ></Grid>

      {/* Main Content */}
      <Grid
        width="calc(100% - 232px)"
        backgroundColor="white"
        position="relative"
        sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
      >
        <Grid display="flex" justifyContent="space-between" marginRight="40px">
          <Typography
            margin="40px 0px 0px 46px"
            color={colors.ascendo.darkblue}
            sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
          >
            Create Mapping
          </Typography>
          <Grid marginTop="20px">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Typography
          margin="8px 0px 20px 46px"
          color={colors.ascendo.darkblue}
          sx={{ fontSize: "14px", lineHeight: "17px" }}
        >
         Create a mapping that can help discover issue trends.
        </Typography>
        <Grid variant="stepperFixedtop">
          <Divider variant="middle" />
          <CustomStepper steps={steps} activeStep={4} />
        </Grid>

        <Grid variant="stepperScroller">
          <div>
            <Grid margin="26px 0px 29px 46px">
              <Typography
                fontWeight="500"
                lineHeight="17px"
                color={colors.ascendo.darkblue}
              >
                Primary Details
              </Typography>
              <Grid container marginTop="20px">
                <Grid item md={2.4}>
                  <Grid>
                    <Typography
                      fontSize="14px"
                      lineHeight="17px"
                      color={colors.grey.border}
                    >
                      Mapping Name
                    </Typography>

                    <Typography
                      marginTop="6px"
                      fontSize="16px"
                      lineHeight="19px"
                      color={colors.ascendo.blue}
                    >
                      {mapping_details?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={2.4}>
                  <Grid>
                    <Typography
                      fontSize="14px"
                      lineHeight="17px"
                      color={colors.grey.border}
                    >
                      Business Unit
                    </Typography>

                    <Typography
                      marginTop="6px"
                      fontSize="16px"
                      lineHeight="19px"
                      color={colors.ascendo.blue}
                    >
                      {mapping_details?.business_unit.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={2.4}>
                  <Grid>
                    <Typography
                      fontSize="14px"
                      lineHeight="17px"
                      color={colors.grey.border}
                    >
                      Data Source
                    </Typography>

                    <Typography
                      marginTop="6px"
                      fontSize="16px"
                      lineHeight="19px"
                      color={colors.ascendo.blue}
                    >
                      {mapping_details?.sub_datasource.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            {mapping_details?.sub_datasource?.datasource?.name==="pdf" ? <Grid>
<FilesTable />
            </Grid>:
            <Grid marginTop="26px" marginX="46px" height="412px">
              <Typography
                fontSize="16px"
                fontWeight="500"
                color={colors.ascendo.darkblue}
              >
                Mapped Fields
              </Typography>
              <Grid marginTop="12px" container spacing={2}>
                {field_mapping_details?.map((field_mapping, index) => (
                  <Grid item md={4}>
                    <Box
                      component="div"
                      border="1px solid"
                      borderRadius="6px"
                      borderColor={colors.grey.light}
                    >
                      <Grid container alignItems="center" height="80px">
                        <Grid
                          item
                          md={3}
                          justifyContent="center"
                          display="flex"
                        >
                          <Typography
                            fontSize="16px"
                            fontWeight="500"
                            color={colors.ascendo.darkblue}
                          >
                            {index + 1}
                          </Typography>
                        </Grid>
                        <Grid item md={9}>
                          <Typography
                            fontSize="12px"
                            fontWeight="400"
                            color={colors.grey.border}
                          >
                            {field_mapping.ascendo_field}
                          </Typography>
                          <Typography
                            fontSize="14px"
                            fontWeight="400"
                            color={colors.ascendo.darkblue}
                          >
                            {field_mapping.source_field?.field}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
}
          </div>
        </Grid>

        <Divider variant="middle" />

        {/* Continue Button */}
        <Grid variant="stepperFixedbottombtn">
          <Grid
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
            <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                startIcon={<ArrowLeftIcon />}
                onClick={handlePrevStep}
              >
                Back
              </Button>
            </Grid>

            <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                onClick={handleFinish}
                sx={{
                  backgroundColor: colors.ascendo.yellow,
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                endIcon={<ArrowRightIcon />}
              >
                {mapping_details?.sub_datasource.name==='Tickets'? "Continue" : "Request"}
                
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MapSummary;
