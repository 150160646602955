import React from "react";

function DotIcon() {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="#071942" fillOpacity="0.32" />
    </svg>
  );
}

export default DotIcon;
