import React from 'react'

function SelectChannelIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4 22.75H7.6C3.21 22.75 1.25 20.79 1.25 16.4V12.6C1.25 8.21 3.21 6.25 7.6 6.25H10.6C11.01 6.25 11.35 6.59 11.35 7C11.35 7.41 11.01 7.75 10.6 7.75H7.6C4.02 7.75 2.75 9.02 2.75 12.6V16.4C2.75 19.98 4.02 21.25 7.6 21.25H11.4C14.98 21.25 16.25 19.98 16.25 16.4V13.4C16.25 12.99 16.59 12.65 17 12.65C17.41 12.65 17.75 12.99 17.75 13.4V16.4C17.75 20.79 15.79 22.75 11.4 22.75Z" fill="#B0B5C3"/>
    <path d="M16.9996 14.15H13.7996C10.9896 14.15 9.84961 13.01 9.84961 10.2V6.99999C9.84961 6.69999 10.0296 6.41999 10.3096 6.30999C10.5896 6.18999 10.9096 6.25999 11.1296 6.46999L17.5296 12.87C17.7396 13.08 17.8096 13.41 17.6896 13.69C17.5796 13.97 17.2996 14.15 16.9996 14.15ZM11.3496 8.80999V10.2C11.3496 12.19 11.8096 12.65 13.7996 12.65H15.1896L11.3496 8.80999Z" fill="#B0B5C3"/>
    <path d="M15.5996 2.75H11.5996C11.1896 2.75 10.8496 2.41 10.8496 2C10.8496 1.59 11.1896 1.25 11.5996 1.25H15.5996C16.0096 1.25 16.3496 1.59 16.3496 2C16.3496 2.41 16.0096 2.75 15.5996 2.75Z" fill="#B0B5C3"/>
    <path d="M7 5.75C6.59 5.75 6.25 5.41 6.25 5C6.25 2.93 7.93 1.25 10 1.25H12.62C13.03 1.25 13.37 1.59 13.37 2C13.37 2.41 13.03 2.75 12.62 2.75H10C8.76 2.75 7.75 3.76 7.75 5C7.75 5.41 7.41 5.75 7 5.75Z" fill="#B0B5C3"/>
    <path d="M19.1904 17.75C18.7804 17.75 18.4404 17.41 18.4404 17C18.4404 16.59 18.7804 16.25 19.1904 16.25C20.3304 16.25 21.2504 15.32 21.2504 14.19V8C21.2504 7.59 21.5904 7.25 22.0004 7.25C22.4104 7.25 22.7504 7.59 22.7504 8V14.19C22.7504 16.15 21.1504 17.75 19.1904 17.75Z" fill="#B0B5C3"/>
    <path d="M22 8.74999H19C16.34 8.74999 15.25 7.65999 15.25 4.99999V1.99999C15.25 1.69999 15.43 1.41999 15.71 1.30999C15.99 1.18999 16.31 1.25999 16.53 1.46999L22.53 7.46999C22.74 7.67999 22.81 8.00999 22.69 8.28999C22.58 8.56999 22.3 8.74999 22 8.74999ZM16.75 3.80999V4.99999C16.75 6.82999 17.17 7.24999 19 7.24999H20.19L16.75 3.80999Z" fill="#B0B5C3"/>
    </svg>
  )
}

export default SelectChannelIcon