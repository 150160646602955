import React, { useEffect } from 'react';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Card, Grid } from "@mui/material";
import './homeview.scss';
import homeIcon from '../homeview/home-page.svg';

function HomeView() {
  useEffect(()=>{
       


if (!(localStorage.getItem("ci"))) 
window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.origin}`;

   
  },[])
  return (
    <div className='homeWrap'
      style={{ overflow: "scroll", height: "calc(100vh - 70px)", marginTop: '5px' }}>
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Home
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Welcome to Ascendo! Support beyond Expectations.
            </Typography>
          </div>
        </div>
        <Grid container spacing={2}>

          <Grid item xs={12} md={12} sm={12} className="cardGrid">
            <Card className="commonCard">
              <Grid item xs={12} md={6} sm={6} className='flexCenter'>
                <div>
                <Typography className='titleblack'>
                  Unlock Modern <span className='titleblue'>Support</span><br /> Experience
                </Typography>
                <Typography className='smallText'>
                  Get ready to resolve questions and issues using Ascendo's predictive algorithms. Tackle problems proactively by addressing them even before they emerge.
                </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} sm={6} className='flexCenter imageWrap'>
                <img src={homeIcon} />
              </Grid>
            </Card>

          </Grid>

        </Grid>
      </Container>
    </div>
  )
}

export default HomeView