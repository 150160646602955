import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Radio,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ArrowRightIcon from "../../assets/ArrowRightIcon";
import Accordion1 from "../../components/accordion/accordion1";
import DataSelectionCard from "../../components/dataSelectionCard/dataSelectionCard";
import CustomStepper from "../../components/stepper/customStepper";
import { resetAccordion } from "../../redux/slices/accordionSlice";
import { getbusinessunitsbyUser } from "../../redux/slices/businessunitsSlice";
import { getAllDataSource } from "../../redux/slices/datasourceSlice";
import {
  createMapping,
  getAllActiveModules,
  getMapping,
  resetStatus,
  updateMapping,
  updateMappingName,
  updateSelectedBuValue,
  updateSelectedDsValue
} from "../../redux/slices/mapping/mappingStep1Slice";

function SelectDataSource() {
  const steps = ["Select Data Source", "Field Mapping", "Obfuscation", "View Mapping", "Summary"];
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const user = JSON.parse(localStorage.getItem("ud"));

  //  State Value
  const [customer_uuid] = useState(localStorage.getItem("ci"));
  const [mappingName, setMappingName] = useState("");
  const [open, setOpen] = React.useState(false);

  // Redux
  const { bu_details } = useSelector((state) => state.businessunitsReducer);
  const { ds_details } = useSelector((state) => state.datasourceReducer);

  const {expanded0, expanded1, expanded2 } = useSelector(
    (state) => state.accordionReducer
  );
  const {
    selected_bu,
    selected_ds,
    mapping_name,
    get_mapping_status,
    mapping_uuid,
    create_mapping_status,
    status_code,
    selected_module,
    modules
  } = useSelector((state) => state.mappingStep1Reducer);

  // Functions

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    dispatch(resetStatus());
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  const handleTextFieldChange = (event) => {
    const value = event.target.value;
    setMappingName(value);
    dispatch(updateMappingName(value));
  };

  const handleClose = () => {
    dispatch(resetAccordion());
    navigate(`/${customer_uuid}/mapping`);
  };
  const handleNextStep = () => {
    let mapping_data = {
      mapping_uuid: params.mapping_uuid,
      sub_datasource_uuid: selected_ds?.uuid,
      business_unit_uuid: selected_bu.uuid,
      name: mapping_name,
      module_uuid:selected_module.uuid

    };
    if (params.mapping_uuid) 
      dispatch(updateMapping(mapping_data));
      


     else dispatch(createMapping(mapping_data));
  };

  const handleBuChange = (value) => {
    const selectedBu = bu_details.find((bu) => bu.uuid === value);

    dispatch(updateSelectedBuValue(selectedBu));
  };

  const handleDsChange = (value) => {
    const selectedDs = ds_details.find((ds) => ds?.uuid === value);
    dispatch(updateSelectedDsValue(selectedDs));
  };

  useEffect(() => {
    if (create_mapping_status) {
        if(selected_ds?.datasource?.name!=="pdf")
          navigate(`/${customer_uuid}/mapping/${params?.mapping_uuid??mapping_uuid}/field-mapping`);
        else
        navigate(`/${customer_uuid}/mapping/${params?.mapping_uuid??mapping_uuid}/files-uploaded`);
    
    }
  }, [create_mapping_status]);

  useEffect(() => {
    setOpen(status_code);
  }, [status_code]);
  useEffect(() => {
    let get_bu_by_user = {
      customer_uuid: customer_uuid,
      user_uuid: user.uuid,
    };
    dispatch(getbusinessunitsbyUser(get_bu_by_user));
    dispatch(getAllDataSource(selected_module?[selected_module?.uuid]:[]));


  }, [selected_module?.uuid]);

  useEffect(() => {
    if (params.mapping_uuid) {
      dispatch(getMapping(params.mapping_uuid));
    }
    dispatch(getAllActiveModules())
  }, []);
  
  
  return (
    <Grid
      container
      sx={{
        height: "100vh",
        zIndex: 1300,
        position: "absolute",
        top: 0,
        left: 0,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {/* Sidebar */}
      <Grid
        width="232px"
        sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
      ></Grid>

      {/* Main Content */}
      <Grid
        width="calc(100% - 232px)"
        backgroundColor="white"
        position="relative"
        sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
      >
        <Grid display="flex" variant="stepperheaderFixedtop" marginRight="40px">
          <Grid width="100%">
            <Typography
              margin="40px 0px 0px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Create Mapping
            </Typography>

            <Typography
              margin="8px 0px 20px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontSize: "14px", lineHeight: "17px" }}
            >
              Create a mapping that can help discover issue trends.
            </Typography>
          </Grid>
          <Typography marginTop="20px">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
        </Grid>

        <Grid variant="stepperFixedtop">
          <Divider variant="middle" />
          <CustomStepper steps={steps} activeStep={0} />
        </Grid>

        <Grid variant="stepperScroller">
          <div>
            <Divider variant="middle" />
            <Typography
              margin="26px 0px 29px 46px"
              fontWeight="500"
              lineHeight="17px"
              color={colors.ascendo.darkblue}
            >
              Create Mapping
            </Typography>
            <Grid marginLeft="46px" marginBottom="26px" width="460px">
              {(!params.mapping_uuid || get_mapping_status) && (
                <TextField
                  size="small"
                  placeholder="Enter mapping name here..."
                  label="Mapping Name"
                  fullWidth
                  defaultValue={mapping_name}
                  onChange={handleTextFieldChange}
                  required
                ></TextField>
              )}
            </Grid>
            <Divider variant="middle" />
            <Grid margin="20px  46px 0px 46px">
              <Accordion1
                accKey="0"
                accordionSummary={
                  <Grid width="100%" display="flex">
                    <Typography lineHeight="19px" fontWeight="500">
                      Select Module
                    </Typography>
                    {selected_module && !expanded0 && (
                      <Typography
                        color={colors.ascendo.blue}
                        lineHeight="19px"
                        fontWeight="500"
                        marginLeft="12px" 
                      >
                        {selected_module.name}
                      </Typography>
                    )}
                  </Grid>
                }
                accordionDetails={
                  <Grid>
                    <Divider sx={{ marginTop: "18px" }} />
                    <Grid marginTop="20px" container spacing={3}>
                      {modules?.map((mod) => (
                        <DataSelectionCard
                          key={mod.uuid}
                          text={mod.name}
                          modValue={mod.uuid}
                          type={"module"}
                          icon={
                            <Radio
                              required
                              size="small"
                              onChange={()=>{
                              }}
                              checked={selected_module?.uuid === mod.uuid}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  </Grid>
                }
              />
              <Divider sx={{ marginTop: "20px" }} />
            </Grid>
            <Grid margin="20px  46px 0px 46px">
              <Accordion1
                accKey="1"
                accordionSummary={
                  <Grid width="100%" display="flex">
                    <Typography lineHeight="19px" fontWeight="500">
                      Select Business Unit
                    </Typography>
                    {selected_bu && !expanded1 && (
                      <Typography
                        color={colors.ascendo.blue}
                        lineHeight="19px"
                        fontWeight="500"
                        marginLeft="12px" 
                      >
                        {selected_bu.name}
                      </Typography>
                    )}
                  </Grid>
                }
                accordionDetails={
                  <Grid>
                    <Divider sx={{ marginTop: "18px" }} />
                    <Grid marginTop="20px" container spacing={3}>
                      {bu_details?.map((bu) => (
                        <DataSelectionCard
                          key={bu.uuid}
                          buValue={bu.uuid}
                          buType="mapping"
                          text={bu.name}
                          logo_uri={bu.logo_uri}
                          icon={
                            <Radio
                              required
                              size="small"
                              checked={selected_bu?.uuid === bu.uuid}
                              onChange={() => handleBuChange(bu.uuid)}
                              value={bu.uuid}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  </Grid>
                }
              />
              <Divider sx={{ marginTop: "20px" }} />
            </Grid>
            <Grid margin="20px  46px 20px 46px">
              <Accordion1
                accKey="2"
                accordionSummary={
                  <Grid width="100%" display="flex">
                    <Typography lineHeight="19px" fontWeight="500">
                      Select Data Source
                    </Typography>
                    {selected_ds && !expanded2 && (
                      <Typography
                        color={colors.ascendo.blue}
                        lineHeight="19px"
                        fontWeight="500"
                        marginLeft="12px"
                      >
                        {selected_ds.name}
                      </Typography>
                    )}
                  </Grid>
                }
                accordionDetails={
                  <Grid>
                    <Divider sx={{ marginTop: "18px" }} />
                    <Grid
                      marginTop="-4px"
                      marginBottom="20px"
                      container
                      columnSpacing={3}
                      rowSpacing={3}
                    >
                      {ds_details?.map((ds) => (
                        <DataSelectionCard
                          key={ds?.uuid}
                          dsType="mapping"
                          dsValue={ds?.uuid}
                          text={ds.name}
                          logo_uri={ds.logo_uri}
                          icon={
                            <Radio
                              required
                              size="small"
                              checked={selected_ds?.uuid === ds?.uuid}
                              onChange={() => handleDsChange(ds?.uuid)}
                              value={ds?.uuid}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </div>
        </Grid>

        <Divider variant="middle" />

        {/* Continue Button */}
        <Grid
          variant="stepperFixedbottombtn"
          display="flex"
          justifyContent="flex-end"
          marginBottom="32px"
          marginRight="40px"
          gap={2}
        >
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            message={status_code}
            action={action}
            onClose={handleSnackBarClose}
          />

          <Grid item width="120px" margin="26px 0px 0px 0px">
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.ascendo.yellow,
                color: colors.ascendo.darkblue,
                textTransform: "none",
              }}
              endIcon={
                <ArrowRightIcon
                  opacity={selected_module&&mapping_name && selected_bu && selected_ds ? 1 : 0.2}
                />
              }
              onClick={handleNextStep}
              disabled={!(selected_module&&mapping_name && selected_bu && selected_ds)}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SelectDataSource;
