import React from 'react'

function AiresolveIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.0607 0.84375H13.6191C16.9023 0.84375 18.8595 2.80101 18.8505 6.08414V11.6215C18.2125 11.2246 17.4593 10.9953 16.6526 10.9953C14.3514 10.9953 12.4859 12.8608 12.4859 15.162C12.4859 16.7345 13.3571 18.1036 14.6432 18.813C14.3154 18.8594 13.9707 18.883 13.6101 18.883H6.0607C2.77757 18.883 0.820312 16.9257 0.820312 13.6336V6.08414C0.820312 2.80101 2.77757 0.84375 6.0607 0.84375ZM13.6101 10.0708C13.9619 10.0708 14.2415 9.7912 14.2415 9.43944H14.2325V7.76179C14.2325 7.68061 14.2234 7.59944 14.1874 7.51826C14.1242 7.36493 13.998 7.23865 13.8446 7.17552C13.7634 7.14846 13.6823 7.13042 13.6011 7.13042H11.9234C11.5717 7.13042 11.2921 7.41003 11.2921 7.76179C11.2921 8.11356 11.5717 8.39316 11.9234 8.39316H12.0858L10.1827 10.2963L9.26266 8.92532C9.16345 8.76297 8.99208 8.66375 8.80267 8.64571C8.61325 8.62767 8.43286 8.69081 8.29757 8.82611L5.60972 11.5139C5.36619 11.7665 5.36619 12.1634 5.60972 12.4069C5.72698 12.5241 5.88933 12.5873 6.05168 12.5873C6.21404 12.5873 6.36737 12.5332 6.49365 12.4069L8.64031 10.2602L9.56031 11.6312C9.65953 11.7936 9.8309 11.8928 10.0203 11.9108C10.2097 11.9289 10.3901 11.8657 10.5254 11.7304L12.9787 9.27709V9.43944C12.9787 9.7912 13.2584 10.0708 13.6101 10.0708ZM9.84685 4.22559L10.2384 5.28365L11.2964 5.67517L10.2384 6.06668L9.84685 7.12475L9.45533 6.06668L8.39727 5.67517L9.45533 5.28365L9.84685 4.22559ZM5.96841 7.26377L5.7074 6.5584L5.44639 7.26377L4.74102 7.52479L5.44639 7.7858L5.7074 8.49117L5.96841 7.7858L6.67379 7.52479L5.96841 7.26377Z" fill="#071942" fill-opacity="0.8"/>
<path d="M19.4304 17.5482C19.3487 17.5482 19.267 17.5164 19.2079 17.4573L18.3634 16.6122C18.2408 16.4895 18.2408 16.2896 18.3634 16.1623C18.486 16.0396 18.6858 16.0396 18.8129 16.1623L19.6574 17.0075C19.78 17.1302 19.78 17.3301 19.6574 17.4573C19.5939 17.5164 19.5121 17.5482 19.4304 17.5482Z" fill="#071942" fill-opacity="0.8"/>
<path d="M18.222 16.021L18.2219 16.0209L18.2194 16.0236C18.0236 16.2268 18.0199 16.5514 18.222 16.7535L19.0665 17.5987C19.1649 17.6972 19.2984 17.7482 19.4304 17.7482C19.5637 17.7482 19.6938 17.6965 19.7936 17.6038L19.7938 17.604L19.8014 17.5961C19.9973 17.3928 20.0009 17.0683 19.7989 16.8661L18.9544 16.021L18.9544 16.0209L18.9518 16.0184C18.7486 15.8223 18.424 15.8187 18.222 16.021Z" stroke="#071942" stroke-opacity="0.8" stroke-width="0.4"/>
<path d="M16.6346 17.313C17.9261 17.313 18.9731 16.266 18.9731 14.9745C18.9731 13.6829 17.9261 12.6359 16.6346 12.6359C15.3431 12.6359 14.2961 13.6829 14.2961 14.9745C14.2961 16.266 15.3431 17.313 16.6346 17.313Z" stroke="#071942" stroke-opacity="0.8" stroke-width="1.4"/>
</svg>
  )
}

export default AiresolveIcon