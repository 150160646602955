import axios from "../../../utils/axios";

export const GetModuleByModuleName = async (module_name) => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY+
    `module/public/module_name/${module_name}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};


export const GetAllModulesByUserUUID= async (customer_uuid,user_uuid) => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY+
    `module/private/${customer_uuid}/user_uuid/${user_uuid}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};



export const GetAllActiveModules= async () => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY+
    `module/private/${localStorage.getItem("ci")}?is_active=true&is_dataintegration=true`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};



