

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetMapping ,GetFieldMapping} from '../../actions/mapping/mappingActions';


export const getmappingSummary = createAsyncThunk('getmapping',
    async (data, thunkAPI) => {

        const getmappingResponse = await GetMapping(data.mapping_uuid)
        const getfieldmappingResponse = await GetFieldMapping( data.mapping_uuid)
        const responses = { getmappingResponse, getfieldmappingResponse }
        return responses
    }
)

const MappingSummarySlice = createSlice({
    name: 'getmappingSummary',
    initialState: {
        mapping_details: null,
        field_mapping_details: null,


        status: false


    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getmappingSummary.pending, (state, action) => {
            state.status = false
        })
            .addCase(getmappingSummary.fulfilled, (state, action) => {
                if(action.payload?.getmappingResponse?.status===200 && action.payload?.getfieldmappingResponse?.status===200){
                state.mapping_details = action.payload.getmappingResponse.data
                state.field_mapping_details = action.payload.getfieldmappingResponse.data?.filter((item)=>item.ascendo_field!=="summary")
                state.status = true
                }
                else{
                    state.status=false
                }

            })
            .addCase(getmappingSummary.rejected, (state, action) => {
                state.status = false
            })
    },
})

export default MappingSummarySlice.reducer