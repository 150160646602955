import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

function Barcharts({ value }) {
  useEffect(() => {
    if (!value || !value.x_axis || !value.y_axis) {
      // Exit early if value or x_axis or y_axis is undefined
      return;
    }

    const chart = Highcharts.chart('barchart', {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
        align: 'left',
      },
      xAxis: {
        labels: {
          //step: 2,
          formatter: function() {
            var ret = this.value,
                len = ret.length;
            if( len > 5 ) {
              ret = ret.slice(0,5);
            }
            
            if( len > 15 ) {
              ret = ret.slice(0,15);
            }
            
            return ret;
          }
        },
        categories: value.x_axis.map(timestamp => new Date(timestamp).toDateString()),
        title: {
          text: value.x_title,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: value.y_title,
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: value.y_axis.map(dataSet => ({
        name: dataSet.name,
        type: dataSet.type,
        data: dataSet.data,
      })),
    });

    return () => {
      chart.destroy();
    };
  }, [value]); // Add value as a dependency to useEffect

  return <div id="barchart" style={{ height: "300px" }}></div>;
}

export default Barcharts;
