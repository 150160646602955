import axios from "../../../utils/axios";

export const ExportErrorFile = async (mapping_request_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `mapping_request/${localStorage.getItem("ci")}/${mapping_request_uuid}/error_file`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    
     
    
    }
  );
  return { data: response.data, status: response.status };
};

export const GetFilesByMappingRequest = async (mapping_request_uuid,page_no,page_size,search) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `mapping_request/${localStorage.getItem("ci")}/${mapping_request_uuid}/mapping_request__file_upload/table?page_no=${page_no}&page_size=${page_size}&search=${search}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    
     
    
    }
  );
  return { data: response.data, status: response.status };
};