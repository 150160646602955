import React from 'react'

function PreviewIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4999 0.941339L7.49992 0.941339C5.22594 0.941339 3.57935 1.42674 2.50317 2.50292C1.42698 3.57911 0.941584 5.22569 0.941584 7.49967L0.941584 12.4997C0.941584 14.7737 1.42698 16.4202 2.50317 17.4964C3.57935 18.5726 5.22594 19.058 7.49992 19.058L12.4999 19.058C14.7739 19.058 16.4205 18.5726 17.4967 17.4964C18.5729 16.4202 19.0583 14.7737 19.0583 12.4997L19.0583 7.49967C19.0583 5.22569 18.5729 3.57911 17.4967 2.50292C16.4205 1.42674 14.7739 0.941339 12.4999 0.941339ZM17.6083 12.4997C17.6083 14.4135 17.2668 15.681 16.474 16.4738C15.6812 17.2666 14.4137 17.608 12.4999 17.608L7.49992 17.608C5.58614 17.608 4.31864 17.2666 3.52584 16.4738C2.73304 15.681 2.39158 14.4135 2.39158 12.4997L2.39158 7.49967C2.39158 5.5859 2.73304 4.31839 3.52584 3.52559C4.31864 2.73279 5.58614 2.39134 7.49992 2.39134L12.4999 2.39134C14.4137 2.39134 15.6812 2.73279 16.474 3.52559C17.2668 4.31839 17.6083 5.5859 17.6083 7.49967L17.6083 12.4997Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    <path d="M12.225 1.66732C12.225 1.27042 11.8969 0.942316 11.5 0.942316C11.1031 0.942316 10.775 1.27042 10.775 1.66732L10.775 18.334C10.775 18.7309 11.1031 19.059 11.5 19.059C11.8969 19.059 12.225 18.7309 12.225 18.334L12.225 1.66732Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    <rect x="14" y="6" width="1.93333" height="1.66667" rx="0.8" fill="#071942"/>
    <rect x="14" y="9.33398" width="1.93333" height="1.66667" rx="0.8" fill="#071942"/>
    <rect x="14" y="12.668" width="1.93333" height="1.66667" rx="0.8" fill="#071942"/>
    </svg>
    
  )
}

export default PreviewIcon