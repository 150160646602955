import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Typography,
  useTheme,
  Snackbar,Tooltip
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect } from "react";
import ArrowRightIcon from "../../../assets/ArrowRightIcon";
import EditIcon from "../../../assets/EditIcon";
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading-animation.json";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ComboBox from "../../../components/autocomplete/comboBox";
import { updateTableRows } from "../../../redux/slices/dataTableSlice";
import DataTable from "../../../components/dataTable/dataTable";
import {
  updateCustomFields,
  deleteCustomFieldMapping,
  createcustomField,
  updateCustomFieldMapping,
  getFieldMapping,
  getMapping,
} from "../../../redux/slices/mapping/mappingStep2Slice";
import "./editFieldMapping.scss";
import {
  getMappingRequests,
  requestData,
  zendeskRequestData,
} from "../../../redux/slices/mapping/listMappingSlice";
import CompletedIcon from "../../../assets/tableIcons/CompletedIcon";
import CustomMultiBox from "../../../components/autocomplete/customMultiBox";
import DatePickerIcon from "../../../assets/DatePickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ArrowLeftIcon from "../../../assets/ArrowLeftIcon";


function EditFieldMapping() {
  const navigate = useNavigate();
  const customer_uuid = localStorage.getItem("ci");
  const params = useParams();
  const [newfield, setNewfield] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [customFieldName, setCustomFieldName] = useState("");

  const [openSnack,setOpenSnack] = useState(false)


  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  const {
    field_mapping_details,
    original_field_details,
    field_mapping_status,
    sub_fields,
    get_mapping,
  } = useSelector((state) => state.mappingStep2Reducer);
  const [tableVisiblity, setTableVisibility] = useState(true);

  const handleTextFieldChange = (event) => {
    const value = event.target.value;
    setTextFieldValue(value);
    const valid = !field_mapping_details?.some(detail => detail?.ascendo_field?.toLowerCase() === value?.toLowerCase());
    if(valid){
      setOpenSnack(false)
    }
    else{
      setOpenSnack(true)
    }
  };
  const mapping_uuid = params.mapping_uuid;

  const dispatch = useDispatch();

  const handleClose = () => {
    navigate(`/${customer_uuid}/mapping`);
  };

  const handleNextStep = () => {
    dispatch(requestData(params.mapping_uuid));
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/all-requests`)

  };

  const handlePrevStep = () => {
    setTableVisibility(true);
  };



  useEffect(() => {
    if (params.mapping_uuid) {
      dispatch(getMapping(mapping_uuid));
      dispatch(getFieldMapping(mapping_uuid));
    }
  }, [customer_uuid, params.mapping_uuid]);



  const handleAddFields = () => {
    const shouldAddField = !field_mapping_details?.some(detail => detail?.ascendo_field?.toLowerCase() === textFieldValue?.toLowerCase())&&textFieldValue.length;
if(shouldAddField){
    let by_custom_field_name = {
      mapping_uuid: mapping_uuid,
      custom_field: textFieldValue,
    };
    dispatch(createcustomField(by_custom_field_name));
    setOpenSnack(false)
    setNewfield(false);
  }
  else{
    setOpenSnack(true)
  }
  };

  const handleNewField = () => {
    setNewfield(true);
  };
  const handleCancelNewField = () => {
    setNewfield(false);
    setOpenSnack(false)
  };
  const handleEdit = (field) => {

    const updatedFields = field_mapping_details.map((prevField) => {
      if (prevField.ascendo_field === field.ascendo_field) {
        return {
          ...prevField,
          isEdit: true,
          ascendo_field:field.ascendo_field
        };
      }
      return prevField;
    });

  
  dispatch(updateCustomFields(updatedFields))
  setCustomFieldName(field.ascendo_field);

};
const handleDeletefield = (field) => {

  let by_field_mapping_uuids = {
    field_mapping_uuids: field.uuid,
    mapping_uuid: mapping_uuid,
  };

  dispatch(deleteCustomFieldMapping(by_field_mapping_uuids));

};
const handleEditFieldName = (event,field) => {
  const changedValue = event.target.value;

  const updatedFields = field_mapping_details.map((prevField) => {
    if (prevField.uuid === field.uuid) {
      return {
        ...prevField,
        ascendo_field:changedValue
      };
    }
    return prevField;
  });
dispatch(updateCustomFields(updatedFields))
};

const handleCancelEdit = (field) => {
    const updatedFields = field_mapping_details.map((prevField) => {
      if (prevField.uuid === field.uuid) {
        return {
          ...prevField,
          isEdit: false,
          ascendo_field:original_field_details.find((item)=>item.uuid===field.uuid).ascendo_field
        };
      }
      return prevField;
    });
    dispatch(updateCustomFields(updatedFields))




};
const handleSaveEdit = (field) => {
  const shouldAddField = !original_field_details?.some(detail => detail?.ascendo_field?.toLowerCase() === field.ascendo_field?.toLowerCase())&&field.ascendo_field.length
  if(shouldAddField){
    const updatedFields = field_mapping_details.map((prevField) => {
      if (prevField.uuid === field.uuid) {
        return {
          ...prevField,
          isEdit: false,
        };
      }
      return prevField;
    });
    dispatch(updateCustomFields(updatedFields))

  let by_custom_name = {
    customer_uuid: customer_uuid,
    field_mapping_uuid: field.uuid,
    mapping_uuid: mapping_uuid,
    custom_field: field.ascendo_field,
  };
  dispatch(updateCustomFieldMapping(by_custom_name));
  setOpenSnack(false)
}
else{
  setOpenSnack(true)
}
};

  const handleTabSwitch =()=>{
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/all-requests`)
  }
  const theme = useTheme();
  const colors = theme.palette;

  const originalTime = get_mapping?.created_at;
  const formattedTime = moment(originalTime).format("MMM DD YYYY HH:mm");

  const moduleDetails=JSON.parse(localStorage.getItem("md"))

  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          zIndex: 9999,
          position: "absolute",
          top: 0,
          left: 0,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Sidebar */}
        <Grid
          width="232px"
          sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
        ></Grid>

        {/* Main Content */}
        <Grid
          width="calc(100% - 232px)"
          backgroundColor="white"
          position="relative"
          sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
        >
          <Grid
            display="flex"
            justifyContent="space-between"
            marginRight="40px"
          >
            <Typography
              margin="16px 0px 5px 24px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Mapping Details{" "}
            </Typography>

            <Grid marginTop="20px">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography
            margin="-14px 0px 10px 24px"
            color={colors.ascendo.darkblue}
            sx={{ fontSize: "14px", lineHeight: "17px" }}
          >
            {" "}
            View or update mapping here.
          </Typography>
          <Grid variant="stepperFixedtop1">
            <Grid marginX="46px" display="flex" flex="1 1 auto">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                component="div"
                borderRadius="4px"
                bgcolor="rgba(205, 205, 205, 0.12)"
                height="100px"
                paddingX="46px"
                width="100%"
              >
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Mapping Name
                  </Typography>
                  <Tooltip title={get_mapping?.name}>
                  <Typography fontWeight="600" className="roundText mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.name}
                  </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Module
                  </Typography>
                  <Typography fontWeight="600" className="roundText mapDesc" color={colors.ascendo.blue}>
                    {moduleDetails?.find((item)=>item?.uuid===get_mapping?.module_uuid)?.name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Business Unit
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.business_unit.name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Data Source
                  </Typography>
                  <Tooltip title={get_mapping?.sub_datasource.name}>
                  <Typography fontWeight="600"  className="roundText mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.sub_datasource.name}
                  </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created By
                  </Typography>
                  <Typography fontWeight="600"  className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.created_by_user.username}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created At
                  </Typography>
                  <Typography fontWeight="600"  className="mapDesc" color={colors.ascendo.blue}>
                    {formattedTime}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <div className="tabWrapper">
          <input className="radio" id="one" name="group" type="radio"             
/>
<input
              className="radio"
              id="obs"
              name="group"
              type="radio"
              onClick={()=>  navigate(`/${customer_uuid}/mapping/${mapping_uuid}/edit/obfuscation`)}
            />
            <input
              className="radio"
              id="two"
              name="group"
              type="radio"
              onClick={()=>  navigate(`/${customer_uuid}/mapping/${mapping_uuid}/edit/view-mapping`)}
            />
            <input
              className="radio"
              id="three"
              name="group"
              type="radio"
              onClick={()=>  navigate(`/${customer_uuid}/mapping/${mapping_uuid}/all-requests`)}
      
            />
            <div className="tabs">
              <label className="tab active" id="one-tab" for="one">
                Field Mapping
              </label>
              <label className="tab" id="obs-tab" for="obs">
                Obfuscation
              </label>
              <label className="tab" id="two-tab" for="two">
                View Mapping
              </label>
               <label className="tab" id="three-tab" for="three">
                All Requests
              </label>
            </div>
            <div className="panels">
              <div className="panel" id="one-panel">
                <Grid variant="stepperScroller" >
                  <div className="innerField">
                    {field_mapping_details
                      ?.filter((item) => !item.is_custom)
                      ?.map((field) => {
                        return (
                          <>
                            <Grid
                              key={field.uuid}
                              marginX="46px"
                              marginY="16px"
                              alignItems="center"
                              container
                            >
                              <Grid
                                display="flex"
                                alignItems="center"
                                item
                                md={5}
                              >
                                <Typography
                                  fontWeight="500"
                                  color={colors.ascendo.darkblue}
                                >
                                  {field.ascendo_field}
                                </Typography>
                              </Grid>

                              <Grid item width="300px">
                                
                                  <ComboBox
                                    label="Field Selection"
                                    defaultValue={
                                      field.source_field
                                        ? field.source_field.field
                                        : ""
                                    }
                                    options={
                                      sub_fields
                                        ? sub_fields?.map((sub_field) => ({
                                            label: sub_field.field,
                                            uuid: sub_field.uuid,
                                          }))
                                        : []
                                    }
                                    field_uuid={field.uuid}
                                  />
                              
                              </Grid>
                            </Grid>
                            <Divider variant="middle" />
                          </>
                        );
                      })}
                    {field_mapping_details
                      ?.filter((item) => item.is_custom)
                      ?.map((field) => {
                        return (
                          <Grid                               key={field.uuid}
                          >
                            <Grid
                              marginX="46px"
                              marginY="24px"
                              alignItems="center"
                              container
                            >
                              {!field.isEdit && (
                                <Grid
                                  display="flex"
                                  alignItems="center"
                                  item
                                  md={5}
                                >
                                  <Typography
                                    fontWeight="500"
                                    color={colors.ascendo.darkblue}
                                  >
                                    {field.ascendo_field}
                                  </Typography>
                                  <IconButton onClick={() => handleEdit(field)}>
                                    <EditIcon
                                      sx={{ color: colors.grey.border }}
                                    />
                                  </IconButton>
                                </Grid>
                              )}
                              {field.isEdit && (
                                <>
                                  <Grid display="flex" item md={5}>
                                    <Grid width="300px">
                                      <TextField
                                        autoFocus
                                        size="small"
                                        value={field.ascendo_field}
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Enter name for the field"
                                        onChange={(e)=>handleEditFieldName(e,field)}
                                      >
                                        Enter name for the field
                                      </TextField>
                                    </Grid>
                                    <Grid
                                      marginLeft="10px"
                                      gap="10px"
                                      display="flex"
                                    >
                                      <IconButton
                                        onClick={() => handleSaveEdit(field)}
                                      >
                                        <CheckIcon
                                          sx={{ color: colors.grey.border }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => handleCancelEdit(field)}
                                      >
                                        <ClearIcon
                                          sx={{ color: colors.grey.border }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              <Grid item width="300px">
                              
                                  <ComboBox
                                    label="Field Selection"
                                    defaultValue={
                                      field.source_field
                                        ? field.source_field.field
                                        : ""
                                    }
                                    options={
                                      sub_fields
                                        ? sub_fields?.map((sub_field) => ({
                                            label: sub_field.field,
                                            uuid: sub_field.uuid,
                                          }))
                                        : []
                                    }
                                    field_uuid={field.uuid}
                                  />
                            
                              </Grid>
                              <IconButton
                                onClick={() => handleDeletefield(field)}
                              >
                                <RemoveCircleOutlineIcon
                                  sx={{ color: colors.grey.border }}
                                />
                              </IconButton>
                            </Grid>
                            <Divider variant="middle" />
                          </Grid>
                        );
                      })}

                    {newfield && (
                      <>
                        <Grid
                          marginX="46px"
                          marginY="24px"
                          alignItems="center"
                          container
                        >
                          <Grid item md={3}>
                            <TextField
                              autoFocus
                              fullWidth
                              size="small"
                              variant="outlined"
                              placeholder="Enter name for the field"
                              onChange={handleTextFieldChange}
                            >
                              Enter name for the field
                            </TextField>
                          </Grid>
                          <Grid marginLeft="10px" gap="10px" display="flex">
                            <IconButton onClick={handleAddFields}>
                              <CheckIcon sx={{ color: colors.grey.border }} />
                            </IconButton>
                            <IconButton onClick={handleCancelNewField}>
                              <ClearIcon sx={{ color: colors.grey.border }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Divider variant="middle" />
                      </>
                    )}

                    <Grid
                      marginLeft="26px"
                      marginY="26px"
                      width="140px"
                      height="40px"
                    >
                      <Button
                        fullWidth
                        sx={{
                          backgroundColor: "none",
                          color: colors.ascendo.darkblue,
                          textTransform: "none",
                          margin: "0px",
                          fontSize: "16px",
                        }}
                        startIcon={<AddIcon />}
                        onClick={handleNewField}
                      >
                        Add Fields
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              </div>
              
            </div>
          </div>

          {/* Continue Button */}
          <Grid
            variant="stepperFixedbottombtn"
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
             <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSnack}
            message="Field name cannot be empty or duplicate"
            action={action}
            onClose={handleSnackBarClose}
          />
            {!tableVisiblity && (
              <Grid item width="120px" margin="26px 0px 0px 0px">
                <Button
                  fullWidth
                  sx={{
                    color: colors.ascendo.darkblue,
                    textTransform: "none",
                  }}
                  startIcon={<ArrowLeftIcon />}
                  onClick={handlePrevStep}
                >
                  Back
                </Button>
              </Grid>
            )}

            <Grid item width="150px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  backgroundColor: colors.ascendo.yellow,
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                endIcon={
                  <ArrowRightIcon
                    opacity={
                      !field_mapping_details?.every(
                        (updated_field) => updated_field.source_field !== null
                      )
                        ? 0.2
                        : 1
                    }
                  />
                }
                disabled={
                  !field_mapping_details?.every(
                    (updated_field) => updated_field.source_field !== null
                  )
                }
                onClick={
                  ([
                    "Tickets",
                    "Confluence",
                   
                    "Help Articles",
                    "Macros",
                    "SAP Activities",
                  ].includes(get_mapping?.sub_datasource?.name)||["pdf","ServiceNow"].includes(get_mapping?.sub_datasource?.datasource?.name))
                    ? handleTabSwitch
                    : handleNextStep
                }
              >
                Request Data
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EditFieldMapping;
