import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateFieldMapping } from "../../redux/slices/mapping/mappingStep2Slice";
import { useParams } from "react-router-dom";

export default function ComboBox({
  width,
  label,
  options,
  field_uuid,
  defaultValue,
  placeholder
}) {
  const theme = useTheme();
  const colors = theme.palette;
  const [value, setValue] = React.useState(defaultValue);
  const dispatch = useDispatch();
  const customer_uuid = localStorage.getItem("ci");
  const params = useParams();
  const mapping_uuid = params.mapping_uuid;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (label === "Field Selection") {
      let by_fields = {
        field_mapping_uuid: field_uuid,
        source_field_uuid: newValue.uuid,
        mapping_uuid: mapping_uuid,
      };

      dispatch(updateFieldMapping(by_fields));
    }
  
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      disableClearable
      size="small"
      sx={{
        width: width,
        height: "40px",
        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
          height: "40px",
        },
        "& .MuiAutocomplete-input": {
          fontSize: "14px",
          width: width,
          color: colors.ascendo.darkblue,
        },
        "& .MuiInputLabel-root": {
          maxWidth: "calc(100% - 62px)",

          fontSize: "14px",
        },
        "& .MuiInputLabel-shrink": {
          maxWidth: width,
        },
      }}
      disablePortal
      id="combo-box-demo"
      options={options}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder}/>}
    />
  );
}
