import axios from "../../../utils/axios";

export const PredictDocument = async (
  business_unit_uuid,
  product_group,
  query,
  sub_datasource_uuids,
  api_trigger,
  guide_filter,
  request_channel
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `knowledge_base/${localStorage.getItem(
        "ci"
      )}/${business_unit_uuid}/predict?api_trigger=${api_trigger}&request_channel=${
        request_channel
          ? request_channel
          : api_trigger === "external"
          ? "AI+Resolve+Ext"
          : "AI+Resolve"
      }`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        guide_filter,
        product_group,
        query,
        sub_datasource_uuids,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GenerateAnswer = async (
  business_unit_uuid,
  product_group,
  query,
  sub_datasource_uuids,
  api_trigger,
  guide_filter,
  request_channel
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `knowledge_base/${localStorage.getItem(
        "ci"
      )}/${business_unit_uuid}/generate_answer?api_trigger=${api_trigger}&request_channel=${
        request_channel
          ? request_channel
          : api_trigger === "external"
          ? "AI+Resolve+Ext"
          : "AI+Resolve"
      }`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        guide_filter,
        product_group,
        query,
        sub_datasource_uuids,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetDocument = async (
  business_unit_uuid,
  query,
  sub_datasource_uuid,
  document_id
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `knowledge_base/${localStorage.getItem(
        "ci"
      )}/${business_unit_uuid}/${sub_datasource_uuid}/${document_id}?query=${query}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const ToggleDocumentVote = async (
  business_unit_uuid,
  sub_datasource_uuid,
  document_id,
  query,
  product_group,
  vote_direction
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `knowledge_base/${localStorage.getItem(
        "ci"
      )}/${business_unit_uuid}/${sub_datasource_uuid}/${document_id}/toggle_vote?query=${query}&product_group=${product_group}&vote_direction=${vote_direction}&request_channel=AI+Resolve`,
    {
      method: "POST",
      data: {},
    }
  );
  return { data: response.data, status: response.status };
};

export const CreateDocumentView = async (
  business_unit_uuid,
  sub_datasource_uuid,
  document_id,
  query,
  product_group
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `knowledge_base/${localStorage.getItem(
        "ci"
      )}/${business_unit_uuid}/${sub_datasource_uuid}/${document_id}/view?query=${query}&product_group=${product_group}&request_channel=AI+Resolve`,
    {
      method: "POST",
      data: {},
    }
  );
  return { data: response.data, status: response.status };
};
