import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteCustomer } from "../../actions/identity/customerActions";
import { GetAllAvailability } from "../../actions/identity/availabilityActions";
import { getAllBellNotification, updateUserAvailability } from "../../actions/notification/notificationActions";

export const deleteCustomer = createAsyncThunk(
  "deleteCustomer",
  async (thunkAPI) => {
    await DeleteCustomer();
  }
);

export const getAllAvailability = createAsyncThunk(
  "getAllAvailability",
  async (thunkAPI) => {
    const response = await GetAllAvailability();
    return response;
  }
);

const SharedComponentsSlice = createSlice({
  name: "SharedComponents",
  initialState: {
    checkedMultiBox: null,
    availabilities: [],
    notifications:[]
  },
  reducers: {
    updateMultiBox: (state, action) => {
      state.checkedMultiBox = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAvailability.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.availabilities = action.payload.data;
      })
      .addCase(getAllBellNotification.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.notifications = action.payload.data.data;
      })
      .addCase(updateUserAvailability.fulfilled, (state, action) => {
        const status_uuid = action.meta.arg;
        const userDetails = JSON.parse(localStorage.getItem("ud"));
        const updatedUserDetails = JSON.stringify({
          ...userDetails,
          availability: status_uuid,
        });
        if (action.payload.status === 200) {
          localStorage.setItem("ud",updatedUserDetails);
        }
      });
  },
});

export const { updateMultiBox } = SharedComponentsSlice.actions;
export default SharedComponentsSlice.reducer;
