import React from 'react'

function DownIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.63952 12.0276L10.7426 17.0859L15.8457 12.0276" stroke="#E25757" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7441 10.0036V16.9453" stroke="#E25757" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7441 2.92031V6.69531" stroke="#E25757" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  )
}

export default DownIcon