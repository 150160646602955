import axios from "../../../utils/axios";

export const GetInsightsDashboard = async (
  business_unit_uuids,
  end_date,
  start_date,
  product_groups,
  regions,
  request_channels
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuids,
        end_date,
        start_date,
        product_groups,
        regions,
        timezone: localStorage.getItem("tz"),
        request_channels,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const ExportInsights = async (
  business_unit_uuids,
  end_date,
  start_date,
  product_groups,
  regions,
  request_channels
) => {
  try {
    const response = await axios(
      process.env.REACT_APP_PREDICTION_SERVICE +
        `insights/${localStorage.getItem("ci")}/export`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        responseType: "blob",
        data: {
          business_unit_uuids,
          end_date,
          start_date,
          product_groups,
          regions,
          timezone: localStorage.getItem("tz"),
          request_channels,
        },
      }
    );

    if (response.headers["content-disposition"]) {
      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch = contentDisposition.match(
        /filename\*=utf-8''(.+?)(;|$)/
      );
      if (filenameMatch) {
        const filename = decodeURIComponent(filenameMatch[1]);
        return { response, filename };
      }
    }

    // If content-disposition header or filename not found, use a default filename
    return { response, filename: "default_filename.xlsx" };
  } catch (error) {
    console.error("Error in DownloadTopIssues:", error);
    throw error; // Rethrow the error for higher-level error handling
  }
};

export const GetEmailsForExclusion = async () => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights/${localStorage.getItem("ci")}/emails`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetInsightsRequestChannels = async () => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights/${localStorage.getItem("ci")}/request_channel`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const ValidateEmailForExclusion = async (email) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights/${localStorage.getItem(
        "ci"
      )}/email_validate?email=${encodeURIComponent(email)}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const AddEmailForExclusion = async (email_address) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights/${localStorage.getItem("ci")}/emails/add_exclusion`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        email_address,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const RemoveEmailForExclusion = async (email_address) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights/${localStorage.getItem("ci")}/emails/remove_exclusion`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        email_address,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetPredictionTable = async (
  business_unit_uuids,
  end_date,
  start_date,
  product_groups,
  regions,
  page_no,
  page_size,
  search,
  request_channels
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights_table/${localStorage.getItem(
        "ci"
      )}/prediction?page_no=${page_no}&page_size=${page_size}&search=${search}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuids,
        end_date,
        start_date,
        product_groups,
        regions,
        timezone: localStorage.getItem("tz"),
        request_channels,
      },
    }
  );
  return { data: response.data, status: response.status };
};
export const GetUniquePredictionTable = async (
  business_unit_uuids,
  end_date,
  start_date,
  product_groups,
  regions,
  page_no,
  page_size,
  search,
  request_channels
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights_table/${localStorage.getItem(
        "ci"
      )}/unique_prediction?page_no=${page_no}&page_size=${page_size}&search=${search}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuids,
        end_date,
        start_date,
        product_groups,
        regions,
        timezone: localStorage.getItem("tz"),
        request_channels,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetViewsTable = async (
  business_unit_uuids,
  end_date,
  start_date,
  product_groups,
  regions,
  page_no,
  page_size,
  search,
  request_channels
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights_table/${localStorage.getItem(
        "ci"
      )}/view?page_no=${page_no}&page_size=${page_size}&search=${search}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuids,
        end_date,
        start_date,
        product_groups,
        regions,
        timezone: localStorage.getItem("tz"),
        request_channels,
      },
    }
  );
  return { data: response.data, status: response.status };
};
export const GetVotesTable = async (
  business_unit_uuids,
  end_date,
  start_date,
  product_groups,
  regions,
  page_no,
  page_size,
  search,
  request_channels
) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights_table/${localStorage.getItem(
        "ci"
      )}/vote?page_no=${page_no}&page_size=${page_size}&search=${search}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuids,
        end_date,
        start_date,
        product_groups,
        regions,
        timezone: localStorage.getItem("tz"),
        request_channels,
      },
    }
  );
  return { data: response.data, status: response.status };
};
