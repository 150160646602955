import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../assets/EditIcon";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ArrowLeftIcon from "../../assets/ArrowLeftIcon";
import ArrowRightIcon from "../../assets/ArrowRightIcon";
import DotIcon from "../../assets/DotIcon";
import ComboBox from "../../components/autocomplete/comboBox";
import CustomStepper from "../../components/stepper/customStepper";
import {
  getFieldMapping,
  updateCustomFields,
  updateCustomFieldMapping,
  deleteCustomFieldMapping,
  createcustomField,
  getMapping,
} from "../../redux/slices/mapping/mappingStep2Slice";
import { resetStatus } from "../../redux/slices/mapping/mappingStep1Slice";

function FieldMapping() {
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const mapping_uuid = params.mapping_uuid;
  const customer_uuid = localStorage.getItem("ci");

  // State Value
  const [newfield, setNewfield] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [customFieldName, setCustomFieldName] = useState("");

  // Redux

  const {
    field_mapping_details,
    original_field_details,
    field_mapping_status,
    get_mapping,
    sub_fields,
  } = useSelector((state) => state.mappingStep2Reducer);

  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  const handleTextFieldChange = (event) => {
    const value = event.target.value;
    setTextFieldValue(value);
    const valid = !field_mapping_details?.some(
      (detail) => detail?.ascendo_field?.toLowerCase() === value?.toLowerCase()
    );
    if (valid) {
      setOpenSnack(false);
    } else {
      setOpenSnack(true);
    }
  };

  const handleClose = () => {
    navigate(`/${customer_uuid}/mapping`);
  };

  const handlePrevStep = () => {
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/select-datasource`);
  };

  const handleNextStep = () => {
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/obfuscation`);
  };
  const steps = [
    "Select Data Source",
    "Field Mapping",
    "Obfuscation",
    "View Mapping",
    "Summary",
  ];

  const handleAddFields = () => {
    const shouldAddField =
      !field_mapping_details?.some(
        (detail) =>
          detail?.ascendo_field?.toLowerCase() === textFieldValue?.toLowerCase()
      ) && textFieldValue.length;
    if (shouldAddField) {
      let by_custom_field_name = {
        mapping_uuid: mapping_uuid,
        custom_field: textFieldValue,
      };
      dispatch(createcustomField(by_custom_field_name));

      setNewfield(false);
      setOpenSnack(false);
    } else {
      setOpenSnack(true);
    }
  };

  const handleNewField = () => {
    setNewfield(true);
  };

  const handleCancelNewField = () => {
    setNewfield(false);
    setOpenSnack(false);
  };

  const handleEdit = (field) => {
    const updatedFields = field_mapping_details.map((prevField) => {
      if (prevField.ascendo_field === field.ascendo_field) {
        return {
          ...prevField,
          isEdit: true,
          ascendo_field: field.ascendo_field,
        };
      }
      return prevField;
    });

    dispatch(updateCustomFields(updatedFields));
    setCustomFieldName(field.ascendo_field);
  };
  const handleDeletefield = (field) => {
    let by_field_mapping_uuids = {
      field_mapping_uuids: field.uuid,
      mapping_uuid: mapping_uuid,
    };

    dispatch(deleteCustomFieldMapping(by_field_mapping_uuids));
  };
  const handleEditFieldName = (event, field) => {
    const changedValue = event.target.value;

    const updatedFields = field_mapping_details.map((prevField) => {
      if (prevField.uuid === field.uuid) {
        return {
          ...prevField,
          ascendo_field: changedValue,
        };
      }
      return prevField;
    });
    dispatch(updateCustomFields(updatedFields));
  };

  const handleCancelEdit = (field) => {
    const updatedFields = field_mapping_details.map((prevField) => {
      if (prevField.uuid === field.uuid) {
        return {
          ...prevField,
          isEdit: false,
          ascendo_field: original_field_details.find(
            (item) => item.uuid === field.uuid
          ).ascendo_field,
        };
      }
      return prevField;
    });
    dispatch(updateCustomFields(updatedFields));
  };
  const handleSaveEdit = (field) => {
    const shouldAddField =
      !original_field_details?.some(
        (detail) =>
          detail?.ascendo_field?.toLowerCase() ===
          field.ascendo_field?.toLowerCase()
      ) && field.ascendo_field.length;
    if (shouldAddField) {
      const updatedFields = field_mapping_details.map((prevField) => {
        if (prevField.uuid === field.uuid) {
          return {
            ...prevField,
            isEdit: false,
          };
        }
        return prevField;
      });
      dispatch(updateCustomFields(updatedFields));

      let by_custom_name = {
        customer_uuid: customer_uuid,
        field_mapping_uuid: field.uuid,
        mapping_uuid: mapping_uuid,
        custom_field: field.ascendo_field,
      };
      dispatch(updateCustomFieldMapping(by_custom_name));
      setOpenSnack(false);
    } else {
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    dispatch(getMapping(mapping_uuid));
    dispatch(getFieldMapping(mapping_uuid));
  }, [mapping_uuid]);

  useEffect(()=>{
    dispatch(resetStatus())

  },[])
  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          zIndex: 9999,
          position: "absolute",
          top: 0,
          left: 0,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Sidebar */}
        <Grid
          width="232px"
          sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
        ></Grid>

        {/* Main Content */}
        <Grid
          width="calc(100% - 232px)"
          backgroundColor="white"
          position="relative"
          sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
        >
          <Grid
            display="flex"
            justifyContent="space-between"
            marginRight="40px"
          >
            <Typography
              margin="40px 0px 0px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Create Mapping
            </Typography>
            <Grid marginTop="20px">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography
            margin="8px 0px 20px 46px"
            color={colors.ascendo.darkblue}
            sx={{ fontSize: "14px", lineHeight: "17px" }}
          >
            Create a mapping that can help discover issue trends.
          </Typography>

          <Grid variant="stepperFixedtop">
            <Divider variant="middle" />
            <CustomStepper steps={steps} activeStep={1} />
          </Grid>

          <Grid variant="stepperScroller">
            <div>
              <Divider variant="middle" />
              <Grid marginX="46px" marginY="24px" marginTop="26px">
                <Typography fontWeight="500" color={colors.ascendo.darkblue}>
                  Mapping
                </Typography>
                <Grid display="flex" marginTop="20px">
                  <Grid display="flex">
                    <Typography fontSize="14px" color={colors.grey.normal}>
                      {get_mapping?.business_unit.name}
                    </Typography>
                    <Grid marginX="16px">
                      <DotIcon />
                    </Grid>
                  </Grid>
                  <Grid display="flex">
                    <Typography fontSize="14px" color={colors.grey.normal}>
                      {get_mapping?.sub_datasource.name}
                    </Typography>
                    <Grid marginX="16px">
                      <DotIcon />
                    </Grid>
                  </Grid>
                  <Grid display="flex">
                    <Typography fontSize="14px" color={colors.ascendo.blue}>
                      Field Mapping
                    </Typography>
                    <Grid marginX="16px">
                      <DotIcon />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider variant="middle" sx={{ marginTop: "26px" }} />
              {field_mapping_details
                ?.filter((item) => !item.is_custom)
                ?.map((field) => {
                  return (
                    <>
                      <Grid
                        key={field.uuid}
                        marginX="46px"
                        marginY="24px"
                        alignItems="center"
                        container
                      >
                        <Grid display="flex" alignItems="center" item md={5}>
                          <Typography
                            fontWeight="500"
                            color={colors.ascendo.darkblue}
                          >
                            {field.ascendo_field}
                          </Typography>
                        </Grid>

                        <Grid item width="300px">
                          <ComboBox
                            label="Field Selection"
                            defaultValue={
                              field.source_field ? field.source_field.field : ""
                            }
                            options={
                              sub_fields
                                ? sub_fields?.map((sub_field) => ({
                                    label: sub_field.field,
                                    uuid: sub_field.uuid,
                                  }))
                                : []
                            }
                            field_uuid={field.uuid}
                          />
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                    </>
                  );
                })}
              {field_mapping_details
                ?.filter((item) => item.is_custom)
                ?.map((field) => {
                  return (
                    <Grid key={field.uuid}>
                      <Grid
                        marginX="46px"
                        marginY="24px"
                        alignItems="center"
                        container
                      >
                        {!field.isEdit && (
                          <Grid display="flex" alignItems="center" item md={5}>
                            <Typography
                              fontWeight="500"
                              color={colors.ascendo.darkblue}
                            >
                              {field.ascendo_field}
                            </Typography>
                            <IconButton onClick={() => handleEdit(field)}>
                              <EditIcon sx={{ color: colors.grey.border }} />
                            </IconButton>
                          </Grid>
                        )}
                        {field.isEdit && (
                          <>
                            <Grid display="flex" item md={5}>
                              <Grid width="300px">
                                <TextField
                                  autoFocus
                                  fullWidth
                                  size="small"
                                  value={field.ascendo_field}
                                  variant="outlined"
                                  placeholder="Enter name for the field"
                                  onChange={(e) =>
                                    handleEditFieldName(e, field)
                                  }
                                >
                                  Enter name for the field
                                </TextField>
                              </Grid>
                              <Grid marginLeft="10px" gap="10px" display="flex">
                                <IconButton
                                  onClick={() => handleSaveEdit(field)}
                                >
                                  <CheckIcon
                                    sx={{ color: colors.grey.border }}
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleCancelEdit(field)}
                                >
                                  <ClearIcon
                                    sx={{ color: colors.grey.border }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        <Grid item width="300px">
                          <ComboBox
                            label="Field Selection"
                            defaultValue={
                              field.source_field ? field.source_field.field : ""
                            }
                            options={
                              sub_fields
                                ? sub_fields?.map((sub_field) => ({
                                    label: sub_field.field,
                                    uuid: sub_field.uuid,
                                  }))
                                : []
                            }
                            field_uuid={field.uuid}
                          />
                        </Grid>
                        <IconButton onClick={() => handleDeletefield(field)}>
                          <RemoveCircleOutlineIcon
                            sx={{ color: colors.grey.border }}
                          />
                        </IconButton>
                      </Grid>
                      <Divider variant="middle" />
                    </Grid>
                  );
                })}

              {newfield && (
                <>
                  <Grid
                    marginX="46px"
                    marginY="24px"
                    alignItems="center"
                    container
                  >
                    <Grid item md={3}>
                      <TextField
                        autoFocus
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Enter name for the field"
                        onChange={handleTextFieldChange}
                      >
                        Enter name for the field
                      </TextField>
                    </Grid>
                    <Grid marginLeft="10px" gap="10px" display="flex">
                      <IconButton onClick={handleAddFields}>
                        <CheckIcon sx={{ color: colors.grey.border }} />
                      </IconButton>
                      <IconButton onClick={handleCancelNewField}>
                        <ClearIcon sx={{ color: colors.grey.border }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider variant="middle" />
                </>
              )}
              {!newfield&&
              <Grid
                marginLeft="26px"
                marginY="26px"
                width="140px"
                height="40px"
              >
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "none",
                    color: colors.ascendo.darkblue,
                    textTransform: "none",
                    margin: "0px",
                    fontSize: "16px",
                  }}
                  startIcon={<AddIcon />}
                  onClick={handleNewField}
                >
                  Add Fields
                </Button>
              </Grid>
}
            </div>
          </Grid>

          <Divider variant="middle" />

          {/* Continue Button */}
          <Grid
            variant="stepperFixedbottombtn"
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              message={"Field name cannot be empty or duplicate"}
              action={action}
              onClose={handleSnackBarClose}
            />
            <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                startIcon={<ArrowLeftIcon />}
                onClick={handlePrevStep}
              >
                Back
              </Button>
            </Grid>

            <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  backgroundColor: colors.ascendo.yellow,
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                endIcon={
                  <ArrowRightIcon
                    opacity={
                      field_mapping_details?.every(
                        (updated_field) => updated_field.source_field !== null
                      )
                        ? 1
                        : 0.2
                    }
                  />
                }
                onClick={handleNextStep}
                disabled={
                  !field_mapping_details?.every(
                    (updated_field) => updated_field.source_field !== null
                  )
                }
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default FieldMapping;
