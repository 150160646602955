import axios from "../../../utils/axios";
export const GetAllUploadedFiles = async (
   mapping_uuid,
    search,
    page_no,
    page_size
  ) => {
    let url =
      process.env.REACT_APP_DATA_INTEGRATION +
      `mapping/${localStorage.getItem(
        "ci"
      )}/${mapping_uuid}/mapping_request__file_upload/table?page_no=${page_no}&page_size=${page_size}`;
    if (search !== null) {
      url += `&search=${search}`;
    }
    let response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data:{}
    
    });
    return { data: response.data, status: response.status };
  };