import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Typography,
  useTheme,
  Snackbar,
  Tooltip,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useMemo } from "react";
import ArrowRightIcon from "../../../assets/ArrowRightIcon";
import EditIcon from "../../../assets/EditIcon";
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading-animation.json";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ComboBox from "../../../components/autocomplete/comboBox";
import {
  updateFilesPagination,
  updateTableRows,
} from "../../../redux/slices/dataTableSlice";
import DataTable from "../../../components/dataTable/dataTable";
import {
  updateCustomFields,
  deleteCustomFieldMapping,
  createcustomField,
  updateCustomFieldMapping,
  getFieldMapping,
  getMapping,
  getAllUploadedFiles,
} from "../../../redux/slices/mapping/mappingStep2Slice";
import "./uploadedFiles.scss";
import {
  getMappingRequests,
  requestData,
  zendeskRequestData,
} from "../../../redux/slices/mapping/listMappingSlice";
import CompletedIcon from "../../../assets/tableIcons/CompletedIcon";
import CustomMultiBox from "../../../components/autocomplete/customMultiBox";
import DatePickerIcon from "../../../assets/DatePickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ArrowLeftIcon from "../../../assets/ArrowLeftIcon";
import ResizableTable from "../../../components/dataTable/simpleTable";
import DangerIcon from "../../../assets/tableIcons/DangerIcon";
import FailedIcon from "../../../assets/tableIcons/FailedIcon";
import { resetStatus } from "../../../redux/slices/mapping/mappingStep1Slice";

function FilesTable() {
  const dispatch = useDispatch();

  const { filesTableDetails } = useSelector(
    (state) => state.mappingStep2Reducer
  );
  const { files_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  const cols = useMemo(() =>
    filesTableDetails?.columns
      ? filesTableDetails?.columns
          ?.filter((col) => col !== "action")
          ?.map((column) => {
            if (column === "parsing_status") {
              return {
                accessorKey: column,
                header: filesTableDetails?.display_map[column],
                Cell: ({ cell }) => {
                  const status = cell.getValue();
                  let icon = null;
  
                  switch (status) {
                    case "Completed":
                      icon = <CompletedIcon />;
                      break;
                    case "Pending due to error":
                      icon = <DangerIcon />;
                      break;
                    case "NO DATA":
                      icon = <DangerIcon />;
                      break;
                    case "Failed":
                      icon = <FailedIcon />;
                      break;
                    case "Validation":
                      icon = (
                        <Box width="60px" marginRight="-16px" marginLeft="-24px">
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                    case "Processing":
                      icon = (
                        <Box width="60px" marginRight="-16px" marginLeft="-24px">
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                    case "Learning Data":
                      icon = (
                        <Box width="60px" marginRight="-16px" marginLeft="-24px">
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                    case "Parsing Data":
                      icon = (
                        <Box width="60px" marginRight="-16px" marginLeft="-24px">
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                  }
  
                  return (
                    <Tooltip title={cell.getValue()}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {icon}
                        </div>
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {status}
                        </div>
                      </div>
                    </Tooltip>
                  );
                },
              };
            }
            if (column === "created_at") {
              return {
                accessorKey: column,
                header: filesTableDetails?.display_map[column],
                Cell: ({ cell }) => {
                  const timestamp = cell.getValue();
                  const formattedDate =
                    moment(timestamp).format("MMM DD YYYY HH:mm");

                  return (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                    {cell.getValue()?formattedDate:""}
                    </div>
                  );
                },
              };
            }
            return {
              accessorKey: column,
              header: filesTableDetails?.display_map[column],
              Cell: ({ cell }) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              ),
            };
          })
      : []
  );

  const rows = filesTableDetails?.data
    ? filesTableDetails?.data?.map((dataItem) => {
        const row = { id: dataItem.uuid };

        filesTableDetails?.columns
          ?.filter((col) => col !== "action")
          ?.forEach((column) => {
            row[column] = dataItem[column];
          });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateFilesPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {
    {
    }
  };

  return (
    <>
      <>
        <div
          className="listmappingWrapper"
          style={{ overflow: "scroll", height: "calc(100vh - 170px)" }}
        >
          <Grid className="listMappingWrap">
            <ResizableTable
              data={rows}
              columns={cols}
              paginationDetails={files_paginationDetails}
              onTablePaginationChange={handlePaginationModelChange}
              onTableRowClick={handleRowClick}
              rowCount={filesTableDetails?.total ?? 0}
            />
          </Grid>
        </div>
      </>
    </>
  );
}

function UploadedFiles() {
  const navigate = useNavigate();
  const customer_uuid = localStorage.getItem("ci");
  const params = useParams();

  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  const { get_mapping } = useSelector((state) => state.mappingStep2Reducer);
  const { files_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  const [tableVisiblity, setTableVisibility] = useState(true);

  const mapping_uuid = params.mapping_uuid;

  const dispatch = useDispatch();

  const handleClose = () => {
    navigate(`/${customer_uuid}/mapping`);
  };

  const handleNextStep = () => {
    dispatch(requestData(params.mapping_uuid));
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/all-requests`);
  };

  const handlePrevStep = () => {
    setTableVisibility(true);
  };

  useEffect(() => {
    dispatch(getMapping(mapping_uuid));
    dispatch(resetStatus())
  }, []);
  useEffect(() => {
    let by_filters_and_pagination = {
      mapping_uuid: params.mapping_uuid,
      search: null,
      page_no: parseInt(files_paginationDetails.page) + 1,
      page_size: files_paginationDetails.pageSize,
    };
    dispatch(getAllUploadedFiles(by_filters_and_pagination));
  }, [files_paginationDetails]);

  const handleTabSwitch = () => {
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/all-requests`);
  };
  const theme = useTheme();
  const colors = theme.palette;

  const originalTime = get_mapping?.created_at;
  const formattedTime = moment(originalTime).format("MMM DD YYYY HH:mm");

  const moduleDetails = JSON.parse(localStorage.getItem("md"));

  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          zIndex: 9999,
          position: "absolute",
          top: 0,
          left: 0,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Sidebar */}
        <Grid
          width="232px"
          sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
        ></Grid>

        {/* Main Content */}
        <Grid
          width="calc(100% - 232px)"
          backgroundColor="white"
          position="relative"
          sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
        >
          <Grid
            display="flex"
            justifyContent="space-between"
            marginRight="40px"
          >
            <Typography
              margin="16px 0px 5px 24px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Mapping Details{" "}
            </Typography>

            <Grid marginTop="20px">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography
            margin="-14px 0px 10px 24px"
            color={colors.ascendo.darkblue}
            sx={{ fontSize: "14px", lineHeight: "17px" }}
          >
            {" "}
            View or update mapping here.
          </Typography>
          <Grid variant="stepperFixedtop1">
            <Grid marginX="46px" display="flex" flex="1 1 auto">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                component="div"
                borderRadius="4px"
                bgcolor="rgba(205, 205, 205, 0.12)"
                height="100px"
                paddingX="46px"
                width="100%"
              >
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Mapping Name
                  </Typography>
                  <Tooltip title={get_mapping?.name}>
                  <Typography fontWeight="600" className="roundText mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.name}
                  </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Module
                  </Typography>
                  <Typography fontWeight="600" className="roundText mapDesc"  color={colors.ascendo.blue}>
                    {
                      moduleDetails?.find(
                        (item) => item?.uuid === get_mapping?.module_uuid
                      )?.name
                    }
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Business Unit
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.business_unit.name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Data Source
                  </Typography>
                  <Tooltip title= {get_mapping?.sub_datasource.name}>
                  <Typography fontWeight="600" className="roundText mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.sub_datasource.name}
                  </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created By
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.created_by_user.username}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created At
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {formattedTime}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <div className="tabWrapper">
            <input className="radio" id="one" name="group" type="radio" />
            <input
              className="radio"
              id="obs"
              name="group"
              type="radio"
              onClick={()=>  navigate(`/${customer_uuid}/mapping/${mapping_uuid}/edit/obfuscation`)}
            />
            <input
              className="radio"
              id="two"
              name="group"
              type="radio"
              onClick={()=>  navigate(`/${customer_uuid}/mapping/${mapping_uuid}/edit/view-mapping`)}
            />
            <input
              className="radio"
              id="three"
              name="group"
              type="radio"
              onClick={() =>
                navigate(
                  `/${customer_uuid}/mapping/${mapping_uuid}/all-requests`
                )
              }
            />
            <div className="tabs">
              <label className="tab active" id="one-tab" for="one">
                Files Uploaded
              </label>
              <label className="tab" id="obs-tab" for="obs">
                Obfuscation
              </label>
              <label className="tab" id="two-tab" for="two">
                View Mapping
              </label>
              <label className="tab" id="three-tab" for="three">
                All Requests
              </label>
            </div>
            <div className="panels">
              <div className="panel" id="one-panel">
                <Grid variant="stepperScroller" >
                  <div className="innerField">
                    <FilesTable />
                  </div>
                </Grid>
              </div>
            </div>
          </div>

          {/* Continue Button */}
          <Grid
            variant="stepperFixedbottombtn"
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
           
           

            <Grid item width="150px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  backgroundColor: colors.ascendo.yellow,
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                endIcon={<ArrowRightIcon opacity={1} />}
                onClick={
                  ([
                    "Tickets",
                    "Confluence",
                   
                    "Help Articles",
                    "Macros",
                    "SAP Activities",
                  ].includes(get_mapping?.sub_datasource?.name)||["pdf","ServiceNow"].includes(get_mapping?.sub_datasource?.datasource?.name))
                    ? handleTabSwitch
                    : handleNextStep
                }
              >
                Request Data
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default UploadedFiles;
