import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import "./settingsView.scss";

import { Button, Card, TextField, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { RemoveCircleOutline } from "@mui/icons-material";
import { AddCircleOutline } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  removeEmailForAibotNotification,
  validateEmailForAibotNotification,
  getEmailsForAibotNotification,
  addEmailForAibotNotification,
  updateAddEmails,
  updateIsAddEmail,
} from "../../../redux/slices/supportChannels/supportChannelsSlice";
import validator from "validator";
function SettingsView() {
  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();
  const { included_emails, add_emails, is_add_email } = useSelector(
    (state) => state.supportChannelReducer
  );

  useEffect(() => {
    if (add_emails.length === 0) {
      dispatch(updateIsAddEmail(true));
    }
  }, [add_emails.length]);
  useEffect(() => {
    dispatch(getEmailsForAibotNotification())
  }, [])
  return (
    <div
      className="settingsWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        marginTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <div className="titleFlex">
              <Typography variant="h4" sx={{ mb: 2 }}>
                Settings
              </Typography>
            </div>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Manage settings for all your support channels.
            </Typography>
          </div>
        </div>

        <Grid container spacing={2} className="cardGrid">
          <Card className="commonCard">
            <Grid className="width100">
              <Typography className="primaryText">Notifications</Typography>
              <Typography className="secondaryText">
                All notifications with regards to support channels will be sent to below email ids.
              </Typography>
              <div>
                <Grid display="flex" flexDirection={"column"} gap="12px">
                  {included_emails.map((email) => (
                    <Grid
                      display="flex"
                      key={email.uuid}
                      gap="8px"
                      alignItems="center"
                    >
                      <Typography
                        color={colors.ascendo.darkblue}
                        fontSize={"14px"}
                      >
                        {email.email}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          dispatch(
                            removeEmailForAibotNotification(email.email)
                          )
                        }
                      >
                        <RemoveCircleOutline fontSize="small" />
                      </IconButton>
                    </Grid>
                  ))}
                  {add_emails?.map((email) => (
                    <Grid
                      display="flex"
                      key={email.id}
                      gap="8px"
                      alignItems="flex-start"
                    >
                      <TextField
                        type="email"
                        onChange={(e) => {
                          dispatch(
                            updateAddEmails(
                              add_emails.map((item) => {
                                if (item.id === email.id) {
                                  return {
                                    ...item,
                                    value: e.target.value,
                                    isValidEmail: validator.isEmail(
                                      e.target.value
                                    ),
                                    isApiValidatedEmail: true,
                                  };
                                } else {
                                  return item;
                                }
                              })
                            )
                          );
                          if (validator.isEmail(e.target.value))
                            dispatch(
                              validateEmailForAibotNotification(e.target.value)
                            );
                        }}
                        error={
                          (!email.isValidEmail || !email.isApiValidatedEmail) &&
                          email.value
                        }
                        helperText={
                          email?.value
                            ? email.isValidEmail
                              ? email.isApiValidatedEmail
                                ? ""
                                : "Email already exists or maybe not a part of Ascendo's user list. Kindly update this value or remove it to proceed further."
                              : "* Please enter a valid email"
                            : ""
                        }
                        id="outlined-basic"
                        label="Enter Email"
                        variant="outlined"
                        className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedStart css-wjp1ot emailInput"
                      />
                      <IconButton
                        onClick={() =>
                          dispatch(
                            updateAddEmails(
                              add_emails.filter((item) => item.id !== email.id)
                            )
                          )
                        }
                      >
                        <RemoveCircleOutline fontSize="small" />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
                {is_add_email && (
                  <Button
                    sx={{ color: colors.ascendo.blue, textTransform: "none" }}
                    startIcon={<AddCircleOutline />}
                    onClick={() => {
                      dispatch(updateIsAddEmail(false));
                      dispatch(
                        updateAddEmails([
                          ...add_emails,
                          {
                            id: Date.now(),
                            value: null,
                            isValidEmail: false,
                            isApiValidatedEmail: false,
                          },
                        ])
                      );
                    }}
                  >
                    Add new email
                  </Button>
                )}
              </div>

              <div className="saveBtn">
                <Button
                  className="mat-focus-indicator mat-flat-button mat-button-base mat-yellow"
                  disabled={
                    !(
                      add_emails.every((email) => email.isValidEmail === true) &&
                      add_emails.length
                    )
                  }
                  onClick={() => {
                    dispatch(
                      addEmailForAibotNotification(
                        add_emails
                          .filter(
                            (email) =>
                              email.isValidEmail && email.isApiValidatedEmail
                          )
                          .map((email) => email.value)
                      )
                    );
                  }}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </div>
  );
}

export default SettingsView;
