import React from 'react'

function AibotColorIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.1932 3.14578C28.1932 4.64573 26.9683 5.86655 25.4512 5.86655C23.9341 5.86655 22.7092 4.64573 22.7092 3.14578C22.7092 1.64582 23.9341 0.425 25.4512 0.425C26.9683 0.425 28.1932 1.64582 28.1932 3.14578Z" fill="#1A73E8" stroke="#1A73E8" stroke-width="0.85"/>
<line x1="25.0332" y1="4.18945" x2="25.0332" y2="12.5782" stroke="#1A73E8" stroke-width="1.25"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.06201 12.8935V37.0828C4.06201 41.1374 6.77699 41.9975 8.13448 41.9207H32.5693L36.2131 48.3712C38.2708 51.32 38.9281 49.5998 38.9995 48.3712V44.6852C39.171 42.2893 41.0715 41.8439 42.0003 41.9207C45.4297 41.1835 46.0013 37.6204 45.8584 35.931V12.8935C46.2014 10.129 43.4292 8.51641 42.0003 8.05566H8.13448C5.21945 8.05566 4.20491 11.2809 4.06201 12.8935ZM10.7561 18.6047C10.7561 17.2239 11.8754 16.1047 13.2561 16.1047H36.6363C38.017 16.1047 39.1363 17.2239 39.1363 18.6047V27.6996C39.1363 29.0803 38.017 30.1996 36.6363 30.1996H13.2561C11.8754 30.1996 10.7561 29.0803 10.7561 27.6996V18.6047ZM22.0844 23.007C22.0844 24.9531 20.4961 26.5307 18.5369 26.5307C16.5776 26.5307 14.9893 24.9531 14.9893 23.007C14.9893 21.0609 16.5776 19.4833 18.5369 19.4833C20.4961 19.4833 22.0844 21.0609 22.0844 23.007ZM31.3537 26.5307C33.313 26.5307 34.9013 24.9531 34.9013 23.007C34.9013 21.0609 33.313 19.4833 31.3537 19.4833C29.3945 19.4833 27.8062 21.0609 27.8062 23.007C27.8062 24.9531 29.3945 26.5307 31.3537 26.5307ZM25.111 36.7752C26.1465 36.7752 26.9859 35.9358 26.9859 34.9003C26.9859 33.8648 26.1465 33.0253 25.111 33.0253C24.0755 33.0253 23.236 33.8648 23.236 34.9003C23.236 35.9358 24.0755 36.7752 25.111 36.7752ZM31.7731 34.9979C31.7731 35.6883 31.2097 36.2479 30.5147 36.2479C29.8197 36.2479 29.2563 35.6883 29.2563 34.9979C29.2563 34.3076 29.8197 33.748 30.5147 33.748C31.2097 33.748 31.7731 34.3076 31.7731 34.9979ZM19.7401 36.2311C20.4304 36.2311 20.99 35.6752 20.99 34.9895C20.99 34.3038 20.4304 33.748 19.7401 33.748C19.0498 33.748 18.4901 34.3038 18.4901 34.9895C18.4901 35.6752 19.0498 36.2311 19.7401 36.2311Z" fill="#1A73E8"/>
<path d="M47.9932 33.5557C49.0977 33.5557 49.9932 32.6602 49.9932 31.5557V18.7782C49.9932 17.6736 49.0977 16.7782 47.9932 16.7782H43.6592V33.5557H47.9932Z" fill="#1A73E8"/>
<path d="M2 33.5557C0.89543 33.5557 0 32.6602 0 31.5557L0 18.7782C0 17.6736 0.89543 16.7782 2 16.7782H6.33401L6.33401 33.5557H2Z" fill="#1A73E8"/>
</svg>
  )
}

export default AibotColorIcon