import React from 'react'

function SendIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8501 18.0254C10.8667 18.0254 9.47507 17.3338 8.37507 14.0254L7.77506 12.2254L5.97506 11.6254C2.67507 10.5254 1.9834 9.13376 1.9834 8.15043C1.9834 7.17543 2.67507 5.77543 5.97506 4.66709L13.0501 2.30876C14.8167 1.71709 16.2917 1.89209 17.2001 2.79209C18.1084 3.69209 18.2834 5.17543 17.6917 6.94209L15.3334 14.0171C14.2251 17.3338 12.8334 18.0254 11.8501 18.0254ZM6.36673 5.85876C4.05006 6.63376 3.22506 7.55043 3.22506 8.15043C3.22506 8.75043 4.05006 9.66709 6.36673 10.4338L8.46673 11.1338C8.65006 11.1921 8.80006 11.3421 8.8584 11.5254L9.5584 13.6254C10.3251 15.9421 11.2501 16.7671 11.8501 16.7671C12.4501 16.7671 13.3667 15.9421 14.1417 13.6254L16.5001 6.55043C16.9251 5.26709 16.8501 4.21709 16.3084 3.67543C15.7667 3.13376 14.7167 3.06709 13.4417 3.49209L6.36673 5.85876Z" fill="#071942"/>
    <path d="M8.42467 12.0003C8.26634 12.0003 8.10801 11.942 7.98301 11.817C7.74134 11.5753 7.74134 11.1753 7.98301 10.9337L10.9663 7.94199C11.208 7.70033 11.608 7.70033 11.8497 7.94199C12.0913 8.18366 12.0913 8.58366 11.8497 8.82533L8.86634 11.817C8.74967 11.942 8.58301 12.0003 8.42467 12.0003Z" fill="#071942"/>
    </svg>
  )
}

export default SendIcon