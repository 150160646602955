import React from 'react'

function InsightsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={0.8}>
    <path d="M18.3327 18.3359H1.66602C1.32435 18.3359 1.04102 18.0526 1.04102 17.7109C1.04102 17.3693 1.32435 17.0859 1.66602 17.0859H18.3327C18.6743 17.0859 18.9577 17.3693 18.9577 17.7109C18.9577 18.0526 18.6743 18.3359 18.3327 18.3359Z" fill="#071942"/>
    <path d="M8.125 3.33073V18.3307H11.875V3.33073C11.875 2.41406 11.5 1.66406 10.375 1.66406H9.625C8.5 1.66406 8.125 2.41406 8.125 3.33073Z" fill="#071942"/>
    <path d="M2.5 8.33073V18.3307H5.83333V8.33073C5.83333 7.41406 5.5 6.66406 4.5 6.66406H3.83333C2.83333 6.66406 2.5 7.41406 2.5 8.33073Z" fill="#071942"/>
    <path d="M14.166 12.5026V18.3359H17.4993V12.5026C17.4993 11.5859 17.166 10.8359 16.166 10.8359H15.4993C14.4993 10.8359 14.166 11.5859 14.166 12.5026Z" fill="#071942"/>
    </svg>
  )
}

export default InsightsIcon