import React from 'react'

function TicketOutline() {
  return (
    <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9086 18.9583H6.09193C2.61693 18.9583 1.55859 17.9 1.55859 14.425V14.0417C1.55859 13.7 1.84193 13.4167 2.18359 13.4167C2.91693 13.4167 3.51693 12.8167 3.51693 12.0833C3.51693 11.35 2.91693 10.75 2.18359 10.75C1.84193 10.75 1.55859 10.4667 1.55859 10.125V9.74168C1.55859 6.26668 2.61693 5.20834 6.09193 5.20834H13.9086C17.3836 5.20834 18.4419 6.26668 18.4419 9.74168V10.525C18.4419 10.8667 18.1586 11.15 17.8169 11.15C17.0836 11.15 16.4836 11.75 16.4836 12.4833C16.4836 13.2167 17.0836 13.8083 17.8169 13.8083C18.1586 13.8083 18.4419 14.0917 18.4419 14.4333C18.4336 17.9 17.3753 18.9583 13.9086 18.9583ZM2.81693 14.5917C2.83359 17.2417 3.35859 17.7083 6.10026 17.7083H13.9169C16.5169 17.7083 17.1169 17.2833 17.1919 14.975C16.0753 14.6917 15.2419 13.6833 15.2419 12.475C15.2419 11.2667 16.0753 10.25 17.2003 9.96668V9.73334C17.2003 6.94168 16.7086 6.45001 13.9169 6.45001H6.09193C3.35859 6.45001 2.83359 6.92501 2.80859 9.56668C3.93359 9.85001 4.76693 10.8667 4.76693 12.075C4.76693 13.2833 3.93359 14.3083 2.81693 14.5917Z" fill="#071942"/>
    <path d="M8.33203 8.54168C7.99036 8.54168 7.70703 8.25834 7.70703 7.91668V5.83334C7.70703 5.49168 7.99036 5.20834 8.33203 5.20834C8.6737 5.20834 8.95703 5.49168 8.95703 5.83334V7.91668C8.95703 8.25834 8.6737 8.54168 8.33203 8.54168Z" fill="#071942"/>
    <path d="M8.33203 14.0999C7.99036 14.0999 7.70703 13.8165 7.70703 13.4749V10.6999C7.70703 10.3582 7.99036 10.0749 8.33203 10.0749C8.6737 10.0749 8.95703 10.3582 8.95703 10.6999V13.4749C8.95703 13.8165 8.6737 14.0999 8.33203 14.0999Z" fill="#071942"/>
    <path d="M8.33203 18.9583C7.99036 18.9583 7.70703 18.675 7.70703 18.3333V16.25C7.70703 15.9083 7.99036 15.625 8.33203 15.625C8.6737 15.625 8.95703 15.9083 8.95703 16.25V18.3333C8.95703 18.675 8.6737 18.9583 8.33203 18.9583Z" fill="#071942"/>
    <path d="M13.6091 6.45844H6.03413C5.78413 6.45844 5.5508 6.30844 5.45913 6.0751C5.36746 5.84177 5.41746 5.56677 5.59246 5.39177L8.03413 2.9501C10.2841 0.700104 11.6591 0.700104 13.9008 2.9501L14.4008 3.4501C14.5175 3.56677 14.5841 3.7251 14.5841 3.89177C14.5841 4.05844 14.5175 4.21677 14.4008 4.33344C14.0675 4.66677 13.9758 5.15844 14.1675 5.58344C14.2591 5.7751 14.2425 6.0001 14.1258 6.18344C14.0175 6.3501 13.8175 6.45844 13.6091 6.45844ZM7.54246 5.20844H12.8175C12.8008 4.76677 12.9091 4.3251 13.1341 3.94177L13.0258 3.83344C11.2841 2.09177 10.6675 2.09177 8.9258 3.83344L7.54246 5.20844Z" fill="#071942"/>
    </svg>
  )
}

export default TicketOutline