import React from 'react'

function UpIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8468 7.9724L10.7437 2.91406L5.64062 7.9724" stroke="#45C277" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7422 9.99635V3.05469" stroke="#45C277" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7422 17.0797V13.3047" stroke="#45C277" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  )
}

export default UpIcon