import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  debounce,
  useTheme,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import AddCircle from "../../../assets/AddCircle";
import SearchNormalIcon from "../../../assets/SearchNormalIcon";
import CompletedIcon from "../../../assets/tableIcons/CompletedIcon";
import DataTable from "../../../components/dataTable/dataTable";
import {
  deleteSelection,
  updateIsDelete,
  updateMappingPagination,
  updateRowCount,
  updateSelectionModelChange,
  updateTableRows,
} from "../../../redux/slices/dataTableSlice";
import {
  deleteMapping,
  updateCheckedBusinessUnit,
  updateCheckedDataSource,
} from "../../../redux/slices/mapping/listMappingSlice";
import {
  getBusinessUnitAndDataSource,
  listMapping,
  updateListStatus,
} from "../../../redux/slices/mapping/listMappingSlice";
import {
  getAllActiveModules,
  resetMappingValues,
  resetStatus,
  updateSelectedModule,
  updateSelectedModulesList,
} from "../../../redux/slices/mapping/mappingStep1Slice";
import "./listMappings.scss";
import { resetValues } from "../../../redux/slices/mapping/mappingStep2Slice";
import ResizableTable from "../../../components/dataTable/resizableTable";
import MyMultiSelect from "../../../components/dropdown/myMultiSelect";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MappingTable({ searchProcess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { columns, mappings, display_map, mappingTableDetails } = useSelector(
    (state) => state.listMappingReducer
  );
  const { mapping_paginationDetails, rowCount } = useSelector(
    (state) => state.dataTableReducer
  );

  const { checkedDataSource, checkedBusinessUnit } = useSelector(
    (state) => state.listMappingReducer
  );

  const {selected_modules_list}=useSelector((state)=>state.mappingStep1Reducer)


  useEffect(() => {
    let by_filters_and_pagination = {
      module_uuids:selected_modules_list.map((i)=>i.id),
      business_unit_uuids: checkedBusinessUnit.map((bu) => bu.id),
      sub_datasource_uuids: checkedDataSource.map((ds) => ds.id),
      search: searchProcess ? searchProcess : null,
      page_no: parseInt(mapping_paginationDetails.page) + 1,
      page_size: mapping_paginationDetails.pageSize,
    };
    dispatch(listMapping(by_filters_and_pagination));
  }, [
    checkedBusinessUnit,
    checkedDataSource,
    mapping_paginationDetails,
    selected_modules_list,
    searchProcess,
  ]);

  const cols = useMemo(() =>
    columns
      ? columns?.map((column) => {
          if (column === "created_at") {
            return {
              accessorKey: column,
              header: display_map[column],
              Cell: ({ cell }) => {
                const timestamp = cell.getValue();
                const formattedDate =
                  moment(timestamp).format("MMM DD YYYY HH:mm");

                return (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formattedDate}
                  </div>
                );
              },
            };
          }
          return {
      
            accessorKey: column,
            header: display_map[column],
            Cell: ({ cell }) => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {cell.getValue()}
              </div>
            ),
         
          };
          
        })
      : []
  );

  const rows = mappings
    ? mappings?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateMappingPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {
    const mapping_uuid = params.original.id;

    navigate(`/${localStorage.getItem("ci")}/mapping/${mapping_uuid}/edit/fields-files`);
  };

  return (
    <>
      <>
        <div
          className="listmappingWrapper"
          style={{ overflow: "scroll", height: "calc(100vh - 170px)" }}
        >
          <Grid className="listMappingWrap">
            <ResizableTable
              data={rows}
              columns={cols}
              paginationDetails={mapping_paginationDetails}
              onTablePaginationChange={handlePaginationModelChange}
              onTableRowClick={handleRowClick}
              rowCount={mappingTableDetails?.total || 0}
            />
          </Grid>
        </div>
      </>
    </>
  );
}

function ListMappings(props) {
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkedDataSource, checkedBusinessUnit } = useSelector(
    (state) => state.listMappingReducer
  );

  // State Value
  const { isDeleteVisible } = useSelector((state) => state.dataTableReducer);
  const { business_units, listStatus, dataSources } =
    useSelector((state) => state.listMappingReducer);
  const { mapping_paginationDetails} = useSelector(
    (state) => state.dataTableReducer
  );
  const {modules,selected_modules_list}=useSelector((state)=>state.mappingStep1Reducer)

  const [searchProcessChange, setSearchProcessChange] = useState();
  const [searchProcess, setSearchProcess] = useState();
  const [open, setOpen] = useState(false);

  // Redux

  const { selectionModel} = useSelector(
    (state) => state.dataTableReducer
  );
  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  useEffect(() => {
    dispatch(resetStatus());
    dispatch(resetValues());
    dispatch(resetMappingValues());
    dispatch(getAllActiveModules())

  }, []);

  useEffect(() => {
      dispatch(getBusinessUnitAndDataSource(selected_modules_list.map((item)=>item.id)));
  }, [selected_modules_list]);

  const handleSearchProcessChange = (event) => {
    const value = event.target.value;
    setSearchProcessChange(value);
    setSearchProcess(value);
  };

  const debounceOnChange = debounce(handleSearchProcessChange, 1000);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchProcess(searchProcessChange);
    }
  };
  const handleDeleteMapping = () => {
    const mappingUUIDs = Object.keys(selectionModel);

    let by_mapping_uuids = {
      customer_uuid: localStorage.getItem("ci"),
      module_uuids:selected_modules_list.map((i)=>i.id),
      mapping_uuids: mappingUUIDs,
      business_unit_uuids: checkedBusinessUnit.map((bu) => bu.id),
      sub_datasource_uuids: checkedDataSource.map((ds) => ds.id),
      search: searchProcess ? searchProcess : null,
      page_no: parseInt(mapping_paginationDetails.page) + 1,
      page_size: mapping_paginationDetails.pageSize,
    };
    dispatch(deleteMapping(by_mapping_uuids));
    dispatch(updateIsDelete(false));
    setOpen(false);
    setOpenSnack(true);
  };

  const handleMappingCreation = () => {
    navigate(`/${localStorage.getItem("ci")}/mapping/select-datasource`);
  };

  const handleBusinessUnitSelected = (newValue) => {
    dispatch(updateCheckedBusinessUnit(newValue));
  };

  const handleDataSourceSelected = (newValue) => {
    dispatch(updateCheckedDataSource(newValue));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid backgroundColor={colors.grey.touch}>
        <Grid
          display="flex"
          sx={{
            padding: "10px 0 0 12px",
          }}
          justifyContent="space-between"
        >
          <Grid>
          <div className="mainTitle" style={{marginBottom: "16px"}}>
            <Typography variant="h4" sx={{ mb: 2 }}>
            Mapping
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
            Map fields to utilize them for finding issue trends.{" "}
            </Typography>
          </div>
           
          </Grid>
          <Grid display="flex" gap="16px" marginTop="10px">
          <Grid width="200px">
              <MyMultiSelect
                options={
                 modules.map((m)=>({
                  label:m.name,
                  id:m.uuid

                 }))
                    
                }
                label="Modules"
                selectedValues={selected_modules_list}
                handleChange={(newValue) =>
                  dispatch(updateSelectedModulesList((newValue)))
                }
              />
            </Grid>
            <Grid width="200px">
              <MyMultiSelect
                options={
                  listStatus
                    ? business_units?.map((bu) => ({
                        label: bu.name,
                        id: bu.uuid,
                      }))
                    : []
                }
                label="Business Units"
                selectedValues={checkedBusinessUnit}
                handleChange={(newValue) =>
                  handleBusinessUnitSelected(newValue)
                }
              />
            </Grid>
            <Grid width="200px" marginRight="26px">
              <MyMultiSelect
                options={
                  listStatus
                    ? dataSources?.map((ds) => ({
                        label: ds.name,
                        id: ds?.uuid,
                      }))
                    : []
                }
                label="Datasources"
                selectedValues={checkedDataSource}
                handleChange={(newValue) => handleDataSourceSelected(newValue)}
              />
            
            </Grid>
          </Grid>
        </Grid>
        {/* <Divider variant="middle" /> */}
        <Grid marginLeft="12px" marginRight="12px" height="706px">
          <Paper
            sx={{
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Grid
              display="flex"
              marginLeft="0px"
              marginTop="0px"
              paddingTop="12px"
              justifyContent="space-between"
            >
              <Grid style={{ flex: 1 }} marginLeft="12px" marginRight="12px">
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchNormalIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for mapping by name"
                  size="small"
                  fullWidth
                  onChange={debounceOnChange}
                  onKeyDown={handleKeyDown}
                />
              </Grid>
              {isDeleteVisible && (
                <Grid item style={{ width: "184px", marginRight: "16px" }}>
                  <Button
                    variant="ascendo"
                    startIcon={
                      <DeleteOutlineIcon
                        sx={{ color: colors.ascendo.darkblue }}
                      />
                    }
                    onClick={handleClickOpen}
                    fullWidth
                  >
                    Delete Mapping
                  </Button>
                </Grid>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the selected mappings?
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 3, pb: 2.5 }}>
                  <Button
                    variant="ascendo"
                    width="75px"
                    sx={{
                      color: "white",
                      backgroundColor: "red",
                      "&:hover": {
                        color: "rgba(7, 25, 66)",
                      },
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="ascendo"
                    width="75px"
                    onClick={handleDeleteMapping}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={openSnack}
                message="Deleted successfully"
                action={action}
                onClose={handleSnackBarClose}
                autoHideDuration={3500}
              />
              <Grid item style={{ width: "184px" }} marginRight="12px">
                <Button
                  variant="ascendo"
                  startIcon={
                    <AddCircle
                      sx={{ color: colors.ascendo.darkblue }}
                      opacity={!listStatus ? 0.2 : 1}
                    />
                  }
                  onClick={handleMappingCreation}
                  fullWidth
                  disabled={!listStatus}
                >
                  Create Mapping
                </Button>
              </Grid>
            </Grid>
            <MappingTable searchProcess={searchProcess} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ListMappings;
