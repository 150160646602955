import React from "react";

function ArrowLeftIcon({opacity}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.52247 4.39432C8.36786 4.23971 8.17071 4.16706 7.97474 4.16706C7.77877 4.16706 7.58162 4.23971 7.42701 4.39432L2.36867 9.45266C2.06843 9.7529 2.06843 10.2479 2.36867 10.5481L7.42701 15.6065C7.72725 15.9067 8.22223 15.9067 8.52247 15.6065C8.82272 15.3062 8.82272 14.8112 8.52247 14.511L4.01187 10.0004L8.52247 5.48979C8.82272 5.18954 8.82272 4.69457 8.52247 4.39432Z"
        fill="#071942"
        fillOpacity={opacity}
        stroke="#071942"
        strokeWidth="0.3"
      />
      <path
        d="M17.084 9.225L3.05899 9.225C2.63448 9.225 2.28399 9.57549 2.28399 10C2.28399 10.4245 2.63448 10.775 3.05899 10.775L17.084 10.775C17.5085 10.775 17.859 10.4245 17.859 10C17.859 9.57549 17.5085 9.225 17.084 9.225Z"
        fill="#071942"
        stroke="#071942"
        strokeWidth="0.3"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
