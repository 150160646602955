import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ArrowRightIcon from "../../../assets/ArrowRightIcon";
import CustomStepper from "../../../components/stepper/customStepper";
import { resetAccordion } from "../../../redux/slices/accordionSlice";
import {
  createMapping,
  resetStatus,
  updateMapping,
  updateMappingName,
} from "../../../redux/slices/mapping/mappingStep1Slice";
import {
  createNewAiBot,
  getAibot,
  getBotSetting,
  getBotVersions,
  updateBotSetting,
  updateNewAiBot,
  updateSnackMessage,
} from "../../../redux/slices/supportChannels/supportChannelsSlice";

function BasicDetails() {
  const steps = ["Basic Details", "Mapping Selection", "Summary"];
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [openSnack, setOpenSnack] = useState(false);

  const { new_aibot_uuid, bot_versions, bot_settings, is_created ,snack_message} =
    useSelector((state) => state.supportChannelReducer);

  //  State Value
  const [customer_uuid] = useState(localStorage.getItem("ci"));
  const [aibotName, setAibotName] = useState("");
  const [version, setVersion] = useState("");
  const [open, setOpen] = React.useState(false);

  // Redux

  const {
    selected_bu,
    selected_ds,
    mapping_name,
    get_mapping_status,

    status_code,
    selected_module,
  } = useSelector((state) => state.mappingStep1Reducer);

  // Functions

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  

  const handleClose = () => {
    dispatch(resetAccordion());
    navigate(`/${customer_uuid}/support-channel/aibots`);
  };

  const handleNextStep = () => {
    let data = {
      bot_uuid:params?.bot_uuid,
      name: aibotName,
      version_uuid: version?.uuid,
    };
    if (params?.bot_uuid){ dispatch(updateBotSetting(data));
      navigate(
        `/${customer_uuid}/support-channel/aibots/${params.bot_uuid}/step-2`
      );
    }
    else dispatch(createNewAiBot(data));
  };

  useEffect(() => {
    if (params?.bot_uuid) dispatch(getAibot(params.bot_uuid));
    dispatch(getBotVersions());
  }, []);

  useEffect(() => {
    if (bot_settings && bot_versions&&params?.bot_uuid) {

      setAibotName(bot_settings?.name);
      setVersion(
        bot_versions?.find((item) => item.uuid === bot_settings?.version_uuid)
      );
    }
  }, [bot_settings, bot_versions]);

  useEffect(() => {
    if (is_created) {
      dispatch(updateNewAiBot(null));
      navigate(
        `/${customer_uuid}/support-channel/aibots/${new_aibot_uuid}/step-2`
      );
    }
  }, [is_created]);
  useEffect(()=>{
    if(snack_message){
      setOpenSnack(true)
      const timer = setTimeout(() => {
        setOpenSnack(false)
        dispatch(updateSnackMessage(null));
      }, 3500);
      // Cleanup function to clear the timer if snack_message changes or component unmounts
      return () => clearTimeout(timer);
    }
  },[snack_message])

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        zIndex: 1300,
        position: "absolute",
        top: 0,
        left: 0,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {/* Sidebar */}
      <Grid
        width="232px"
        sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
      ></Grid>

      {/* Main Content */}
      <Grid
        width="calc(100% - 232px)"
        backgroundColor="white"
        position="relative"
        sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
      >
        <Grid display="flex" variant="stepperheaderFixedtop" marginRight="40px">
          <Grid width="100%">
            <Typography
              margin="40px 0px 0px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Create AI Bot
            </Typography>

            <Typography
              margin="8px 0px 20px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontSize: "14px", lineHeight: "17px" }}
            >
              Create a AI Bot that satisfies your requirements.
            </Typography>
          </Grid>
          <Typography marginTop="20px">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
        </Grid>

        <Grid variant="stepperFixedtop">
          <Divider variant="middle" />
          <CustomStepper steps={steps} activeStep={0} />
        </Grid>

        <Grid variant="stepperScroller">
          <div>
            <Divider variant="middle" />
            <Typography
              margin="26px 0px 29px 46px"
              fontWeight="500"
              lineHeight="17px"
              color={colors.ascendo.darkblue}
            >
              Create AI Bot
            </Typography>
            <Grid marginLeft="46px" marginBottom="26px">
              <Grid width="460px" >
                <TextField
                  size="small"
                  placeholder="Enter AI Bot name here..."
                  label="AI Bot Name"
                  fullWidth
                  value={aibotName}
                  onChange={(e) => setAibotName(e.target.value)}
                  required
                />
              </Grid>
              <Grid
                width="460px"
                display={"flex"}
                alignItems={"center"}
                mt="20px"
              >
                <Autocomplete
                  disablePortal
                  fullWidth
                  disableClearable
                  options={bot_versions}
                  value={version}
                  onChange={(event, newValue) => setVersion(newValue)}
                  isOptionEqualToValue={(option, value) =>
                    option?.uuid === value?.uuid
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Version" />
                  )}
                />
              </Grid>
            </Grid>
            <Divider variant="middle" />
          </div>
        </Grid>

        <Divider variant="middle" />

        {/* Continue Button */}
        <Grid
          variant="stepperFixedbottombtn"
          display="flex"
          justifyContent="flex-end"
          marginBottom="32px"
          marginRight="40px"
          gap={2}
        >
         <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={3500}
          message={snack_message}
          action={action}
          onClose={handleSnackBarClose}
        />

          <Grid item width="120px" margin="26px 0px 0px 0px">
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.ascendo.yellow,
                color: colors.ascendo.darkblue,
                textTransform: "none",
              }}
              endIcon={
                <ArrowRightIcon
                  opacity={version?.uuid && aibotName.length > 0 ? 1 : 0.2}
                />
              }
              onClick={handleNextStep}
              disabled={!(version?.uuid && aibotName.length > 0)}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BasicDetails;
