import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import {
  CalendarMonth,
  Cancel,
  CheckCircle,
  Close,
  FilterAlt
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import AibotColorIcon from "../../assets/AibotColorIcon";
import AiresolveIcon from "../../assets/airesolveIcon";
import DummyIcon from "../../assets/dummyIcon";
import InboxFilter from "../../assets/InboxFilter";
import Inboxrefresh from "../../assets/Inboxrefresh";
import MagicWand from "../../assets/MagicWand";
import SendIcon from "../../assets/SendIcon";
import SlackColorIcon from "../../assets/SlackColorIcon";
import TicketOutline from "../../assets/TicketOutline";
import ResizableTable from "../../components/dataTable/autoPaginationTable";
import MyDateRangePicker from "../../components/datepicker/myDateRangePicker";
import AigenerateIcon from "../../assets/images/aigenerate.svg";
import {
  addRoom,
  createTicket,
  editTab,
  getAgents,
  getInteractionById,
  getInteractionDetails,
  getInteractionStatusList,
  getInteractions,
  getNewInteractions,
  getRoomByInteraction,
  getSentiment,
  removeRoom,
  resetTicketDetails,
  toggleSelectAllInteractionFilter,
  updateAgent,
  updateChats,
  updateInteractionFilter,
  updateIsListLoading,
  updateSelectAllInteraction,
  updateSelectedTab,
  updateTabs,
} from "../../redux/slices/inboxSlice";
import InboxPredictions from "../inboxPredictionView/inboxPredictionView";
import "./inboxview.scss";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  let initials = name ? name.split(" ")[0][0] : ""; // First initial

  // Add second initial if it exists
  if (name && name.split(" ")[1]) {
    initials += name.split(" ")[1][0];
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 24,
      height: 24,
      fontSize: 12,
    },
    children: initials,
  };
}

function transform(value) {
  if (value) {
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    if (seconds < 29) {
      return "Just now";
    }

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let counter;
    for (const i in intervals) {
      counter = Math.floor(seconds / intervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          return counter + " " + i + " ago"; // singular (1 day ago)
        } else {
          return counter + " " + i + "s ago"; // plural (2 days ago)
        }
      }
    }
  }
  return value;
}

const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

function InboxTable() {
  const dispatch = useDispatch();
  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  useEffect(() => {
    dispatch(getNewInteractions());

    const intervalId = setInterval(() => {
      dispatch(getNewInteractions());
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);
  const { new_interactions_details, agents } = useSelector(
    (state) => state.inboxReducer
  );

  const handleUpdateAgent = (agent, row) => {
    let data = {
      interaction_id: row.id,
      agent_uuid: agent.uuid,
      flag: true,
      updated_by: JSON.parse(localStorage.getItem("ud")).uuid,
      interaction_type_id: row.interaction_type_id,
      status: "Agent Assigned",
      assigned_by: JSON.parse(localStorage.getItem("ud")).uuid,
    };
    dispatch(updateAgent(data));
    setOpenSnack(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "label",
        header: "Id",
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {cell.getValue()}
            </div>
          </Tooltip>
        ),
      },
      {
        accessorKey: "problem",
        header: "Title",
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {cell.getValue()}
            </div>
          </Tooltip>
        ),
      },
      {
        accessorKey: "interaction_type_name",
        header: "Type",
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {cell.getValue()}
            </div>
          </Tooltip>
        ),
      },
      {
        accessorKey: "username",
        header: "Owner",
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {cell.getValue()}
            </div>
          </Tooltip>
        ),
      },
      {
        accessorKey: "agent_uuid",
        header: "Assigned To",
        minSize: 250,
        Cell: ({ cell }) => {
          const row = cell.row.original;
          const value = cell.getValue();
          const agent = agents.find((item) => item.uuid === value);
          const selectedValue = agent
            ? { ...agent, label: agent?.username, id: agent?.uuid }
            : "";

          return (
            <Grid width="100%" key={cell.row.original.id}>
              <Autocomplete
                disableClearable
                size="small"
                value={selectedValue}
                onChange={(event, newValue) => handleUpdateAgent(newValue, row)}
                sx={{
                  "& .MuiAutocomplete-input": {
                    marginTop: "-4px",
                  },
                }}
                getOptionDisabled={(option) =>
                  option?.status?.value !== "available"
                }
                options={agents.map((item) => ({
                  ...item,
                  label: item.username,
                  id: item.uuid,
                }))}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{ textTransform: "capitalize" }}
                    key={option.id}
                  >
                    {option.label !== "--Support--" && (
                      <span>
                        {option.status?.value === "available" ? (
                          <CheckCircle
                            fontSize="12px"
                            sx={{
                              marginBottom: "-2px",
                              marginRight: "4px",
                              color: "green",
                            }}
                          />
                        ) : (
                          <Cancel
                            fontSize="12px"
                            sx={{
                              marginBottom: "-2px",
                              marginRight: "4px",
                            }}
                          />
                        )}
                      </span>
                    )}
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Assignee"
                    fullWidth
                    sx={{ height: "28px" }}
                  />
                )}
              />
            </Grid>
          );
        },
      },
      {
        accessorKey: "created_at",
        header: "Created At",
        Cell: ({ cell }) => {
          const timestamp = cell.getValue();
          const formattedDate = transform(timestamp);

          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {formattedDate}
            </div>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {cell.getValue()}
            </div>
          </Tooltip>
        ),
      },
    ],
    []
  );

  const rows = new_interactions_details
    ? new_interactions_details?.data?.map((dataItem) => {
        const row = { id: dataItem.uuid, ...dataItem };

        columns.forEach((column) => {
          row[column.accessorKey] = dataItem[column.accessorKey];
        });

        return row;
      })
    : [];

  const handleRowClick = (params) => {};

  return (
    <>
      <Grid marginTop="20px">
        <ResizableTable
          columns={columns}
          onTableRowClick={handleRowClick}
          data={rows}
          rowCount={new_interactions_details?.total || 0}
        />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        autoHideDuration={3500}
        message={"Agent Assigned Successfully!"}
        action={action}
        onClose={handleSnackBarClose}
      />
    </>
  );
}

export default function Inboxview() {
  const [alignment, setAlignment] = React.useState("web");
  const [socket, setSocket] = useState();
  const [openDate, setOpenDate] = useState(false);
  const [dateLabel, setDateLabel] = useState();
  const [date, setDate] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");

  const [genAiDetails, setGenAiDetails] = useState();
  // useEffect(()=>{
  //   const audio = new Audio(bellSound);
  //   audio.play();
  // },[])

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleDateChange = (state) => {
    setDateLabel(
      `${moment(state.startDate).format("MM/DD/YYYY")}-${moment(
        state.endDate
      ).format("MM/DD/YYYY")}`
    );
    setDate({
      start_date: moment(state.startDate).format("YYYY-MM-DD"),
      end_date: moment(state.endDate).format("YYYY-MM-DD"),
    });
  };
  const [value, setValue] = useState("");

  const [openGenAi, setOpenGenAi] = useState(false);

  const theme = useTheme();
  const colors = theme.palette;

  const dispatch = useDispatch();

  const {
    selectedTab,
    allTabs,
    interaction_status_list,
    interactionFilter,
    selectAllInteraction,
    interactions,
    agents,
    rooms,
    ticket_details,
    isListLoading,
    new_interactions_details,
  } = useSelector((state) => state.inboxReducer);

  const [search, setSearch] = useState("");
  const [tabValue, setTabValue] = useState(1);
  const [filterOpen, setFilterOpen] = useState(false);
  const [genAiOpen, setGenAiOpen] = useState(false);
  const [agentMessage, setAgentMessage] = useState("");
  const ref = useRef();
  const activeTab = allTabs.find((item) => item.uuid === selectedTab);
  const [resetState, setResetState] = useState(false);
  const [draft, setDraft] = useState("");
  const [genRes, setGenRes] = useState("");
  const props = {
    business_unit: activeTab?.source_product,
    query: activeTab?.problem,
    request_channel: "Inbox",
    product_group: activeTab?.product_family,
    read_documents: activeTab?.chats?.flatMap((chat) => {
      if (chat?.message?.length > 0) {
        return chat.message.flatMap((message) => {
          if (message?.prediction_results?.length > 0) {
            return message.prediction_results.map((pr) => pr._id);
          }
          return [];
        });
      }
      return [];
    }),
  };

  useEffect(() => {
    if (activeTab?.chats?.length) {
      ref.current?.scrollIntoView({
        behaviour: "smooth",
        block: "end",
        inline: "end",
      });
    }
  }, [activeTab?.chats?.length]);

  useEffect(() => {
    if (ticket_details?.message) {
      setMessage(`${ticket_details?.message} - ${ticket_details?.ticket_id}`);
      setOpenSnack(true);

      // Reset ticket_details after 6 seconds
      const resetTimeout = setTimeout(() => {
        setOpenSnack(false); // Assuming you also want to close the snack after resetting
        dispatch(resetTicketDetails());
      }, 3500);

      // Cleanup the timeout to prevent memory leaks
      return () => clearTimeout(resetTimeout);
    }
    if (ticket_details?.detail) {
      setMessage(ticket_details?.detail);
      setOpenSnack(true);

      // Reset ticket_details after 6 seconds
      const resetTimeout = setTimeout(() => {
        setOpenSnack(false); // Assuming you also want to close the snack after resetting
        dispatch(resetTicketDetails());
      }, 3500);

      // Cleanup the timeout to prevent memory leaks
      return () => clearTimeout(resetTimeout);
    }
  }, [ticket_details?.message, ticket_details?.detail]);

  const getColor = (status) => {
    switch (status) {
      case "In Progress":
        return "#EF9E42";
        break;
      case "Solved":
      case "Closed":
        return "#32C49E";
        break;
      case "Failed & Closed":
        return "#E25757";
      default:
        return "#EF9E42";
    }
  };

  const getStatusOptions = (status) => {
    switch (status) {
      case "In Progress":
      case "On Hold":
        return interaction_status_list.filter((item) =>
          [
            "in_progress",
            "on_hold",
            "solved",
            "closed",
            "failed_and_closed",
          ].includes(item.value)
        );
        break;
      case "Agent Requested":
        return interaction_status_list.filter((item) =>
          ["agent_requested"].includes(item.value)
        );
        break;
      case "Agent Assigned":
        return interaction_status_list.filter((item) =>
          ["agent_assigned"].includes(item.value)
        );
        break;
      case "Solved":
        return interaction_status_list.filter((item) =>
          ["in_progress", "solved", "closed", "failed_and_closed"].includes(
            item.value
          )
        );
        break;
      case "Closed":
        return interaction_status_list.filter((item) =>
          ["closed"].includes(item.value)
        );
        break;
      case "Failed & Closed":
        return interaction_status_list.filter((item) =>
          ["failed_and_closed"].includes(item.value)
        );
        break;
      case "Waiting On Ticket":
        return interaction_status_list.filter((item) =>
          ["ticket_closed", "solved", "waiting_on_ticket"].includes(item.value)
        );
        break;
      case "New":
        return interaction_status_list.filter((item) =>
          ["new"].includes(item.value)
        );
        break;
      case "Bot":
        return interaction_status_list.filter((item) =>
          ["bot"].includes(item.value)
        );
        break;

      default:
        // Return a default array or an empty array if needed
        return [];
    }
  };
  const handleGetInteraction = () => {
    let data = {
      status_list: interaction_status_list
        .filter((item) => interactionFilter.includes(item.value))
        .map((item) => item.label),
      search,
      from_date: date?.start_date ?? "",
      to_date: date?.end_date ?? "",
    };
    dispatch(getInteractions(data));
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };
  useEffect(() => {
    if (genAiDetails) {
      setOpenGenAi(genAiDetails.openGenAi);
      setDraft(genAiDetails.description);
    }
  }, [genAiDetails]);

  useEffect(() => {
    createSocketConnection();
    return () => {
      disconnectSocket();
    };
    // ... other initialization logic ...
  }, []);

  useEffect(() => {
    if (activeTab?.room_details) {
      if (!rooms.includes(activeTab?.room_details?.room_name)) {
        joinRoom();
        dispatch(addRoom(activeTab?.room_details?.room_name));
      }
    }
  }, [activeTab?.room_details]);
  useEffect(() => {
    dispatch(updateIsListLoading(true));
  }, [search, interactionFilter, date]);

  const disconnectSocket = () => {
    if (socket) socket.disconnect();
  };

  useEffect(() => {
    const debouncedHandleGetInteraction = debounce(handleGetInteraction, 1500);
    debouncedHandleGetInteraction();
    const intervalId = setInterval(() => {
      if (selectedTab === "home28082001" && tabValue === 1)
        handleGetInteraction();
    }, 15000);

    return () => {
      debouncedHandleGetInteraction.cancel();
      clearInterval(intervalId);
    };
  }, [interactionFilter, search, date, selectedTab, tabValue]);
  useEffect(() => {
    dispatch(getNewInteractions());

    const intervalId = setInterval(() => {
      if (selectedTab === "home28082001") dispatch(getNewInteractions());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [selectedTab]);

  useEffect(() => {
    dispatch(getInteractionStatusList());
    dispatch(getAgents());
  }, []);

  useEffect(() => {
    if (selectedTab !== "home28082001") {
      dispatch(getInteractionDetails(selectedTab));
      dispatch(getInteractionById(selectedTab));
      dispatch(getRoomByInteraction(selectedTab));
      dispatch(getSentiment(selectedTab));
    }
  }, [selectedTab]);

  const handleRefresh = () => {
    setSearch("");
    dispatch(getInteractionStatusList());
    setResetState(true);
  };

  const handleCreateTicket = () => {
    let data = {
      body: activeTab?.problem,
      interaction_uuid: selectedTab,
      title: activeTab?.problem,
      priority: "high",
      updated_by: JSON.parse(localStorage.getItem("ud")).uuid,
    };
    dispatch(createTicket(data));
  };

  const createSocketConnection = () => {
    const newSocket = io(process.env.REACT_APP_SOCKET);
    setSocket(newSocket);
  };

  const joinRoom = () => {
    const data = {
      user_type: "agent",
      room_name: activeTab?.room_details?.room_name,
    };
    socket.emit("joinPrivateRoom", data);
  };
  useEffect(() => {
    if (socket && activeTab?.room_details?.room_name) {
      function onReceivePrivateMessage(message) {
        let date = new Date();
        const newChat = {
          username: message.data.sender.username,
          message: [
            {
              text: message.data.message,
              prediction_results: [],
              quick_replies: [],

              user_activity: message.data.user_activity,
            },
          ],
          created_at: moment(date).format("MMM DD YYYY HH:mm"),
          email: message.data.sender.email,
        };
        if (activeTab?.room_details?.room_name === message?.data?.room_name)
          dispatch(updateChats(newChat));
      }

      // Listen for the 'receivePrivateMessage' event
      socket.on("receivePrivateMessage", onReceivePrivateMessage);

      return () => {
        // Clean up the event listener when the component unmounts
        socket.off("receivePrivateMessage", onReceivePrivateMessage);
      };
    }
  }, [socket, activeTab?.room_details]);

  const leaveRoom = (user_activity) => {
    const data = {
      user_type: "agent",
      room_name: activeTab?.room_details?.room_name,
      message: "",
      user_activity: user_activity ?? {},
    };
    if (rooms.includes(activeTab?.room_details?.room_name)) {
      socket.emit("leavePrivateRoom", data);
      dispatch(removeRoom(activeTab?.room_details?.room_name));
    }
  };

  const sendMessage = (user_activity) => {
    let date = new Date();
    const data = {
      customer_uuid: localStorage.getItem("ci"),
      interaction_id: selectedTab,
      room_name: activeTab?.room_details?.room_name,
      message: agentMessage,
      sender: {
        uuid: JSON.parse(localStorage.getItem("ud")).uuid,
        username: JSON.parse(localStorage.getItem("ud")).username,
        email: JSON.parse(localStorage.getItem("ud")).email,
      },
      user_type: "agent",
      user_activity: user_activity ?? null,
    };
    const newChat = {
      username: JSON.parse(localStorage.getItem("ud")).username,
      message: [
        {
          text: agentMessage,
          prediction_results: [],
          quick_replies: [],

          user_activity: user_activity ?? null,
        },
      ],
      created_at: moment(date).format("MMM DD YYYY HH:mm"),
      email: JSON.parse(localStorage.getItem("ud")).email,
    };
    dispatch(updateChats(newChat));

    if (activeTab?.status === "Agent Assigned") {
      let data = {
        interaction_id: selectedTab,
        agent_uuid: null,
        flag: true,
        updated_by: JSON.parse(localStorage.getItem("ud")).uuid,
        interaction_type_id: activeTab?.interaction_type_id,
        status: "In Progress",
        assigned_by: JSON.parse(localStorage.getItem("ud")).uuid,
      };
      dispatch(updateAgent(data));
      dispatch(editTab({ tab: selectedTab, data: { status: "In Progress" } }));
    }

    socket.emit("sendPrivateMessage", data);

    setAgentMessage("");
  };

  const handleOpenInteractionTab = (tab) => {
    if (!allTabs.some((item) => item.uuid === tab.uuid))
      dispatch(updateTabs([...allTabs, { ...tab, name: tab.label }]));
    dispatch(updateSelectedTab(tab.uuid));
  };

  const handleChangeStatus = (status) => {
    let data = {
      interaction_id: selectedTab,
      agent_uuid: null,
      flag: true,
      updated_by: JSON.parse(localStorage.getItem("ud")).uuid,
      interaction_type_id: activeTab?.interaction_type_id,
      status: status,
      assigned_by: JSON.parse(localStorage.getItem("ud")).uuid,
    };
    dispatch(editTab({ tab: selectedTab, data: { status: status } }));
    setMessage("Status Updated Successfully!");
    setOpenSnack(true);
    dispatch(updateAgent(data));

    if (status === "Closed" || status === "Failed & Closed") {
      setAgentMessage("");
      let user_activity = {
        showInAgent: "Conversation " + status,
        showInCustomer: "Conversation Closed",
        status: "Closed",
      };
      sendMessage(user_activity);
      leaveRoom(user_activity);
    } else if (status === "Solved") {
      setAgentMessage("");
      let user_activity = {
        showInAgent: "Conversation Solved",
        showInCustomer: "Conversation Solved",
        status: "Solved",
      };
      sendMessage(user_activity);
      leaveRoom(user_activity);
      // this.updateInteraction();
    }
  };

  const handleChangeAgent = (agent) => {
    let data = {
      interaction_id: selectedTab,
      agent_uuid: agent?.uuid,
      flag: true,
      updated_by: JSON.parse(localStorage.getItem("ud")).uuid,
      interaction_type_id: activeTab?.interaction_type_id,
      status: activeTab?.status,
      assigned_by: JSON.parse(localStorage.getItem("ud")).uuid,
    };
    dispatch(editTab({ tab: selectedTab, data: { agent_uuid: agent?.uuid } }));
    dispatch(updateAgent(data));
    setMessage("Agent Assigned Successfully!");
    setOpenSnack(true);
  };

  useEffect(() => {
    if (interactionFilter?.length === interaction_status_list?.length) {
      dispatch(updateSelectAllInteraction(true));
    } else {
      dispatch(updateSelectAllInteraction(false));
    }
  }, [interactionFilter]);

  return (
    <div
      className="inboxWrap"
      style={{ overflow: "scroll", height: "calc(100vh - 70px)" }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Inbox
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Manage customer interactions across all channels in one place
            </Typography>
          </div>
          {tabValue === 1 && (
            <div>
              <Grid>
                <Button
                  fullWidth
                  onClick={() => setOpenDate(true)}
                  variant="ascendo"
                  endIcon={<CalendarMonth />}
                  sx={{
                    textTransform: "none",
                    textAlign: "left",
                    color: "grey",
                    borderColor: "grey",
                    border: "1px solid grey",
                    backgroundColor: "transparent",
                  }}
                >
                  {dateLabel}
                </Button>
              </Grid>
              <Grid
                display={openDate ? "block" : "none"}
                sx={{ position: "absolute", zIndex: 2, top: 70, right: 6 }}
                onMouseLeave={() => setOpenDate(false)}
              >
                <Card elevation={10}>
                  <MyDateRangePicker
                    onStateChange={handleDateChange}
                    reset={resetState}
                    resetState={() => setResetState(false)}
                  />
                </Card>
              </Grid>
            </div>
          )}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} className="pt0">
            <Tabs value={selectedTab} centered>
              {allTabs.map((tab, index) => (
                <Tab
                  className="scrollable"
                  disableRipple
                  label={
                    <Tooltip
                      title={
                        tab?.interaction_type_name
                          ? `${tab?.interaction_type_name} - ${tab?.problem}`
                          : null
                      }
                    >
                      <Grid display={"flex"} alignItems={"center"} gap="3px">
                        {tab.uuid !== "home28082001" &&
                          tab?.interaction_type_name &&
                          (tab.interaction_type_name === "Slack" ? (
                            <SlackColorIcon />
                          ) : tab.interaction_type_name ===
                            "Resolution Prediction" ? (
                            <AiresolveIcon />
                          ) : (
                            <AibotColorIcon />
                          ))}

                        <Typography
                          fontWeight={selectedTab === tab.uuid ? 600 : 400}
                        >
                          {tab.name}
                        </Typography>
                      </Grid>
                    </Tooltip>
                  }
                  value={tab.uuid}
                  onClick={() => dispatch(updateSelectedTab(tab.uuid))}
                  icon={
                    tab.uuid !== "home28082001" ? (
                      <CloseIcon
                        fontSize="small"
                        onClick={(e) => {
                          const nextIndex =
                            index === allTabs?.length - 1
                              ? index - 1
                              : index + 1;
                          e.stopPropagation();
                          dispatch(updateSelectedTab(allTabs[nextIndex].uuid));

                          dispatch(
                            updateTabs(
                              allTabs.filter((item) => item.uuid !== tab.uuid)
                            )
                          );
                        }}
                      />
                    ) : null
                  }
                  iconPosition="end"
                />
              ))}
            </Tabs>
          </Grid>
          {selectedTab === "home28082001" && (
            <Grid item xs={12} md={12} lg={12} className="pt0">
              {tabValue === 1 && (
                <Grid>
                  <div className="centerDiv">
                    <TextField
                      size="small"
                      sx={{
                        width: "95%",
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          padding: 0,
                        },
                      }}
                      value={search}
                      onChange={handleSearchChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleGetInteraction();
                      }}
                      type="text"
                      placeholder="Search Inbox"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ marginRight: "12px" }}
                          >
                            {search.length > 0 && (
                              <IconButton
                                size="small"
                                onClick={() => setSearch("")}
                              >
                                <Close fontSize="14px" />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Tooltip title="Refresh">
                      <IconButton onClick={handleRefresh}>
                        <Inboxrefresh />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter">
                      <IconButton
                        onClick={() => setFilterOpen(true)}
                        className="inboxFilter"
                      >
                        {interactionFilter?.length > 0 ? (
                          <FilterAlt sx={{ color: colors.ascendo.darkblue }} />
                        ) : (
                          <InboxFilter />
                        )}
                        {interactionFilter?.length > 0 && (
                          <span className="filterCount">
                            {interactionFilter?.length}
                          </span>
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              )}
              <Grid>
                <Tabs value={tabValue} centered>
                  <Tab
                    value={1}
                    disableRipple
                    onClick={() => setTabValue(1)}
                    className="scrollable"
                    label={`My interactions ( ${interactions.length} )`}
                  />
                  <Tab
                    value={2}
                    disableRipple
                    onClick={() => setTabValue(2)}
                    className="scrollable"
                    label={`New interactions ( ${
                      new_interactions_details?.total ?? "0"
                    } )`}
                  />
                </Tabs>
              </Grid>
              <Divider />
              {tabValue === 1 && (
                <Grid>
                  <Grid>
                    <Stack direction="row" spacing={1} className="chipWrap">
                      {interactionFilter.map((item) => (
                        <Chip
                          key={item}
                          label={`Status - ${
                            interaction_status_list.find(
                              (interaction) => interaction.value === item
                            )?.label
                          }`}
                          onDelete={() =>
                            dispatch(updateInteractionFilter(item))
                          }
                        />
                      ))}
                    </Stack>
                  </Grid>
                  <Grid>
                    {isListLoading && (
                      <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"400px"}
                      >
                        <CircularProgress
                          sx={{ color: colors.ascendo.darkblue }}
                        />
                      </Grid>
                    )}
                    {!isListLoading && interactions.length === 0 && (
                      <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"400px"}
                      >
                        <DummyIcon />{" "}
                        <Typography sx={{ fontSize: "1.5rem" }}>
                          No results found
                        </Typography>
                      </Grid>
                    )}
                    {!isListLoading &&
                      interactions.map((item) => (
                        <Card
                          className="commonCard chartCard"
                          onClick={() => handleOpenInteractionTab(item)}
                        >
                          <div className="cardStatuswrap">
                            <div className="leftCardwrap">
                              <Tooltip title={item.email}>
                                <p className="nameTitle">
                                  <span className="badgeTitle">
                                    {item.username[0]}
                                  </span>{" "}
                                  {item.username}
                                </p>
                              </Tooltip>
                              <Tooltip title="Interaction Id">
                                {" "}
                                <p className="idNumber">{`#${item.label}`}</p>
                              </Tooltip>
                              <Tooltip title="Product Family">
                                <p className="channelName">
                                  {item.product_family}
                                </p>
                              </Tooltip>
                              <div className="d-flex alignCenter">
                                <Button
                                  className="statusBtn"
                                  sx={{
                                    color: getColor(item.status),

                                    borderColor: getColor(item.status),
                                  }}
                                >
                                  {item.status}
                                </Button>
                                <Tooltip title={item?.interaction_type_name}>
                                  {" "}
                                  {item.interaction_type_name === "Slack" ? (
                                    <SlackColorIcon />
                                  ) : item.interaction_type_name ===
                                    "Resolution Prediction" ? (
                                    <AiresolveIcon />
                                  ) : (
                                    <AibotColorIcon />
                                  )}
                                </Tooltip>
                              </div>
                            </div>
                            <div className="rightCardwrap">
                              <Grid className="questansWrap">
                                <Tooltip title="Problem Statement">
                                  <div className="questionWrap">
                                    {item.problem}
                                  </div>
                                </Tooltip>
                                <div className="answerWrap">
                                 {item?.resolution_summary}
                                </div>
                                {/* <div className="sentimentIcons good">
                                <SentimentGood /> Good
                              </div> */}
                              </Grid>
                              <Grid className="footerWrap">
                                <div className="resolutionIcon">
                                  {/* <IconButton>
                                    <InboxResolution />{" "}
                                  </IconButton>
                                  Instant Resolution */}
                                </div>
                                <div className="updateTime">
                                  {`Updated ` +
                                    transform(item.updated_at) +
                                    `, Created ` +
                                    transform(item.created_at)}
                                </div>
                              </Grid>
                            </div>
                          </div>
                        </Card>
                      ))}
                  </Grid>
                </Grid>
              )}
              {tabValue === 2 && (
                <Grid>
                  <Card sx={{ p: "12px" }}>
                    <InboxTable />
                  </Card>
                </Grid>
              )}
            </Grid>
          )}
          {selectedTab !== "home28082001" && (
            <Grid item xs={12} md={12} lg={12} display={"flex"} className="pt0">
              <Grid width={"20%"}>
                {activeTab?.interaction_type_name === "Slack" && (
                  <Card className="commonCard slackTips">
                    <TipsAndUpdatesIcon /> For Slack Agents. The inbox is only
                    for tracking. Please use the Slack App to continue the
                    conversation.
                  </Card>
                )}
                <Card className="commonCard inboxMain">
                  <div className="inboxmainInner">
                    <p className="nameTitle username">
                      <Avatar
                        className="badgeAvatar"
                        {...stringAvatar(
                          `${activeTab?.username?.toUpperCase()}`
                        )}
                      />

                      {/* <span className="badgeTitle">
                          {activeTab?.username[0]}
                        </span>{" "} */}
                      {activeTab?.username}
                    </p>
                    <p className="nameTitle username">{activeTab?.email}</p>
                  </div>
                </Card>
                <Card className="commonCard inboxMain">
                  <div className="inboxmainInner sideBar">
                    <p className="labelText sentimentText">Sentiment </p>
                      <div
                        className="sentimentIcons ">
                     
                      <img src={AigenerateIcon}/> {activeTab?.sentiment_label}
                      </div>
                  </div>
                </Card>
                <Card className="commonCard inboxMain">
                  <div className="inboxmainInner interactionDetails">
                    <p className="labelText">Interaction Details </p>
                    <span className="labelTitle">Interaction Id</span>
                    <p className="nameTitle idNumber">
                      {/* <span className="badgeTitle">#</span>{" "} */}
                      {`#` + activeTab?.label}
                    </p>

                    <span className="labelTitle">Subject</span>
                    <p className="nameTitle">
                      {/* <span className="badgeTitle">
                        <InboxSubject />
                      </span>{" "} */}
                      <Typography className="innerQuest">
                        {activeTab?.problem}
                      </Typography>
                    </p>

                    <span className="labelTitle">Updated on</span>
                    <p className="nameTitle">
                      {/* <span className="badgeTitle">
                          <InboxCreated />
                        </span>{" "} */}
                      {transform(activeTab?.updated_at)}
                    </p>

                    <span className="labelTitle">Created on</span>
                    <p className="nameTitle">
                      {/* <span className="badgeTitle">
                          <InboxUpdated />
                        </span>{" "} */}
                      {transform(activeTab?.created_at)}
                    </p>

                    <Divider />
                    <Grid
                      my="16px"
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"16px"}
                    >
                      {activeTab?.status && (
                        <Autocomplete
                          disableClearable
                          size="small"
                          onChange={(event, newValue) =>
                            handleChangeStatus(newValue.label)
                          }
                          value={interaction_status_list.find(
                            (status) => status.label === activeTab?.status
                          )}
                          options={getStatusOptions(activeTab?.status)}
                          renderInput={(params) => (
                            <TextField {...params} label="Status" />
                          )}
                        />
                      )}
                      {!["Failed & Closed", "Closed", "Solved"].includes(
                        activeTab?.status
                      ) && (
                        <Autocomplete
                          disableClearable
                          size="small"
                          onChange={(event, newValue) =>
                            handleChangeAgent(newValue)
                          }
                          value={
                            activeTab?.agent_uuid
                              ? {
                                  ...agents.find(
                                    (agent) =>
                                      agent.uuid === activeTab?.agent_uuid
                                  ),
                                  label: agents.find(
                                    (agent) =>
                                      agent.uuid === activeTab?.agent_uuid
                                  )?.username,
                                  id: agents.find(
                                    (agent) =>
                                      agent.uuid === activeTab?.agent_uuid
                                  )?.uuid,
                                }
                              : { label: "--Support--", id: "support28082001" }
                          }
                          getOptionDisabled={(option) =>
                            option?.status?.value !== "available"
                          }
                          options={
                            activeTab?.agent_uuid
                              ? agents.map((item) => ({
                                  ...item,
                                  label: item.username,
                                  id: item.uuid,
                                }))
                              : [
                                  {
                                    label: "--Support--",
                                    id: "support28082001",
                                  },
                                  ...agents.map((item) => ({
                                    ...item,
                                    label: item.username,
                                    id: item.uuid,
                                  })),
                                ]
                          }
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{ textTransform: "capitalize" }}
                              key={option.id}
                            >
                              {option.label !== "--Support--" && (
                                <span>
                                  {option.status?.value === "available" ? (
                                    <CheckCircle
                                      fontSize="12px"
                                      sx={{
                                        marginBottom: "-2px",
                                        marginRight: "4px",
                                        color: "green",
                                      }}
                                    />
                                  ) : (
                                    <Cancel
                                      fontSize="12px"
                                      sx={{
                                        marginBottom: "-2px",
                                        marginRight: "4px",
                                      }}
                                    />
                                  )}
                                </span>
                              )}
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Dedicated Hero" />
                          )}
                        />
                      )}
                    </Grid>
                    <Divider />
                    <div className="createBtn">
                      <Button
                        sx={{
                          textTransform: "none",
                          color: colors.ascendo.darkblue,
                          mt: "8px",
                          fontSize: "14px",
                        }}
                        disabled={activeTab?.ticket_id}
                        startIcon={
                          <Grid
                            sx={{ opacity: activeTab?.ticket_id ? 0.4 : 1 }}
                          >
                            <TicketOutline />
                          </Grid>
                        }
                        onClick={handleCreateTicket}
                      >
                        {activeTab?.ticket_id
                          ? "Ticket Created"
                          : "Create Ticket"}
                      </Button>
                    </div>
                  </div>
                </Card>
              </Grid>

              <Grid width={"80%"}>
                <section className="chatbotWrap">
                  <div className="chatbot-container">
                    <div ref={ref}>
                      {!activeTab?.chats && (
                        <Grid
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          height={"400px"}
                        >
                          <CircularProgress
                            sx={{ color: colors.ascendo.darkblue }}
                          />
                        </Grid>
                      )}
                      {activeTab?.chats?.length > 0 &&
                        activeTab?.chats.map((chat, mainIndex) =>
                          chat?.email ===
                          JSON.parse(localStorage.getItem("ud")).email
                            ? chat?.message.map((item) => (
                                <Grid className="bot-time">
                                  {chat?.email !==
                                    activeTab?.chats[mainIndex - 1]?.email && (
                                    <div>
                                      <Tooltip
                                        title={JSON.parse(
                                          localStorage.getItem("ud")
                                        )?.username}
                                      >
                                        <Avatar
                                          className="bot-avatar"
                                          {...stringAvatar(
                                            `${JSON.parse(
                                              localStorage.getItem("ud")
                                            )?.username?.toUpperCase()}`
                                          )}
                                        />
                                      </Tooltip>
                                      <span className="bot-details">
                                        {moment(chat?.created_at).format(
                                          "MMM DD YYYY HH:mm"
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  {item?.text && (
                                    <Grid className="bot-wrap">
                                      <div className="bot-avatar">AI</div>

                                      <Typography className="bot-message">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.text,
                                          }}
                                        />
                                      </Typography>
                                    </Grid>
                                  )}
                                  {item?.user_activity && (
                                    <Grid
                                      marginY="10px"
                                      width="100%"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Grid
                                        backgroundColor="#fff"
                                        py="6px"
                                        px="14px"
                                        borderRadius="14px"
                                      >
                                        <Typography
                                          color="rgba(7, 25, 66, 0.6)"
                                          fontFamily="Nunito Sans"
                                          fontSize="12px"
                                        >
                                          {item?.user_activity?.showInAgent}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))
                            : chat?.message.map((item, index) => (
                                <Grid className="user-time">
                                  {activeTab?.chats[mainIndex - 1]
                                    ?.user_activity !== chat?.username &&
                                    index === 0 && (
                                      <Tooltip title={chat?.username}>
                                        <Avatar
                                          className="user-avatar"
                                          {...stringAvatar(
                                            `${chat?.username?.toUpperCase()}`
                                          )}
                                        />
                                      </Tooltip>
                                    )}
                                  {!Object.keys(chat).includes("username") &&
                                    Object.keys(
                                      activeTab?.chats[mainIndex - 1]
                                    )?.includes("username") &&
                                    index === 0 && (
                                      <Tooltip title="Ascendo Bot">
                                        {" "}
                                        <AibotColorIcon />
                                      </Tooltip>
                                    )}
                                  {index === 0 && (
                                    <span className="user-details">
                                      {moment(chat?.created_at).format(
                                        "MMM DD YYYY HH:mm"
                                      )}
                                    </span>
                                  )}
                                  <Grid className="user-wrap">
                                    <Typography className="user-message">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item?.text,
                                        }}
                                      />{" "}
                                    </Typography>
                                    <div className="user-avatar">GK</div>
                                  </Grid>
                                  {item?.quick_replies && (
                                    <ul className="businessunit-options">
                                      {item.quick_replies?.map((qr) => (
                                        <li>
                                          {" "}
                                          <span className="active1">{qr}</span>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                  {item.prediction_results &&
                                    item.prediction_results?.map((pr) => (
                                      <>
                                        <Accordion className="accordion-wrap">
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                          >
                                            <Grid
                                              display="flex"
                                              justifyContent="space-between"
                                            >
                                              <Typography
                                                fontSize="13px"
                                                display="flex"
                                                alignItems="center"
                                              >
                                                {pr?.header?.Title}
                                              </Typography>{" "}
                                              <div
                                                className="datasource-set"
                                                style={{
                                                  marginLeft: "16px",
                                                  padding: "0px",
                                                }}
                                              >
                                                <span
                                                  className="score"
                                                  style={{
                                                    marginLeft: "16px",
                                                    backgroundColor:
                                                      pr.probability > 70
                                                        ? "#32C49E"
                                                        : pr.probability > 30
                                                        ? "#EF9E42"
                                                        : "#E25757",
                                                  }}
                                                >
                                                  {pr.probability}
                                                </span>
                                              </div>
                                            </Grid>
                                            {pr?.sub_datasource && (
                                              <div className="datasource-set">
                                                <span
                                                  className="datasource-label"
                                                  style={{
                                                    color:
                                                      pr?.sub_datasource.color,
                                                    backgroundColor: hexToRGBA(
                                                      pr?.sub_datasource.color,
                                                      0.08
                                                    ),
                                                  }}
                                                >
                                                  {pr.sub_datasource.name}
                                                </span>
                                              </div>
                                            )}
                                          </AccordionSummary>
                                          <AccordionDetails className="accordion-details">
                                            <div className="desc-content">
                                              {pr.body?.map((item, index) => (
                                                <div key={index}>
                                                  {Object.entries(item).map(
                                                    (
                                                      [key, value],
                                                      subIndex
                                                    ) => (
                                                      <>
                                                        {value && (
                                                          <Typography
                                                            key={subIndex}
                                                            sx={{
                                                              whiteSpace:
                                                                "pre-line",
                                                            }}
                                                          >
                                                            <b className="key-title">
                                                              {key}:
                                                            </b>
                                                            {key === "URL" ? (
                                                              <a
                                                                href={value}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                              >
                                                                {value}
                                                              </a>
                                                            ) : (
                                                              <div
                                                                dangerouslySetInnerHTML={{
                                                                  __html: value,
                                                                }}
                                                              />
                                                            )}
                                                          </Typography>
                                                        )}
                                                      </>
                                                    )
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                          </AccordionDetails>
                                        </Accordion>
                                      </>
                                    ))}
                                </Grid>
                              ))
                        )}
                    </div>
                  </div>
                  {activeTab?.interaction_type_name !== "Slack" &&
                    !(
                      activeTab?.status?.includes("Solved") ||
                      activeTab?.status?.includes("Closed")
                    ) && (
                      <footer>
                        <div class="input-container">
                          <Tooltip title="Predict">
                            <IconButton onClick={() => setOpenGenAi(true)}>
                              <MagicWand />
                            </IconButton>
                          </Tooltip>
                          <TextField
                            value={agentMessage}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") sendMessage();
                            }}
                            onChange={(e) => setAgentMessage(e.target.value)}
                            id="user-input"
                            label="Type to chat"
                            fullWidth
                            size="small"
                          />
                          <IconButton
                            id="send-button"
                            variant="contained"
                            color="primary"
                            onClick={() => sendMessage()}
                          >
                            <SendIcon />
                          </IconButton>
                        </div>
                      </footer>
                    )}
                </section>
              </Grid>
            </Grid>
          )}
       

          <Drawer
            sx={{
              "& .MuiPaper-root.MuiDrawer-paper ": {
                marginTop: "64px",
                width: "465px",
                padding: "16px",
                marginBottom: "65px",
                height: "calc(100% - 65px)",
                zIndex: "1200 !important",
              },
            }}
            anchor={"right"}
            open={filterOpen}
            onClose={() => setFilterOpen(false)}
          >
            <div sx={{ overflow: "hidden" }}>
              <div className="fixedWrap">
                <IconButton
                  className="closeIcon"
                  sx={{ position: "fixed", right: "10px", top: "70px" }}
                  onClick={() => setFilterOpen(false)}
                >
                  <CloseIcon sx={{ fontSize: "large" }} />
                </IconButton>
                <Typography
                  className="primaryText boldText"
                  color={colors.ascendo.darkblue}
                >
                  Filter
                  <Divider />
                </Typography>
              </div>
              <div>
                <Typography
                  mt="40px"
                  fontSize={"14px"}
                  fontWeight={600}
                  color={colors.ascendo.darkblue}
                >
                  Status
                </Typography>

                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  onClick={() => dispatch(toggleSelectAllInteractionFilter())}
                >
                  <Checkbox
                    size="small"
                    checked={selectAllInteraction}
                    disableRipple
                    sx={{ padding: "2px 4px 2px 0px" }}
                  />
                  <Typography fontSize={"13px"} color={colors.ascendo.darkblue}>
                    Select All
                  </Typography>
                </Grid>
                {interaction_status_list?.map((item) => (
                  <Grid
                    key={item.value}
                    display={"flex"}
                    alignItems={"center"}
                    onClick={() =>
                      dispatch(updateInteractionFilter(item.value))
                    }
                  >
                    <Checkbox
                      size="small"
                      checked={interactionFilter.includes(item.value)}
                      disableRipple
                      sx={{ padding: "2px 4px 2px 0px" }}
                    />
                    <Typography
                      fontSize={"13px"}
                      color={colors.ascendo.darkblue}
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                ))}
              </div>
            </div>
          </Drawer>
          <Drawer
            sx={{
              "& .MuiPaper-root.MuiDrawer-paper ": {
                marginTop: "64px",
                width: "1000px",
                padding: "16px",
                marginBottom: "65px",
                backgroundColor:"#f5f5f5"
              },
            }}
            anchor={"right"}
            open={openGenAi}
            onClose={() => setOpenGenAi(false)}
          >
            <div>
              

              <div>
                <div style={{ position: "absolute", right: "10px" }}>
                  <IconButton size="small" onClick={() => setOpenGenAi(false)}>
                    <Close />
                  </IconButton>
                </div>
                <InboxPredictions
                  props={props}
                  handleOpenGenAi={(value) => setGenAiDetails(value)}
                />
              </div>
            </div>
          </Drawer>
        </Grid>
        <Snackbar
          key="ticket"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={5000}
          message={message}
          action={action}
          onClose={handleSnackBarClose}
        />
      </Container>
    </div>
  );
}
