import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateMapping,
  UpdateMapping,
  GetMapping,
} from "../../actions/mapping/mappingActions";
import { GetActiveChannels } from "../../actions/dataIntegration/channelActions";
import { GetAllActiveModules } from "../../actions/identity/moduleActions";

export const getMapping = createAsyncThunk(
  "getMapping",
  async (mapping_uuid, thunkAPI) => {
    const response = await GetMapping(mapping_uuid);
    return response;
  }
);

export const createMapping = createAsyncThunk(
  "createmapping",
  async (data, thunkAPI) => {
    const response = await CreateMapping(
      data.sub_datasource_uuid,
      data.business_unit_uuid,
      data.name,
      data.module_uuid
    );

    return response;
  }
);

export const updateMapping = createAsyncThunk(
  "updatemapping",
  async (data, thunkAPI) => {
    const response = await UpdateMapping(
      data.sub_datasource_uuid,
      data.business_unit_uuid,
      data.name,
      data.mapping_uuid,
      data.module_uuid

    );

    return response;
  }
);

export const getActiveChannelsMapping = createAsyncThunk(
  "getActiveChannels",
  async (thunkAPI) => {
    const response = await GetActiveChannels();
    return response;
  }
);

export const getAllActiveModules = createAsyncThunk(
  "getAllActiveModules",
  async (thunkAPI) => {
    const response = await GetAllActiveModules();
    return response;
  }
);
const mappingStep1Slice = createSlice({
  name: "mappingStep1",
  initialState: {
    selected_bu: null,
    selected_ds: null,
    selected_module: null,
    selected_modules_list:[],
    mapping_name: null,
    get_mapping_status: null,
    mapping_uuid: null,
    status_code: null,
    create_mapping_status: false,
    channels: [],
    selected_channels: [],
    makeDefault: false,
    aibot_board: [],
    slackbot_board: [],
    modules: [],
  },
  reducers: {
    updateSelectedBuValue: (state, action) => {
      state.selected_bu = action.payload;
    },
    updateSelectedDsValue: (state, action) => {
      state.selected_ds = action.payload;
    },
    updateSelectedModule: (state, action) => {
      state.selected_module = action.payload;
    },
    updateMappingName: (state, action) => {
      state.mapping_name = action.payload;
    },
    resetMappingValues: (state, action) => {
      state.selected_bu = null;
      state.selected_ds = null;
      state.selected_module = null;
      state.mapping_name = null;
    },
    resetStatus: (state, action) => {
      state.create_mapping_status = null;
      state.status_code = null;
    },
    newStatus: (state, action) => {
      state.create_mapping_status = true;
    },
    updateSelectedModulesList:(state,action)=>{
      state.selected_modules_list=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMapping.pending, (state, action) => {
        state.get_mapping_status = false;
      })
      .addCase(getMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.selected_bu = action.payload.data.business_unit;
          state.selected_ds = action.payload.data.sub_datasource;
          state.selected_module = state.modules.find(
            (item) => item.uuid === action.payload.data.module_uuid
          );

          state.mapping_name = action.payload.data.name;
          state.get_mapping_status = true;
        } else {
          state.get_mapping_status = false;
        }
      })
      .addCase(getMapping.rejected, (state, action) => {
        state.get_mapping_status = false;
      })
      .addCase(createMapping.pending, (state, action) => {
        state.create_mapping_status = false;
      })
      .addCase(createMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.mapping_uuid = action.payload.data.mapping_uuid;
          state.create_mapping_status = true;
        } else {
          state.create_mapping_status = false;
          state.status_code = action.payload.data.detail;
        }
      })
      .addCase(createMapping.rejected, (state, action) => {
        state.create_mapping_status = false;
        state.status_code = 400;
      })
      .addCase(updateMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.mapping_uuid = action.payload.data.mapping_uuid;
          state.create_mapping_status = true;
        } else {
          state.create_mapping_status = false;
          state.status_code = action.payload.data.detail;
        }
      })
      .addCase(updateMapping.rejected, (state, action) => {
        state.create_mapping_status = false;
        state.status_code = 400;
      })
      .addCase(getAllActiveModules.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.modules = action.payload.data.data;
          state.selected_modules_list=action.payload.data.data.map((item)=>({label:item.name,id:item.uuid}))
        }
      });
  },
});

export const {
  updateSelectedBuValue,
  updateSelectedDsValue,
  updateMappingName,
  resetMappingValues,
  resetStatus,
  updateSelectedModule,
  updateSelectedModulesList
} = mappingStep1Slice.actions;
export default mappingStep1Slice.reducer;
