import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ActivateBot,
  CreateNewAiBot,
  DeleteBots,
  GetAibot,
  GetAllBots,
  GetBotVersions,
  RefreshApiKeyForBot,
  UpdateBotSetting,
  UpdateBotVersion
} from "../../actions/chatServices/chatServicesActions";
import {
  GetChannelByName,
  RefreshApiKeyForChannel,
} from "../../actions/dataIntegration/channelActions";
import {
  CreateChannelMappingForAibot,
  DeleteChannelMapping,
  GetChannelMappingTable,
  GetChannelMappingTableForSlack,
  UpdateChannelMappingStatus,
  UpdateChannelMappingStatusForSlack,
} from "../../actions/dataIntegration/channelMappingActions";
import {
  AddEmailForAibotNotification,
  GetEmailsForAibotNotification,
  RemoveEmailForAibotNotification,
  ValidateEmailForAibotNotification,
} from "../../actions/identity/aibotEmailSettings";
import { ListMappingAll } from "../../actions/listMappingActions";
import { GetSlackWorkspaces } from "../../actions/slack/slackActions";

export const getChannelByName = createAsyncThunk(
  "getChannelByName",
  async (channel_name, thunkAPI) => {
    const response = await GetChannelByName(channel_name);
    return response;
  }
);

export const refreshApiKeyForChannel = createAsyncThunk(
  "refreshApiKeyForChannel",
  async (channel_uuid, thunkAPI) => {
    const response = await RefreshApiKeyForChannel(channel_uuid);
    return response;
  }
);

export const getChannelMappingTable = createAsyncThunk(
  "getChannelMappingTable",
  async (data, thunkAPI) => {
    const response = await GetChannelMappingTable(
      data.search,
      data.page_no,
      data.page_size,
      data.bot_uuid
    );
    return response;
  }
);

export const getChannelMappingTableForSlack = createAsyncThunk(
  "getChannelMappingTableForSlack",
  async (data, thunkAPI) => {
    const response = await GetChannelMappingTableForSlack(
      data.page_no,
      data.page_size,
      data.workspace_uuid
    );
    return response;
  }
);

export const getSlackWorkspaces = createAsyncThunk(
  "getSlackWorkspaces",
  async (thunkAPI) => {
    const response = await GetSlackWorkspaces();
    return response;
  }
);

export const updateChannelMappingStatus = createAsyncThunk(
  "updateChannelMappingStatus",
  async (data, thunkAPI) => {
    const response = await UpdateChannelMappingStatus(
      data.channel_mapping_uuid,
      data.bot_uuid,
      data.is_active
    );
    return response;
  }
);

export const updateChannelMappingStatusForSlack = createAsyncThunk(
  "updateChannelMappingStatusForSlack",
  async (data, thunkAPI) => {
    await UpdateChannelMappingStatusForSlack(
      data.channel_mapping_uuid,
      data.workspace_uuid,
      data.is_active
    );
    const response = await GetChannelMappingTableForSlack(
      data.page_no,
      data.page_size,
      data.workspace_uuid
    );
    return response;
  }
);

export const getEmailsForAibotNotification = createAsyncThunk(
  "getEmailsForAibotNotification",
  async (thunkAPI) => {
    const response = await GetEmailsForAibotNotification();
    return response;
  }
);

export const validateEmailForAibotNotification = createAsyncThunk(
  "validateEmailForAibotNotification",
  async (email, thunkAPI) => {
    const response = await ValidateEmailForAibotNotification(email);

    return { response, email };
  }
);

export const addEmailForAibotNotification = createAsyncThunk(
  "addEmailForAibotNotification",
  async (email, thunkAPI) => {
    await AddEmailForAibotNotification(email);
    const response = await GetEmailsForAibotNotification();

    return response;
  }
);

export const removeEmailForAibotNotification = createAsyncThunk(
  "removeEmailForAibotNotification",
  async (email_address, thunkAPI) => {
    await RemoveEmailForAibotNotification(email_address);
    const response = await GetEmailsForAibotNotification();

    return response;
  }
);

export const deleteChannelMappingFromTable = createAsyncThunk(
  "deleteChannelMappingFromTable",
  async (data, thunkAPI) => {
    await DeleteChannelMapping(data.deleteData);
    const response = await GetChannelMappingTable(
      data.search,
      data.page_no,
      data.page_size,
      data.channel_name
    );
    return response;
  }
);

export const getBotVersions = createAsyncThunk(
  "getBotVersions",
  async (thunkAPI) => {
    const response = await GetBotVersions();
    return response;
  }
);
export const getAibot = createAsyncThunk(
  "getAibot",
  async (bot_uuid, thunkAPI) => {
    const response = await GetAibot(bot_uuid);
    return response;
  }
);
export const createNewAiBot = createAsyncThunk(
  "createNewAiBot",
  async (data,thunkAPI) => {
    const response = await CreateNewAiBot(data.name,data.version_uuid);
    return response;
  }
);
export const getAllBots = createAsyncThunk(
  "getAllBots",
  async (data, thunkAPI) => {
    const response = await GetAllBots(data.search,data.page_no, data.page_size);
    return response;
  }
);
export const deleteBots = createAsyncThunk(
  "deleteBots",
  async (data, thunkAPI) => {
    await DeleteBots(data.bot_uuids);
    const response = await GetAllBots(data.search,data.page_no, data.page_size);

    
    return response;
  }
);
export const refreshApiKeyForBot = createAsyncThunk(
  "refreshApiKeyForBot",
  async (data, thunkAPI) => {
    await RefreshApiKeyForBot(data.bot_uuid);
    const response = await GetAllBots(data.page_no, data.page_size);

    return response;
  }
);
export const updateBotVersion = createAsyncThunk(
  "updateBotVersion",
  async (version, thunkAPI) => {
    const response = await UpdateBotVersion(version);
    return response;
  }
);
export const updateBotSetting = createAsyncThunk(
  "updateBotSetting",
  async (data, thunkAPI) => {
    const response = await UpdateBotSetting(
      data.bot_uuid,
      data.name,
      data.version_uuid
    );
    return response;
  }
);
export const listMappingsForAibot = createAsyncThunk(
  "listMappingsForAibot",
  async (data, thunkAPI) => {
    const response = await ListMappingAll(
      data.business_unit_uuids,
      data.sub_datasource_uuids
    );
    return response;
  }
);

export const createChannelMappingsForAibot = createAsyncThunk(
  "createChannelMappingsForAibot",
  async (data, thunkAPI) => {
    const response = await CreateChannelMappingForAibot(
      data.bot_uuid,
      data.mapping_uuids
    );
    return response;
  }
);
export const activateBot = createAsyncThunk(
  "activateBot",
  async (data, thunkAPI) => {
    const response = await ActivateBot(
      data.bot_uuid,
      data.is_active
    );
    return response;
  }
);

const supportChannelSlice = createSlice({
  name: "supportChannel",
  initialState: {
    channel_details: null,
    aibot_table: null,
    slack_table: null,
    slack_workspaces: [],
    selected_workspace: null,
    included_emails: [],
    add_emails: [],
    is_add_email: true,
    bot_versions: [],
    selected_version: null,
    all_bots: [],
    new_aibot_uuid: null,
    is_created: false,
    bot_settings: null,
    selected_bu: [],
    selected_ds: [],
    selected_mappings: [],
    aibot_mappings: null,
  is_channel_mapping_created:false,
  snack_message:null,
  },
  reducers: {
    updateSelectedWorkspace: (state, action) => {
      state.selected_workspace = action.payload;
    },
    updateAddEmails: (state, action) => {
      state.add_emails = action.payload;
    },
    updateIsAddEmail: (state, action) => {
      state.is_add_email = action.payload;
    },
    updateSelectedVersion: (state, action) => {
      state.selected_version = action.payload;
    },
    updateNewAiBot: (state, action) => {
      state.is_created = action.payload;
    },
    updateSelectedBu: (state, action) => {
      state.selected_bu = state.selected_bu.includes(action.payload)
        ? state.selected_bu.filter((bu) => bu !== action.payload)
        : [...state.selected_bu, action.payload];
    },
    updateSelectedDs: (state, action) => {
      state.selected_ds = state.selected_ds.includes(action.payload)
        ? state.selected_ds.filter((ds) => ds !== action.payload)
        : [...state.selected_ds, action.payload];
    },
    updateSelectedMapping: (state, action) => {
      state.selected_mappings = state.selected_mappings.includes(action.payload)
      ? state.selected_mappings.filter((mappings) => mappings !== action.payload)
      : [...state.selected_mappings, action.payload];
    },
    updateIsChannelMappingCreated:(state,action)=>{
      state.is_channel_mapping_created=action.payload
    },
    resetAibotValues:(state,action)=>{
      state.selected_bu=[]
      state.selected_ds=[]
      state.selected_mappings=[]
      state.aibot_mappings=null
      state.bot_settings=null

    },
    updateSnackMessage:(state,action)=>{
      state.snack_message=action.payload
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getChannelByName.pending, (state) => {
        state.channel_details = null;
      })
      .addCase(getChannelByName.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.channel_details = action.payload.data;
        }
      })
      .addCase(refreshApiKeyForChannel.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.channel_details = action.payload.data;
        }
      })
      .addCase(getChannelMappingTable.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.aibot_table = action.payload.data;
      })
      .addCase(deleteChannelMappingFromTable.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          switch (action.meta.arg.channel_name) {
            case "AI Bot":
              state.aibot_table = action.payload.data;
              break;
          }
        }
      })
      .addCase(getChannelMappingTableForSlack.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.slack_table = action.payload.data;
        }
      })
      .addCase(
        updateChannelMappingStatusForSlack.fulfilled,
        (state, action) => {
          if (action.payload.status === 200) {
            state.slack_table = action.payload.data;
          }
        }
      )
      .addCase(getSlackWorkspaces.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.slack_workspaces = action.payload.data;
          state.selected_workspace = {
            ...action.payload.data[0],
            label: action.payload.data[0]?.team_name,
            id: action.payload.data[0]?.uuid,
          };
        }
      })
      .addCase(getEmailsForAibotNotification.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.included_emails = action.payload.data;
      })
      .addCase(addEmailForAibotNotification.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.included_emails = action.payload.data;
        state.add_emails = [];
      })
      .addCase(removeEmailForAibotNotification.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.included_emails = action.payload.data;
      })
      .addCase(validateEmailForAibotNotification.fulfilled, (state, action) => {
        state.add_emails = state.add_emails.map((email) => {
          if (email.value === action.payload.email) {
            return {
              ...email,
              isApiValidatedEmail: !action.payload.response.data,
            };
          } else {
            return email;
          }
        });
        if (!action.payload.response.data) {
          state.is_add_email = true;
        }
      })
      .addCase(getBotVersions.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.bot_versions = action.payload.data;
      })
      .addCase(getAllBots.fulfilled, (state, action) => {
        if (action.payload.status === 200) state.all_bots = action.payload.data;
      })
      .addCase(deleteBots.fulfilled, (state, action) => {
        if (action.payload.status === 200) state.all_bots = action.payload.data;
      })
      .addCase(refreshApiKeyForBot.fulfilled, (state, action) => {
        if (action.payload.status === 200) state.all_bots = action.payload.data;
      })
      .addCase(createNewAiBot.pending, (state, action) => {
       state.snack_message=null
      })
      .addCase(createNewAiBot.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.new_aibot_uuid = action.payload.data.aibot_uuid;
          state.is_created = true;
        }
        else{
          state.snack_message=action.payload.data.detail
        }
      })
      .addCase(getAibot.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.bot_settings = action.payload.data.data
        }
      })
      .addCase(listMappingsForAibot.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.aibot_mappings = action.payload.data;
        }
      })
      .addCase(createChannelMappingsForAibot.pending, (state, action) => {
          state.snack_message=null
        
      })
      .addCase(createChannelMappingsForAibot.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.is_channel_mapping_created=true
        }
        else{
          state.snack_message=action.payload.data.detail
        }

      }),
});

export const {
  updateSelectedWorkspace,
  updateAddEmails,
  updateIsAddEmail,
  updateSelectedVersion,
  updateNewAiBot,
  updateSelectedBu,
  updateSelectedDs,
  updateSelectedMapping,
  updateIsChannelMappingCreated,
  resetAibotValues,
  updateSnackMessage
} = supportChannelSlice.actions;
export default supportChannelSlice.reducer;
