import React from 'react';
import {
    Container,
    Grid,
    Typography
} from "@mui/material";
import {
    Card,
    Divider,
    Box,
    Drawer,
    Checkbox,
    TextField,
    Button,
    useTheme,
    IconButton,
    Snackbar,
    Tooltip,
    InputAdornment,
    CircularProgress,
  } from "@mui/material";
import "./NewInsights.scss";
import NorthIcon from '@mui/icons-material/North';
import Linecharts from '../../components/highcharts/linecharts';
import SemiPie from '../../components/highcharts/semipie';
import DonutCharts from '../../components/highcharts/donutcharts';
import Cardline from '../../components/highcharts/cardline';

function NewInsights() {
    return (
        <div
            className="newinsightsWrap"
            style={{
                overflow: "scroll",
                height: "calc(100vh - 70px)",
                marginTop: "12px",
            }}
        >
            <Container maxWidth="xl">
                <div className="mainTitleWrap">
                    <div className="mainTitle">
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            New Insights
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Track Your Progress and Reclaim the best customer experience!
                        </Typography>
                    </div>
                </div>
                <Grid container spacing={2} className="sectionHeader d-flex" >
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} >
                                <Card className="cardSection">
                                <div className='CardTitle'>Signed User</div>
                                <div className='d-flex imageTextrap'>
                            <Typography variant='h4'>56</Typography>
                            <div className='arrowWrap'><NorthIcon/></div>
                            </div>
                                    </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Card className="cardSection">
                            <div className='CardTitle'>Signed User</div>
                            <div className='d-flex imageTextrap'>
                            <Typography variant='h4'>56</Typography>
                            <div className='arrowWrap'><NorthIcon/></div>
                            </div>
                                    </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Card className="cardSection">
                            <div className='CardTitle'>Signed User</div>
                            <div className='d-flex imageTextrap'>
                            <Typography variant='h4'>56</Typography>
                            <div className='arrowWrap'><NorthIcon/></div>
                            </div>
                                    </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Card className="cardSection">
                            <div className='CardTitle'>Signed User</div>
                            <div className='d-flex imageTextrap'>
                            <Typography variant='h4'>56</Typography>
                            <div className='arrowWrap'><NorthIcon/></div>
                            </div>
                                    </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Card className="cardSection">
                                <div className='CardTitle'>Signed User</div>
                                <div className='d-flex'>
                              <Cardline/>
                                </div>
                                    </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default NewInsights;
