import axios from "../../../utils/axios";

export const GetServiceNowStatus = async (subdatasoure_name) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `servicenow/${localStorage.getItem("ci")}/status?subdatasource_name=${subdatasoure_name}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};