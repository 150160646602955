import React from "react";

function FailedIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6291_49721)">
        <path
          d="M7.00033 13.2702C3.54116 13.2702 0.729492 10.4585 0.729492 6.99935C0.729492 3.54018 3.54116 0.728516 7.00033 0.728516C10.4595 0.728516 13.2712 3.54018 13.2712 6.99935C13.2712 10.4585 10.4595 13.2702 7.00033 13.2702ZM7.00033 1.60352C4.02533 1.60352 1.60449 4.02435 1.60449 6.99935C1.60449 9.97435 4.02533 12.3952 7.00033 12.3952C9.97533 12.3952 12.3962 9.97435 12.3962 6.99935C12.3962 4.02435 9.97533 1.60352 7.00033 1.60352Z"
          fill="#E25757"
        />
        <path
          d="M7 8.02018C6.76083 8.02018 6.5625 7.82185 6.5625 7.58268V4.66602C6.5625 4.42685 6.76083 4.22852 7 4.22852C7.23917 4.22852 7.4375 4.42685 7.4375 4.66602V7.58268C7.4375 7.82185 7.23917 8.02018 7 8.02018Z"
          fill="#E25757"
        />
        <path
          d="M7.00033 9.9157C6.92449 9.9157 6.84866 9.8982 6.77866 9.86904C6.70866 9.83987 6.64449 9.79904 6.58616 9.74654C6.53366 9.6882 6.49283 9.62987 6.46366 9.55404C6.43449 9.48404 6.41699 9.4082 6.41699 9.33237C6.41699 9.25654 6.43449 9.1807 6.46366 9.1107C6.49283 9.0407 6.53366 8.97654 6.58616 8.9182C6.64449 8.8657 6.70866 8.82487 6.77866 8.7957C6.91866 8.73737 7.08199 8.73737 7.22199 8.7957C7.29199 8.82487 7.35616 8.8657 7.41449 8.9182C7.46699 8.97654 7.50783 9.0407 7.53699 9.1107C7.56616 9.1807 7.58366 9.25654 7.58366 9.33237C7.58366 9.4082 7.56616 9.48404 7.53699 9.55404C7.50783 9.62987 7.46699 9.6882 7.41449 9.74654C7.35616 9.79904 7.29199 9.83987 7.22199 9.86904C7.15199 9.8982 7.07616 9.9157 7.00033 9.9157Z"
          fill="#E25757"
        />
      </g>
      <defs>
        <clipPath id="clip0_6291_49721">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FailedIcon;
