import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import {
  getModuleInfo,
  getUserInfo,
} from "../redux/slices/authenticationSlice";

const Authentication = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
 

  const customer_uuid = params.customer_uuid;
  const access_token = params.access_token;
  const refresh_token = params.refresh_token;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirectPath = urlParams.get("redirect_path");
  localStorage.setItem("tz", timeZone);
  localStorage.setItem("ci", customer_uuid);
  localStorage.setItem("at", access_token);
  localStorage.setItem("rt", refresh_token);
  const { userdetails } = useSelector((state) => state.authenticationReducer);
  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getModuleInfo(process.env.REACT_APP_MODULE_NAME));
  }, []);

  useEffect(() => {
    if (userdetails && redirectPath) {
      if (redirectPath.length > 1){
        if(!redirectPath.includes("resolution-prediction"))
       navigate(`${redirectPath}`);
      if(redirectPath.includes("resolution-prediction-v2"))
      {
        const datasource=urlParams.get("datasource")
        const product_group=urlParams.get("product_group")
        const question=urlParams.get("question")
        navigate(`${redirectPath}&datasource=${datasource}&product_group=${product_group}&question=${question}`);

      }
      if(redirectPath.includes("resolution-prediction-zen"))
      {
        const datasources=urlParams.get("datasources")
        const product_group=urlParams.get("product_group")
        const question=urlParams.get("question")
        navigate(`${redirectPath}&datasources=${datasources}&product_group=${product_group}&question=${question}`);

      }
      }
      else navigate(`/${customer_uuid}/home`);
    }
  }, [redirectPath, userdetails]);

  return <></>;
};

export default Authentication;
