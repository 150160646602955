import axios from "../../../utils/axios";

export const GetEmailsForNotification = async () => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights_email_settings/${localStorage.getItem("ci")}/emails`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const ValidateEmailForNotification = async (email) => {
  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `insights_email_settings/${localStorage.getItem(
        "ci"
      )}/email_validate?email=${encodeURIComponent(email)}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const AddEmailForNotification = async (email_address) => {
    let response = await axios(
      process.env.REACT_APP_PREDICTION_SERVICE +
        `insights_email_settings/${localStorage.getItem(
          "ci"
        )}/emails/add`,
      {
        method: "POST",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
        data:{
            email_address
        }
      }
    );
    return { data: response.data, status: response.status };
  };

  export const RemoveEmailForNotification = async (email_address) => {
    let response = await axios(
      process.env.REACT_APP_PREDICTION_SERVICE +
        `insights_email_settings/${localStorage.getItem(
          "ci"
        )}/emails/remove`,
      {
        method: "PATCH",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
        data:{
            email_address
        }
      }
    );
    return { data: response.data, status: response.status };
  };
  
  
