import React from 'react'

function GenAiIcon() {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="lamp-charge">
    <path id="Vector" d="M8.17376 13.6664C8.13376 13.6664 8.08709 13.6598 8.04709 13.6464C6.70709 13.2664 5.30043 13.2664 3.96043 13.6464C3.71376 13.7131 3.45376 13.5731 3.38709 13.3264C3.31376 13.0798 3.46043 12.8198 3.70709 12.7531C5.21376 12.3264 6.80043 12.3264 8.3071 12.7531C8.55376 12.8264 8.70043 13.0798 8.62709 13.3264C8.56043 13.5331 8.37376 13.6664 8.17376 13.6664Z" fill="#1A73E8"/>
    <path id="Vector_2" d="M10.807 3.24001C10.1136 1.84001 8.77364 0.806674 7.22031 0.466674C5.59364 0.106674 3.92697 0.493341 2.65364 1.52001C1.37364 2.54001 0.646973 4.06667 0.646973 5.70001C0.646973 7.42667 1.68031 9.23334 3.24031 10.28V10.8333C3.23364 11.02 3.22697 11.3067 3.45364 11.54C3.68697 11.78 4.03364 11.8067 4.30697 11.8067H7.72697C8.08697 11.8067 8.36031 11.7067 8.54697 11.52C8.80031 11.26 8.79364 10.9267 8.78697 10.7467V10.28C10.8536 8.88667 12.1536 5.94667 10.807 3.24001ZM7.14697 6.74667L6.43364 7.98667C6.34031 8.14667 6.17364 8.24001 6.00031 8.24001C5.91364 8.24001 5.82697 8.22001 5.75364 8.17334C5.51364 8.03334 5.43364 7.72667 5.56697 7.49334L6.13364 6.50667H5.57364C5.24031 6.50667 4.96697 6.36001 4.82031 6.11334C4.67364 5.86001 4.68697 5.55334 4.85364 5.26001L5.56697 4.02001C5.70697 3.78001 6.01364 3.70001 6.24697 3.83334C6.48697 3.97334 6.56697 4.28001 6.43364 4.51334L5.86697 5.50001H6.42697C6.76031 5.50001 7.03364 5.64667 7.18031 5.89334C7.32697 6.14667 7.31364 6.46001 7.14697 6.74667Z" fill="#1A73E8"/>
    </g>
    </svg>
    
  )
}

export default GenAiIcon