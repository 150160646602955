import axios from "../../../utils/axios";

export const CreateProcess = async (
  business_unit_uuid,
  process_name,
  mapping_uuids
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuid: business_unit_uuid,
        process_name: process_name,
        mapping_uuids: mapping_uuids,
        module_uuid:localStorage.getItem('mi')
      },
    }
  );

  return { data: response.data, status: response.status };
};
export const GetProcess = async (process_uuid) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data:{        module_uuid:localStorage.getItem('mi')

      }
    }
  );
  return { data: response.data, status: response.status };
};

export const UpdateProcess = async (
  business_unit_uuid,
  process_name,
  mapping_uuids,
  process_uuid
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuid: business_unit_uuid,
        process_name: process_name,
        mapping_uuids: mapping_uuids,
        module_uuid:localStorage.getItem('mi')

      },
    }
  );

  return { data: response.data, status: response.status };
};

export const DeleteProcess = async (process_uuids) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        process_uuids: process_uuids,

      },
    }
  );

  return { data: response.data, status: response.status };
};

export const DeleteRuns = async (run_uuids) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `run/${localStorage.getItem("ci")}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        run_uuids: run_uuids,
      },
    }
  );

  return { data: response.data, status: response.status };
};
export const RunProcess = async (process_uuid,date_from,date_to) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `run/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        process_uuid: process_uuid,
        date_from:date_from,
        date_to:date_to
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const UpdateProcessSettings = async (
  process_uuid,
 
  days_of_data,
  interval_of_days,
  is_recurring,
  date_from,
  date_to
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/setting`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
     
    
        is_recurring:is_recurring,
        recurring_setting:{
          days_of_data: days_of_data,
          interval_of_days: interval_of_days,
        },
        one_time_setting:{
          date_from:date_from,
          date_to:date_to
        }
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const UpdateStopWords = async (
  process_uuid,
  stop_words,
  remove_names,
  remove_product_names
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/stop_words`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        stop_words: stop_words,
        remove_names: remove_names,
        remove_product_names: remove_product_names,
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const UpdateExclusion = async (
  process_uuid,
  exclusion_words,
 
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/exclusion`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        exclusion_words: exclusion_words,
      
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const GetStopWords = async (process_uuid) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/stop_words`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const GetExclusions = async (process_uuid) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/exclusion`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const GetSummary = async (process_uuid) => {
  let response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/summary`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetProcessTable = async (
  module_uuid,
  business_unit_uuids,
  sub_datasource_uuids,
  search,
  page_no,
  page_size
) => {
  let url =
    process.env.REACT_APP_AUTO_CATEGORIZATION +
    `process/${localStorage.getItem(
      "ci"
    )}/table?module_uuid=${localStorage.getItem('mi')}&page_no=${page_no}&page_size=${page_size}`;
  if (search !== null) {
    url += `&search=${search}`;
  }
  let response = await axios(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
    data: {
      filter: {

        business_unit_uuids: business_unit_uuids,
        sub_datasource_uuids: sub_datasource_uuids,
      },
    },
  });
  return { data: response.data, status: response.status };
};

export const GetZendeskGroups = async () => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `zendesk/${localStorage.getItem("ci")}/groups`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetZendeskStatus = async () => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `zendesk/${localStorage.getItem("ci")}/status`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};


export const GetConfuenceSpaces = async () => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `confluence/${localStorage.getItem("ci")}/spaces`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetProcessRunTable = async (process_uuid, page_no, page_size) => {
  let url =
    process.env.REACT_APP_AUTO_CATEGORIZATION +
    `run/${localStorage.getItem(
      "ci"
    )}/table?page_no=${page_no}&page_size=${page_size}`;

  let response = await axios(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
    data: {
      process_uuid: process_uuid,
    },
  });
  return { data: response.data, status: response.status };
};
export const UpdateRunName = async (run_uuid,name) => {
  
 
  let response = await axios(   process.env.REACT_APP_AUTO_CATEGORIZATION +
    `run/${localStorage.getItem(
      "ci"
    )}/${run_uuid}/update`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
    data: {
      name: name,
    },
  });
  return { data: response.data, status: response.status };
};
export const DownloadRunResult = async (run_uuid) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `run/${localStorage.getItem("ci")}/${run_uuid}/export`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );

  return response;
};

export const CreateDirective = async (
  process_uuid,words,category
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/directive`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
       directives:[{
        words:words,
        category:category
       }]
      },
    }
  );
  return { data: response.data, status: response.status };
};


export const UpdateDirective = async (
  process_uuid,directive_uuid,words,category
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/directive`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
       directive_uuid:directive_uuid,
        words:words,
        category:category
       
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetDirectives = async (
  process_uuid
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/directive`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",

        "Content-Type": "application/json"


      },
   
  
    }
  );
  return { data: response.data, status: response.status };
};

export const DeleteDirective = async (
  process_uuid,
  directive_uuid
) => {
  const response = await axios(
    process.env.REACT_APP_AUTO_CATEGORIZATION +
      `process/${localStorage.getItem("ci")}/${process_uuid}/directive`,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",

        "Content-Type": "application/json"


      },
      data:{
        directive_uuids:[directive_uuid]
      }
   
  
    }
  );
  return { data: response.data, status: response.status };
};


