import React, { useEffect, useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {
  Card,
  Divider,
  Box,
  Drawer,
  Checkbox,
  TextField,
  Button,
  useTheme,
  IconButton,
  Snackbar,
  Tooltip,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./insightviews.scss";
import Areacharts from "../../components/highcharts/areacharts";
import Datepicker from "../../components/datepicker/datepicker";
import DummyIcon from "../../assets/dummyIcon";
import Topprediction from "../../components/highcharts/insights/topprediction";
import Predictionproduct from "../../components/highcharts/insights/predictionproduct";
import Predictionsovertime from "../../components/highcharts/insights/predictionsovertime";
import Topproduct from "../../components/highcharts/insights/topproduct";
import Topusers from "../../components/highcharts/insights/topusers";
import Datasource from "../../components/highcharts/insights/datasource";
import Cumulativedatasources from "../../components/highcharts/insights/cumulativedatasources";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment";
import MyDateRangePicker from "../../components/datepicker/myDateRangePicker";
import {
  AddCircle,
  AddCircleOutline,
  CalendarMonth,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  addEmailForNotification,
  exportInsights,
  getAllBuHavingPgUnderUser,
  getAllPgInBuForUserInsights,
  getEmailsForExclusion,
  getEmailsForNotification,
  getInsightsDashboard,
  removeEmailForExclusion,
  removeEmailForNotification,
  updateAddEmails,
  updateIsAddEmail,
  updateIsIncludeEmailValid,
  updateRemoveEmails,
  validateEmailForExclusion,
  validateEmailForNotification,
  updateIsRemoveEmail,
  addEmailForExclusion,
  getAllAccessableRegionForGroup,
  updateAnswer,
  getPredictionTable,
  getUniquePredictionTable,
  getViewsTable,
  getVotesTable,
  getInsightsRequestChannels,
} from "../../redux/slices/insightsSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { color } from "highcharts";
import validator from "validator";
import UserIcon from "../../assets/UserIcon";
import PredictionIcon from "../../assets/PredictionIcon";
import SearchIcon from "../../assets/SearchIcon";
import Uniquesearch from "../../assets/Uniquesearch";
import SearchNormalIcon from "../../assets/SearchNormalIcon";
import NlpIcon from "../../assets/NlpIcon";
import InboxFilter from "../../assets/InboxFilter";
import { getAnswersForInsights } from "../../redux/actions/nlpsql/nlpsqlActions";
import ResizableTable from "../../components/dataTable/simpleTable";
import PercentageTimeResultAvailable from "../../components/highcharts/insights/prencentageTimeResultAvailable";

function Insightsviews() {
  const theme = useTheme();
  const colors = theme.palette;
  const [openSnack, setOpenSnack] = useState(false);
  const [state, setState] = React.useState({
    right: false,
    right1: false,
  });
  const [openDate, setOpenDate] = useState(false);
  const [dateLabel, setDateLabel] = useState();
  const [date, setDate] = useState();
  const [selectedBu, setSelectedBu] = useState([]);
  const [buSelectAll, setBuSelectAll] = useState(true);
  const [pgSelectAll, setPgSelectAll] = useState(true);
  const [chSelectAll, setChSelectAll] = useState(true);

  const [regionsSelectAll, setRegionsSelectAll] = useState(true);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedPg, setSelectedPg] = useState([]);
  const [searchBu, setSearchBu] = useState("");
  const [searchPg, setSearchPg] = useState("");
  const [searchRegions, setSearchRegions] = useState("");
  const [searchChannels, setSearchChannels] = useState("");

  const [nlpOpen, setNlpOpen] = useState(false);
  const [nlpSearch, setNlpSearch] = useState("");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [predictionsPagination, setPredictionsPagination] = useState({
    page: 0,
    pageSize: 5,
  });
  const [uniquePredictionsPagination, setUniquePredictionsPagination] =
    useState({
      page: 0,
      pageSize: 5,
    });
  const [viewsPagination, setViewsPagination] = useState({
    page: 0,
    pageSize: 5,
  });
  const [votesPagination, setVotesPagination] = useState({
    page: 0,
    pageSize: 5,
  });

  const dispatch = useDispatch();
  const {
    business_units,
    product_groups,
    regions,
    dashboard_details,
    add_emails,
    is_add_email,
    included_emails,
    excluded_emails,
    remove_emails,
    is_remove_email,
    answer_for_insights,
    is_answer_loading,
    prediction_table_details,
    unique_prediction_table_details,
    views_table_details,
    votes_table_details,
    req_channels,
  } = useSelector((state) => state.insightsReducer);

  const predictionTableColumns = useMemo(() =>
    prediction_table_details?.columns
      ? prediction_table_details.columns?.map((column) => {
          return {
            accessorKey: column,
            header: prediction_table_details?.display_map[column],
            Cell: ({ cell }) => (
              <Tooltip title={cell.getValue()}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              </Tooltip>
            ),
          };
        })
      : []
  );

  const predictionTableRows = prediction_table_details?.data
    ? prediction_table_details.data?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        prediction_table_details?.columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const uniquePredictionTableColumns = useMemo(() =>
    unique_prediction_table_details?.columns
      ? unique_prediction_table_details.columns?.map((column) => {
          return {
            accessorKey: column,
            header: unique_prediction_table_details?.display_map[column],
            Cell: ({ cell }) => (
              <Tooltip title={cell.getValue()}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              </Tooltip>
            ),
          };
        })
      : []
  );

  const uniquePredictionTableRows = unique_prediction_table_details?.data
    ? unique_prediction_table_details.data?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        unique_prediction_table_details?.columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const viewsTableColumns = useMemo(() =>
    views_table_details?.columns
      ? views_table_details.columns?.map((column) => {
          return {
            accessorKey: column,
            header: views_table_details?.display_map[column],
            Cell: ({ cell }) => (
              <Tooltip title={cell.getValue()}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              </Tooltip>
            ),
          };
        })
      : []
  );

  const viewsTableRows = views_table_details?.data
    ? views_table_details.data?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        views_table_details?.columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const votesTableColumns = useMemo(() =>
    votes_table_details?.columns
      ? votes_table_details.columns?.map((column) => {
          return {
            accessorKey: column,
            header: votes_table_details?.display_map[column],
            Cell: ({ cell }) => (
              <Tooltip title={cell.getValue()}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              </Tooltip>
            ),
          };
        })
      : []
  );

  const votesTableRows = votes_table_details?.data
    ? votes_table_details.data?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        votes_table_details?.columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleDateChange = (state) => {
    setDateLabel(
      `${moment(state.startDate).format("MM/DD/YYYY")}-${moment(
        state.endDate
      ).format("MM/DD/YYYY")}`
    );
    setDate({
      start_date: moment(state.startDate).format("YYYY-MM-DD"),
      end_date: moment(state.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleBuClick = (bu) => {
    if (selectedBu.some((item) => item.uuid === bu.uuid))
      setSelectedBu(selectedBu.filter((item) => item.uuid !== bu.uuid));
    else setSelectedBu([...selectedBu, bu]);
  };

  const handlePgClick = (pg) => {
    if (selectedPg.some((item) => item.uuid === pg.uuid))
      setSelectedPg(selectedPg.filter((item) => item.uuid !== pg.uuid));
    else setSelectedPg([...selectedPg, pg]);
  };
  const handleRegionClick = (pg) => {
    if (selectedRegions.some((item) => item.uuid === pg.uuid))
      setSelectedRegions(
        selectedRegions.filter((item) => item.uuid !== pg.uuid)
      );
    else setSelectedRegions([...selectedRegions, pg]);
  };
  const handleChannelClick = (ch) => {
    if (selectedChannels.some((item) => item.value === ch.value))
      setSelectedChannels(
        selectedChannels.filter((item) => item.value !== ch.value)
      );
    else setSelectedChannels([...selectedChannels, ch]);
  };

  useEffect(() => {
    dispatch(getAllBuHavingPgUnderUser());
    dispatch(getAllAccessableRegionForGroup());
    dispatch(getInsightsRequestChannels());
    dispatch(getEmailsForNotification());
    dispatch(getEmailsForExclusion());
  }, []);

  useEffect(() => {
    setSelectedBu(business_units);
  }, [business_units]);
  useEffect(() => {
    setSelectedPg(product_groups);
  }, [product_groups]);
  useEffect(() => {
    setSelectedRegions(regions);
  }, [regions]);

  useEffect(() => {
    setSelectedChannels(req_channels);
  }, [req_channels]);

  useEffect(() => {
    if (selectedBu.length === business_units.length) setBuSelectAll(true);
    else setBuSelectAll(false);
    dispatch(getAllPgInBuForUserInsights(selectedBu.map((bu) => bu.uuid)));
  }, [selectedBu]);

  useEffect(() => {
    if (selectedPg.length === product_groups.length) setPgSelectAll(true);
    else setPgSelectAll(false);
  }, [selectedPg]);

  useEffect(() => {
    if (selectedRegions.length === regions.length) setRegionsSelectAll(true);
    else setRegionsSelectAll(false);
  }, [selectedRegions]);

  useEffect(() => {
    if (selectedChannels.length === req_channels.length) setChSelectAll(true);
    else setChSelectAll(false);
  }, [selectedChannels]);

  useEffect(() => {
    let by_filter = {
      business_unit_uuids: selectedBu.map((bu) => bu.uuid),
      end_date: date?.end_date,
      start_date: date?.start_date,
      product_groups: selectedPg.map((pg) => pg.name),
      regions: selectedRegions.map((rg) => rg.region_name),
      request_channels:selectedChannels.map((ch)=>ch.label)
    };
    if (date && product_groups.length && business_units.length)
      dispatch(getInsightsDashboard(by_filter));
  }, [selectedBu, selectedPg, date, selectedRegions,selectedChannels]);
  useEffect(() => {
    let by_filter = {
      business_unit_uuids: selectedBu.map((bu) => bu.uuid),
      end_date: date?.end_date,
      start_date: date?.start_date,
      product_groups: selectedPg.map((pg) => pg.name),
      regions: selectedRegions.map((rg) => rg.region_name),
      page_no: parseInt(predictionsPagination.page) + 1,
      page_size: predictionsPagination.pageSize,
      search: "",
      request_channels:selectedChannels.map((ch)=>ch.label)

    };
    if (date && product_groups.length && business_units.length)
      dispatch(getPredictionTable(by_filter));
  }, [
    selectedBu,
    selectedPg,
    date,
    selectedRegions,
    predictionsPagination.page,
    predictionsPagination.pageSize,
    selectedChannels
  ]);

  useEffect(() => {
    let by_filter = {
      business_unit_uuids: selectedBu.map((bu) => bu.uuid),
      end_date: date?.end_date,
      start_date: date?.start_date,
      product_groups: selectedPg.map((pg) => pg.name),
      regions: selectedRegions.map((rg) => rg.region_name),
      page_no: parseInt(uniquePredictionsPagination.page) + 1,
      page_size: uniquePredictionsPagination.pageSize,
      search: "",
      request_channels:selectedChannels.map((ch)=>ch.label)

    };
    if (date && product_groups.length && business_units.length)
      dispatch(getUniquePredictionTable(by_filter));
  }, [
    selectedBu,
    selectedPg,
    date,
    selectedRegions,
    uniquePredictionsPagination.page,
    uniquePredictionsPagination.pageSize,
    selectedChannels
  ]);
  useEffect(() => {
    let by_filter = {
      business_unit_uuids: selectedBu.map((bu) => bu.uuid),
      end_date: date?.end_date,
      start_date: date?.start_date,
      product_groups: selectedPg.map((pg) => pg.name),
      regions: selectedRegions.map((rg) => rg.region_name),
      page_no: parseInt(viewsPagination.page) + 1,
      page_size: viewsPagination.pageSize,
      search: "",
      request_channels:selectedChannels.map((ch)=>ch.label)

    };
    if (date && product_groups.length && business_units.length)
      dispatch(getViewsTable(by_filter));
  }, [
    selectedBu,
    selectedPg,
    date,
    selectedRegions,
    viewsPagination.page,
    viewsPagination.pageSize,
    selectedChannels
  ]);
  useEffect(() => {
    let by_filter = {
      business_unit_uuids: selectedBu.map((bu) => bu.uuid),
      end_date: date?.end_date,
      start_date: date?.start_date,
      product_groups: selectedPg.map((pg) => pg.name),
      regions: selectedRegions.map((rg) => rg.region_name),
      page_no: parseInt(votesPagination.page) + 1,
      page_size: votesPagination.pageSize,
      search: "",
      request_channels:selectedChannels.map((ch)=>ch.label)

    };
    if (date && product_groups.length && business_units.length)
      dispatch(getVotesTable(by_filter));
  }, [
    selectedBu,
    selectedPg,
    date,
    selectedRegions,
    votesPagination.page,
    votesPagination.pageSize,
    selectedChannels
  ]);
  const handleExport = () => {
    let by_filter = {
      business_unit_uuids: selectedBu.map((bu) => bu.uuid),
      end_date: date?.end_date,
      start_date: date?.start_date,
      product_groups: selectedPg.map((pg) => pg.name),
      regions: selectedRegions.map((rg) => rg.region_name),
      request_channels:selectedChannels.map((ch)=>ch.label)

    };
    dispatch(exportInsights(by_filter));
  };

  useEffect(() => {
    if (add_emails.length === 0) {
      dispatch(updateIsAddEmail(true));
    }
  }, [add_emails.length]);

  useEffect(() => {
    if (remove_emails.length === 0) {
      dispatch(updateIsRemoveEmail(true));
    }
  }, [remove_emails.length]);

  return (
    <div
      className="insightsviewsWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        marginTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Insights
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Track Your Progress and Reclaim the best customer experience!
            </Typography>
          </div>
          <div className="dropdownWrap">
            <Grid>
              <Button
                fullWidth
                onClick={() => setOpenDate(true)}
                variant="ascendo"
                endIcon={<CalendarMonth />}
                sx={{
                  textTransform: "none",
                  textAlign: "left",
                  color: "grey",
                  borderColor: "grey",
                  border: "1px solid grey",
                  backgroundColor: "transparent",
                }}
              >
                {dateLabel}
              </Button>
            </Grid>
            <Grid
              display={openDate ? "block" : "none"}
              sx={{ position: "absolute", zIndex: 2, top: 70, right: 6 }}
              onMouseLeave={() => setOpenDate(false)}
            >
              <Card elevation={10}>
                <MyDateRangePicker onStateChange={handleDateChange} />
              </Card>
            </Grid>
            <div className="flexCenter">
              <Tooltip title="Export">
                {" "}
                <GetAppOutlinedIcon onClick={handleExport} />
              </Tooltip>

              <MarkEmailUnreadOutlinedIcon
                onClick={toggleDrawer("right", true)}
              />
              <div className="filterIconwrap">
                {selectedBu.length +
                  selectedPg.length +
                  selectedRegions.length >
                0 ? (
                  <div>
                    <FilterAltIcon onClick={toggleDrawer("right1", true)} />
                    <span className="filterNumber">
                      {selectedBu.length +
                        selectedPg.length +
                        selectedRegions.length}
                    </span>
                  </div>
                ) : (
                  <InboxFilter onClick={toggleDrawer("right1", true)} />
                )}
              </div>
              <div className="filterIconwrap" onClick={() => setNlpOpen(true)}>
                <Tooltip title="Ask me">
                  {" "}
                  <NlpIcon />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Tooltip title="Total number of Users that used Ascendo">
                <div className="innerWrap">
                  <div className="textWrap">
                    <Typography className="numberTxt">
                      {dashboard_details?.main_count_data.users}
                    </Typography>
                    <Typography className="smallTxt">Users</Typography>
                  </div>
                  <div className="imageWrap">
                    <UserIcon />
                  </div>
                </div>
              </Tooltip>
            </Card>
          </Grid>

          <Grid item xs={12} md={3} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Tooltip title="Provides the total predictions used by the users. Predictions include any predictions used such as root cause, solutions, knowledge elements, etc.">
                <div className="innerWrap">
                  <div className="textWrap">
                    <Typography className="numberTxt">
                      {dashboard_details?.main_count_data.predictions}
                    </Typography>
                    <Typography className="smallTxt">Predictions</Typography>
                  </div>
                  <div className="imageWrap">
                    <PredictionIcon />
                  </div>
                </div>
              </Tooltip>
            </Card>
          </Grid>

          <Grid item xs={12} md={3} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Tooltip title="Total searches done on Ascendo">
                <div className="innerWrap">
                  <div className="textWrap">
                    <Typography className="numberTxt">
                      {dashboard_details?.main_count_data.searches}
                    </Typography>
                    <Typography className="smallTxt">Searches</Typography>
                  </div>
                  <div className="imageWrap">
                    <SearchIcon />
                  </div>
                </div>
              </Tooltip>
            </Card>
          </Grid>

          <Grid item xs={12} md={3} sm={3} className="cardGrid">
            <Card className="commonCard highlightWrap">
              <Tooltip title="Unique searches done on Ascendo">
                <div className="innerWrap">
                  <div className="textWrap">
                    <Typography className="numberTxt">
                      {dashboard_details?.main_count_data.unique_searches}
                    </Typography>
                    <Typography className="smallTxt">
                      Unique Searches
                    </Typography>
                  </div>
                  <div className="imageWrap">
                    <Uniquesearch />
                  </div>
                </div>
              </Tooltip>
            </Card>
          </Grid>
          {dashboard_details && (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>Top Predictions</Typography>
                  </div>
                  <Topprediction value={dashboard_details["Top Predictions"]} />
                </Card>
              </Grid>

              <Grid xs={12} md={4} lg={4}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>Predictions By Product</Typography>
                  </div>
                  <Predictionproduct
                    value={dashboard_details["Predictions By Product"]}
                  />
                </Card>
              </Grid>

              <Grid xs={12} md={4} lg={4}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>Predictions Over Time</Typography>
                  </div>
                  <Predictionsovertime
                    value={dashboard_details["Predictions Over Time"]}
                  />
                </Card>
              </Grid>

              <Grid xs={12} md={4} lg={4}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>Top Products</Typography>
                  </div>
                  <Topproduct value={dashboard_details["Top Products"]} />
                </Card>
              </Grid>

              <Grid xs={12} md={4} lg={4}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography> Top Users</Typography>
                  </div>
                  <Topusers value={dashboard_details["Top Users"]} />
                </Card>
              </Grid>

              <Grid xs={12} md={4} lg={4}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>
                      Percentage of Time Results are Available
                    </Typography>
                  </div>
                  <PercentageTimeResultAvailable
                    value={
                      dashboard_details["Percentage Time Result Available"]
                    }
                  />
                </Card>
              </Grid>

              {/* <Grid xs={12} md={4} lg={4}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>Tribal Knowledge</Typography>
                  </div>
                  <Areacharts value={dashboard_details["Tribal Knowledge"]} />
                </Card>
              </Grid> */}

              <Grid xs={12} md={6} lg={6}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>Data Sources</Typography>
                  </div>
                  <Datasource value={dashboard_details["Data Sources"]} />
                </Card>
              </Grid>

              <Grid xs={12} md={6} lg={6}>
                <Card className="commonCard chartCard">
                  <div className="chartTitle">
                    <Typography>Cumulative Data Sources</Typography>
                  </div>
                  <Cumulativedatasources
                    value={dashboard_details["Cumulative Data Sources"]}
                  />
                </Card>
              </Grid>
            </>
          )}

          {/* <Grid xs={12} md={6} lg={4}>
        <Card className='commonCard'> <DonutCharts/></Card> 
         </Grid> */}
        </Grid>
        {dashboard_details&&
        <Grid
          sx={{
            my: "18px",
            display: "flex",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <Card className="commonCard chartCard">
            <div className="chartTitle">
              <Typography>Predictions</Typography>
            </div>
            <ResizableTable
              data={predictionTableRows}
              columns={predictionTableColumns}
              paginationDetails={predictionsPagination}
              onTablePaginationChange={(value) =>
                setPredictionsPagination(value)
              }
              onTableRowClick={(params) => {}}
              rowCount={prediction_table_details?.total ?? 0}
            />
          </Card>
          <Card className="commonCard chartCard">
            <div className="chartTitle">
              <Typography>Unique Predictions</Typography>
            </div>
            <ResizableTable
              data={uniquePredictionTableRows}
              columns={uniquePredictionTableColumns}
              paginationDetails={uniquePredictionsPagination}
              onTablePaginationChange={(value) =>
                setUniquePredictionsPagination(value)
              }
              onTableRowClick={(params) => {}}
              rowCount={unique_prediction_table_details?.total ?? 0}
            />
          </Card>
          <Card className="commonCard chartCard">
            <div className="chartTitle">
              <Typography>Views</Typography>
            </div>
            <ResizableTable
              data={viewsTableRows}
              columns={viewsTableColumns}
              paginationDetails={viewsPagination}
              onTablePaginationChange={(value) => setViewsPagination(value)}
              onTableRowClick={(params) => {}}
              rowCount={views_table_details?.total ?? 0}
            />
          </Card>
          <Card className="commonCard chartCard">
            <div className="chartTitle">
              <Typography>Votes</Typography>
            </div>
            <ResizableTable
              data={votesTableRows}
              columns={votesTableColumns}
              paginationDetails={votesPagination}
              onTablePaginationChange={(value) => setVotesPagination(value)}
              onTableRowClick={(params) => {}}
              rowCount={votes_table_details?.total ?? 0}
            />
          </Card>
        </Grid>
}
      </Container>
      <div className="rightDrawer">
        <Drawer
          sx={{
            "& .MuiPaper-root.MuiDrawer-paper ": {
              marginTop: "64px",
              width: "465px",
              padding: "16px",
            },
          }}
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <Typography className="titleText">Settings</Typography>
          <Typography className="subtitleText">
            Configure the Email IDs here for insights data.
          </Typography>
          <IconButton
            className="closeIcon"
            sx={{ position: "absolute", right: "10px" }}
            onClick={toggleDrawer("right", false)}
          >
            <CloseIcon sx={{ fontSize: "large" }} />
          </IconButton>
          <Divider />
          <Typography className="primaryText">Email IDs</Typography>
          <Typography className="secondaryText">
            Configure the Email IDs here to share insights data regularly.
          </Typography>
          <div>
            <Grid display="flex" flexDirection={"column"} gap="12px">
              {included_emails.map((email) => (
                <Grid
                  display="flex"
                  key={email.uuid}
                  gap="8px"
                  alignItems="center"
                >
                  <Typography color={colors.ascendo.darkblue} fontSize={"14px"}>
                    {email.user_email}
                  </Typography>
                  <IconButton
                    onClick={() =>
                      dispatch(removeEmailForNotification(email.user_email))
                    }
                  >
                    <RemoveCircleOutline fontSize="small" />
                  </IconButton>
                </Grid>
              ))}
              {add_emails?.map((email) => (
                <Grid
                  display="flex"
                  key={email.id}
                  gap="8px"
                  alignItems="flex-start"
                >
                  <TextField
                    type="email"
                    onChange={(e) => {
                      dispatch(
                        updateAddEmails(
                          add_emails.map((item) => {
                            if (item.id === email.id) {
                              return {
                                ...item,
                                value: e.target.value,
                                isValidEmail: validator.isEmail(e.target.value),
                                isApiValidatedEmail: true,
                              };
                            } else {
                              return item;
                            }
                          })
                        )
                      );
                      if (validator.isEmail(e.target.value))
                        dispatch(validateEmailForNotification(e.target.value));
                    }}
                    error={
                      (!email.isValidEmail || !email.isApiValidatedEmail) &&
                      email.value
                    }
                    helperText={
                      email?.value
                        ? email.isValidEmail
                          ? email.isApiValidatedEmail
                            ? ""
                            : "Email already exists or maybe not a part of Ascendo's user list. Kindly update this value or remove it to proceed further."
                          : "* Please enter a valid email"
                        : ""
                    }
                    id="outlined-basic"
                    label="Enter Email"
                    variant="outlined"
                    className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedStart css-wjp1ot emailInput"
                  />
                  <IconButton
                    onClick={() =>
                      dispatch(
                        updateAddEmails(
                          add_emails.filter((item) => item.id !== email.id)
                        )
                      )
                    }
                  >
                    <RemoveCircleOutline fontSize="small" />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
            {is_add_email && (
              <Button
                sx={{ color: colors.ascendo.blue, textTransform: "none" }}
                startIcon={<AddCircleOutline />}
                onClick={() => {
                  dispatch(updateIsAddEmail(false));
                  dispatch(
                    updateAddEmails([
                      ...add_emails,
                      {
                        id: Date.now(),
                        value: null,
                        isValidEmail: false,
                        isApiValidatedEmail: false,
                      },
                    ])
                  );
                }}
              >
                Add new email
              </Button>
            )}
          </div>

          <div className="saveBtn">
            <Button
              className="mat-focus-indicator mat-flat-button mat-button-base mat-yellow"
              disabled={
                !(
                  add_emails.every((email) => email.isValidEmail === true) &&
                  add_emails.length
                )
              }
              onClick={() =>
                dispatch(
                  addEmailForNotification(
                    add_emails
                      .filter(
                        (email) =>
                          email.isValidEmail && email.isApiValidatedEmail
                      )
                      .map((email) => email.value)
                  )
                )
              }
            >
              Save
            </Button>
          </div>
          <Divider />
          <Typography className="primaryText">Exclude User Email</Typography>
          <Typography className="secondaryText">
            Configure the Email IDs here that needs to be ignored in the
            insights data.
          </Typography>
          <div>
            <Grid display="flex" flexDirection={"column"} gap="12px">
              {excluded_emails.map((email) => (
                <Grid
                  display="flex"
                  key={email.uuid}
                  gap="8px"
                  alignItems="center"
                >
                  <Typography color={colors.ascendo.darkblue} fontSize={"14px"}>
                    {email.user_email}
                  </Typography>
                  <IconButton
                    onClick={() =>
                      dispatch(removeEmailForExclusion(email.user_email))
                    }
                  >
                    <RemoveCircleOutline fontSize="small" />
                  </IconButton>
                </Grid>
              ))}
              {remove_emails?.map((email) => (
                <Grid
                  display="flex"
                  key={email.id}
                  gap="8px"
                  alignItems="flex-start"
                >
                  <TextField
                    type="email"
                    onChange={(e) => {
                      dispatch(
                        updateRemoveEmails(
                          remove_emails.map((item) => {
                            if (item.id === email.id) {
                              return {
                                ...item,
                                value: e.target.value,
                                isValidEmail: validator.isEmail(e.target.value),
                                isApiValidatedEmail: true,
                              };
                            } else {
                              return item;
                            }
                          })
                        )
                      );
                      if (validator.isEmail(e.target.value))
                        dispatch(validateEmailForExclusion(e.target.value));
                    }}
                    error={
                      (!email.isValidEmail || !email.isApiValidatedEmail) &&
                      email.value
                    }
                    helperText={
                      email?.value
                        ? email.isValidEmail
                          ? email.isApiValidatedEmail
                            ? ""
                            : "Email already exists or maybe not a part of Ascendo's user list. Kindly update this value or remove it to proceed further."
                          : "* Please enter a valid email"
                        : ""
                    }
                    id="outlined-basic"
                    label="Enter Email"
                    variant="outlined"
                    className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedStart css-wjp1ot emailInput"
                  />
                  <IconButton
                    onClick={() =>
                      dispatch(
                        updateRemoveEmails(
                          remove_emails.filter((item) => item.id !== email.id)
                        )
                      )
                    }
                  >
                    <RemoveCircleOutline fontSize="small" />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
            {is_remove_email && (
              <Button
                sx={{ color: colors.ascendo.blue, textTransform: "none" }}
                startIcon={<AddCircleOutline />}
                onClick={() => {
                  dispatch(updateIsRemoveEmail(false));
                  dispatch(
                    updateRemoveEmails([
                      ...remove_emails,
                      {
                        id: Date.now(),
                        value: null,
                        isValidEmail: false,
                        isApiValidatedEmail: false,
                      },
                    ])
                  );
                }}
              >
                Exclude new email
              </Button>
            )}
          </div>
          <div className="saveBtn">
            <Button
              disabled={
                !(
                  remove_emails.every((email) => email.isValidEmail === true) &&
                  remove_emails.length
                )
              }
              onClick={() =>
                dispatch(
                  addEmailForExclusion(
                    remove_emails
                      .filter(
                        (email) =>
                          email.isValidEmail && email.isApiValidatedEmail
                      )
                      .map((email) => email.value)
                  )
                )
              }
            >
              Save
            </Button>
          </div>
        </Drawer>
        <Drawer
          className="nplWrap"
          sx={{
            "& .MuiPaper-root.MuiDrawer-paper ": {
              marginTop: "64px",
              width: "465px",
              padding: "16px",
              marginBottom: "65px",
              height: "calc(100% - 65px)",
            },
          }}
          anchor={"right"}
          open={nlpOpen}
          onClose={() => {
            setNlpOpen(false);
            dispatch(updateAnswer(""));
            setNlpSearch("");
          }}
        >
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              className="primaryText boldText"
              color={colors.ascendo.darkblue}
            >
              Ask me Question
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                setNlpSearch("");
                setNlpOpen(false);
                dispatch(updateAnswer(""));
              }}
            >
              <CloseIcon fontSize="14px" />
            </IconButton>
          </Grid>
          <Divider />

          <TextField
            size="small"
            sx={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            value={nlpSearch}
            onChange={(e) => setNlpSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(getAnswersForInsights(e.target.value));
              }
            }}
            type="text"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchNormalIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {nlpSearch.length > 0 && (
                    <IconButton size="small" onClick={() => setNlpSearch("")}>
                      <CloseIcon fontSize="14px" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
          {is_answer_loading ? (
            <Grid
              display={"flex"}
              height={"200px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress
                sx={{ color: colors.ascendo.darkblue }}
                size={32}
              />
            </Grid>
          ) : (
            <Grid
              sx={{ fontSize: "14px", display: "flex" }}
              dangerouslySetInnerHTML={{
                __html: answer_for_insights,
              }}
            ></Grid>
          )}
        </Drawer>
        <Drawer
          sx={{
            "& .MuiPaper-root.MuiDrawer-paper ": {
              marginTop: "64px",
              width: "465px",
              padding: "16px",
              marginBottom: "65px",
              height: "calc(100% - 65px)",
            },
          }}
          anchor={"right"}
          open={state["right1"]}
          onClose={toggleDrawer("right1", false)}
        >
          <div sx={{ overflow: "hidden" }}>
            <div className="fixedWrap">
              <IconButton
                className="closeIcon"
                sx={{ position: "fixed", right: "10px", top: "70px" }}
                onClick={toggleDrawer("right1", false)}
              >
                <CloseIcon sx={{ fontSize: "large" }} />
              </IconButton>
              <Typography
                className="primaryText boldText"
                color={colors.ascendo.darkblue}
              >
                Filter
              </Typography>
            </div>
            <div className="innerFilter">
              <Typography
                color={colors.ascendo.darkblue}
                fontSize="14px"
                mt="30px"
                pb="3px"
                style={{ borderBottom: "1px solid #bbb", fontWeight: "600" }}
              >
                Business Units
              </Typography>
              <Grid>
                <TextField
                  size="small"
                  sx={{ marginTop: "8px" }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchNormalIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchBu}
                  onChange={(e) => setSearchBu(e.target.value)}
                />
                {!searchBu.length && (
                  <Grid
                    onClick={() => {
                      setBuSelectAll(!buSelectAll);
                      if (buSelectAll) setSelectedBu([]);
                      else setSelectedBu(business_units);
                    }}
                    mt="10px"
                    display="flex"
                    gap="5px"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                  >
                    <Checkbox
                      size="small"
                      disableRipple
                      sx={{ padding: 0 }}
                      checked={buSelectAll}
                    />{" "}
                    <Typography color={colors.ascendo.darkblue} fontSize="14px">
                      Select All
                    </Typography>
                  </Grid>
                )}
                {business_units
                  ?.filter((bu) =>
                    bu.name.toLowerCase().includes(searchBu.toLowerCase())
                  )
                  .map((bu) => (
                    <Grid
                      key={bu.uuid}
                      mt="5px"
                      display="flex"
                      gap="5px"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleBuClick(bu)}
                    >
                      <Checkbox
                        size="small"
                        disableRipple
                        sx={{ padding: 0 }}
                        checked={selectedBu.some(
                          (selected) => selected.uuid === bu.uuid
                        )}
                      />{" "}
                      <Typography
                        color={colors.ascendo.darkblue}
                        fontSize="14px"
                      >
                        {bu.name}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
              <Typography
                color={colors.ascendo.darkblue}
                fontSize="14px"
                mt="10px"
                pb="3px"
                style={{ borderBottom: "1px solid #bbb", fontWeight: "600" }}
              >
                Product Groups
              </Typography>
              {selectedBu.length && (
                <Grid>
                  <TextField
                    size="small"
                    sx={{ marginTop: "8px" }}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchNormalIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={searchPg}
                    onChange={(e) => setSearchPg(e.target.value)}
                  />
                  {!searchPg.length && (
                    <Grid
                      onClick={() => {
                        setPgSelectAll(!pgSelectAll);
                        if (pgSelectAll) setSelectedPg([]);
                        else setSelectedPg(product_groups);
                      }}
                      mt="5px"
                      display="flex"
                      gap="5px"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        size="small"
                        disableRipple
                        sx={{ padding: 0 }}
                        checked={pgSelectAll}
                      />{" "}
                      <Typography
                        color={colors.ascendo.darkblue}
                        fontSize="14px"
                      >
                        Select All
                      </Typography>
                    </Grid>
                  )}
                  {product_groups
                    ?.filter((pg) =>
                      pg.name.toLowerCase().includes(searchPg.toLowerCase())
                    )
                    .map((pg) => (
                      <Grid
                        key={pg.uuid}
                        mt="5px"
                        display="flex"
                        gap="5px"
                        alignItems="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handlePgClick(pg)}
                      >
                        <Checkbox
                          size="small"
                          disableRipple
                          sx={{ padding: 0 }}
                          checked={selectedPg.some(
                            (selected) => selected.uuid === pg.uuid
                          )}
                        />{" "}
                        <Typography
                          color={colors.ascendo.darkblue}
                          fontSize="14px"
                        >
                          {pg.name}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              )}
              <Typography
                color={colors.ascendo.darkblue}
                fontSize="14px"
                mt="10px"
                pb="3px"
                style={{ borderBottom: "1px solid #bbb", fontWeight: "600" }}
              >
                Regions
              </Typography>
              {selectedBu.length && (
                <Grid>
                  <TextField
                    size="small"
                    sx={{ marginTop: "8px" }}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchNormalIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={searchRegions}
                    onChange={(e) => setSearchRegions(e.target.value)}
                  />
                  {!searchRegions.length && (
                    <Grid
                      onClick={() => {
                        setRegionsSelectAll(!regionsSelectAll);
                        if (regionsSelectAll) setSelectedRegions([]);
                        else setSelectedRegions(product_groups);
                      }}
                      mt="5px"
                      display="flex"
                      gap="5px"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        size="small"
                        disableRipple
                        sx={{ padding: 0 }}
                        checked={regionsSelectAll}
                      />{" "}
                      <Typography
                        color={colors.ascendo.darkblue}
                        fontSize="14px"
                      >
                        Select All
                      </Typography>
                    </Grid>
                  )}
                  {regions
                    ?.filter((rg) =>
                      rg.region_name
                        .toLowerCase()
                        .includes(searchRegions.toLowerCase())
                    )
                    .map((pg) => (
                      <Grid
                        key={pg.uuid}
                        mt="5px"
                        display="flex"
                        gap="5px"
                        alignItems="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleRegionClick(pg)}
                      >
                        <Checkbox
                          size="small"
                          disableRipple
                          sx={{ padding: 0 }}
                          checked={selectedRegions.some(
                            (selected) => selected.uuid === pg.uuid
                          )}
                        />{" "}
                        <Typography
                          color={colors.ascendo.darkblue}
                          fontSize="14px"
                        >
                          {pg.region_name}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              )}
              <Typography
                color={colors.ascendo.darkblue}
                fontSize="14px"
                mt="10px"
                pb="3px"
                style={{ borderBottom: "1px solid #bbb", fontWeight: "600" }}
              >
                Request Channels
              </Typography>
              {selectedBu.length && (
                <Grid>
                  <TextField
                    size="small"
                    sx={{ marginTop: "8px" }}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchNormalIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={searchChannels}
                    onChange={(e) => setSearchChannels(e.target.value)}
                  />
                  {!searchChannels.length && (
                    <Grid
                      onClick={() => {
                        setChSelectAll(!chSelectAll);
                        if (chSelectAll) setSelectedChannels([]);
                        else setSelectedChannels(req_channels);
                      }}
                      mt="5px"
                      display="flex"
                      gap="5px"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <Checkbox
                        size="small"
                        disableRipple
                        sx={{ padding: 0 }}
                        checked={regionsSelectAll}
                      />{" "}
                      <Typography
                        color={colors.ascendo.darkblue}
                        fontSize="14px"
                      >
                        Select All
                      </Typography>
                    </Grid>
                  )}

                  {req_channels
                    ?.filter((ch) =>
                      ch.label
                        .toLowerCase()
                        .includes(searchChannels.toLowerCase())
                    )
                    .map((ch) => (
                      <Grid
                        key={ch.uuid}
                        mt="5px"
                        display="flex"
                        gap="5px"
                        alignItems="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleChannelClick(ch)}
                      >
                        <Checkbox
                          size="small"
                          disableRipple
                          sx={{ padding: 0 }}
                          checked={selectedChannels.some(
                            (selected) => selected.value === ch.value
                          )}
                        />{" "}
                        <Typography
                          color={colors.ascendo.darkblue}
                          fontSize="14px"
                        >
                          {ch.label}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              )}
            </div>
          </div>
        </Drawer>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={3500}
          message="Email already exists or maybe not a part of Ascendo's user list. Kindly update this value or remove it to proceed further."
          action={action}
          onClose={handleSnackBarClose}
        />
      </div>
    </div>
  );
}

export default Insightsviews;
