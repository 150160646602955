import React from 'react'

function SupportchannelIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.83073 1.66406C3.52954 1.66406 1.66406 3.52954 1.66406 5.83073V14.1641C1.66406 16.4652 3.52954 18.3307 5.83073 18.3307H14.1641C16.4652 18.3307 18.3307 16.4652 18.3307 14.1641V5.83073C18.3307 3.52954 16.4652 1.66406 14.1641 1.66406H5.83073ZM7.41897 10.1641C7.05747 10.1641 6.72371 10.2857 6.45647 10.4901V10.0516C6.4398 9.0849 6.79397 8.18073 7.46063 7.50156C8.12313 6.82656 9.0148 6.45573 9.9773 6.45573C11.944 6.45573 13.5398 8.05573 13.5398 10.0182V10.4901C13.2726 10.2857 12.9388 10.1641 12.5773 10.1641H12.5231C11.6481 10.1641 10.9356 10.8766 10.9356 11.7516V12.5349C10.9356 13.4099 11.6481 14.1224 12.5231 14.1224H12.5773C13.4523 14.1224 14.1648 13.4099 14.1648 12.5349L14.1648 10.0182C14.1648 7.7099 12.2856 5.83073 9.9773 5.83073C8.84813 5.83073 7.79813 6.26823 7.0148 7.06406C6.23147 7.86406 5.81063 8.93073 5.83147 10.0599V12.5349C5.83147 13.4099 6.54397 14.1224 7.41897 14.1224H7.47313C8.34813 14.1224 9.06063 13.4099 9.06063 12.5349V11.7516C9.06063 10.8766 8.34813 10.1641 7.47313 10.1641H7.41897Z" fill="#071942" fill-opacity="0.8"/>
</svg>
  )
}

export default SupportchannelIcon