import axios from "../../../utils/axios";
const baseURL = process.env.REACT_APP_IS + `interaction/`;

export const GetInteractionStatusList = async () => {
  let response = await axios(
    process.env.REACT_APP_IS +
      `interaction_status/${localStorage.getItem("ci")}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetInteractions = async (
  status_list,
  search,
  from_date,
  to_date
) => {
  let response = await axios(baseURL + `${localStorage.getItem("ci")}/filter`, {
    method: "POST",
    headers: {
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
    data: {
      status_list,
      search,
      from_date,
      to_date,
    },
  });
  return { data: response.data, status: response.status };
};
export const GetInteractionById = async (interaction_id) => {
  let response = await axios(
    baseURL + `${localStorage.getItem("ci")}/${interaction_id}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetSentiment = async (interaction_id) => {
  let response = await axios(
    baseURL + `${localStorage.getItem("ci")}/${interaction_id}/sentiment`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const UpdateAgent = async (
  interaction_id,
  agent_uuid,
  assigned_by,
  flag,
  interaction_type_id,
  status,
  updated_by
) => {
  let response = await axios(
    baseURL + `${localStorage.getItem("ci")}/update_agent/${interaction_id}`,
    {
      method: "PUT",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: agent_uuid
        ? {
            agent_uuid,
            assigned_by,
            flag,
            interaction_type_id,
            status,
            updated_by,
          }
        : { assigned_by, flag, interaction_type_id, status, updated_by },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetNewInteractions = async (status_list, search) => {
  let response = await axios(
    baseURL + `${localStorage.getItem("ci")}/status/${"Agent Requested"}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetInteractionDetails = async (interaction_id) => {
  let response = await axios(
    process.env.REACT_APP_IS +
      `interaction_details/${localStorage.getItem("ci")}/${interaction_id}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const CreateInteraction = async (
  query,product_group,bu_name
) => {
  let response = await axios(baseURL + `${localStorage.getItem("ci")}`, {
    method: "POST",
    headers: {
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
    data: {
      agent_uuid: localStorage.getItem("ui"),
      close_date: null,
      created_by: localStorage.getItem("ui"),
      how_solved: "Agent Solved",
      interaction_type_id: "8f718b61-4f8b-451b-a571-cdff5f59abcf",
      is_request_from_rp: true,
      label: null,
      organization_uuid: null,
      problem: query,
      product_family: product_group,
      requester: localStorage.getItem("ui"),
      source_product: bu_name,
      status: "New",
      updated_by: localStorage.getItem("ui"),
    },
  });
  if(response.status===200)
  await axios(baseURL+`${localStorage.getItem("ci")}/${response.data.data}`,{
    method: "PUT",
    headers: {
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
    data:{
      close_date: new Date().toISOString(),
      customer_effort:1,
    }
  })
  return { data: response.data, status: response.status };
};
