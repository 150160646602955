import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

function Topprediction({value}) {
  useEffect(() => {
    Highcharts.chart('topPrediction', {
      chart: {
        type: 'bar'
      },
      title: {
        text: '',
        align: 'left'
        
      },
      xAxis: {
        categories: value.y_axis,
        title: {
          text: value.y_title,
          
          
        },
        gridLineWidth: 0,
        lineWidth: 0,
       
      },
      yAxis: {
       
        min: 0,
        title: {
          text: value.x_title,
          align:"low"
        
        },
        labels: {
          overflow: 'justify',
          enabled: false
        },
        gridLineWidth: 0
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        // valueSuffix: ''
      },
      plotOptions: {
        bar: {
          borderRadius: '50%',
          dataLabels: {
            enabled: true
          },
          groupPadding: 0.05,
          pointWidth: 10,
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: '',
        data: value.x_axis,
        showInLegend: "false",
      }]
    });
  }, [value]);

  return <div id="topPrediction" style={{height: "300px"}} />;
}

export default Topprediction;
