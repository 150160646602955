import { Button, Grid, Tooltip, useTheme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageIcon from "../../assets/ImageIcon";
import {
  updateSelectedBuValue,
  updateSelectedDsValue,
  updateSelectedModule,
} from "../../redux/slices/mapping/mappingStep1Slice";

import {
  updateExpanded0,
  updateExpanded1,
  updateExpanded2,
  updateExpanded3,
} from "../../redux/slices/accordionSlice";

function DataSelectionCard({
  text,
  icon,
  buType,
  dsType,
  buValue,
  dsValue,
  logo_uri,
  type,
  modValue
}) {
  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();
  const { selected_bu, selected_ds,modules } = useSelector(
    (state) => state.mappingStep1Reducer
  );

  const { bu_details } = useSelector((state) => state.businessunitsReducer);
  const { ds_details } = useSelector((state) => state.datasourceReducer);

  const handleClick = () => {
    if(type==="module"){
      dispatch(updateSelectedModule(modules.find((item)=>item.uuid===modValue)))
      dispatch(updateSelectedDsValue(null))
      dispatch(updateExpanded0(false))
      dispatch(updateExpanded1(true))
    }
    if (buType === "mapping") {
      const selectedBu = bu_details?.find((bu) => bu.uuid === buValue);

      dispatch(updateSelectedBuValue(selectedBu));
      dispatch(updateExpanded1(false));
      dispatch(updateExpanded2(true));
    }
    if (dsType === "mapping") {
      const selectedDs = ds_details?.find((ds) => ds?.uuid === dsValue);
      dispatch(updateSelectedDsValue(selectedDs));
      dispatch(updateExpanded2(false));
    }
  };

  const isSelected =
    (buType === "mapping" &&
      selected_bu?.uuid === buValue &&
      selected_bu?.uuid !== undefined) ||
    (dsType === "mapping" &&
      selected_ds?.uuid === dsValue &&
      selected_ds?.uuid !== undefined);

  const buttonStyles = {
    height: "72px",
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "none",
    color: colors.ascendo.darkblue,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    background: isSelected
      ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
      : "transparent",
    "&:hover": {
      background: isSelected
        ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
        : "transparent",
      borderColor: isSelected
        ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
        : "rgba(26, 115, 232, 0.5)",
      boxShadow: "0px 4px 32px rgba(26, 115, 232, 0.12)",
    },
    borderColor: isSelected ? colors.ascendo.blue : colors.grey.normal,
  };

  return (
    <Grid item md={3}>
      <Button
        disableRipple
        fullWidth
        variant="outlined"
        sx={buttonStyles}
        endIcon={icon}
        onClick={handleClick}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {logo_uri?.length > 0 ? (
            <img src={logo_uri} height={24} width={24}></img>
          ) : (
            <ImageIcon />
          )}
          <Tooltip title={text} >
            <div
              style={{
                marginLeft: "12px",
                whiteSpace: "nowrap",
                width: "155px",
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {text}
            </div>
          </Tooltip>
        </div>
      </Button>
    </Grid>
  );
}

export default DataSelectionCard;
