import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import "./slackbotView.scss";
import {
  Button,
  Card,
  TextField,
  Grid,
  Autocomplete,
  Switch,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getSlackAuth } from "../../../redux/slices/mapping/listMappingSlice";
import {
  getChannelByName,
  getChannelMappingTableForSlack,
  getSlackWorkspaces,
  updateChannelMappingStatusForSlack,
  updateSelectedWorkspace,
} from "../../../redux/slices/supportChannels/supportChannelsSlice";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { updateSlackPagination } from "../../../redux/slices/dataTableSlice";
import ResizableTable from "../../../components/dataTable/simpleTable";
import ImplementationGuideIcon from "../../../assets/ImplementationGuideIcon";

function SlackTable() {
  const dispatch = useDispatch();

  const { slack_table, selected_workspace } = useSelector(
    (state) => state.supportChannelReducer
  );

  const columns = slack_table?.columns;
  const display_map = slack_table?.display_map;
  const mappings = slack_table?.data;
  const { slack_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  useEffect(() => {
    let by_filters_and_pagination = {
      page_no: parseInt(slack_paginationDetails.page) + 1,
      page_size: slack_paginationDetails.pageSize,
      workspace_uuid: selected_workspace?.uuid,
    };
    dispatch(getChannelMappingTableForSlack(by_filters_and_pagination));
  }, [slack_paginationDetails, selected_workspace]);

  const cols = useMemo(() =>
    columns
      ? columns?.map((column) => {
          if (column === "is_active") {
            return {
              accessorKey: column,
              header: display_map[column],
              Cell: ({ cell }) => {
                const is_active= cell.getValue()
                const channel_mapping_uuid = cell.row.original.id;

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Switch
                        size="small"
                        checked={is_active}
                        onChange={() => {
                          let data = {
                            channel_mapping_uuid: channel_mapping_uuid,
                            is_active: !is_active,
                            workspace_uuid: selected_workspace?.uuid,
                            page_no: parseInt(slack_paginationDetails.page) + 1,
                            page_size: slack_paginationDetails.pageSize,
                            workspace_uuid: selected_workspace?.uuid,
                          };
                          dispatch(updateChannelMappingStatusForSlack(data));
                        }}
                      />
                    </div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {is_active ? "Active" : "Inactive"}
                    </div>
                  </div>
                );
              },
            };
          }
          return {
            accessorKey: column,
            header: display_map[column],
            Cell: ({ cell }) => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {cell.getValue()}
              </div>
            ),
          };
        })
      : []
  );

  const rows = mappings
    ? mappings?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateSlackPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {
    {
    }
  };

  return (
    <>
      <>
        <div
          className="listmappingWrapper"
          style={{
            overflow: "scroll",
            height: "calc(100vh - 170px)",
            width: "100%",
            marginTop: "-14px",
          }}
        >
          <Grid className="listMappingWrap">
            <ResizableTable
              data={rows}
              columns={cols}
              paginationDetails={slack_paginationDetails}
              onTablePaginationChange={handlePaginationModelChange}
              onTableRowClick={handleRowClick}
              rowCount={slack_table?.total || 0}
            />
          </Grid>
        </div>
      </>
    </>
  );
}

function SlackBotView() {
  const dispatch = useDispatch();
  const { channel_details, selected_workspace, slack_workspaces } = useSelector(
    (state) => state.supportChannelReducer
  );

  useEffect(() => {
    dispatch(getChannelByName("Slack Bot"));
    dispatch(getSlackWorkspaces());
  }, []);
  return (
    <div
      className="slackbotWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        marginTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <div className="titleFlex">
              <Typography variant="h4" sx={{ mb: 2 }}>
                Slack
              </Typography>{" "}
              {channel_details && channel_details.is_active ? (
                <span className="statusBtn active">Active</span>
              ) : (
                <span className="statusBtn inactive">Inactive</span>
              )}
            </div>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Integrate Slack and organize data to get predictions with
              Ascendo.ai.
            </Typography>
          </div>
          <div className="rightsideWrap">
            <Grid
              className="implementBtn"
              onClick={() =>
                window.open(
                  "https://ascendo.atlassian.net/wiki/spaces/AKB/pages/147783714/Slack+Integration+Guides"
                )
              }
            >
              {" "}
              <Tooltip title="You can find the implementaion guide for slack here!">
                <Button>
                  <ImplementationGuideIcon /> Implementation Guide{" "}
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              className="regenerateBtn"
              onClick={() => dispatch(getSlackAuth())}
            >
              <Tooltip title="You can add Ascendo to your slack workspace here!">
                <Button className="askBtn"> Integrate Slack </Button>
              </Tooltip>
            </Grid>
          </div>
        </div>

        <Grid className="cardGrid">
          <Grid className="commonCard">
            <Card className="d-flex1">
              <Grid display={"flex"} justifyContent={"flex-end"}>
                <Grid width={"240px"} p="16px">
                  <Autocomplete
                    size="small"
                    disableClearable
                    options={slack_workspaces.map((item) => ({
                      ...item,
                      id: item.uuid,
                      label: item.team_name,
                    }))}
                    value={selected_workspace?.label ?? ""}
                    onChange={(event, newValue) => {
                      dispatch(updateSelectedWorkspace(newValue));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Workspaces" fullWidth />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid className="d-flex">
                <SlackTable />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SlackBotView;
