import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';

function Cumulativedatasources({ value }) {
  useEffect(() => {
    Highcharts.chart('cumulativedataSource', {
      chart: {
        type: 'line',
      },
      title: {
        text: '',
        align: 'left',
      },
      legend: {
        width: 600,
       height: 250,
       floating: false,
        align: 'left',
      // x: 5, // = marginLeft - default spacingLeft
       itemWidth: 150,
        borderWidth: 0
    },
      xAxis: {
        labels: {
          //step: 2,
          formatter: function() {
            var ret = this.value,
                len = ret.length;
            if( len > 10 ) {
              ret = ret.slice(0,10);
            }
            
            if( len > 15 ) {
              ret = ret.slice(0,15);
            }
            
            return ret;
          }
        },
        categories: value.x_axis.map(timestamp => moment(timestamp).format("DD MMM YY")), // Use x_axis from value prop
        title: {
          text: value.x_title, // Use x_title from value prop
        },
        gridLineWidth: 0,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: value.y_title,
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 1,
      },
      tooltip: {
        valueSuffix: '',
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.05,
          pointWidth: 15,
        },
      },
      credits: {
        enabled: false,
      },
      series: value.y_axis.map(dataSource => ({
        name: dataSource.name,
        // Filter out data points with value 0
        data: dataSource.data.filter(point => point !== 0),
      })),
    });
  }, [value]); // Add value as a dependency to useEffect

  return <div id="cumulativedataSource" style={{ height: '300px' }} />;
}

export default Cumulativedatasources;
