import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ApplyObfuscation,
  CreateObfuscator,
  CreateObfuscatorSettings,
  DeleteObfuscator,
  DeleteObfuscatorSettings,
  GetObfuscatedFields,
  GetObfuscator,
  GetObfuscatorSettings,
  GetOperatorValues,
  GetOperators,
  GetSupportedEntities,
  ObfuscateFields,
  UpdateObfuscator,
  UpdateObfuscatorSettings,
  UpdateThreshold,
} from "../../actions/obfuscation/obsActions";

export const getSupportedEntities = createAsyncThunk(
  "getSupportedEntities",
  async (mapping_uuid, thunkAPI) => {
    
    const response = await GetSupportedEntities(mapping_uuid);
    return response;
  }
);
export const getOperators = createAsyncThunk(
  "getOperators",
  async (mapping_uuid, thunkAPI) => {
    const response = await GetOperators(mapping_uuid);
    return response;
  }
);
export const getOperatorValues = createAsyncThunk(
  "getOperatorValues",
  async (data, thunkAPI) => {
    const response = await GetOperatorValues(data.mapping_uuid,data.operator);
    return response;
  }
);
export const applyObfuscation = createAsyncThunk(
  "applyObfuscation",
  async (data, thunkAPI) => {
    const response = await ApplyObfuscation(data.input_text,data.entities,data.threshold);
    return response;
  }
);
export const createObfuscatorSettings = createAsyncThunk(
  "createObfuscatorSettings",
  async (data, thunkAPI) => {
   await CreateObfuscatorSettings(data.mapping_uuid,data.entity,data.operator,data.operator_value);
   const response = await GetObfuscatorSettings(data.mapping_uuid,data.page_no,data.page_size,data.search);

    return response;
  }
);
export const updateObfuscatorSettings = createAsyncThunk(
  "updateObfuscatorSettings",
  async (data, thunkAPI) => {
   await UpdateObfuscatorSettings(data.mapping_uuid,data.obfuscator_setting_uuid,data.entity,data.operator,data.operator_value);
   const response = await GetObfuscatorSettings(data.mapping_uuid,data.page_no,data.page_size,data.search);

    return response;
  }
);
export const deleteObfuscator = createAsyncThunk(
  "deleteObfuscator",
  async (mapping_uuids, thunkAPI) => {
    const response = await DeleteObfuscator(mapping_uuids);
    return response;
  }
);
export const obfuscateFields = createAsyncThunk(
  "obfuscateFields",
  async (data, thunkAPI) => {
    const response = await ObfuscateFields(data.mapping_uuid,data.field_mapping_uuids);
    return response;
  }
);
export const getObfuscator = createAsyncThunk(
  "getObfuscator",
  async (mapping_uuid, thunkAPI) => {
    const response = await GetObfuscator(mapping_uuid);
    return response;
  }
);
export const createObfuscator = createAsyncThunk(
  "createObfuscator",
  async (data, thunkAPI) => {
    await CreateObfuscator(data.mapping_uuid,data.threshold);
    const response = await GetObfuscatorSettings(data.mapping_uuid,data.page_no,data.page_size,data.search);

    return response;
  }
);
export const updateObfuscator = createAsyncThunk(
  "updateObfuscator",
  async (data, thunkAPI) => {
    await UpdateObfuscator(data.mapping_uuid,data.is_active);
    const response = await GetObfuscatorSettings(data.mapping_uuid,data.page_no,data.page_size,data.search);

    return response;
  }
);
export const updateThreshold = createAsyncThunk(
  "updateThreshold",
  async (data, thunkAPI) => {
   const response = await UpdateThreshold(data.mapping_uuid,data.threshold);

    return response;
  }
);
export const getObfuscatorSettings = createAsyncThunk(
  "getObfuscatorSettings",
  async (data, thunkAPI) => {
    const response = await GetObfuscatorSettings(data.mapping_uuid,data.page_no,data.page_size,data.search);
    return response;
  }
);
export const getObfuscatedFields = createAsyncThunk(
  "getObfuscatedFields",
  async (mapping_uuid, thunkAPI) => {
    const response = await GetObfuscatedFields(mapping_uuid);
    return response;
  }
);

export const deleteObfuscatorSettings = createAsyncThunk(
  "deleteObfuscatorSettings",
  async (data, thunkAPI) => {
    await DeleteObfuscatorSettings(data.obfuscator_setting_uuids);
    const response = await GetObfuscatorSettings(data.mapping_uuid,data.page_no,data.page_size,data.search);

    return response;
  }
);

const obsSlice = createSlice({
  name: "obsSlice",
  initialState: {
    supported_entities: [],
    operators: [],
    operator_values: {},
    obfuscated_output:"",
    obfuscation_setting_details:null,
    obfuscator_details:null,
    obfuscated_fields:null,
    
  },
  reducers: {
    updateOperatorValues: (state, action) => {state.operator_values=action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupportedEntities.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.supported_entities = action.payload.data.data;
        }
      })
      .addCase(getOperators.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.operators = action.payload.data.data;
        }
      })
      .addCase(getOperatorValues.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          if(action.meta.arg.operator==="mask"){
            state.operator_values={...action.payload.data.data,chars_to_mask:`${action.payload.data.data.chars_to_mask}`}
          }
          else
          state.operator_values = action.payload.data.data;
        }
      })
      .addCase(applyObfuscation.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscated_output=action.payload.data.data
        }
      })
      .addCase(getObfuscatorSettings.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscation_setting_details=action.payload.data
        }
      })
      .addCase(createObfuscator.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscation_setting_details=action.payload.data
        }
      })
      .addCase(updateObfuscator.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscation_setting_details=action.payload.data
        }
      })
      .addCase(createObfuscatorSettings.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscation_setting_details=action.payload.data
        }
      })
      .addCase(deleteObfuscatorSettings.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscation_setting_details=action.payload.data
        }
        
      })
      .addCase(updateObfuscatorSettings.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscation_setting_details=action.payload.data
        }
        
      })
      .addCase(getObfuscator.pending,(state,action)=>{
        state.obfuscator_details=null

      })
      .addCase(getObfuscator.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscator_details=action.payload.data.data
        }
       
      })
      .addCase(getObfuscatedFields.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.obfuscated_fields=action.payload.data.data
        }
        
      })
      
  },
});

export const {updateOperatorValues}=obsSlice.actions
export default obsSlice.reducer;
