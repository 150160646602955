import React, { useEffect } from "react";

import { Card, CircularProgress, Grid, Tooltip } from "@mui/material";
import Container from "@mui/material/Container";
import './zendeskDetailedView.scss';
//new

import DownvoteIcon from "../../assets/DownvoteIcon";
import UpvoteIcon from "../../assets/UpvoteIcon";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDocumentForZen, toggleDocumentVoteForZen } from "../../redux/slices/zendeskSlice";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
const renderValue = (value) => {
  if (Array.isArray(value)) {
    // Handle the case where value is an array
    return value.map((val, index) =>
      Object.entries(val).map(([key, value]) => (
        <div>
          {value !== null && (
            <p key="index">
              <span class="text-tab">
                <span class="fav-img"></span>
                {key}
              </span>

              <span
                class="answer-tab"
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              />
            </p>
          )}
        </div>
      ))
    );
  }
  // If it's neither an array nor an object, just return the value
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    ></p>
  );
};
function ZendeskDetailedView() {
  const dispatch = useDispatch();
  const params = useParams();

  const { detailed_document,is_document_loading } = useSelector((state) => state.zendeskReducer);

  const handleDetailedToggleVote = (vote_direction) => {
    let vote_details = {
      business_unit_uuid: params?.business_unit_uuid,
      sub_datasource_uuid: detailed_document?.sub_datasource_uuid,
      document_id: detailed_document?._id,
      query: params?.query,
      product_group: detailed_document["Product Group"],
      vote_direction: vote_direction,
    };
    dispatch(toggleDocumentVoteForZen(vote_details));
  };

  const setLocalStorage = () => {
    localStorage.setItem("ci", params.customer_uuid);
    localStorage.setItem("at", params.access_token);
    localStorage.setItem("rt", params.refresh_token);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      setLocalStorage();
      let data = {
        business_unit_uuid: params.business_unit_uuid,
        sub_datasource_uuid: params.sub_datasource_uuid,
        document_id: params.document_id,
        query: params.query,
      };
      await dispatch(getDocumentForZen(data));
    };
  
    fetchData();
  
  }, []);
  

  return (
    <div
      className="zenDetailswrap"
      style={{
        // overflow: "scroll"
        marginTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        {is_document_loading&&
         <Grid item xs={12} md={12} lg={12}>
              <Card className="commonCard bg-lightbg" elevation={0}>
                <div class="moredetail-wrap"> 
            <Grid
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"400px"}
            >
              <CircularProgress color="inherit" />
            </Grid>
          </div>
          </Card>
          </Grid> 
}
        {detailed_document&&!is_document_loading&&
        <Grid item xs={12} md={12} lg={12}>
          <Card className="commonCard bg-lightbg" elevation={0}>
            <div class="moredetail-wrap">
              <div className="moreContent">
                <div class="desc-content">
                  <div class="heading">
                    <Tooltip title="Title of the Solution">
                      <b
                        class="desc-bold"
                        dangerouslySetInnerHTML={{
                          __html: detailed_document["Title"],
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div class="more-contentdata">
                    <b class="desc-heading">Description</b>
                    {detailed_document.is_most_recent && (
                      <span class="most-recent">Most Recent</span>
                    )}
                    <span
                      className="count"
                      style={{
                        backgroundColor:
                          detailed_document.probability > 70
                            ? "#32C49E"
                            : detailed_document.probability > 30
                            ? "#EF9E42"
                            : "#E25757",
                      }}
                    >
                      <Tooltip title="Relevance Score">
                        {detailed_document["probability"]}
                      </Tooltip>
                    </span>{" "}
                    <p
                      class="para-content mar-top25"
                      dangerouslySetInnerHTML={{
                        __html: detailed_document["Description"],
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detailed_document["Part"],
                      }}
                    />
                  </div>
                  {detailed_document.quick_view?.length > 0 && (
                    <div class="quickTitle">
                      <button>Quick View</button>
                    </div>
                  )}
                  <div class="righttab quickView width100">
                    <div class="card height100">
                      <div class="card-content">
                        {detailed_document.quick_view.map((i) => {
                          if (i["key"] !== "Part")
                            return (
                              <div
                                id="filter"
                                class="tabs-container tabs-display-container tabshead"
                              >
                                {i.value !== null && (
                                  <p>
                                    <span class="text-tab">
                                      <span class="fav-img"></span>
                                      {i["key"]}
                                    </span>

                                    <span class="answer-tab">
                                      {renderValue(i.value)}
                                    </span>
                                  </p>
                                )}
                              </div>
                            );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="icon-list">
                  <ul>
                    <li>
                      <Tooltip title="# of times this solution has been upvoted">
                        <span onClick={() => handleDetailedToggleVote("UP")}>
                          <UpvoteIcon
                            fill={
                              detailed_document.user_vote_direction === "UP"
                                ? "#071942"
                                : "none"
                            }
                          />
                          {detailed_document.up_vote_count}
                        </span>{" "}
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="# of times this solution has been downvoted">
                        {" "}
                        <span onClick={() => handleDetailedToggleVote("DOWN")}>
                          <DownvoteIcon
                            fill={
                              detailed_document.user_vote_direction === "DOWN"
                                ? "#071942"
                                : "none"
                            }
                          />
                          {detailed_document.down_vote_count}
                        </span>
                      </Tooltip>
                      <span class="dot-li">●</span>
                    </li>
                    <li>
                      <Tooltip title="# of times this solution has been viewed">
                        {" "}
                        <span>
                          <img src="https://storage.googleapis.com/exteneral_logo/sap/eye-view.svg" />
                          {detailed_document.view_data.count}
                        </span>
                      </Tooltip>
                      <span class="dot-li">●</span>
                    </li>
                    <li>
                      <Tooltip
                        title="# of times a top technician has recommended this

solution!"
                      >
                        {" "}
                        <span className="links">
                          <img src="https://storage.googleapis.com/exteneral_logo/sap/expert.svg" />
                          {detailed_document.expert_up_vote_count}
                        </span>
                      </Tooltip>
                      <span className="dot-li">●</span>
                    </li>
                    <li>
                      <Tooltip
                        title="# of times a super expert has recommended this

solution!"
                      >
                        {" "}
                        <span className="links">
                          <img src="https://storage.googleapis.com/exteneral_logo/sap/super-expert.svg" />
                          {detailed_document.super_expert_up_vote_count}
                        </span>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
                <div class="righttab">
                  <div class="card height100">
                    <div class="card-content">
                      <div class="tabs-bar tabs-black">
                        <button
                          class="tabs-bar-item tabs-button tablink"
                          id="fil-tab"
                        >
                          Others
                        </button>
                      </div>

                      <div
                        id="filter"
                        class="tabs-container tabs-display-container tabshead"
                      >
                        <p>
                          <span class="text-tab">
                            <span class="fav-img"></span>
                            Unique Number
                          </span>
                          <span class="answer-tab">
                            {detailed_document["Unique Number"]}
                          </span>
                        </p>
                        <p>
                          <span class="text-tab">
                            <span class="fav-img"></span>
                            Product Group
                          </span>
                          <span class="answer-tab">
                            {detailed_document["Product Group"]}
                          </span>
                        </p>
                        <p>
                          <span class="text-tab">
                            <span class="fav-img"></span>
                            URL
                          </span>

                          <a
                            href={detailed_document["URL"]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {detailed_document["URL"]}
                          </a>
                        </p>

                        {detailed_document.custom.map((i) => {
                          if (i["key"] !== "Part")
                            return (
                              <div>
                                {i.value?.length > 0 && i.value !== null && (
                                  <p>
                                    <span class="text-tab">
                                      <span class="fav-img"></span>
                                      {i["key"]}
                                    </span>

                                    <span class="answer-tab">
                                      {renderValue(i.value)}
                                    </span>
                                  </p>
                                )}
                              </div>
                            );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
}
      </Container>
    </div>
  );
}

export default ZendeskDetailedView;
