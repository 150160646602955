import axios from "../../../utils/axios"

export const GetSupportedEntities = async (mapping_uuid) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_OBS}obfuscator_settings/${localStorage.getItem("ci")}/${mapping_uuid}/get_supported_entities`,
      {
        method:"GET",
        headers: {
          Accept: "application/json,text/plain",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
      }
    );
    
    return { data: response.data, status: response.status };
  } catch (error) {
    // Handle error appropriately, maybe log it or rethrow it
    console.error(error);
    return { data: null, status: error.response ? error.response.status : 500 };
  }
};

export const GetOperators = async (mapping_uuid) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_OBS}obfuscator_settings/${localStorage.getItem("ci")}/${mapping_uuid}/get_operators`,
      {
        method:"GET",
        headers: {
          Accept: "application/json,text/plain",
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
      }
    );
    
    return { data: response.data, status: response.status };
  } catch (error) {
    // Handle error appropriately, maybe log it or rethrow it
    console.error(error);
    return { data: null, status: error.response ? error.response.status : 500 };
  }
};

export const GetOperatorValues = async (mapping_uuid,operator) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator_settings/${localStorage.getItem("ci")}/${mapping_uuid}/get_operator_values?operator=${operator}`,
        {
          method:"GET",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };

  export const ApplyObfuscation = async (input_text,entities,threshold) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}apply_obfuscation/${localStorage.getItem("ci")}/apply_obfuscation`,
        {
          method:"POST",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{input_text,entities,threshold}
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };
  
  export const CreateObfuscatorSettings = async (mapping_uuid,entity,operator,operator_value) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator_settings/${localStorage.getItem("ci")}/${mapping_uuid}`,
        {
          method:"POST",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
            entity,
            operator,
            operator_value
          }
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };
  export const UpdateObfuscatorSettings = async (mapping_uuid,obfuscator_setting_uuid,entity,operator,operator_value) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator_settings/${localStorage.getItem("ci")}/${mapping_uuid}/update`,
        {
          method:"PATCH",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
            obfuscator_setting_uuid,
            entity,
            operator,
            operator_value
          }
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };

  export const GetObfuscatorSettings = async (mapping_uuid,page_no,page_size,search) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator_settings/${localStorage.getItem("ci")}/${mapping_uuid}?page_no=${page_no}&page_size=${page_size}&search=${search}`,
        {
          method:"GET",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
         
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };

  export const CreateObfuscator = async (mapping_uuid,threshold) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator/${localStorage.getItem("ci")}/${mapping_uuid}`,
        {
          method:"POST",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
            threshold
          }
          
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };
  export const UpdateObfuscator = async (mapping_uuid,is_active) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator/${localStorage.getItem("ci")}/${mapping_uuid}/create_and_update`,
        {
          method:"POST",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
            is_active
          }
          
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };

  export const UpdateThreshold = async (mapping_uuid,threshold) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator/${localStorage.getItem("ci")}/${mapping_uuid}/create_and_update`,
        {
          method:"POST",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
            threshold
          }
          
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };
  export const GetObfuscator = async (mapping_uuid) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator/${localStorage.getItem("ci")}/${mapping_uuid}/obfuscator`,
        {
          method:"GET",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
         
          
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };
  export const DeleteObfuscator = async (mapping_uuids) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator/${localStorage.getItem("ci")}/obfuscator`,
        {
          method:"DELETE",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
           mapping_uuids
          }
          
        }
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };

  export const DeleteObfuscatorSettings = async (obfuscator_setting_uuids) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_OBS}obfuscator_settings/${localStorage.getItem("ci")}`,
        {
          method:"DELETE",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
           obfuscator_setting_uuids
          }
          
        }
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };

  
  export const ObfuscateFields= async (mapping_uuid,field_mapping_uuids) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_DATA_INTEGRATION}obfuscation/${localStorage.getItem("ci")}/${mapping_uuid}`,
        {
          method:"PUT",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
          data:{
           field_mapping_uuids
          }
          
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };
  
  export const GetObfuscatedFields= async (mapping_uuid) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_DATA_INTEGRATION}obfuscation/${localStorage.getItem("ci")}/${mapping_uuid}`,
        {
          method:"GET",
          headers: {
            Accept: "application/json,text/plain",
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
  
          },
         
          
        }
      );
      
      return { data: response.data, status: response.status };
    } catch (error) {
      // Handle error appropriately, maybe log it or rethrow it
      console.error(error);
      return { data: null, status: error.response ? error.response.status : 500 };
    }
  };
  