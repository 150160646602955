import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { CreateDocumentView, GetDocument, ToggleDocumentVote } from "../actions/predictionService/knowledgebaseActions";

export const getDocumentForZen = createAsyncThunk(
    "getDocumentForZen",
    async (data, thunkAPI) => {
     
      const response = await GetDocument(
        data.business_unit_uuid,
        data.query,
        data.sub_datasource_uuid,
        data.document_id
      );
      await CreateDocumentView(
        data.business_unit_uuid,
        data.sub_datasource_uuid,
        data.document_id,
        data.query,
        response.data.data.product_group
      );
      return response
    }
  );
  export const toggleDocumentVoteForZen = createAsyncThunk(
    "toggleDocumentVoteForZen",
    async (data, thunkAPI) => {
    const response=await ToggleDocumentVote(
        data.business_unit_uuid,
        data.sub_datasource_uuid,
        data.document_id,
        data.query,
        data.product_group,
        data.vote_direction
      );
     
      return response;
    }
  );
const zendeskSlice = createSlice({
  name: "zendeskSlice",
  initialState: {
  detailed_document:null,
  is_document_loading:false

  },
  reducers: {
 
  },
  extraReducers:(builder)=>{
    builder
    .addCase(getDocumentForZen.pending,(state,action)=>{
      state.is_document_loading=true
  }).addCase(getDocumentForZen.fulfilled,(state,action)=>{
        if(action.payload.status===200){
            state.detailed_document=action.payload.data.data
        }
        state.is_document_loading=false
    })
    .addCase(toggleDocumentVoteForZen.fulfilled,(state,action)=>{
        if(action.payload.status===200){
            state.detailed_document={...state.detailed_document,...action.payload.data.data}
        }
    })
  }
});

export const {} = zendeskSlice.actions;
export default zendeskSlice.reducer;
