import React from 'react'
import "./slackAuthView.scss";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AscendoDark from "../slackAuthView/ascendo-dark.png"
import slackImg from "../slackAuthView/slack.png"
import {
  Button
} from "@mui/material";

function SlackAuthView() {
  return (
    <div className="row d-flexCenter SlackAuthViewWrap" style={{height:"100vh"}}>
    <div>
        <div className="vertical--alignCenter pt-4">
            <div className="d-flex mb-2" style={{justifyContent:'center'}}>
                <img className="mt-1" src={AscendoDark} style={{width: '11vw',height:'4vh'}} /> 
                <span className="material-icons" style={{fontSize: '2rem',marginRight: '0.5em', marginLeft: '0.5em'}}><ArrowForwardIcon/></span>
                <img src={slackImg} style={{width: '3vw',height:'4%'}}/>
            </div>
            <h6 className="page-title dark-blue-title" style={{fontSize: '2rem'}}>Ascendo has been successfully installed!</h6>
            <h6 className="dark-blue-title sub-title"> Start using Ascendo on Slack.</h6>
            <h6 className="dark-blue-title rubik-regular getText" >Get started with the following slash commands</h6>
            <div className="row pt-1">
                <ul className="pl-3 slack-desc">
                    <li className="rubik-light dark-blue-title mb-2" style={{fontSize: '15px'}}> 
                        <b className="rubik-regular"><u>/askascendo </u></b>- Get the best solution from Ascendo.
                    </li>
                    <li className="rubik-light dark-blue-title mb-2" style={{fontSize: '15px'}}>
                        <b className="rubik-regular"><u>/ascendomax </u></b>- Get the top 3 best solutions from Ascendo.
                    </li>
                  
                    <li className="rubik-light dark-blue-title  mb-2" style={{fontSize: '15px'}}> 
                        <b className="rubik-regular"><u>/setbusinessandfamily </u></b>- Set the business and family to make solutions more precise.
                    </li>
                    <li className="rubik-light dark-blue-title" style={{fontSize: '15px'}}> 
                        <b className="rubik-regular"><u>/onfly </u></b>- Get the onfly solutions from Ascendo.
                        </li>
                </ul>
            </div>
            <div className="display-flex ml-0 pr-0 pl-0 goSlackBtn" style={{marginTop: '25px'}}>
            
                    <Button className="goSlackBtn float-right" onClick={()=>    window.open('https://slack.com/intl/en-in/', '_blank')}>Go to Slack</Button>
              
            </div>
            {/* <div className="row pt-1 action-info">*You can choose to go to Slack or close the tab.</div> */}
            <div className="row pt-1 dark-blue-content help-content">
                Need help? Reach out to us at <span className="blue-content">customer.service@ascendo.ai</span></div>

        </div>
    </div>
    {/* <div className="col l8 m8 s8" style={{height: '100vh',backgroundColor: 'rgba(7, 25, 66, 1)' }}>
        <img src="../../../assets/images/ascendo_logo.svg" style={{margin: '12px 0 12px'}}/>
        <h3 className="right-text plain-content rubik-regular" style={{
        marginTop: '29.5%',
        paddingRight: '10%',
        marginLeft: '10%',
        lineHeight: '1.25em'}}>Human-Assisted Intelligence For Customer Support</h3>
        <div class='copyright'>Copyright @  Ascendo.ai. All rights reserved.</div>
    </div> */}
</div>
  )
}

export default SlackAuthView