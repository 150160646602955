import React from 'react'

function BuIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="36" rx="18" transform="matrix(-1 0 0 1 36 0)" fill="#071942" fill-opacity="0.12"/>
<path d="M22.0568 16.4338C23.3308 16.4338 24.3635 15.4011 24.3635 14.1271C24.3635 12.8531 23.3308 11.8203 22.0568 11.8203C20.7828 11.8203 19.75 12.8531 19.75 14.1271C19.75 15.4011 20.7828 16.4338 22.0568 16.4338Z" fill="#071942"/>
<path d="M22.06 8.30859H13.9379C10.4099 8.30859 8.30664 10.4118 8.30664 13.9398V22.062C8.30664 23.1184 8.49079 24.0392 8.84941 24.8146C9.68295 26.6561 11.4663 27.6932 13.9379 27.6932H22.06C25.588 27.6932 27.6913 25.59 27.6913 22.062V19.8424V13.9398C27.6913 10.4118 25.588 8.30859 22.06 8.30859ZM26.1114 18.4855C25.3554 17.8361 24.1342 17.8361 23.3782 18.4855L19.3462 21.9457C18.5902 22.5951 17.3689 22.5951 16.6129 21.9457L16.2834 21.6743C15.5953 21.0734 14.5 21.0152 13.7246 21.5386L10.0997 23.9714C9.88649 23.4286 9.76049 22.7986 9.76049 22.062V13.9398C9.76049 11.2066 11.2046 9.76244 13.9379 9.76244H22.06C24.7933 9.76244 26.2374 11.2066 26.2374 13.9398V18.5921L26.1114 18.4855Z" fill="#071942"/>
</svg>

  )
}

export default BuIcon