import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  CreateCustomField,
  GetFieldMapping,
  UpdateCustomFieldMapping,
  DeleteCustomFieldMapping,
  UpdateFieldMapping,
  GetMapping,
} from "../../actions/mapping/mappingActions";
import { GetSubDataSourceFields } from "../../actions/shared/sharedActions";
import { GetAllUploadedFiles } from "../../actions/dataIntegration/mappingActions";

export const getFieldMapping = createAsyncThunk(
  "fieldmapping",
  async (mapping_uuid, thunkAPI) => {
    const response = await GetFieldMapping(mapping_uuid);
    return response;
  }
);
export const getAllUploadedFiles = createAsyncThunk(
  "getAllUploadedFiles",
  async (data, thunkAPI) => {
   
    const response = await GetAllUploadedFiles(
    data.mapping_uuid,
      data.search,
      data.page_no,
      data.page_size
    );
    return response;
  }
);

export const createcustomField = createAsyncThunk(
  "createcustomField",
  async (data, thunkAPI) => {
    await CreateCustomField(data.mapping_uuid, data.custom_field);
    const response = await GetFieldMapping(data.mapping_uuid);
    return response;
  }
);

export const updateCustomFieldMapping = createAsyncThunk(
  "updatecustomfields",
  async (data, thunkAPI) => {
    await UpdateCustomFieldMapping(data.field_mapping_uuid, data.custom_field);
    const response = await GetFieldMapping(data.mapping_uuid);
    return response;
  }
);

export const deleteCustomFieldMapping = createAsyncThunk(
  "deletecustomfieldMapping",
  async (data, thunkAPI) => {
    await DeleteCustomFieldMapping(data.field_mapping_uuids);
    const response = await GetFieldMapping(data.mapping_uuid);
    return response;
  }
);

export const updateFieldMapping = createAsyncThunk(
  "updatefields",
  async (data, thunkAPI) => {
    await UpdateFieldMapping(data.field_mapping_uuid, data.source_field_uuid);
    const response = await GetFieldMapping(data.mapping_uuid);
    return response;
  }
);

export const getMapping = createAsyncThunk(
  "getmapping",
  async (mapping_uuid, thunkAPI) => {
    const getmappingResponse = await GetMapping(mapping_uuid);
    const getsubdatasourcefieldsResponse = await GetSubDataSourceFields(
      getmappingResponse.data.sub_datasource.uuid
    );
    const responses = { getmappingResponse, getsubdatasourcefieldsResponse };
    return responses;
  }
);
const mappingStep2Slice = createSlice({
  name: "mappingStep2",
  initialState: {
    field_mapping_details: null,
    original_field_details: null,
    field_mapping_status: false,
    sub_fields: null,
    get_mapping: null,
    status: false,
    update_field_status: null,
    zendeskGroups: null,
    ticketStatus: null,
    filesTableDetails:null
  },
  reducers: {
    updateCustomFields: (state, action) => {
      state.field_mapping_details = action.payload;
    },
    resetValues: (state, action) => {
      state.update_field_status = false;
      state.zendeskGroups = null;
      state.ticketStatus = null;
    },
    updateZendeskGroups: (state, action) => {
      state.zendeskGroups = action.payload;
    },
    updateTicketStatus: (state, action) => {
      state.ticketStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFieldMapping.pending, (state, action) => {
        state.field_mapping_status = false;
      })
      .addCase(getFieldMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.original_field_details = action.payload.data;
          state.field_mapping_details = action.payload.data?.map((field) =>
            field.is_custom ? { ...field, isEdit: false } : field
          );
          state.field_mapping_status = true;
        } else {
          state.field_mapping_status = false;
        }
      })
      .addCase(getFieldMapping.rejected, (state, action) => {
        state.field_mapping_status = false;
        state.isLoading = false;
      })
      .addCase(createcustomField.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.original_field_details = action.payload.data;
          state.field_mapping_details = action.payload.data?.map((field) =>
            field.is_custom ? { ...field, isEdit: false } : field
          );
          state.field_mapping_status = true;
        } else {
          state.field_mapping_status = false;
        }
      })
      .addCase(updateCustomFieldMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.original_field_details = action.payload.data;
          state.field_mapping_details = action.payload.data?.map((field) =>
            field.is_custom ? { ...field, isEdit: false } : field
          );
          state.field_mapping_status = true;
        } else {
          state.field_mapping_status = false;
        }
      })
      .addCase(deleteCustomFieldMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.original_field_details = action.payload.data;
          state.field_mapping_details = action.payload.data?.map((field) =>
            field.is_custom ? { ...field, isEdit: false } : field
          );
          state.field_mapping_status = true;
        } else {
          state.field_mapping_status = false;
        }
      })
      .addCase(updateFieldMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.original_field_details = action.payload.data;
          state.field_mapping_details = action.payload.data?.map((field) =>
            field.is_custom ? { ...field, isEdit: false } : field
          );
          state.field_mapping_status = true;
        } else {
          state.field_mapping_status = false;
        }
      })
      .addCase(getMapping.pending, (state, action) => {
        state.status = false;
      })
      .addCase(getMapping.fulfilled, (state, action) => {
        if (
          action.payload?.getmappingResponse?.status === 200 &&
          action.payload?.getsubdatasourcefieldsResponse?.status === 200
        ) {
          state.get_mapping = action.payload.getmappingResponse.data;
          state.sub_fields = action.payload.getsubdatasourcefieldsResponse.data;
          state.status = true;
        } else {
          state.status = false;
        }
      })
      .addCase(getMapping.rejected, (state, action) => {
        state.status = false;
      })
      .addCase(getAllUploadedFiles.fulfilled,(state,action)=>{
        if(action.payload.status===200){
          state.filesTableDetails=action.payload.data
        }
      })
  },
});

export const {
  updateCustomFields,
  resetValues,
  updateTicketStatus,
  updateZendeskGroups,
} = mappingStep2Slice.actions;

export default mappingStep2Slice.reducer;
