import axios from "../../utils/axios";

export const ListMapping = async (
  module_uuids,
  business_unit_uuids,
  sub_datasource_uuids,
  search,
  page_no,
  page_size
) => {
  let url =
    process.env.REACT_APP_DATA_INTEGRATION +
    `mapping/${localStorage.getItem(
      "ci"
    )}/table?page_no=${page_no}&page_size=${page_size}`;
  if (search !== null) {
    url += `&search=${search}`;
  }
  let response = await axios(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      "Content-Type": "application/json",
    },
    data: {
      filter: {
        module_uuids:module_uuids,
        business_unit_uuids: business_unit_uuids,
        sub_datasource_uuids: sub_datasource_uuids,
      },
    },
  });
  return { data: response.data, status: response.status };
};
export const GetMappingRequests = async (mapping_uuid, page_no, page_size) => {
  let url =
    process.env.REACT_APP_DATA_INTEGRATION +
    `mapping/${localStorage.getItem(
      "ci"
    )}/${mapping_uuid}/requests?module_uuid=${localStorage.getItem(
      "mi"
    )}&page=${page_no}&size=${page_size}`;

  let response = await axios(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      "Content-Type": "application/json",
    },
  });
  return { data: response.data, status: response.status };
};

export const RequestData = async (mapping_uuid) => {
  let url =
    process.env.REACT_APP_DATA_INTEGRATION +
    `mapping/${localStorage.getItem("ci")}/${mapping_uuid}/request_data`;

  let response = await axios(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      "Content-Type": "application/json",
    },
    data: {},
  });
  return { data: response.data, status: response.status };
};

export const ZendeskRequestData = async (req_data,mapping_uuid) => {
  let url =
    process.env.REACT_APP_DATA_INTEGRATION +
    `mapping/${localStorage.getItem("ci")}/${mapping_uuid}/request_data`;

  let response = await axios(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      "Content-Type": "application/json",
    },
    data: req_data
  });
  return { data: response.data, status: response.status };
};

export const ListMappingAll = async (
  business_unit_uuids,
  sub_datasource_uuids
) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION + `mapping/${localStorage.getItem("ci")}/all`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        filter: {
          module_uuids: [localStorage.getItem("mi")],
          business_unit_uuids,
          sub_datasource_uuids,
        },
      },
    }
  );
  return { data: response.data, status: response.status };
};
