import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Tabs,
  Tab,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import ArrowLeftIcon from "../../../assets/ArrowLeftIcon";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import React, { useEffect, useState } from "react";
import "./viewMapping.scss";

import CustomStepper from "../../../components/stepper/customStepper";
import { useDispatch } from "react-redux";
import {
  createChannelMapping,
  deleteChannelMapping,
  getAllChannelMappings,
  getAscendoFields,
  getChannelDetails,
  getSlackAuth,
} from "../../../redux/slices/mapping/listMappingSlice";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowRightIcon from "../../../assets/ArrowRightIcon";
import SelectChannelIcon from "../../../assets/SelectChannelIcon";
import { getMapping } from "../../../redux/slices/mapping/mappingStep2Slice";
function MappedCard({ text, cardId, index, searchText }) {
  return (
    <Draggable draggableId={cardId} index={index}>
      {(provided) => (
        <>
          {
            <div
              className="lists"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {text}
            </div>
          }
        </>
      )}
    </Draggable>
  );
}
function CustomCard({ text, cardId, index }) {
  return (
    <Draggable draggableId={cardId} index={index}>
      {(provided) => (
        <div
          className="lists"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {text}
        </div>
      )}
    </Draggable>
  );
}
function MappedList({ title, cards, listId, searchText }) {
  return (
    <Droppable droppableId={listId} type="CARD">
      {(provided) => (
        <ul
          className="cards"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {cards
            .map((card, cardIndex) => ({ ...card, originalIndex: cardIndex }))
            .filter((item) =>
              item.text.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((card, filteredIndex) => (
              <MappedCard
                key={card.id}
                text={card.text}
                cardId={card.id}
                index={card.originalIndex} // Now you have the original index
                filteredIndex={filteredIndex} // And the filtered index
                searchText={searchText}
              />
            ))}

          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
}
function CustomList({ title, cards, listId }) {
  return (
    <Droppable droppableId={listId} type="CARD">
      {(provided) => (
        <ul
          className="cards"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {cards.map((card, cardIndex) => (
            <CustomCard
              key={card.id}
              text={card.text}
              cardId={card.id}
              index={cardIndex}
            />
          ))}

          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
}

function ViewMapping() {
  const [value, setValue] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchSlackText, setSearchSlackText] = useState("");
  const [defaultAll, setDefaultAll] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [tab, setTab] = useState();

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(`/${params.customer_uuid}/mapping`);
  };

  const [tabs, setTabs] = useState([]);
  const handleTabs = (tab) => {
    if (tabs.includes(tab)) {
      setDialogOpen(true);
      setTab(tab);
    } else {
      setTabs([...tabs, tab]);
      setValue(tab);
    }
  };
  const { all_ascendo_fields, channels, channel_mappings, is_created } =
    useSelector((state) => state.listMappingReducer);
  const { get_mapping } = useSelector((state) => state.mappingStep2Reducer);
  const steps = [
    "Select Data Source",
    get_mapping?.sub_datasource?.datasource?.name === "pdf"
      ? "Files Uploaded"
      : "Field Mapping",
    "Obfuscation",
    "View Mapping",
    "Summary",
  ];
  const theme = useTheme();
  const colors = theme.palette;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  const initialBoard = {
    mapped: all_ascendo_fields
      ?.filter(
        (item) =>
          item?.ascendo_field !== "Title" &&
          !channel_mappings
            ?.find((item) => item?.channel?.name === "AI Bot")
            ?.view_mapping?.body?.includes(item?.uuid)
      )

      ?.map((item) => ({ id: item?.uuid, text: item?.ascendo_field })),
    custom: all_ascendo_fields
      ?.filter(
        (item) =>
          item?.ascendo_field !== "Title" &&
          channel_mappings
            ?.find((item) => item?.channel?.name === "AI Bot")
            ?.view_mapping?.body?.includes(item?.uuid)
      )
      ?.map((item) => ({ id: item?.uuid, text: item?.ascendo_field })),
  };
  const initialSlackBoard = {
    mapped: all_ascendo_fields
      ?.filter(
        (item) =>
          item?.ascendo_field !== "Title" &&
          !channel_mappings
            ?.find((item) => item?.channel?.name === "Slack Bot")
            ?.view_mapping?.body?.includes(item?.uuid)
      )
      ?.map((item) => ({ id: item?.uuid, text: item?.ascendo_field })),
    custom: all_ascendo_fields
      ?.filter(
        (item) =>
          item?.ascendo_field !== "Title" &&
          channel_mappings
            ?.find((item) => item?.channel?.name === "Slack Bot")
            ?.view_mapping?.body?.includes(item?.uuid)
      )
      ?.map((item) => ({ id: item?.uuid, text: item?.ascendo_field })),
  };

  const [board, setBoard] = useState(initialBoard);
  const [slackBoard, setSlackBoard] = useState(initialSlackBoard);
  useEffect(() => {
    if (all_ascendo_fields.length > 0 || channel_mappings.length > 0) {
      setBoard(initialBoard);
      setSlackBoard(initialSlackBoard);
      if (channel_mappings.length > 0) {
        if (
          channel_mappings.map((item) => item.channel.name).includes("AI Bot")
        ) {
          setValue("AI Bot");
          if (
            channel_mappings
              .map((item) => item.channel.name)
              .includes("Slack Bot")
          )
            setTabs(["Slack Bot", "AI Bot"]);
          else setTabs(["AI Bot"]);
        } else if (
          channel_mappings
            .map((item) => item.channel.name)
            .includes("Slack Bot")
        ) {
          setTabs(["Slack Bot"]);
          setValue("Slack Bot");
        }
      }
    }
  }, [all_ascendo_fields, channel_mappings]);

  const deepCopyBoard = (board) => {
    return {
      mapped: board.mapped.map((item) => ({ ...item })),
      custom: board.custom.map((item) => ({ ...item })),
    };
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (
      source?.droppableId === "custom" &&
      destination?.droppableId === "mapped"
    )
      setSearchText("");

    // If there is no destination or the item was dropped back into its original position
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newBoard = deepCopyBoard(board);

    const sourceList = newBoard[source.droppableId];
    const destinationList = newBoard[destination.droppableId];

    // Remove the item from the source list
    const [removed] = sourceList.splice(source.index, 1);

    // Add the item to the destination list
    destinationList.splice(destination.index, 0, removed);

    setBoard(newBoard);
    if (defaultAll) {
      setSlackBoard(deepCopyBoard(newBoard));
    }
  };

  const onDragEndSlack = (result) => {
    const { destination, source, draggableId } = result;
    if (
      source?.droppableId === "custom" &&
      destination?.droppableId === "mapped"
    )
      setSearchSlackText("");
    // If there is no destination or the item was dropped back into its original position
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newBoard = deepCopyBoard(slackBoard);

    const sourceList = newBoard[source.droppableId];
    const destinationList = newBoard[destination.droppableId];

    // Remove the item from the source list
    const [removed] = sourceList.splice(source.index, 1);

    // Add the item to the destination list
    destinationList.splice(destination.index, 0, removed);

    setSlackBoard(newBoard);
    if (defaultAll) {
      setBoard(deepCopyBoard(newBoard));
    }
  };

  const handleViewMapping = () => {
    setIsNext(true);

    setMessage("View mapping updated successfully.");

    if (tabs.includes("AI Bot")) {
      setOpenSnack(true);

      let data = {
        channel_uuid: channels.find((item) => item.name === "AI Bot").uuid,
        mapping_uuid: params.mapping_uuid,
        view_mapping: {
          header: all_ascendo_fields.find(
            (item) => item.ascendo_field === "Title"
          ).uuid,
          body: board.custom.map((item) => item.id),
        },
      };
      dispatch(createChannelMapping(data));
    }
    if (tabs.includes("Slack Bot")) {
      setOpenSnack(true);

      let data = {
        channel_uuid: channels.find((item) => item.name === "Slack Bot").uuid,
        mapping_uuid: params.mapping_uuid,
        view_mapping: {
          header: all_ascendo_fields.find(
            (item) => item.ascendo_field === "Title"
          ).uuid,
          body: slackBoard.custom.map((item) => item.id),
        },
      };
      dispatch(createChannelMapping(data));
    }
    if (!tabs.includes("Slack Bot") && !tabs.includes("AI Bot"))
      navigate(
        `/${params.customer_uuid}/mapping/${params.mapping_uuid}/summary`
      );
  };

  const handleDeleteMapping = () => {
    setOpenSnack(true);
    setMessage("Mappings deleted successfully.");

    if (channel_mappings?.map((ch) => ch.channel?.name).includes(tab)) {
      let data = {
        filter: {
          channel_uuids: [channels.find((item) => item.name === tab).uuid],
          mapping_uuids: [params.mapping_uuid],
        },
      };
      dispatch(deleteChannelMapping(data));
    }
  };

  useEffect(() => {
    if (defaultAll) {
      if (value === "AI Bot") {
        setSlackBoard(deepCopyBoard(board));
      }
      if (value === "Slack Bot") setBoard(deepCopyBoard(slackBoard));
    }
  }, [defaultAll]);

  useEffect(() => {
    dispatch(getAscendoFields(params.mapping_uuid));
    dispatch(getChannelDetails());
    dispatch(getMapping(params.mapping_uuid));
    dispatch(getAllChannelMappings(params.mapping_uuid));
    let intervalId = setInterval(() => {
      dispatch(getChannelDetails());
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handlePrevStep = () => {
    
      navigate(
        `/${params.customer_uuid}/mapping/${params.mapping_uuid}/obfuscation`
      );
  };
  const handleNextStep = () => {
    handleViewMapping();
  };

  useEffect(() => {
    if (is_created && isNext)
      navigate(
        `/${params.customer_uuid}/mapping/${params.mapping_uuid}/summary`
      );
  }, [is_created, isNext]);

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        zIndex: 1300,
        position: "absolute",
        top: 0,
        left: 0,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {/* Sidebar */}
      <Grid
        width="232px"
        sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
      ></Grid>

      {/* Main Content */}
      <Grid
        width="calc(100% - 232px)"
        backgroundColor="white"
        position="relative"
        sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
      >
        <Grid display="flex" variant="stepperheaderFixedtop">
          <Grid width="100%">
            <Typography
              margin="16px 0px 5px 24px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Create Mapping
            </Typography>

            <Typography
              margin="0px 0px 10px 24px"
              color={colors.ascendo.darkblue}
              sx={{ fontSize: "14px", lineHeight: "17px" }}
            >
              Create a mapping that can help discover issue trends.
            </Typography>
          </Grid>
          <Typography marginTop="20px">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
        </Grid>

        <Grid variant="stepperFixedtop">
          <Divider variant="middle" />
          <CustomStepper steps={steps} activeStep={3} />
        </Grid>

        <Grid variant="stepperScroller">
          <div>
            <Divider variant="middle" />
            <Typography
              margin="16px 0px 10px 24px"
              fontWeight="600"
              lineHeight="17px"
              color={colors.ascendo.darkblue}
            >
              View Mapping
            </Typography>
            <div className="viewMapwrap">
              <div className="tabSection">
                <div
                  className="selectionTxt"
                  onClick={() => handleTabs("AI Bot")}
                >
                  <Checkbox size="small" checked={tabs.includes("AI Bot")} />
                  <p>AI Bot</p>
                </div>

                <div
                  className="selectionTxt"
                  onClick={() => {
                    if (
                      channels.find((channel) => channel.name === "Slack Bot")
                        ?.is_setup
                    )
                      handleTabs("Slack Bot");
                  }}
                >
                  {" "}
                  <Checkbox
                    size="small"
                    checked={tabs.includes("Slack Bot")}
                    disabled={
                      !channels.find((channel) => channel.name === "Slack Bot")
                        ?.is_setup
                    }
                  />
                  <p
                    style={{
                      opacity: channels.find(
                        (channel) => channel.name === "Slack Bot"
                      )?.is_setup
                        ? 1
                        : 0.4,
                    }}
                  >
                    Slack
                  </p>
                  {!channels.find((channel) => channel.name === "Slack Bot")
                    ?.is_setup && (
                    <p
                      style={{
                        marginLeft: "5px",
                        textDecoration: "underline",
                        color: colors.ascendo.blue,
                        cursor: "pointer",
                      }}
                      onClick={() => dispatch(getSlackAuth())}
                    >
                      Setup
                    </p>
                  )}
                </div>
              </div>
              <Typography fontSize={"15px"}>
                Please select support channels to customize the template, click
                setup if already not setup.
              </Typography>
              {tabs.length === 0 && (
                <Card className="selectChannel">
                  <SelectChannelIcon /> Select a Support channel to customize
                  the template.
                </Card>
              )}
              {tabs.length > 0 && (
                <div sx={{ margin: "10px" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    className="tabmenuWrap"
                  >
                    {tabs.includes("AI Bot") && (
                      <Tab label="AI Bot" value="AI Bot" />
                    )}
                    {tabs.includes("Slack Bot") && (
                      <Tab label="Slack" value="Slack Bot" />
                    )}
                  </Tabs>
                </div>
              )}
              {value === "AI Bot" && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="board"
                    direction="horizontal"
                    type="COLUMN"
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="innerTabs"
                      >
                        <div className="leftMapped">
                          <Typography>Mapped Fields</Typography>
                          <div className="innerMapped">
                            <input
                              className="searchWrap"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              type="text"
                              placeholder="Search"
                            />

                            <MappedList
                              key={"mapped"}
                              title={"mapped"}
                              cards={board["mapped"]}
                              listId={"mapped"}
                              searchText={searchText}
                            />
                          </div>
                        </div>
                        <div className="rightCustom">
                          <Typography>Customized Layouts</Typography>
                          <div className="innerCustom">
                            <Typography className="titleBg">Title</Typography>
                            <CustomList
                              key={"custom"}
                              title={"custom"}
                              cards={board["custom"]}
                              listId={"custom"}
                            />
                          </div>
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {value === "Slack Bot" && (
                <DragDropContext onDragEnd={onDragEndSlack}>
                  <Droppable
                    droppableId="board"
                    direction="horizontal"
                    type="COLUMN"
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="innerTabs"
                      >
                        <div className="leftMapped">
                          <Typography>Mapped Fields </Typography>
                          <div className="innerMapped">
                            <input
                              className="searchWrap"
                              value={searchSlackText}
                              onChange={(e) =>
                                setSearchSlackText(e.target.value)
                              }
                              type="text"
                              placeholder="Search"
                            />

                            <MappedList
                              key={"mapped"}
                              title={"mapped"}
                              cards={slackBoard["mapped"]}
                              listId={"mapped"}
                              searchText={searchSlackText}
                            />
                          </div>
                        </div>
                        <div className="rightCustom">
                          <Typography>Customized Layouts</Typography>
                          <div className="innerCustom">
                            <Typography className="titleBg">Title</Typography>

                            <CustomList
                              key={"custom"}
                              title={"custom"}
                              cards={slackBoard["custom"]}
                              listId={"custom"}
                            />
                          </div>
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {tabs.length === 2 && (
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  onClick={() => setDefaultAll(!defaultAll)}
                >
                  <Checkbox size="small" checked={defaultAll} />
                  <Typography fontSize={"14px"}>
                    Make this default for all support channels.
                  </Typography>
                </Grid>
              )}
            </div>
          </div>
        </Grid>

        <Divider variant="middle" />

        {/* Continue Button */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          message={message}
          autoHideDuration={3500}
          action={action}
          onClose={handleSnackBarClose}
        />
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Removing channel will remove all the templates saved and will not
              be available for this mapping.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ pr: 3, pb: 2.5 }}>
            <Button
              variant="ascendo"
              width="75px"
              sx={{
                color: "white",
                backgroundColor: "red",
                "&:hover": {
                  color: "rgba(7, 25, 66)",
                },
              }}
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="ascendo"
              width="75px"
              onClick={() => {
                setDialogOpen(false);
                handleDeleteMapping();
                setTabs(tabs.filter((t) => t !== tab));
                setValue(tabs.find((t) => t !== tab));
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Grid
          variant="stepperFixedbottombtn"
          display="flex"
          justifyContent="flex-end"
          marginBottom="32px"
          marginRight="40px"
          gap={2}
        >
          <Grid item width="120px" margin="26px 0px 0px 0px">
            <Button
              fullWidth
              sx={{
                color: colors.ascendo.darkblue,
                textTransform: "none",
              }}
              startIcon={<ArrowLeftIcon />}
              onClick={handlePrevStep}
            >
              Back
            </Button>
          </Grid>
          <Grid item width="120px" margin="26px 0px 0px 0px">
            <Button
              fullWidth
              endIcon={<ArrowRightIcon />}
              onClick={handleNextStep}
              sx={{
                backgroundColor: colors.ascendo.yellow,
                color: colors.ascendo.darkblue,
                textTransform: "none",
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ViewMapping;
