import React from 'react'

function SlackColorIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5405 1.87988C13.3105 1.87988 12.3105 2.87986 12.3105 4.10986V8.74988C12.3105 9.97988 13.3105 10.9799 14.5405 10.9799C15.7705 10.9799 16.7706 9.97988 16.7706 8.74988V4.10986C16.7706 2.88986 15.7705 1.87988 14.5405 1.87988Z" fill="#5BB582"/>
    <path d="M20.1802 7.11035C19.1102 7.11035 18.2402 7.98035 18.2402 9.05035V10.6204C18.2402 10.8304 18.4102 11.0004 18.6202 11.0004H20.1902C21.2602 11.0004 22.1302 10.1304 22.1302 9.06036C22.1302 7.99036 21.2502 7.11035 20.1802 7.11035Z" fill="#5BB582"/>
    <path d="M8.49988 6.98926H3.85988C2.62988 6.98926 1.62988 7.98927 1.62988 9.21927C1.62988 10.4493 2.62988 11.4493 3.85988 11.4493H8.49988C9.72988 11.4493 10.7299 10.4493 10.7299 9.21927C10.7299 7.98927 9.72988 6.98926 8.49988 6.98926Z" fill="#64C4EC"/>
    <path d="M8.7896 1.62012C7.7196 1.62012 6.84961 2.49012 6.84961 3.56012C6.84961 4.63012 7.7196 5.50012 8.7896 5.50012H10.3596C10.5696 5.50012 10.7396 5.33012 10.7396 5.12012V3.55011C10.7296 2.50011 9.8596 1.62012 8.7896 1.62012Z" fill="#64C4EC"/>
    <path d="M9.43019 13.0293C8.20019 13.0293 7.2002 14.0293 7.2002 15.2593V19.8993C7.2002 21.1293 8.20019 22.1293 9.43019 22.1293C10.6602 22.1293 11.6602 21.1293 11.6602 19.8993V15.2593C11.6602 14.0293 10.6602 13.0293 9.43019 13.0293Z" fill="#CE335B"/>
    <path d="M5.36058 13.0293H3.79057C2.72057 13.0293 1.85059 13.8993 1.85059 14.9693C1.85059 16.0393 2.72057 16.9093 3.79057 16.9093C4.86057 16.9093 5.73058 16.0393 5.73058 14.9693V13.3993C5.73058 13.1993 5.56058 13.0293 5.36058 13.0293Z" fill="#CE335B"/>
    <path d="M20.1102 12.5703H15.4702C14.2402 12.5703 13.2402 13.5703 13.2402 14.8003C13.2402 16.0303 14.2402 17.0303 15.4702 17.0303H20.1102C21.3402 17.0303 22.3402 16.0303 22.3402 14.8003C22.3402 13.5703 21.3402 12.5703 20.1102 12.5703Z" fill="#E4B64A"/>
    <path d="M15.1795 18.4707H13.6095C13.3995 18.4707 13.2295 18.6407 13.2295 18.8507V20.4207C13.2295 21.4907 14.0995 22.3607 15.1695 22.3607C16.2395 22.3607 17.1095 21.4907 17.1095 20.4207C17.1195 19.3407 16.2495 18.4707 15.1795 18.4707Z" fill="#E4B64A"/>
    </svg>
    
  )
}

export default SlackColorIcon