import React from 'react'

function UpvoteIcon({fill}) {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M6.37259 1.54394L6.37259 1.54394C6.6986 1.15202 7.3014 1.15202 7.62741 1.54394L7.05151 2.02299L7.62741 1.54394L12.062 6.8751C12.5319 7.43997 12.0793 8.20787 11.4346 8.20787H9.36336V13.9366C9.36336 14.4071 8.97676 14.75 8.54806 14.75H5.45194C5.02322 14.75 4.63664 14.4071 4.63664 13.9366V8.20787H2.56543C1.92067 8.20787 1.46814 7.43996 1.93801 6.8751M6.37259 1.54394L1.93801 6.8751M6.37259 1.54394L1.93801 6.8751M6.37259 1.54394L1.93801 6.8751" stroke="#071942" stroke-width="1.5"/>
</svg>

    
  )
}

export default UpvoteIcon