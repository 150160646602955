import axios from "../../../utils/axios";

export const GetBotVersions = async () => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem("ci")}/versions`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};
export const GetAibot = async (bot_uuid) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem("ci")}/${bot_uuid}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};
export const UpdateBotSetting = async (bot_uuid, name, version_uuid) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem("ci")}/${bot_uuid}/update`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        name,
        version_uuid,
      },
    }
  );
  return { data: response.data, status: response.status };
};
export const UpdateBotVersion = async (version) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `bot_setting/${localStorage.getItem("ci")}/version?version=${version}`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};
export const ActivateBot= async (bot_uuid,is_active) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem("ci")}/${bot_uuid}/activate`,
    {
      method: "PUT",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data:{
        is_active
      }
    }
  );
  return { data: response.data, status: response.status };
};


export const GetAllBots = async (search,page_no, page_size) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem(
        "ci"
      )}/table?page_no=${page_no}&page_size=${page_size}&search=${search??""}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const CreateNewAiBot = async (name, version_uuid) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        name,
        version_uuid,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const DeleteBots = async (bot_uuids) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem("ci")}/delete`,
    {
      method: "DELETE",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data:{
        bot_uuids
      }

    }
  );
  return { data: response.data, status: response.status };
};

export const RefreshApiKeyForBot = async (bot_uuid) => {
  let response = await axios(
    process.env.REACT_APP_CHAT_SERVICES +
      `aibot_details/${localStorage.getItem("ci")}/${bot_uuid}/refresh`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    }
  );
  return { data: response.data, status: response.status };
};