import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DeleteProcess,
  GetConfuenceSpaces,
  GetProcessTable,
  GetZendeskGroups,
  GetZendeskStatus,
} from "../actions/process/processActions";
import { GetAllModels } from "../actions/dataIntegration/modelsActions";
import { GetAllPgInBuForUser } from "../actions/identity/organizationActions";
import { GetServiceNowStatus } from "../actions/dataIntegration/servicenowActions";

export const getZendeskDetails = createAsyncThunk(
  "getZendeskDetails",
  async (thunkAPI) => {
    const groups = await GetZendeskGroups();
    const status = await GetZendeskStatus();
    const responses = { groups, status };
    return responses;
  }
);

export const getConfluenceSpaces = createAsyncThunk(
  "getConfluenceSpaces",
  async (thunkAPI) => {
    const response = await GetConfuenceSpaces();
    return response;
  }
);
export const getServicenowStatus = createAsyncThunk(
  "getServicenowStatus",
  async (subdatasource_name,thunkAPI) => {
    const response = await GetServiceNowStatus(subdatasource_name);
    return response;
  }
);

export const getAllModels = createAsyncThunk(
  "getAllModeks",
  async (thunkAPI) => {
    const response = await GetAllModels();
    return response;
  }
);

export const getAllPgInBuForUserMapping = createAsyncThunk(
  "getAllPgInBuForUserMapping",
  async (business_unit_uuid, thunkAPI) => {
    const response = await GetAllPgInBuForUser([business_unit_uuid]);
    return response;
  }
);


const dataOptionsSlice = createSlice({
  name: "dataOptions",
  initialState: {
    zendesk_groups: null,
    zendesk_status: null,
    models: [],
    selected_model: null,
    pg: [],
    selected_pg: null,
    spaces: [],
    selected_spaces: [],
    service_now_status: [],
    selected_service_now_status: [],
    snack_message:null
    
  },
  reducers: {
    updateSelectedModel: (state, action) => {
      state.selected_model = action.payload;
    },
    updateSelectedPg: (state, action) => {
      state.selected_pg = action.payload;
    },
    updateSelectedSpaces: (state, action) => {
      state.selected_spaces = action.payload;
    },
    updateSelectedServicenowStatus: (state, action) => {
      state.selected_service_now_status = action.payload;
    },
    updateSnackMessage:(state,action)=>{
      state.snack_message=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllModels.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.models = action.payload.data;
          state.selected_model = action.payload.data?.find(
            (item) => item.label === "Genie 0.3"
          );
        } else {
        }
      })
      .addCase(getConfluenceSpaces.pending,(state,action)=>{
      state.snack_message=null
      })
      .addCase(getConfluenceSpaces.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.spaces = action.payload.data;
        } else {
          state.snack_message=action.payload.data.detail
        }
        
      })
      .addCase(getServicenowStatus.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.service_now_status = action.payload.data;
        } else {
          state.snack_message=action.payload.data.detail

        }
      })
      .addCase(getAllPgInBuForUserMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.pg = action.payload.data;
        } else {
        }
      })
      .addCase(getZendeskDetails.pending, (state, action) => {
        state.get_zendesk_status = false;
      })
      .addCase(getZendeskDetails.fulfilled, (state, action) => {
        if (
          action.payload?.groups?.status === 200 &&
          action.payload?.status?.status === 200
        ) {
          state.zendesk_groups = action.payload.groups.data;
          state.zendesk_status = action.payload.status.data;
          state.get_zendesk_status = true;
        } else {
          state.get_zendesk_status = false;
        }
      })

      .addCase(getZendeskDetails.rejected, (state, action) => {
        state.get_zendesk_status = false;
      });
  },
});

export const {
  updateTicketStatus,
  updateZendeskGroups,
  updateSelectedModel,
  updateSelectedPg,
  updateSelectedSpaces,
  updateSelectedServicenowStatus,
  updateSnackMessage
} = dataOptionsSlice.actions;

export default dataOptionsSlice.reducer;
