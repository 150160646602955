import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";

import { AddCircleOutlineOutlined, Refresh } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  debounce,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchNormalIcon from "../../../assets/SearchNormalIcon";
import UrlIcon from "../../../assets/UrlIcon";
import ResizableTable from "../../../components/dataTable/resizableTable";
import {
  updateAibotPagination,
  updateIsDelete,
} from "../../../redux/slices/dataTableSlice";
import {
  activateBot,
  createNewAiBot,
  deleteBots,
  deleteChannelMappingFromTable,
  getAllBots,
  getBotVersions,
  getChannelByName,
  getChannelMappingTable,
  refreshApiKeyForBot,
  resetAibotValues,
  updateChannelMappingStatus,
  updateNewAiBot,
  updateSelectedBu,
} from "../../../redux/slices/supportChannels/supportChannelsSlice";
import "./aibotView.scss";
import { forEach, size } from "lodash";
function AibotTable({ searchProcess }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState(false);
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  const { all_bots, aibot_table } = useSelector(
    (state) => state.supportChannelReducer
  );

  const columns = all_bots?.columns;
  const display_map = all_bots?.display_map;
  const mappings = all_bots?.data;
  const { aibot_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );
  useEffect(() => {
    let data = {
      search: searchProcess ? searchProcess : null,

      page_no: parseInt(aibot_paginationDetails.page) + 1,
      page_size: aibot_paginationDetails.pageSize,
    };
    dispatch(getAllBots(data));
  }, [
    aibot_paginationDetails.page,
    aibot_paginationDetails.pageSize,
    searchProcess,
  ]);

  const cols = useMemo(() =>
    columns
      ? [
          ...columns?.map((column) => {
            if (column === "link") {
              return {
                accessorKey: column,
                size: 60,
                header: display_map[column],
                Cell: ({ cell }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      <Tooltip title="Click to copy the AI bot url with API key!">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();

                            setMessage("Copied the AI Bot URL to clipboard.");

                            navigator.clipboard.writeText(`${cell.getValue()}`);
                            setOpenSnack(true);
                          }}
                        >
                          <UrlIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  );
                },
              };
            }
            if (column === "is_active") {
              return {
                accessorKey: column,
                header: display_map[column],
                size: 60,
                Cell: ({ cell }) => {
                  const [is_active, setIsActive] = useState(cell.getValue());
                  const bot_uuid = cell.row.original.id;

                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Switch
                          size="small"
                          checked={is_active}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            e.stopPropagation();
                            let data = {
                              is_active: !is_active,
                              bot_uuid,
                            };
                            setIsActive(!is_active);
                            dispatch(activateBot(data));
                          }}
                        />
                      </div>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {is_active ? "Active" : "Inactive"}
                      </div>
                    </div>
                  );
                },
              };
            }
            return {
              accessorKey: column,
              header: display_map[column],
              Cell: ({ cell }) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              ),
            };
          }),
          {
            accessorKey: "refresh_api_key",
            header: "Refresh Api Key",
            size: 60,

            Cell: ({ cell }) => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {
                  <Tooltip title="Click to refresh api key">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();

                        let data = {
                          bot_uuid: cell.row.original.id,
                          page_no: parseInt(aibot_paginationDetails.page) + 1,
                          page_size: aibot_paginationDetails.pageSize,
                        };
                        dispatch(refreshApiKeyForBot(data));
                        setMessage("Refreshed API Key !");
                        setOpenSnack(true);
                      }}
                    >
                      <Refresh sx={{ color: colors.ascendo.darkblue }} />
                    </IconButton>
                  </Tooltip>
                }
              </div>
            ),
          },
        ]
      : []
  );

  const rows = mappings
    ? mappings?.map((dataItem, index) => {
        const row = { id: dataItem.uuid, mapping_uuid: dataItem.mapping_uuid };

        columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateAibotPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {
    navigate(
      `/${localStorage.getItem("ci")}/support-channel/aibots/${
        params.original.id
      }/view`
    );
  };

  return (
    <div
      className="listmappingWrapper"
      style={{ overflow: "scroll", height: "calc(100vh - 220px)" }}
    >
      <Grid className="listMappingWrap">
        <ResizableTable
          data={rows}
          columns={cols}
          paginationDetails={aibot_paginationDetails}
          onTablePaginationChange={handlePaginationModelChange}
          onTableRowClick={handleRowClick}
          rowCount={all_bots?.total || 0}
        />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        autoHideDuration={3500}
        message={message}
        action={action}
        onClose={handleSnackBarClose}
      />
    </div>
  );
}

function ListBots() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const [searchProcessChange, setSearchProcessChange] = useState();
  const [searchProcess, setSearchProcess] = useState();
  const [message, setMessage] = useState();

  const [open, setOpen] = useState(false);

  const { channel_details, aibot_table, is_created, new_aibot } = useSelector(
    (state) => state.supportChannelReducer
  );
  const { isDeleteVisible, selectionModel } = useSelector(
    (state) => state.dataTableReducer
  );
  const { aibot_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  const [openSnack, setOpenSnack] = useState(false);
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );

  const handleSearchProcessChange = (event) => {
    const value = event.target.value;
    setSearchProcessChange(value);
    setSearchProcess(value);
  };

  const debounceOnChange = debounce(handleSearchProcessChange, 1000);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchProcess(searchProcessChange);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteMapping = async () => {
    setMessage("Bots deleted successfully.");
    let data = {
      search: searchProcess ? searchProcess : null,
      page_no: parseInt(aibot_paginationDetails.page) + 1,
      page_size: aibot_paginationDetails.pageSize,
      bot_uuids: Object.keys(selectionModel),
    };

    dispatch(deleteBots(data));

    dispatch(updateIsDelete(false));
    setOpen(false);
    setOpenSnack(true);
  };

  useEffect(() => {
    dispatch(getChannelByName("AI Bot"));
    dispatch(getBotVersions());
    dispatch(resetAibotValues());
  }, []);

  return (
    <div
      className="aibotWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        paddingTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <div className="titleFlex">
              <Typography variant="h4" sx={{ mb: 2 }}>
                AI Bot
              </Typography>
              {/* {channel_details && channel_details.is_active ? (
                <span className="statusBtn active">Active</span>
              ) : (
                <span className="statusBtn inactive">Inactive</span>
              )} */}
            </div>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Integrate AI bot and organize data to get predictions with
              Ascendo.ai.
            </Typography>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={3500}
          message={message}
          action={action}
          onClose={handleSnackBarClose}
        />

        <Grid className="cardGrid">
          <Card className="commonCard">
            <Grid>
              <Grid
                display="flex"
                marginLeft="0px"
                marginTop="0px"
                paddingTop="12px"
                justifyContent="space-between"
              >
                <Grid style={{ flex: 1 }} marginLeft="12px" marginRight="12px">
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchNormalIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search for AI Bot by name"
                    size="small"
                    type="search"
                    fullWidth
                    onChange={debounceOnChange}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>

                {isDeleteVisible && (
                  <Grid item style={{ marginRight: "16px" }}>
                    <Button
                      variant="ascendo"
                      startIcon={
                        <DeleteOutlineIcon
                          sx={{ color: colors.ascendo.darkblue }}
                        />
                      }
                      onClick={handleClickOpen}
                      fullWidth
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
                <Button
                  variant="ascendo"
                  sx={{ mr: "12px" }}
                  onClick={() =>
                    navigate(
                      `/${localStorage.getItem(
                        "ci"
                      )}/support-channel/aibots/step-1`
                    )
                  }
                  startIcon={<AddCircleOutlineOutlined />}
                >
                  Create AI Bot
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the selected mappings?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ pr: 3, pb: 2.5 }}>
                    <Button
                      variant="ascendo"
                      width="75px"
                      sx={{
                        color: "white",
                        backgroundColor: "red",
                        "&:hover": {
                          color: "rgba(7, 25, 66)",
                        },
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="ascendo"
                      width="75px"
                      onClick={handleDeleteMapping}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid>
                <AibotTable searchProcess={searchProcess} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </div>
  );
}

export default ListBots;
