import React from "react";

function ArrowRightIcon({opacity}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#071942"
      fillOpacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0253 15.6829C11.8669 15.6829 11.7086 15.6246 11.5836 15.4996C11.3419 15.2579 11.3419 14.8579 11.5836 14.6163L16.2003 9.99961L11.5836 5.38294C11.3419 5.14128 11.3419 4.74128 11.5836 4.49961C11.8253 4.25794 12.2253 4.25794 12.4669 4.49961L17.5253 9.55794C17.7669 9.79961 17.7669 10.1996 17.5253 10.4413L12.4669 15.4996C12.3419 15.6246 12.1836 15.6829 12.0253 15.6829Z"
        
       
      />
      <path
        d="M11.4775 15.6057C11.6321 15.7603 11.8293 15.8329 12.0253 15.8329C12.2212 15.8329 12.4184 15.7603 12.573 15.6057L17.6313 10.5473C17.9316 10.2471 17.9316 9.75212 17.6313 9.45188L12.573 4.39354C12.2727 4.0933 11.7778 4.0933 11.4775 4.39354C11.1773 4.69379 11.1773 5.18876 11.4775 5.48901L15.9881 9.99961L11.4775 14.5102C11.1773 14.8105 11.1773 15.3054 11.4775 15.6057Z"
       
      />
      <path
        d="M16.941 10.625H2.91602C2.57435 10.625 2.29102 10.3417 2.29102 10C2.29102 9.65833 2.57435 9.375 2.91602 9.375H16.941C17.2827 9.375 17.566 9.65833 17.566 10C17.566 10.3417 17.2827 10.625 16.941 10.625Z"
       
      />
      <path
        d="M2.91602 10.775H16.941C17.3655 10.775 17.716 10.4245 17.716 10C17.716 9.57549 17.3655 9.225 16.941 9.225H2.91602C2.49151 9.225 2.14102 9.57549 2.14102 10C2.14102 10.4245 2.49151 10.775 2.91602 10.775Z"
       
      />
    </svg>
  );
}

export default ArrowRightIcon;
