import React from 'react'

function Inboxrefresh() {
  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.39256 6.68518L3.39256 6.68518L3.39219 6.68574C2.51462 8.00209 2.05039 9.5297 2.05039 11.1079C2.05039 15.488 5.61176 19.0579 10.0004 19.0579C14.3893 19.0579 17.9504 15.4711 17.9504 11.0912C17.9504 6.71105 14.389 3.14121 10.0004 3.14121C9.09865 3.14121 8.22231 3.27607 7.37999 3.52876C6.99249 3.64501 6.78079 4.05166 6.89628 4.43661C7.01253 4.82411 7.41917 5.03581 7.80413 4.92033L7.80464 4.92017C8.50301 4.70655 9.24312 4.59954 10.0004 4.59954C13.587 4.59954 16.5087 7.51327 16.5087 11.0995C16.5087 14.686 13.5952 17.5995 10.0087 17.5995C6.42229 17.5995 3.50872 14.686 3.50872 11.0995C3.50872 9.81085 3.88627 8.55532 4.60004 7.48868C4.8237 7.15779 4.72523 6.70468 4.39809 6.48338C4.06717 6.25953 3.61392 6.35796 3.39256 6.68518Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
<path d="M6.08412 4.98337L6.08412 4.98337L6.08489 4.98403C6.22079 5.10051 6.39426 5.15811 6.55831 5.15811C6.75406 5.15811 6.95624 5.07049 7.09996 4.9076L7.1003 4.90721L9.50864 2.14888L9.50872 2.14879C9.76929 1.84961 9.74124 1.38684 9.44134 1.12496C9.13986 0.853343 8.67633 0.893794 8.41623 1.19243L8.41621 1.19245L6.0079 3.9591C6.00789 3.9591 6.00789 3.95911 6.00788 3.95912C5.74618 4.25961 5.77654 4.71423 6.08412 4.98337Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
<path d="M8.94131 7.07219L8.94129 7.07221L8.94339 7.07367C9.06799 7.15993 9.22109 7.20811 9.36697 7.20811C9.58632 7.20811 9.81092 7.11264 9.94889 6.90717C10.1887 6.57893 10.1113 6.12578 9.79246 5.89391L6.98427 3.84401L6.98427 3.844L6.98298 3.84308C6.6569 3.61291 6.20252 3.67809 5.96902 4.0082C5.73766 4.32697 5.80451 4.78094 6.12472 5.01391C6.12475 5.01394 6.12479 5.01396 6.12482 5.01399L8.94131 7.07219Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
</svg>
  )
}

export default Inboxrefresh