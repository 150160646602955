import React from 'react'

function UserIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4492 14.525C11.4117 14.525 11.3867 14.525 11.3492 14.525C11.2867 14.5125 11.1992 14.5125 11.1242 14.525C7.49922 14.4125 4.76172 11.5625 4.76172 8.05C4.76172 4.475 7.67422 1.5625 11.2492 1.5625C14.8242 1.5625 17.7367 4.475 17.7367 8.05C17.7242 11.5625 14.9742 14.4125 11.4867 14.525C11.4742 14.525 11.4617 14.525 11.4492 14.525ZM11.2492 3.4375C8.71172 3.4375 6.63672 5.5125 6.63672 8.05C6.63672 10.55 8.58672 12.5625 11.0742 12.65C11.1492 12.6375 11.3117 12.6375 11.4742 12.65C13.9242 12.5375 15.8492 10.525 15.8617 8.05C15.8617 5.5125 13.7867 3.4375 11.2492 3.4375Z" fill="#071942"/>
<path d="M20.674 14.6875C20.6365 14.6875 20.599 14.6875 20.5615 14.675C20.049 14.725 19.524 14.3625 19.474 13.85C19.424 13.3375 19.7365 12.875 20.249 12.8125C20.399 12.8 20.5615 12.8 20.699 12.8C22.524 12.7 23.949 11.2 23.949 9.3625C23.949 7.4625 22.4115 5.925 20.5115 5.925C19.999 5.9375 19.574 5.5125 19.574 5C19.574 4.4875 19.999 4.0625 20.5115 4.0625C23.4365 4.0625 25.824 6.45 25.824 9.375C25.824 12.25 23.574 14.575 20.7115 14.6875C20.699 14.6875 20.6865 14.6875 20.674 14.6875Z" fill="#071942"/>
<path d="M11.4625 28.1875C9.0125 28.1875 6.55 27.5625 4.6875 26.3125C2.95 25.1625 2 23.5875 2 21.875C2 20.1625 2.95 18.575 4.6875 17.4125C8.4375 14.925 14.5125 14.925 18.2375 17.4125C19.9625 18.5625 20.925 20.1375 20.925 21.85C20.925 23.5625 19.975 25.15 18.2375 26.3125C16.3625 27.5625 13.9125 28.1875 11.4625 28.1875ZM5.725 18.9875C4.525 19.7875 3.875 20.8125 3.875 21.8875C3.875 22.95 4.5375 23.975 5.725 24.7625C8.8375 26.85 14.0875 26.85 17.2 24.7625C18.4 23.9625 19.05 22.9375 19.05 21.8625C19.05 20.8 18.3875 19.775 17.2 18.9875C14.0875 16.9125 8.8375 16.9125 5.725 18.9875Z" fill="#071942"/>
<path d="M22.9247 25.9395C22.4872 25.9395 22.0997 25.6395 22.0122 25.1895C21.9122 24.677 22.2372 24.1895 22.7372 24.077C23.5247 23.9145 24.2497 23.602 24.8122 23.1645C25.5247 22.627 25.9122 21.952 25.9122 21.2395C25.9122 20.527 25.5247 19.852 24.8247 19.327C24.2747 18.902 23.5872 18.602 22.7747 18.4145C22.2747 18.302 21.9497 17.802 22.0622 17.2895C22.1747 16.7895 22.6747 16.4645 23.1872 16.577C24.2622 16.8145 25.1997 17.2395 25.9622 17.827C27.1247 18.702 27.7872 19.9395 27.7872 21.2395C27.7872 22.5395 27.1122 23.777 25.9497 24.6645C25.1747 25.2645 24.1997 25.702 23.1247 25.9145C23.0497 25.9395 22.9872 25.9395 22.9247 25.9395Z" fill="#071942"/>
</svg>

  )
}

export default UserIcon