import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAllBuHavingPgUnderUser,
  GetAllPgInBuForUser,
} from "../actions/identity/organizationActions";
import { GetSubDatasourceByBu } from "../actions/dataIntegration/subdatasourcActions";
import {
  CreateDocumentView,
  GenerateAnswer,
  GetDocument,
  PredictDocument,
  ToggleDocumentVote,
} from "../actions/predictionService/knowledgebaseActions";
import { UpdateQuickView } from "../actions/predictionService/quickViewActions";
import {
  GetInteractionById,
  GetInteractionDetails,
  GetInteractionStatusList,
  GetInteractions,
  GetNewInteractions,
  GetSentiment,
  UpdateAgent,
} from "../actions/interactions&sentiments/interactionActions";
import { GetAgents } from "../actions/identity/permissionActions";
import { GetRoomByInteraction } from "../actions/interactions&sentiments/roomActions";
import { CreateTicket } from "../actions/dataIntegration/ticketActions";

export const getAllBuHavingPgUnderUser = createAsyncThunk(
  "getAllBuHavingPgUnderUser",
  async (thunkAPI) => {
    const response = await GetAllBuHavingPgUnderUser();
    return response;
  }
);

export const getAllPgInBuForUser = createAsyncThunk(
  "getAllPgInBuForUser",
  async (business_unit_uuid, thunkAPI) => {
    const response = await GetAllPgInBuForUser([business_unit_uuid]);
    return { response, business_unit_uuid };
  }
);

export const getSubDatasourceByBu = createAsyncThunk(
  "GetSubDatasourceByBu",
  async (business_unit_uuid, thunkAPI) => {
    const response = await GetSubDatasourceByBu(business_unit_uuid);
    return { response, business_unit_uuid };
  }
);

export const generateAnswer = createAsyncThunk(
  "generateAnswer",
  async (data, thunkAPI) => {
    const response = await GenerateAnswer(
      data.business_unit_uuid,
      data.product_group,
      data.query,
      data.sub_datasource_uuids,
      data.api_trigger,
      data.guide_filter
    );
    return { response, business_unit_uuid: data.business_unit_uuid };
  }
);

// Define predictDocument thunk
export const predictDocument = createAsyncThunk(
  "predictDocument",
  async (data, thunkAPI) => {
    const response = await PredictDocument(
      data.business_unit_uuid,
      data.product_group,
      data.query,
      data.sub_datasource_uuids,
      data.api_trigger,
      data.guide_filter
    );

    return { response, business_unit_uuid: data.business_unit_uuid };
  }
);

export const getDocument = createAsyncThunk(
  "getDocument",
  async (data, thunkAPI) => {
    await CreateDocumentView(
      data.business_unit_uuid,
      data.sub_datasource_uuid,
      data.document_id,
      data.query,
      data.product_group
    );
    const response = await GetDocument(
      data.business_unit_uuid,
      data.query,
      data.sub_datasource_uuid,
      data.document_id
    );
    return {
      response,
      document_id: data.document_id,
      business_unit_uuid: data.business_unit_uuid,
    };
  }
);

export const toggleDocumentVote = createAsyncThunk(
  "toggleDocumentVote",
  async (data, thunkAPI) => {
    const response = await ToggleDocumentVote(
      data.business_unit_uuid,
      data.sub_datasource_uuid,
      data.document_id,
      data.query,
      data.product_group,
      data.vote_direction
    );

    return response;
  }
);

export const updateQuickView = createAsyncThunk(
  "updateQuickView",
  async (data, thunkAPI) => {
    await UpdateQuickView(
      data.business_unit_uuid,
      data.sub_datasource_uuid,
      data.quick_view_fields,
      data.custom_fields
    );
    const response = await GetDocument(
      data.business_unit_uuid,
      data.query,
      data.sub_datasource_uuid,
      data.document_id
    );
    return {
      response,
      document_id: data.document_id,
      business_unit_uuid: data.business_unit_uuid,
    };
  }
);

export const getInteractionStatusList = createAsyncThunk(
  "getInteractionStatusList",
  async (thunkAPI) => {
    const response = await GetInteractionStatusList();
    return response;
  }
);

export const getInteractions = createAsyncThunk(
  "getInteractions",
  async (data, thunkAPI) => {
    const response = await GetInteractions(data.status_list, data.search,data.from_date,data.to_date);
    return response;
  }
);

export const getAgents = createAsyncThunk(
  "getAgents",
  async (data, thunkAPI) => {
    const response = await GetAgents();
    return response;
  }
);

export const getInteractionDetails = createAsyncThunk(
  "getInteractionDetails",
  async (interaction_id, thunkAPI) => {
    const response = await GetInteractionDetails(interaction_id);
    return response;
  }
);
export const getNewInteractions = createAsyncThunk(
  "getNewInteractions",
  async (thunkAPI) => {
    const response = await GetNewInteractions();
    return response;
  }
);
export const getRoomByInteraction = createAsyncThunk(
  "getRoomByInteraction",
  async (interaction_id, thunkAPI) => {
    const response = await GetRoomByInteraction(interaction_id);
    return response;
  }
);
export const updateAgent = createAsyncThunk(
  "updateAgent",
  async (data, thunkAPI) => {
    await UpdateAgent(
      data.interaction_id,
      data.agent_uuid,
      data.assigned_by,
      data.flag,
      data.interaction_type_id,
      data.status,
      data.updated_by
    );

    const response = await GetNewInteractions();
    return response;
  }
);

export const createTicket = createAsyncThunk(
  "createTicket",
  async (data, thunkAPI) => {
    const ticketResponse = await CreateTicket(
      data.body,
      data.interaction_uuid,
      data.title,
      data.priority,
      data.updated_by
    );
    const response = await GetInteractionById(data.interaction_uuid);
    return { response, ticketResponse };
  }
);

export const getInteractionById = createAsyncThunk(
  "getInteractionById",
  async (interaction_id, thunkAPI) => {
    const response = await GetInteractionById(interaction_id);

    return response;
  }
);
export const getSentiment = createAsyncThunk(
  "getSentiment",
  async (interaction_id, thunkAPI) => {
    const response = await GetSentiment(interaction_id);

    return response;
  }
);

const inboxSlice = createSlice({
  name: "inboxSlice",
  initialState: {
    business_units: [],
    allTabs: [{ name: "home", uuid: "home28082001" }],
    selectedTab: "home28082001",
    detail: { api_trigger: null, guide_filter: {} },
    interaction_status_list: [],
    interactionFilter: [],
    selectAllInteraction: false,
    interactions: [],
    agents: [],
    chats: [],
    rooms: [],
    new_interactions_details: null,
    ticket_details: null,
    isListLoading: true,
  },
  reducers: {
    updateTabs: (state, action) => {
      state.allTabs = action.payload;
    },
    editTab: (state, action) => {
      state.allTabs = state.allTabs.map((tab) => {
        if (tab.uuid === action.payload.tab)
          return { ...tab, ...action.payload.data };
        else return tab;
      });
    },
    updateChats: (state, action) => {
      state.allTabs = state.allTabs.map((tab) => {
        if (tab.uuid === state.selectedTab)
          return { ...tab, chats: [...tab.chats, action.payload] };
        else return tab;
      });
    },
    addRoom: (state, action) => {
      if (!state.rooms.includes(action.payload))
        state.rooms = [...state.rooms, action.payload];
    },
    removeRoom: (state, action) => {
      if (state.rooms.includes(action.payload))
        state.rooms = state.rooms.filter((room) => room !== action.payload);
    },

    updateTableDetails: (state, action) => {
      state.new_interactions_details = {
        ...state.new_interactions_details,
        data: action.payload,
      };
    },
    updateSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    updateIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    updatePredictedDocuments: (state, action) => {
      state.predicted_documents = action.payload;
    },
    updateDetailedDocument: (state, action) => {
      state.detailed_document = action.payload;
    },
    updateDetail: (state, action) => {
      state.detail = action.payload;
    },
    updateInteractionFilter: (state, action) => {
      
      if (state.interactionFilter.includes(action.payload)) {
        state.interactionFilter = state.interactionFilter.filter(
          (item) => item !== action.payload
        );
        if (state.selectAllInteraction) state.selectAllInteraction = false;
      } else {
        state.interactionFilter = [...state.interactionFilter, action.payload];
        if (
          state.interactionFilter.length ===
          state.interaction_status_list.length
        )
          state.selectAllInteraction = true;
      }
    },
    toggleSelectAllInteractionFilter: (state) => {
      if (state.selectAllInteraction) {
        state.interactionFilter = [];
        state.selectAllInteraction = false;
      } else {
        state.interactionFilter = state.interaction_status_list.map(
          (item) => item.value
        );
        state.selectAllInteraction = true;
      }
    },
    updateSelectAllInteraction:(state,action)=>{
      state.selectAllInteraction=action.payload
    },
    resetTicketDetails:(state)=>{
      state.ticket_details=null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBuHavingPgUnderUser.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.business_units = action.payload.data;
      })
      .addCase(toggleDocumentVote.fulfilled, (state, action) => {
        const { document_id, business_unit_uuid } = action.meta.arg;

        if (action.payload.status === 200) {
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.document_id === document_id) {
              return {
                ...tab,
                detailed_document: {
                  ...tab.detailed_document,
                  user_vote_direction:
                    action.payload.data.data.user_vote_direction,

                  up_vote_count: action.payload.data.data.up_vote_count,

                  down_vote_count: action.payload.data.data.down_vote_count,
                  expert_up_vote_count:
                    action.payload.data.data.expert_up_vote_count,
                  super_expert_up_vote_count:
                    action.payload.data.data.super_expert_up_vote_count,
                },
              };
            } else if (tab.uuid === business_unit_uuid) {
              return {
                ...tab,
                predicted_documents: tab.predicted_documents.map((pd) => {
                  if (pd._id === document_id) {
                    return {
                      ...pd,
                      user_vote_direction:
                        action.payload.data.data.user_vote_direction,

                      up_vote_count: action.payload.data.data.up_vote_count,

                      down_vote_count: action.payload.data.data.down_vote_count,
                      expert_up_vote_count:
                        action.payload.data.data.expert_up_vote_count,
                      super_expert_up_vote_count:
                        action.payload.data.data.super_expert_up_vote_count,
                    };
                  } else return pd;
                }),
              };
            } else return tab;
          });
        }
      })
      .addCase(getSubDatasourceByBu.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.allTabs = state.allTabs.map((tab) => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const data_source = urlParams.get("datasource");

            const url = window.location.pathname;
            const containsResolutionPredictionV2 = url.includes(
              "resolution-prediction-v2"
            );
            const selectedDS = containsResolutionPredictionV2
              ? [
                  action.payload.response.data.data.find(
                    (item) =>
                      item.name?.toLowerCase() === data_source?.toLowerCase()
                  ),
                ].map((item) => ({
                  ...item,
                  id: item.uuid,
                  label: item.name,
                }))
              : action.payload.response.data.data
                  .filter((item) =>
                    action.payload.response.data.default_selections.includes(
                      item.uuid
                    )
                  )
                  .map((item) => ({
                    ...item,
                    id: item.uuid,
                    label: item.name,
                  }));
            if (tab.uuid === action.payload.business_unit_uuid) {
              return {
                ...tab,
                datasources: action.payload.response.data.data,
                selected_ds: selectedDS,
              };
            } else return tab;
          });
        }
      })
      .addCase(getAllPgInBuForUser.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);

          const product_group = urlParams.get("product_group");
          const url = window.location.pathname;
          const containsResolutionPredictionV2 = url.includes(
            "resolution-prediction-v2"
          );
          const productGroup = action.payload.response.data.find(
            (item) => item.name?.toLowerCase() === product_group?.toLowerCase()
          );
          const selectedProductGroup = containsResolutionPredictionV2
            ? {
                ...productGroup,
                id: productGroup.uuid,
                label: productGroup.name,
              }
            : {
                ...action.payload.response.data[0],
                id: action.payload.response.data[0].uuid,
                label: action.payload.response.data[0].name,
              };
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.uuid === action.payload.business_unit_uuid) {
              return {
                ...tab,
                product_groups: action.payload.response.data,
                selected_pg: selectedProductGroup,
              };
            } else return tab;
          });
        }
      })
      .addCase(predictDocument.pending, (state, action) => {
        const { business_unit_uuid } = action.meta.arg;
        state.allTabs = state.allTabs.map((tab) => {
          if (tab.uuid === business_unit_uuid) {
            return {
              ...tab,
              isPredicting: true,
            };
          } else return tab;
        });
      })

      .addCase(predictDocument.fulfilled, (state, action) => {
        if (action.payload.response.status === 200)
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.uuid === action.payload.business_unit_uuid) {
              return {
                ...tab,
                predicted_documents: action.payload.response.data.data,
                guides: action.payload.response.data.guide_filter,
                call_onfly: action.payload.response.data.data.length > 0,
                isPredicting: false,
              };
            } else return tab;
          });
      })
      .addCase(generateAnswer.pending, (state, action) => {
        const { business_unit_uuid } = action.meta.arg;
        state.allTabs = state.allTabs.map((tab) => {
          if (tab.uuid === business_unit_uuid) {
            return {
              ...tab,
              onfly: null,
              isGenerating: true,
              call_onfly: false,
            };
          } else return tab;
        });
      })
      .addCase(generateAnswer.fulfilled, (state, action) => {
        const { query } = action.meta.arg;

        if (action.payload.response.status === 200)
          state.allTabs = state.allTabs.map((tab) => {
            if (
              tab.uuid === action.payload.business_unit_uuid &&
              tab.req_query === query
            ) {
              return {
                ...tab,
                onfly: action.payload.response.data.data,
                isGenerating: false,
              };
            } else return tab;
          });
      })

      .addCase(getDocument.fulfilled, (state, action) => {
        if (action.payload.response.status === 200)
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.document_id === action.payload.document_id) {
              return {
                ...tab,
                detailed_document: action.payload.response.data.data,
              };
            }
            if (tab.uuid === action.payload.business_unit_uuid) {
              return {
                ...tab,
                predicted_documents: tab.predicted_documents.map((pd) => {
                  if (pd._id === action.payload.document_id) {
                    return {
                      ...pd,
                      view_data: {
                        ...pd.view_data,
                        count: pd.view_data.count + 1,
                      },
                    };
                  } else return pd;
                }),
              };
            } else return tab;
          });
      })
      .addCase(updateQuickView.fulfilled, (state, action) => {
        if (action.payload.response.status === 200)
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.document_id === action.payload.document_id) {
              return {
                ...tab,
                detailed_document: action.payload.response.data.data,
              };
            }
            if (tab.uuid === action.payload.business_unit_uuid) {
              return {
                ...tab,
                predicted_documents: tab.predicted_documents.map((pd) => {
                  if (pd._id === action.payload.document_id) {
                    return {
                      ...pd,
                      view_data: {
                        ...pd.view_data,
                        count: pd.view_data.count + 1,
                      },
                    };
                  } else return pd;
                }),
              };
            } else return tab;
          });
      })
      .addCase(getInteractionStatusList.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.interaction_status_list = action.payload.data;
          state.interactionFilter = action.payload.data
            .filter((item) =>
              ["in_progress", "agent_assigned"].includes(item.value)
            )
            .map((item) => item.value);
        }
      })
      .addCase(getInteractions.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.interactions = action.payload.data.data;
        }
        state.isListLoading = false;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.agents = action.payload.data;
        }
      })
      .addCase(getInteractionDetails.fulfilled, (state, action) => {
        const interaction_id = action.meta.arg;

        if (action.payload.status === 200) {
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.uuid === interaction_id)
              return {
                ...tab,
                chats: action.payload.data.data,
              };
            else return tab;
          });
        }
      })
      .addCase(getNewInteractions.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.new_interactions_details = action.payload.data;
        }
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.new_interactions_details = action.payload.data;
        }
      })
      .addCase(getRoomByInteraction.fulfilled, (state, action) => {
        const interaction_id = action.meta.arg;
        if (action.payload.status === 200) {
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.uuid === interaction_id)
              return {
                ...tab,
                room_details: action.payload.data,
              };
            else return tab;
          });
        }
      })
      .addCase(getInteractionById.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.uuid === action.payload.data.uuid)
              return { ...tab, ...action.payload.data };
            else return tab;
          });
        }
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.uuid === action.payload.response.data.uuid)
              return { ...tab, ...action.payload.response.data };
            else return tab;
          });
        }
        
          state.ticket_details = action.payload.ticketResponse.data;
        
      })
      .addCase(getSentiment.fulfilled, (state, action) => {
        const interaction_id = action.meta.arg;
        if (action.payload.status === 200) {
          state.allTabs = state.allTabs.map((tab) => {
            if (tab.uuid === interaction_id)
              return {
                ...tab,
                sentiment_label: action.payload.data.sentiment,
              };
            else return tab;
          });
        }
      });
  },
});

export const {
  updateTabs,
  updateSelectedTab,
  updatePredictedDocuments,
  updateDetailedDocument,
  updateDetail,
  updateInteractionFilter,
  toggleSelectAllInteractionFilter,
  updateChats,
  editTab,
  addRoom,
  removeRoom,
  updateIsListLoading,
  updateTableDetails,
  updateSelectAllInteraction,
  resetTicketDetails
} = inboxSlice.actions;

export default inboxSlice.reducer;
