import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  Grid,
  IconButton,

  Snackbar,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AddCircle, AddCircleOutline, ArrowDropDownRounded } from "@mui/icons-material";
import ArrowLeftIcon from "../../../assets/ArrowLeftIcon";
import ArrowRightIcon from "../../../assets/ArrowRightIcon";
import ImageIcon from "../../../assets/ImageIcon";
import ResizableTable from "../../../components/dataTable/resizableTable";
import CustomStepper from "../../../components/stepper/customStepper";
import { resetAccordion } from "../../../redux/slices/accordionSlice";
import { getbusinessunitsbyUser } from "../../../redux/slices/businessunitsSlice";
import { updateChannelMappingPagination } from "../../../redux/slices/dataTableSlice";
import { getAllDataSource } from "../../../redux/slices/datasourceSlice";
import {
  resetStatus,
  updateSelectedDsValue,
} from "../../../redux/slices/mapping/mappingStep1Slice";
import {
  createChannelMappingsForAibot,
  getChannelByName,
  getChannelMappingTable,
  listMappingsForAibot,
  updateChannelMappingStatus,
  updateIsChannelMappingCreated,
  updateSelectedBu,
  updateSelectedDs,
  updateSelectedMapping,
  updateSnackMessage,
} from "../../../redux/slices/supportChannels/supportChannelsSlice";

function MappingSelection() {
  const steps = ["Basic Details", "Mapping Selection", "Summary"];
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  //  State Value
  const [customer_uuid] = useState(localStorage.getItem("ci"));

  const [expanded, setExpanded] = useState({
    bu: true,
    ds: false,
    mappings: false,
  });

  // Redux

  const { bu_details } = useSelector((state) => state.businessunitsReducer);
  const { ds_details } = useSelector((state) => state.datasourceReducer);

  const { selected_bu, selected_ds, selected_mappings, aibot_mappings ,is_channel_mapping_created,snack_message} =
    useSelector((state) => state.supportChannelReducer);
    const [openSnack, setOpenSnack] = useState(false);
    const handleSnackBarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };
    const action = (
      <Button color="secondary" size="small" onClick={handleSnackBarClose}>
        Okay
      </Button>
    );
  
  // Functions

  const handleClose = () => {
    dispatch(resetAccordion());
    navigate(`/${customer_uuid}/support-channel/aibots`);
  };
  const handlePrevStep = () => {
    navigate(
      `/${customer_uuid}/support-channel/aibots/${params.bot_uuid}/step-1`
    );
  };
  const handleNextStep = () => {
let data={
  bot_uuid:params.bot_uuid,
  mapping_uuids:selected_mappings
}
dispatch(createChannelMappingsForAibot(data))
    
  };

  useEffect(() => {
    dispatch(getChannelByName("AI Bot"));
    dispatch(getbusinessunitsbyUser());
    dispatch(getAllDataSource([localStorage.getItem("mi")]));
  }, []);

  useEffect(()=>{
    if(is_channel_mapping_created){
      dispatch(updateIsChannelMappingCreated(false))
    navigate(
      `/${customer_uuid}/support-channel/aibots/${params.bot_uuid}/step-3`
    );
  }
  },[is_channel_mapping_created])

  useEffect(() => {
    if (selected_bu.length > 0 && selected_ds.length > 0) {
      let data = {
        business_unit_uuids: selected_bu,
        sub_datasource_uuids: selected_ds,
      };
      dispatch(listMappingsForAibot(data));
    }
    if(selected_bu.length>0){
      setExpanded({...expanded,ds:true})
    }
    if(selected_bu.length>0&&selected_ds.length>0)
      setExpanded({...expanded,mappings:true})

    
  }, [selected_bu, selected_ds]);

  useEffect(()=>{
    if(snack_message){
      setOpenSnack(true)
      const timer = setTimeout(() => {
        setOpenSnack(false)

        dispatch(updateSnackMessage(null));
      }, 3500);
      // Cleanup function to clear the timer if snack_message changes or component unmounts
      return () => clearTimeout(timer);
    }
  },[snack_message])

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        zIndex: 1300,
        position: "absolute",
        top: 0,
        left: 0,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {/* Sidebar */}
      <Grid
        width="232px"
        sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
      ></Grid>

      {/* Main Content */}
      <Grid
        width="calc(100% - 232px)"
        backgroundColor="white"
        position="relative"
        sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
      >
        <Grid display="flex" variant="stepperheaderFixedtop" marginRight="40px">
          <Grid width="100%">
            <Typography
              margin="40px 0px 0px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Create AI Bot
            </Typography>

            <Typography
              margin="8px 0px 20px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontSize: "14px", lineHeight: "17px" }}
            >
              Create a AI Bot that satisfies your requirements.
            </Typography>
          </Grid>
          <Typography marginTop="20px">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
        </Grid>

        <Grid variant="stepperFixedtop">
          <Divider variant="middle" />
          <CustomStepper steps={steps} activeStep={1} />
        </Grid>

        <Grid variant="stepperScroller">
          <Grid>
            <Grid margin="0px 46px 0px 46px">
              <Accordion
                square
                disableGutters
                expanded={expanded.bu}
                onChange={() => setExpanded({ ...expanded, bu: !expanded.bu })}
                sx={{
                  margin: "0px",
                  boxShadow: "none",
                  "&.MuiAccordion-root:before, &.MuiAccordion-root:after": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    color: colors.ascendo.darkblue,
                    padding: "0px",
                    alignItems: "center",
                    "& .Mui-expanded": {
                      color: colors.ascendo.blue,
                    },
                  }}
                  expandIcon={
                    <ArrowDropDownRounded
                      fontSize="large"
                      sx={
                        {
                          // ? colors.ascendo.blue
                          // : colors.ascendo.darkblue,
                        }
                      }
                    />
                  }
                >
                  <Grid width="100%" display="flex">
                    <Typography lineHeight="19px" fontWeight="500">
                      Select Business Units
                    </Typography>
                    {selected_bu.length > 0 &&
                      !expanded.bu &&
                      selected_bu.map((bu) => (
                        <Typography
                          color={colors.ascendo.blue}
                          lineHeight="19px"
                          fontWeight="500"
                          marginLeft="12px"
                        >
                          {bu_details.find((item) => item.uuid === bu).name}
                        </Typography>
                      ))}
                  </Grid>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: "0px" }}>
                  <Grid>
                    <Divider />
                    <Grid
                      marginTop="0px"
                      marginBottom="20px"
                      container
                      spacing={3}
                    >
                      {bu_details?.map((bu) => (
                        <Grid item md={3}>
                          <Button
                            disableRipple
                            fullWidth
                            variant="outlined"
                            sx={{
                              height: "72px",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                              color: colors.ascendo.darkblue,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingLeft: "16px",
                              paddingRight: "16px",
                              background: selected_bu.includes(bu.uuid)
                                ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                : "transparent",
                              "&:hover": {
                                background: selected_bu.includes(bu.uuid)
                                  ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                  : "transparent",
                                borderColor: selected_bu.includes(bu.uuid)
                                  ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                  : "rgba(26, 115, 232, 0.5)",
                                boxShadow:
                                  "0px 4px 32px rgba(26, 115, 232, 0.12)",
                              },
                              borderColor: selected_bu.includes(bu.uuid)
                                ? colors.ascendo.blue
                                : colors.grey.normal,
                            }}
                            endIcon={
                              <Checkbox
                                size="small"
                                checked={selected_bu.includes(bu.uuid)}
                              />
                            }
                            onClick={() => dispatch(updateSelectedBu(bu.uuid))}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {bu?.logo_uri?.length > 0 ? (
                                <img
                                  src={bu?.logo_uri}
                                  height={24}
                                  width={24}
                                ></img>
                              ) : (
                                <ImageIcon />
                              )}
                              <Tooltip title={bu.name}>
                                <div
                                  style={{
                                    marginLeft: "12px",
                                    whiteSpace: "nowrap",
                                    width: "155px",
                                    textAlign: "left",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {bu.name}
                                </div>
                              </Tooltip>
                            </div>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Divider variant="middle" />
            <Grid margin="0px  46px 0px 46px">
              <Accordion
                square
                disableGutters
                expanded={expanded.ds}
                onChange={() => setExpanded({ ...expanded, ds: !expanded.ds })}
                sx={{
                  margin: "0px",
                  boxShadow: "none",
                  "&.MuiAccordion-root:before, &.MuiAccordion-root:after": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    color: colors.ascendo.darkblue,
                    padding: "0px",
                    alignItems: "center",
                    "& .Mui-expanded": {
                      color: colors.ascendo.blue,
                    },
                  }}
                  expandIcon={
                    <ArrowDropDownRounded
                      fontSize="large"
                      sx={
                        {
                          // ? colors.ascendo.blue
                          // : colors.ascendo.darkblue,
                        }
                      }
                    />
                  }
                >
                  <Grid width="100%" display="flex">
                    <Typography lineHeight="19px" fontWeight="500">
                      Select Data Sources
                    </Typography>
                    {selected_ds &&
                      !expanded.ds &&
                      selected_ds.map((item) => (
                        <Typography
                          color={colors.ascendo.blue}
                          lineHeight="19px"
                          fontWeight="500"
                          marginLeft="12px"
                        >
                          {ds_details.find((ds) => ds.uuid === item).name}
                        </Typography>
                      ))}
                  </Grid>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: "0px" }}>
                  <Grid>
                    <Divider />
                    <Grid
                      marginTop="0px"
                      marginBottom="20px"
                      container
                      spacing={3}
                    >
                      {ds_details?.map((ds) => (
                        <Grid item md={3}>
                          <Button
                            disableRipple
                            fullWidth
                            variant="outlined"
                            sx={{
                              height: "72px",
                              fontSize: "14px",
                              fontWeight: "400",
                              textTransform: "none",
                              color: colors.ascendo.darkblue,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingLeft: "16px",
                              paddingRight: "16px",
                              background: selected_ds.includes(ds.uuid)
                                ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                : "transparent",
                              "&:hover": {
                                background: selected_ds.includes(ds.uuid)
                                  ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                  : "transparent",
                                borderColor: selected_ds.includes(ds.uuid)
                                  ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                  : "rgba(26, 115, 232, 0.5)",
                                boxShadow:
                                  "0px 4px 32px rgba(26, 115, 232, 0.12)",
                              },
                              borderColor: selected_ds.includes(ds.uuid)
                                ? colors.ascendo.blue
                                : colors.grey.normal,
                            }}
                            endIcon={
                              <Checkbox
                                size="small"
                                checked={selected_ds.includes(ds.uuid)}
                              />
                            }
                            onClick={() => dispatch(updateSelectedDs(ds.uuid))}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {ds?.logo_uri?.length > 0 ? (
                                <img
                                  src={ds?.logo_uri}
                                  height={24}
                                  width={24}
                                ></img>
                              ) : (
                                <ImageIcon />
                              )}
                              <Tooltip title={ds.name}>
                                <div
                                  style={{
                                    marginLeft: "12px",
                                    whiteSpace: "nowrap",
                                    width: "155px",
                                    textAlign: "left",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {ds.name}
                                </div>
                              </Tooltip>
                            </div>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Divider variant="middle" />

            <Grid margin="0px 46px 0px 46px">
              <Accordion
                square
                disableGutters
                expanded={expanded.mappings}
                onChange={() =>
                  setExpanded({ ...expanded, mappings: !expanded.mappings })
                }
                sx={{
                  margin: "0px",
                  boxShadow: "none",
                  "&.MuiAccordion-root:before, &.MuiAccordion-root:after": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    color: colors.ascendo.darkblue,
                    padding: "0px",
                    alignItems: "center",
                    "& .Mui-expanded": {
                      color: colors.ascendo.blue,
                    },
                  }}
                  expandIcon={
                    <ArrowDropDownRounded
                      fontSize="large"
                      sx={
                        {
                          // ? colors.ascendo.blue
                          // : colors.ascendo.darkblue,
                        }
                      }
                    />
                  }
                >
                  <Grid width="100%" display="flex">
                    <Typography lineHeight="19px" fontWeight="500">
                      Select Mappings
                    </Typography>
                    {selected_mappings?.length > 0 &&
                      !expanded.mappings &&
                      selected_mappings?.map((mappings) => (
                        <Typography
                          color={colors.ascendo.blue}
                          lineHeight="19px"
                          fontWeight="500"
                          marginLeft="12px"
                        >
                          {
                            aibot_mappings?.find(
                              (item) => item.uuid === mappings
                            ).name
                          }
                        </Typography>
                      ))}
                  </Grid>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: "0px" }}>
                  <Grid >
                    {aibot_mappings && (
                      <Box
                        marginTop="0px"
                        marginBottom="20px"
                        component="div"
                        height={aibot_mappings.length > 0 ? "258px" : "140px"}
                        sx={{
                          border: "1px solid",
                          borderRadius: "6px",
                          borderColor: "rgba(0, 0, 0, 0.1)",
                          overflow: "auto"
                        }}
                      >
                        <Grid padding="26px 0px 0px 26px">
                          <Typography
                            marginBottom="20px"
                            fontSize="14px"
                            lineHeight="17px"
                            color={colors.ascendo.darkblue}
                            display="flex"
                            alignItems="center"
                          >
                            There {aibot_mappings?.length > 1 ? "are" : "is"}{" "}
                            <Typography
                              marginX="4px"
                              fontSize="14px"
                              color={colors.ascendo.blue}
                            >
                              {" "}
                              {aibot_mappings?.length}
                              {aibot_mappings?.length > 1
                                ? " Mappings"
                                : " Mapping"}{" "}
                            </Typography>
                            for the selected combination
                          </Typography>
                          <Divider
                            sx={{ marginLeft: "-10px" }}
                            variant="middle"
                          />
                          <Grid
                             marginTop="0px"
                             marginBottom="20px"
                             container
                             spacing={3}
                          >
                            {aibot_mappings?.map((mappings) => (
                              <Grid item md={3} key={mappings.uuid}>
                              
                                  <Box
                                    width="100%"
                                    component="div"
                                    height="72px"
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingX="20px"
                                    onClick={() =>
                                      dispatch(
                                        updateSelectedMapping(mappings.uuid)
                                      )
                                    }
                                    sx={{
                                      border: "1px solid",
                                      borderRadius: "4px",
                                      background: selected_mappings.includes(
                                        mappings.uuid
                                      )
                                        ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                        : "transparent",
                                      "&:hover": {
                                        background: selected_mappings.includes(
                                          mappings.uuid
                                        )
                                          ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                          : "transparent",
                                        borderColor: selected_mappings.includes(
                                          mappings.uuid
                                        )
                                        ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                                        : "rgba(26, 115, 232, 0.5)",

                                        boxShadow:
                                          "0px 4px 32px rgba(26, 115, 232, 0.12)",
                                      },
                                      borderColor: selected_mappings.includes(
                                        mappings.uuid
                                      )
                                      ? colors.ascendo.blue
                                : "rgba(26, 115, 232, 0.5)",
                                     
                                    }}
                                  >
                                    <Typography
                                      color={colors.ascendo.darkblue}
                                      sx={{
                                        fontWeight: "500",
                                        lineHeight: "19px",
                                      }}
                                    >
                                      {mappings.name}
                                    </Typography>

                                    <Checkbox
                                      sx={{
                                        height: "16px",
                                        width: "16px",
                                      }}
                                      size="small"
                                      checked={selected_mappings.includes(
                                        mappings.uuid
                                      )}
                                    />
                                  </Box>
                              </Grid>
                            ))}
                          </Grid>
                          {aibot_mappings.length > 0 && (
                            <Divider
                              variant="middle"
                              sx={{ marginTop: "20px", marginLeft: "-10px" }}
                            />
                          )}
                        </Grid>
                        <Grid
                          marginTop={aibot_mappings.length > 0 && "20px"}
                          paddingLeft="6px"
                          width="220px"
                        >
                          <Link
                            to={`/${customer_uuid}/mapping/select-datasource`}
                            target="_blank"
                          >
                            <Button
                              fullWidth
                              sx={{
                                backgroundColor: "none",
                                color: colors.ascendo.darkblue,
                                textTransform: "none",
                                margin: "0px",
                              }}
                              startIcon={<AddCircleOutline />}
                            >
                              Create New Mapping
                            </Button>
                          </Link>
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Divider variant="middle" />
          </Grid>
        </Grid>

        <Divider variant="middle" />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={3500}
          message={snack_message}
          action={action}
          onClose={handleSnackBarClose}
        />

        {/* Continue Button */}
        <Grid
          variant="stepperFixedbottombtn"
          display="flex"
          justifyContent="flex-end"
          marginBottom="32px"
          marginRight="40px"
          gap={2}
        >
          <Grid item width="120px" margin="26px 0px 0px 0px">
            <Button
              fullWidth
              sx={{
                color: colors.ascendo.darkblue,
                textTransform: "none",
              }}
              startIcon={<ArrowLeftIcon />}
              onClick={handlePrevStep}
            >
              Back
            </Button>
          </Grid>

          <Grid item width="120px" margin="26px 0px 0px 0px">
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.ascendo.yellow,
                color: colors.ascendo.darkblue,
                textTransform: "none",
              }}
              endIcon={<ArrowRightIcon opacity={selected_mappings.length>0?1:0.1} />}
              onClick={handleNextStep}
              disabled={!(selected_mappings.length>0)}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MappingSelection;
