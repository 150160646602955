import React from "react";

function DangerIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.918 7.5V11.6667"
        stroke="#E25757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.918 17.8414H5.55424C2.48289 17.8414 1.19948 15.7747 2.68647 13.2497L5.44802 8.56641L8.05026 4.16641C9.62576 1.49141 12.2103 1.49141 13.7858 4.16641L16.388 8.57474L19.1496 13.2581C20.6366 15.7831 19.3443 17.8497 16.2818 17.8497H10.918V17.8414Z"
        stroke="#E25757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9131 14.166H10.921"
        stroke="#E25757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DangerIcon;
