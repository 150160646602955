import axios from "../../../utils/axios";

export const CreateTicket = async (body,interaction_uuid,title,priority,updated_by) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `ticket/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data:{
        body,
        interaction_uuid,
        title,
        priority,
        updated_by
      }
     
    
    }
  );
  return { data: response.data, status: response.status };
};