import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Lottie from "lottie-react";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import loadingAnimation from "../../../assets/animations/loading-animation.json";
import ArrowLeftIcon from "../../../assets/ArrowLeftIcon";
import ArrowRightIcon from "../../../assets/ArrowRightIcon";
import DatePickerIcon from "../../../assets/DatePickerIcon";
import DowloadIcon from "../../../assets/DowloadIcon";
import CompletedIcon from "../../../assets/tableIcons/CompletedIcon";
import DangerIcon from "../../../assets/tableIcons/DangerIcon";
import FailedIcon from "../../../assets/tableIcons/FailedIcon";
import CustomMultiBox from "../../../components/autocomplete/customMultiBox";
import ResizableTable from "../../../components/dataTable/simpleTable";
import {
  getSapActivityStatus,
  getSapActivityType,
} from "../../../redux/actions/dataIntegration/sapFsmActions";
import {
  getAllModels,
  getAllPgInBuForUserMapping,
  getConfluenceSpaces,
  getServicenowStatus,
  getZendeskDetails,
  updateSelectedModel,
  updateSelectedPg,
  updateSelectedServicenowStatus,
  updateSelectedSpaces,
  updateSnackMessage,
} from "../../../redux/slices/dataOptionsSlice";
import { updateAllRequestPagination } from "../../../redux/slices/dataTableSlice";
import {
  exportErrorFile,
  getFilesByMappingRequest,
  getMappingRequests,
  updateSapActivityStatus,
  updateSapActivityType,
  updateSuggestionForSubDatasource,
  updateSummaryForSubDatasource,
  zendeskRequestData,
} from "../../../redux/slices/mapping/listMappingSlice";
import {
  getFieldMapping,
  getMapping,
} from "../../../redux/slices/mapping/mappingStep2Slice";
import "./allRequest.scss";

function RequestsTable({ handleRowClick }) {
  const dispatch = useDispatch();

  const { req_mappingTableDetails } = useSelector(
    (state) => state.listMappingReducer
  );
  const { allRequest_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  const columns = useMemo(() =>
    req_mappingTableDetails?.columns
      ? req_mappingTableDetails.columns?.map((column) => {
          if (column === "status") {
            return {
              accessorKey: column,
              header: req_mappingTableDetails?.display_map[column],
              Cell: ({ cell }) => {
                const status = cell.getValue();
                let icon = null;

                switch (status) {
                  case "Completed":
                    icon = <CompletedIcon />;
                    break;
                  case "Pending due to error":
                    icon = <DangerIcon />;
                    break;
                  case "NO DATA":
                    icon = <DangerIcon />;
                    break;
                  case "Failed":
                    icon = <FailedIcon />;
                    break;
                  case "Validation":
                    icon = (
                      <Box width="60px" marginRight="-16px" marginLeft="-24px">
                        <Lottie animationData={loadingAnimation} />
                      </Box>
                    );
                    break;
                  case "Processing":
                    icon = (
                      <Box width="60px" marginRight="-16px" marginLeft="-24px">
                        <Lottie animationData={loadingAnimation} />
                      </Box>
                    );
                    break;
                  case "Learning Data":
                    icon = (
                      <Box width="60px" marginRight="-16px" marginLeft="-24px">
                        <Lottie animationData={loadingAnimation} />
                      </Box>
                    );
                    break;
                  case "Parsing Data":
                    icon = (
                      <Box width="60px" marginRight="-16px" marginLeft="-24px">
                        <Lottie animationData={loadingAnimation} />
                      </Box>
                    );
                    break;
                  case "Data Inserted, Summarization Failed":
                    icon = <DangerIcon />;
                    break;

                  default:
                    icon = <DangerIcon />;
                }

                return (
                  <Tooltip title={cell.getValue()}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {icon}
                      </div>
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {status}
                      </div>
                    </div>
                  </Tooltip>
                );
              },
            };
          }
          if (column === "gcs_uri") {
            return {
              accessorKey: column,
              header: req_mappingTableDetails?.display_map[column],
              Cell: ({ cell }) => {
                const status = cell.getValue();
                const uuid = cell.row.original.id;
                const filename = cell.row.original.gcs_uri;

                const handleExportErrorFile = () => {
                  let data = {
                    mapping_request_uuid: uuid,
                    filename: filename,
                  };
                  dispatch(exportErrorFile(data));
                };
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    {status?.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleExportErrorFile}
                      >
                        <DowloadIcon />
                      </div>
                    )}
                  </div>
                );
              },
            };
          }
          if (column === "requested_at") {
            return {
              accessorKey: column,
              header: req_mappingTableDetails?.display_map[column],
              Cell: ({ cell }) => {
                const timestamp = cell.getValue();
                const formattedDate =
                  moment(timestamp).format("MMM DD YYYY HH:mm");

                return (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formattedDate}
                  </div>
                );
              },
            };
          }

          return {
            accessorKey: column,
            header: req_mappingTableDetails?.display_map[column],
            Cell: ({ cell }) => (
              <Tooltip title={cell.getValue()}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              </Tooltip>
            ),
          };
        })
      : []
  );

  const rows = req_mappingTableDetails?.data
    ? req_mappingTableDetails.data?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        req_mappingTableDetails?.columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateAllRequestPagination(newPaginationModel));
  };

  return (
      <Grid marginTop="20px">
        <ResizableTable
          columns={columns}
          onTableRowClick={handleRowClick}
          data={rows}
          paginationDetails={allRequest_paginationDetails}
          onTablePaginationChange={handlePaginationModelChange}
          rowCount={req_mappingTableDetails?.total || 0}
        />
      </Grid>
  );
}
function AllRequests() {
  const theme = useTheme();
  const colors = theme.palette;

  const moduleDetails = JSON.parse(localStorage.getItem("md"));

  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();
  const [value, setValue] = useState(false);
  const [isGenerateSuggestion, setIsGenerateSuggestion] = useState(false);
  const [zendeskGroups, setZendeskGroups] = useState([]);
  const [tickeStatus, setTicketStatus] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [pullDate, setPullDate] = useState();
  const today = dayjs(new Date()).startOf("day");
  const [endDate, setEndDate] = useState(today.format("MM/DD/YY HH:mm:ss"));
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [openSnack, setOpenSnack] = useState(false);
  const [tableVisiblity, setTableVisibility] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState();
  const [filesPagination, setFilesPagination] = useState({
    page: 0,
    pageSize: 5,
  });

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );
  const {
    sap_activity_status,
    sap_activity_type,
    selected_sap_activity_status,
    selected_sap_activity_type,
    files_table_details,
  } = useSelector((state) => state.listMappingReducer);
  const { field_mapping_details, get_mapping } = useSelector(
    (state) => state.mappingStep2Reducer
  );

  const originalTime = get_mapping?.created_at;
  const formattedTime = moment(originalTime).format("MMM DD YYYY HH:mm");

  const {
    zendesk_groups,
    zendesk_status,
    models,
    selected_model,
    pg,
    selected_pg,
    spaces,
    selected_spaces,
    service_now_status,
    selected_service_now_status,
    snack_message,
  } = useSelector((state) => state.dataOptionsReducer);

  const { allRequest_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  const cols = useMemo(() =>
    files_table_details?.columns
      ? files_table_details?.columns
          ?.filter((col) => col !== "action")
          ?.map((column) => {
            if (column === "parsing_status") {
              return {
                accessorKey: column,
                header: files_table_details?.display_map[column],
                Cell: ({ cell }) => {
                  const status = cell.getValue();
                  let icon = null;

                  switch (status) {
                    case "Completed":
                      icon = <CompletedIcon />;
                      break;
                    case "Pending due to error":
                      icon = <DangerIcon />;
                      break;
                    case "NO DATA":
                      icon = <DangerIcon />;
                      break;
                    case "Failed":
                      icon = <FailedIcon />;
                      break;
                    case "Validation":
                      icon = (
                        <Box
                          width="60px"
                          marginRight="-16px"
                          marginLeft="-24px"
                        >
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                    case "Processing":
                      icon = (
                        <Box
                          width="60px"
                          marginRight="-16px"
                          marginLeft="-24px"
                        >
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                    case "Learning Data":
                      icon = (
                        <Box
                          width="60px"
                          marginRight="-16px"
                          marginLeft="-24px"
                        >
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                    case "Parsing Data":
                      icon = (
                        <Box
                          width="60px"
                          marginRight="-16px"
                          marginLeft="-24px"
                        >
                          <Lottie animationData={loadingAnimation} />
                        </Box>
                      );
                      break;
                    case "Data Inserted, Summarization Failed":
                      icon = <DangerIcon />;
                      break;

                    default:
                      icon = <DangerIcon />;
                  }

                  return (
                    <Tooltip title={cell.getValue()}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {icon}
                        </div>
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {status}
                        </div>
                      </div>
                    </Tooltip>
                  );
                },
              };
            }
            if (column === "created_at") {
              return {
                accessorKey: column,
                header: files_table_details?.display_map[column],
                Cell: ({ cell }) => {
                  const timestamp = cell.getValue();
                  const formattedDate =
                    moment(timestamp).format("MMM DD YYYY HH:mm");

                  return (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {cell.getValue() ? formattedDate : ""}
                    </div>
                  );
                },
              };
            }
            return {
              accessorKey: column,
              header: files_table_details?.display_map[column],
              Cell: ({ cell }) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              ),
            };
          })
      : []
  );

  const rows = files_table_details?.data
    ? files_table_details?.data?.map((dataItem) => {
        const row = { id: dataItem.uuid };

        files_table_details?.columns
          ?.filter((col) => col !== "action")
          ?.forEach((column) => {
            row[column] = dataItem[column];
          });
        return row;
      })
    : [];

  const handleRowClick = (params) => {
    if (get_mapping?.sub_datasource?.datasource?.name === "pdf") {
      setSelectedRequest(params.original.id);
      setTableVisibility(false);
    }
  };

  const handleClose = () => {
    navigate(`/${localStorage.getItem("ci")}/mapping`);
  };

  const handlePrevStep = () => {
    setTableVisibility(true);
    setSelectedRequest(null);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);

    setPullDate(newDate.format("MM/DD/YY HH:mm:ss"));
  };
  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
    setEndDate(newDate.format("MM/DD/YY HH:mm:ss"));
  };
  const handleNextStep = () => {
    if (tableVisiblity) {
      setTableVisibility(false);
    } else {
      let data = {
        zendesk: {
          product_group: selected_pg?.uuid,
          zendesk: {
            group: zendeskGroups.map((item) => item.label),
            pull_date: pullDate,
            end_date: endDate,
            status: tickeStatus.map((item) => item.label),
          },
        },
        confluence: {
          product_group: selected_pg?.uuid,
          confluence: selected_spaces,
        },
        sap: {
          sap: {
            pull_date: pullDate,
          },
        },
        servicenow: {
          product_group: selected_pg?.uuid,
          servicenow: {
            pull_date: pullDate,
            end_date: endDate,
            status: selected_service_now_status.map((i) => i.label),
          },
        },
        sap_fsm: {
          product_group: selected_pg?.uuid,
          sap_fsm: {
            pull_date: pullDate,
            activity_type: selected_sap_activity_type.map((i) => i.label),
            activity_status: selected_sap_activity_status.map((i) => i.label),
          },
        },
        pdf: {
          product_group: selected_pg?.uuid,
        },
      };

      let final_data = ["Tickets", "Help Articles", "Macros"].includes(
        get_mapping?.sub_datasource?.name
      )
        ? data.zendesk
        : get_mapping?.sub_datasource?.name === "Confluence"
        ? data.confluence
        : get_mapping?.sub_datasource?.name === "SAP"
        ? data.sap
        : get_mapping?.sub_datasource?.datasource?.name === "ServiceNow"
        ? data.servicenow
        : get_mapping?.sub_datasource?.name === "SAP Activities"
        ? data.sap_fsm
        : get_mapping?.sub_datasource?.datasource?.name === "pdf"
        ? data.pdf
        : {};

      let by_details = {
        mapping_uuid: params?.mapping_uuid,
        req_data:
          get_mapping?.sub_datasource?.name === "FAQ"
            ? { model_uuid: selected_model.uuid }
            : value
            ? { ...final_data, model_uuid: selected_model.uuid }
            : final_data,
      };
      dispatch(zendeskRequestData(by_details));
      setTableVisibility(true);
    }
  };

  const handleGroupChange = (value) => {
    setZendeskGroups(value);
  };

  const handleStatusChange = (value) => {
    setTicketStatus(value);
  };
  useEffect(() => {
    if (params?.mapping_uuid) {
      dispatch(getMapping(params?.mapping_uuid));
      dispatch(getFieldMapping(params?.mapping_uuid));
    }
  }, [localStorage.getItem("ci"), params?.mapping_uuid]);
  useEffect(() => {
    dispatch(getAllModels());
  }, []);
  useEffect(() => {
    if (snack_message && !tableVisiblity) {
      setOpenSnack(true);

      let interval = setInterval(() => {
          dispatch(updateSnackMessage(null));
      }, 4500);
return ()=>{
  clearInterval(interval)
}

    }


  }, [snack_message,tableVisiblity]);

  useEffect(() => {
    let by_filters_and_pagination = {
      mapping_uuid: params?.mapping_uuid,
      page_no: parseInt(allRequest_paginationDetails.page) + 1,
      page_size: allRequest_paginationDetails.pageSize,
    };
    let interval = setInterval(() => {
      if (tableVisiblity)
        dispatch(getMappingRequests(by_filters_and_pagination));
    }, 10000);
    if(tableVisiblity)
    dispatch(getMappingRequests(by_filters_and_pagination));

    return () => {
      clearInterval(interval);
    };
  }, [
    tableVisiblity,
    allRequest_paginationDetails.page,
    allRequest_paginationDetails.pageSize,
  ]);

  useEffect(() => {
    if (!tableVisiblity) {
      if (get_mapping?.sub_datasource.name === "Tickets") {
        dispatch(getZendeskDetails());
      }
      if (get_mapping?.sub_datasource.name === "Confluence") {
        dispatch(getConfluenceSpaces());
      }
      if (get_mapping?.sub_datasource?.datasource?.name === "ServiceNow") {
        dispatch(getServicenowStatus(get_mapping?.sub_datasource?.name));
      }

      if (get_mapping?.sub_datasource.name === "SAP Activities") {
        dispatch(getSapActivityStatus());
        dispatch(getSapActivityType());
      }
      if (get_mapping?.business_unit?.uuid) {
        dispatch(getAllPgInBuForUserMapping(get_mapping?.business_unit?.uuid));
      }
      setValue(get_mapping?.sub_datasource?.summary_generation);
      setIsGenerateSuggestion(
        get_mapping?.sub_datasource?.intent_group_generation
      );
    }
  }, [get_mapping, tableVisiblity]);

  useEffect(() => {
    let data = {
      mapping_request_uuid: selectedRequest,
      page_no: parseInt(filesPagination.page) + 1,
      page_size: filesPagination.pageSize,
      search: "",
    };
    if (selectedRequest) dispatch(getFilesByMappingRequest(data));
  }, [filesPagination.page, filesPagination.pageSize, selectedRequest]);


  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          zIndex: 1300,
          position: "absolute",
          top: 0,
          left: 0,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Sidebar */}
        <Grid
          width="232px"
          sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
        ></Grid>

        {/* Main Content */}
        <Grid
          width="calc(100% - 232px)"
          backgroundColor="white"
          position="relative"
          sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
        >
          <Grid
            display="flex"
            justifyContent="space-between"
            marginRight="40px"
          >
            <Typography
              margin="16px 0px 5px 24px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Mapping Details{" "}
            </Typography>

            <Grid marginTop="20px">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography
            margin="-14px 0px 10px 24px"
            color={colors.ascendo.darkblue}
            sx={{ fontSize: "14px", lineHeight: "17px" }}
          >
            {" "}
            View or update mapping here.
          </Typography>
          <Grid variant="stepperFixedtop1">
            <Grid marginX="46px" display="flex" flex="1 1 auto">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                component="div"
                borderRadius="4px"
                bgcolor="rgba(205, 205, 205, 0.12)"
                height="100px"
                paddingX="46px"
                width="100%"
              >
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Mapping Name
                  </Typography>
                  <Tooltip title={get_mapping?.name}>
                  <Typography className="roundText mapDesc" fontWeight="600" color={colors.ascendo.blue}>
                    {get_mapping?.name}
                  </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Module
                  </Typography>
                  <Typography fontWeight="600" className="roundText mapDesc" color={colors.ascendo.blue}>
                    {
                      moduleDetails?.find(
                        (item) => item?.uuid === get_mapping?.module_uuid
                      )?.name
                    }
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Business Unit
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.business_unit.name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Data Source
                  </Typography>
                  <Tooltip title={get_mapping?.sub_datasource.name}>
                  <Typography fontWeight="600" className="roundText mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.sub_datasource.name}
                  </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created By
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.created_by_user.username}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created At
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc"  color={colors.ascendo.blue}>
                    {formattedTime}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <div className="tabWrapper">
            <input
              className="radio"
              id="one"
              name="group"
              type="radio"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem("ci")}/mapping/${
                    params?.mapping_uuid
                  }/edit/fields-files`
                )
              }
            />{" "}
            <input
              className="radio"
              id="obs"
              name="group"
              type="radio"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem("ci")}/mapping/${
                    params?.mapping_uuid
                  }/edit/obfuscation`
                )
              }
            />
            <input
              className="radio"
              id="two"
              name="group"
              type="radio"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem("ci")}/mapping/${
                    params?.mapping_uuid
                  }/edit/view-mapping`
                )
              }
            />
            <input className="radio" id="three" name="group" type="radio" />
            <div className="tabs">
              <label className="tab " id="one-tab" for="one">
                {get_mapping?.sub_datasource?.datasource?.name !== "pdf"
                  ? "Field Mapping"
                  : "Files Uploaded"}
              </label>
              <label className="tab" id="obs-tab" for="obs">
                Obfuscation
              </label>
              <label className="tab" id="two-tab" for="two">
                View Mapping
              </label>
              <label className="tab active" id="three-tab" for="three">
                All Requests
              </label>
            </div>
            <div className="panels">
              <div className="panel" id="two-panel">
                {tableVisiblity && (
                  <RequestsTable handleRowClick={handleRowClick} />
                )}
                {!tableVisiblity &&
                  (!selectedRequest ? (
                    <Grid
                      marginTop="32px"
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                    >
                      <Grid display={"flex"} gap="40px" alignItems={"center"}>
                        <Grid display={"flex"} alignItems={"center"}>
                          <Checkbox
                            size="small"
                            checked={value}
                            onClick={() => {
                              setValue(!value);
                              dispatch(
                                updateSummaryForSubDatasource(
                                  get_mapping?.sub_datasource?.uuid
                                )
                              );
                            }}
                          />{" "}
                          <Typography fontSize={"13px"}>
                            Generate summary while requesting data.
                          </Typography>
                        </Grid>
                        {get_mapping?.sub_datasource?.datasource?.name !==
                          "pdf" && (
                          <Grid display={"flex"} alignItems={"center"}>
                            <Checkbox
                              size="small"
                              checked={isGenerateSuggestion}
                              onClick={() => {
                                setIsGenerateSuggestion(!isGenerateSuggestion);
                                dispatch(
                                  updateSuggestionForSubDatasource(
                                    get_mapping?.sub_datasource?.uuid
                                  )
                                );
                              }}
                            />{" "}
                            <Typography fontSize={"13px"}>
                              Generate suggestions.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      <Grid
                        display="flex"
                        flexDirection="column"
                        gap="12px"
                        width="30%"
                      >
                        {value && (
                          <Autocomplete
                            size="small"
                            disableClearable
                            value={selected_model}
                            options={models.map((model) => ({
                              ...model,
                              label: model.label,
                              id: model.uuid,
                            }))}
                            onChange={(event, newValue) => {
                              dispatch(updateSelectedModel(newValue));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Model"
                                placeholder="Select Model"
                              />
                            )}
                          />
                        )}
                        {([
                          "Tickets",
                          "Confluence",

                          "Help Articles",
                          "Macros",
                          "SAP Activities",
                        ].includes(get_mapping?.sub_datasource?.name) ||
                          ["pdf", "ServiceNow"].includes(
                            get_mapping?.sub_datasource?.datasource?.name
                          )) && (
                          <Autocomplete
                            size="small"
                            disableClearable
                            options={pg.map((item) => ({
                              ...item,
                              id: item.uuid,
                              label: item.name,
                            }))}
                            value={selected_pg}
                            onChange={(event, newValue) => {
                              dispatch(updateSelectedPg(newValue));
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Product Groups" />
                            )}
                          />
                        )}
                        {([
                          "Tickets",
                          "Confluence",
                          "Help Articles",
                          "Macros",
                          "SAP Activities",
                        ].includes(get_mapping?.sub_datasource?.name) ||
                          ["ServiceNow"].includes(
                            get_mapping?.sub_datasource?.datasource?.name
                          )) && (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disableFuture
                              disableHighlightToday
                              slots={{ openPickerIcon: DatePickerIcon }}
                              sx={{
                                fontSize: "14px",
                                height: "40px",
                                "& .MuiInputBase-root": { height: "40px" },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                  top: "-5px",
                                  textOverflow: "ellipsis",
                                  maxWidth: "calc(100% - 50px)",
                                },
                                "& .MuiIconButton-root": {
                                  borderLeft: "1px solid rgba(7, 25, 66, 0.3) ",
                                  paddingX: "12px",
                                  borderRadius: "0px",
                                  height: "28px",
                                },
                              }}
                              label="Pull Date Since"
                              value={selectedDate} // Set the selected date from state
                              onChange={handleDateChange}
                            />
                          </LocalizationProvider>
                        )}
                        {(["Tickets"].includes(
                          get_mapping?.sub_datasource?.name
                        ) ||
                          ["ServiceNow"].includes(
                            get_mapping?.sub_datasource?.datasource?.name
                          )) && (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disableFuture
                              disableHighlightToday
                              slots={{ openPickerIcon: DatePickerIcon }}
                              sx={{
                                fontSize: "14px",
                                height: "40px",
                                "& .MuiInputBase-root": { height: "40px" },
                                "& .MuiFormLabel-root": {
                                  fontSize: "14px",
                                  top: "-5px",
                                  textOverflow: "ellipsis",
                                  maxWidth: "calc(100% - 50px)",
                                },
                                "& .MuiIconButton-root": {
                                  borderLeft: "1px solid rgba(7, 25, 66, 0.3) ",
                                  paddingX: "12px",
                                  borderRadius: "0px",
                                  height: "28px",
                                },
                              }}
                              label="End Date"
                              value={selectedEndDate} // Set the selected date from state
                              onChange={handleEndDateChange}
                            />
                          </LocalizationProvider>
                        )}
                        {get_mapping?.sub_datasource.name === "Tickets" && (
                          <CustomMultiBox
                            options={
                              zendesk_groups
                                ? zendesk_groups.map((i) => ({
                                    label: i.name,
                                    id: i.id,
                                  }))
                                : []
                            }
                            label="Zendesk Group"
                            placeholder="Select Zendesk Group"
                            handleChange={(value) => handleGroupChange(value)}
                          />
                        )}
                        {get_mapping?.sub_datasource.name === "Tickets" && (
                          <CustomMultiBox
                            options={
                              zendesk_status
                                ? zendesk_status.map((i, index) => ({
                                    label: i,
                                    id: index,
                                  }))
                                : []
                            }
                            label="Zendesk Status"
                            placeholder="Select Zendesk Status"
                            handleChange={(value) => handleStatusChange(value)}
                          />
                        )}

                        {get_mapping?.sub_datasource?.datasource?.name ===
                          "ServiceNow" && (
                          <CustomMultiBox
                            options={service_now_status.map((i, index) => ({
                              label: i,
                              id: index,
                            }))}
                            label="ServiceNow Status"
                            placeholder="Select ServiceNow status"
                            handleChange={(value) =>
                              dispatch(updateSelectedServicenowStatus(value))
                            }
                          />
                        )}

                        {get_mapping?.sub_datasource.name === "Confluence" && (
                          <CustomMultiBox
                            options={spaces.map((i) => ({
                              ...i,
                              label: i.name,
                              id: i.id,
                            }))}
                            label="Confluence Spaces"
                            placeholder="Select Confluence Spaces"
                            handleChange={(value) =>
                              dispatch(updateSelectedSpaces(value))
                            }
                          />
                        )}

                        {get_mapping?.sub_datasource.name ===
                          "SAP Activities" && (
                          <>
                            <CustomMultiBox
                              options={sap_activity_type.map((i) => ({
                                ...i,
                                label: i,
                                id: i,
                              }))}
                              label="Activity Type"
                              placeholder="Select Activity Type"
                              handleChange={(value) =>
                                dispatch(updateSapActivityType(value))
                              }
                            />
                            <CustomMultiBox
                              options={sap_activity_status.map((i) => ({
                                ...i,
                                label: i,
                                id: i,
                              }))}
                              label="Activity Status"
                              placeholder="Select Activity Status"
                              handleChange={(value) =>
                                dispatch(updateSapActivityStatus(value))
                              }
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid marginTop="20px">
                      <ResizableTable
                        data={rows}
                        columns={cols}
                        paginationDetails={filesPagination}
                        onTablePaginationChange={(value) =>
                          setFilesPagination(value)
                        }
                        onTableRowClick={(params) => {}}
                        rowCount={files_table_details?.total ?? 0}
                      />
                    </Grid>
                  ))}
              </div>
            </div>
          </div>

          {/* Continue Button */}
          <Grid
            variant="stepperFixedbottombtn"
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
            {!tableVisiblity && (
              <Grid item width="120px" margin="26px 0px 0px 0px">
                <Button
                  fullWidth
                  sx={{
                    color: colors.ascendo.darkblue,
                    textTransform: "none",
                  }}
                  startIcon={<ArrowLeftIcon />}
                  onClick={handlePrevStep}
                >
                  Back
                </Button>
              </Grid>
            )}
            {!selectedRequest && (
              <Grid item width="150px" margin="26px 0px 0px 0px">
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: colors.ascendo.yellow,
                    color: colors.ascendo.darkblue,
                    textTransform: "none",
                  }}
                  endIcon={
                    <ArrowRightIcon
                      opacity={
                        get_mapping?.sub_datasource?.datasource?.name === "pdf"
                          ? 1
                          : !field_mapping_details?.every(
                              (updated_field) =>
                                updated_field.source_field !== null
                            )
                          ? 0.2
                          : 1
                      }
                    />
                  }
                  disabled={
                    get_mapping?.sub_datasource?.datasource?.name === "pdf"
                      ? false
                      : !field_mapping_details?.every(
                          (updated_field) => updated_field.source_field !== null
                        )
                  }
                  onClick={handleNextStep}
                >
                  {tableVisiblity ? "Request Data" : "Submit"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          message={snack_message}
          action={action}
          onClose={handleSnackBarClose}
          autoHideDuration={4500}
        />
      </Grid>
    </>
  );
}

export default AllRequests;
