import React from 'react'

function InboxIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={0.8}>
<path d="M14.166 2.91406H5.83268C3.33268 2.91406 1.66602 4.16406 1.66602 7.08073V12.9141C1.66602 15.8307 3.33268 17.0807 5.83268 17.0807H14.166C16.666 17.0807 18.3327 15.8307 18.3327 12.9141V7.08073C18.3327 4.16406 16.666 2.91406 14.166 2.91406ZM14.5577 7.98906L11.9493 10.0724C11.3993 10.5141 10.6993 10.7307 9.99935 10.7307C9.29935 10.7307 8.59102 10.5141 8.04935 10.0724L5.44102 7.98906C5.17435 7.7724 5.13268 7.3724 5.34102 7.10573C5.55768 6.83906 5.94935 6.78906 6.21602 7.00573L8.82435 9.08906C9.45768 9.5974 10.5327 9.5974 11.166 9.08906L13.7744 7.00573C14.041 6.78906 14.441 6.83073 14.6493 7.10573C14.866 7.3724 14.8243 7.7724 14.5577 7.98906Z" fill="#071942"/>
</svg>
  )
}

export default InboxIcon