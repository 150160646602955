import axios from "../../../utils/axios"
import { createAsyncThunk } from "@reduxjs/toolkit";


export const updateUserAvailability = createAsyncThunk(
  "updateUserAvailability",
  async (status_uuid, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_NOTIFICATION +
        `user/${localStorage.getItem("ci")}/${localStorage.getItem(
          "ui"
        )}/availability?status_uuid=${status_uuid}
    `,
      {
        method: "PATCH",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
        data:{}
      }
    );
    return { data: response.data, status: response.status };
  }
);

export const getAllBellNotification = createAsyncThunk(
  "getAllBellNotification",
  async (status_uuid, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_NOTIFICATION +
        `notification/all/${localStorage.getItem("ci")}/${localStorage.getItem(
          "ui"
        )}
    `,
      {
        method: "GET",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
        data:{}
      }
    );
    return { data: response.data, status: response.status };
  }
);
