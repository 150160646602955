import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GetBusinessUnitsByUser } from "../../actions/businessunitsActions";
import {
  DeleteMapping,
  UpdateSuggestionForSubDatasource,
  UpdateSummaryForSubDatasource,
} from "../../actions/mapping/mappingActions";
import {
  GetMappingRequests,
  ListMapping,
  RequestData,
  ZendeskRequestData,
} from "../../actions/listMappingActions";
import { ExportErrorFile, GetFilesByMappingRequest } from "../../actions/dataIntegration/mappingRequestActions";
import { GetFieldMapping } from "../../actions/mapping/mappingActions";
import { GetActiveChannels } from "../../actions/dataIntegration/channelActions";
import {
  CreateChannelMapping,
  DeleteChannelMapping,
  GetAllChannelMappings,
} from "../../actions/dataIntegration/channelMappingActions";
import { GetSlackAuth } from "../../actions/identity/authActions";
import { getSapActivityStatus, getSapActivityType } from "../../actions/dataIntegration/sapFsmActions";
import { GetAllSubDatasourceByModules } from "../../actions/dataIntegration/subdatasourcActions";

const downloadCSV = async (responseBody, filename) => {
  // Assuming responseBody is a CSV string
  const blob = new Blob([responseBody], { type: "text/csv" });

  // Use the downloadFile function to download the Blob as a CSV file
  downloadFile(blob, filename);
};

const downloadFile = async (blob, filename) => {
  // Create a temporary link element
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;

  // Trigger the download
  downloadLink.click();

  // Cleanup the temporary link after download
  URL.revokeObjectURL(downloadLink.href);
};

export const exportErrorFile = createAsyncThunk(
  "exportErrorFile",
  async (data, thunkAPI) => {
    const response = await ExportErrorFile(data.mapping_request_uuid);
    downloadCSV(response.data, data.filename);
  }
);

export const listMapping = createAsyncThunk(
  "listmapping",
  async (data, thunkAPI) => {
   
    const response = await ListMapping(
      data.module_uuids,
      data.business_unit_uuids,
      data.sub_datasource_uuids,
      data.search,
      data.page_no,
      data.page_size
    );
    return response;
  }
);
export const getMappingRequests = createAsyncThunk(
  "mappingRequests",
  async (data, thunkAPI) => {
    const response = await GetMappingRequests(
      data.mapping_uuid,
      data.page_no,
      data.page_size
    );
    return response;
  }
);

export const getFilesByMappingRequest = createAsyncThunk(
  "getFilesByMappingRequest",
  async (data, thunkAPI) => {
   
    const response = await GetFilesByMappingRequest(
     data.mapping_request_uuid,
      data.page_no,
      data.page_size,
      data.search
    );
    return response;
  }
);

export const requestData = createAsyncThunk(
  "requestData",
  async (mapping_uuid, thunkAPI) => {
    await RequestData(mapping_uuid);
    const response = await GetMappingRequests(mapping_uuid, 1, 10);

    return response;
  }
);

export const zendeskRequestData = createAsyncThunk(
  "zendeskRequestData",
  async (data, thunkAPI) => {
    await ZendeskRequestData(data.req_data, data.mapping_uuid);
    const response = await GetMappingRequests(data.mapping_uuid, 1, 10);

    return response;
  }
);

export const getBusinessUnitAndDataSource = createAsyncThunk(
  "getBusinessUnitAndDataSource",
  async (module_uuids,thunkAPI) => {
    const user = JSON.parse(localStorage.getItem("ud"));
    const customer_uuid = localStorage.getItem("ci");
    const buResponse = await GetBusinessUnitsByUser(customer_uuid, user.uuid);
    const dsResponse = await GetAllSubDatasourceByModules(module_uuids);
    const responses = { buResponse, dsResponse };
    return responses;
  }
);
export const deleteMapping = createAsyncThunk(
  "deletemapping",
  async (data, thunkAPI) => {


    await DeleteMapping(data.customer_uuid, data.mapping_uuids);
    const response = await ListMapping(
      data.module_uuids,
      data.business_unit_uuids,
      data.sub_datasource_uuids,
      data.search,
      data.page_no,
      data.page_size
    );
    return response;
  }
);

export const getAscendoFields = createAsyncThunk(
  "getAscendoFields",
  async (mapping_uuid, thunkAPI) => {
    const response = await GetFieldMapping(mapping_uuid);
    return response;
  }
);

export const getChannelDetails = createAsyncThunk(
  "getChannelDetails",
  async (thunkAPI) => {
    const response = await GetActiveChannels();
    return response;
  }
);

export const getAllChannelMappings = createAsyncThunk(
  "getAllChannelMappings",
  async (mapping_uuid, thunkAPI) => {
    const response = await GetAllChannelMappings(mapping_uuid);
    return response;
  }
);

export const createChannelMapping = createAsyncThunk(
  "createChannelMapping",
  async (data, thunkAPI) => {
    const response = await CreateChannelMapping(data);
    return response;
  }
);
export const deleteChannelMapping = createAsyncThunk(
  "deleteChannelMapping",
  async (data, thunkAPI) => {
    await DeleteChannelMapping(data);
    const response = await GetAllChannelMappings(data.filter.mapping_uuid);
    return response;
  }
);
export const getSlackAuth = createAsyncThunk(
  "getSlackAuth",
  async (data, thunkAPI) => {
    const response = await GetSlackAuth();
    return response;
  }
);

export const updateSummaryForSubDatasource = createAsyncThunk(
  "updateSummaryForSubDatasource",
  async (sub_datasource_uuid, thunkAPI) => {
    const response = await UpdateSummaryForSubDatasource(sub_datasource_uuid);
    return response;
  }
);
export const updateSuggestionForSubDatasource = createAsyncThunk(
  "updateSuggestionForSubDatasource",
  async (sub_datasource_uuid, thunkAPI) => {
    const response = await UpdateSuggestionForSubDatasource(
      sub_datasource_uuid
    );
    return response;
  }
);

const ListMappingSlice = createSlice({
  name: "listmapping",
  initialState: {
    filtered_bu: null,
    filtered_ds: null,
    business_units: null,
    dataSources: null,
    mappings: [],
    display_map: null,
    columns: null,
    listStatus: null,
    rowStatus: null,
    mappingTableDetails: null,
    req_mappings: [],
    req_display_map: null,
    req_columns: null,
    req_mappingTableDetails: null,
    dsbuStatus: null,
    all_ascendo_fields: [],
    channels: [],
    channel_mappings: [],
    is_created: false,
    checkedBusinessUnit: [],
    checkedDataSource: [],
    selected_sap_activity_status:[],
    sap_activity_status:[],
    selected_sap_activity_type:[],
    sap_activity_type:[],
    files_table_details:null
  },
  reducers: {
    updateListStatus: (state, action) => {
      state.listStatus = action.payload;
    },
    updateCheckedDataSource: (state, action) => {
      state.checkedDataSource = action.payload;
    },
    updateCheckedBusinessUnit: (state, action) => {
      state.checkedBusinessUnit = action.payload;
    },
    updateSapActivityStatus:(state,action)=>{
      state.selected_sap_activity_status=action.payload
    },updateSapActivityType:(state,action)=>{
      state.selected_sap_activity_type=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listMapping.pending, (state, action) => {
        state.rowStatus = false;
      })
      .addCase(listMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.mappings = action.payload.data.data;
          state.display_map = action.payload.data.display_map;
          state.columns = action.payload.data.columns;
          state.mappingTableDetails = action.payload.data;
          state.rowStatus = true;
        } else {
          state.rowStatus = false;
        }
      })
      .addCase(deleteMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.mappings = action.payload.data.data;
          state.display_map = action.payload.data.display_map;
          state.columns = action.payload.data.columns;
          state.mappingTableDetails = action.payload.data;
          state.rowStatus = true;
        } else {
          state.rowStatus = false;
        }
      })
      .addCase(listMapping.rejected, (state, action) => {
        state.rowStatus = false;
      })
      .addCase(getMappingRequests.pending, (state, action) => {
        state.rowStatus = false;
      })
      .addCase(getMappingRequests.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.req_mappings = action.payload.data.data;
          state.req_display_map = action.payload.data.display_map;
          state.req_columns = action.payload.data.columns;
          state.req_mappingTableDetails = action.payload.data;
          state.rowStatus = true;
        } else {
          state.rowStatus = false;
        }
      })

      .addCase(getMappingRequests.rejected, (state, action) => {
        state.rowStatus = false;
      })
      .addCase(getBusinessUnitAndDataSource.pending, (state, action) => {
        state.listStatus = false;
      })
      .addCase(getBusinessUnitAndDataSource.fulfilled, (state, action) => {
        if (
          action.payload?.buResponse?.status === 200 &&
          action.payload?.dsResponse?.status === 200
        ) {
          state.business_units = action.payload.buResponse.data;
          state.dataSources = action.payload.dsResponse.data;
          state.checkedBusinessUnit = state.business_units?.map((bu) => ({
            ...bu,
            id: bu?.uuid,
            label: bu?.name,
          }));
          state.checkedDataSource = state.dataSources?.map((ds) => ({
            ...ds,
            id: ds?.uuid,
            label: ds?.name,
          }));

          state.listStatus = true;
        } else {
          state.listStatus = false;
        }
      })
      .addCase(getBusinessUnitAndDataSource.rejected, (state, action) => {
        // Handle rejected case if needed
        state.listStatus = false;
      })
      .addCase(requestData.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.req_mappings = action.payload.data.data;
          state.req_display_map = action.payload.data.display_map;
          state.req_columns = action.payload.data.columns;
          state.req_mappingTableDetails = action.payload.data;
          state.rowStatus = true;
        } else {
          state.rowStatus = false;
        }
      })
      .addCase(zendeskRequestData.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.req_mappings = action.payload.data.data;
          state.req_display_map = action.payload.data.display_map;
          state.req_columns = action.payload.data.columns;
          state.req_mappingTableDetails = action.payload.data;
          state.rowStatus = true;
        } else {
          state.rowStatus = false;
        }
      })
      .addCase(getAscendoFields.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.all_ascendo_fields = action.payload.data;
        }
      })
      .addCase(getChannelDetails.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.channels = action.payload.data;
        }
      })
      .addCase(getAllChannelMappings.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.channel_mappings = action.payload.data;
        }
      })
      .addCase(getSlackAuth.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          window.open(action.payload.data.data);
        }
      })
      .addCase(createChannelMapping.pending, (state, action) => {
        state.is_created = false;
      })
      .addCase(createChannelMapping.fulfilled, (state, action) => {
        state.is_created = true;
      })
      .addCase(getSapActivityStatus.fulfilled, (state, action) => {
        if(action.payload.status===200)
        state.sap_activity_status = action.payload.data;
      })
      .addCase(getSapActivityType.fulfilled, (state, action) => {
        if(action.payload.status===200)
        state.sap_activity_type = action.payload.data;
      })
      .addCase(deleteChannelMapping.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.channel_mappings = action.payload.data;
      })
      .addCase(getFilesByMappingRequest.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.files_table_details = action.payload.data;
      })
  },
});
export const {
  updateListStatus,
  updateCheckedBusinessUnit,
  updateCheckedDataSource,
  updateSapActivityStatus,
  updateSapActivityType
} = ListMappingSlice.actions;
export default ListMappingSlice.reducer;
