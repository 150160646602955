import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

function Topusers({ value }) {
  useEffect(() => {
    Highcharts.chart('topUsers', {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
        align: 'left',
      },
      xAxis: {
        categories: value.y_axis, // Use y_axis from value prop
        title: {
          text: value.y_title, // Use x_title from value prop
        },
        gridLineWidth: 0,
        lineWidth: 0,
      },
      yAxis: {
        
        min: 0,
        title: {
          text: value.x_title,
          align:"low"
        },
        labels: {
          overflow: 'justify',
          enabled: false
        },
        gridLineWidth: 0,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '',
      },
      plotOptions: {
        bar: {
          borderRadius: '50%',
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.05,
          pointWidth: 15,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: '',
          data: value.x_axis,
          showInLegend: "false",
        },
      ],
    });
  }, [value]); // Add value as a dependency to useEffect

  return <div id="topUsers" style={{ height: '300px' }} />;
}

export default Topusers;
