import React from "react";

function CompletedIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.815909 7.99992C0.815909 12.0093 4.10425 15.2666 8.14808 15.2666C12.1919 15.2666 15.4803 12.0093 15.4803 7.99992C15.4803 3.9905 12.1919 0.733252 8.14808 0.733252C4.10425 0.733252 0.815909 3.9905 0.815909 7.99992ZM2.02505 7.99992C2.02505 4.656 4.77137 1.93325 8.14808 1.93325C11.5248 1.93325 14.2711 4.656 14.2711 7.99992C14.2711 11.3438 11.5248 14.0666 8.14808 14.0666C4.77137 14.0666 2.02505 11.3438 2.02505 7.99992Z" fill="#32C49E" stroke="#32C49E" stroke-width="0.2"/>
<path d="M6.7658 10.3111C6.87859 10.4229 7.03167 10.4867 7.19275 10.4867C7.35383 10.4867 7.50691 10.4229 7.6197 10.3111L11.4343 6.5311C11.6688 6.29864 11.6688 5.91482 11.4343 5.68237C11.2002 5.45041 10.8144 5.45041 10.5804 5.68237L7.19275 9.03928L5.71579 7.5757C5.48171 7.34374 5.09596 7.34374 4.86189 7.5757C4.6273 7.80816 4.6273 8.19197 4.86189 8.42443L6.7658 10.3111Z" fill="#32C49E" stroke="#32C49E" stroke-width="0.2"/>
</svg>

  );
}

export default CompletedIcon;
