import { AddCircleOutline, DeleteOutline } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ResizableTable from "../../../components/dataTable/simpleTable";
import MyMultiSelect from "../../../components/dropdown/myMultiSelect";
import {
  updateIsDelete,
  updateObsPagination,
} from "../../../redux/slices/dataTableSlice";
import {
  getFieldMapping,
  getMapping,
} from "../../../redux/slices/mapping/mappingStep2Slice";
import {
  createObfuscator,
  createObfuscatorSettings,
  deleteObfuscator,
  deleteObfuscatorSettings,
  getObfuscatedFields,
  getObfuscator,
  getObfuscatorSettings,
  getOperators,
  getOperatorValues,
  getSupportedEntities,
  obfuscateFields,
  updateObfuscator,
  updateObfuscatorSettings,
  updateOperatorValues,
  updateThreshold,
} from "../../../redux/slices/obfuscation/obsSlice";
import "./editObfuscation.scss";
import SearchNormalIcon from "../../../assets/SearchNormalIcon";

function EditObfuscation() {
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isDisabled, setIsDisabled] = useState(true);

  //  State Value
  const [entity, setEntity] = useState("");
  const [operator, setOperator] = useState("");
  const [isFormView, setIsFormView] = useState(false);
  const [isObs, setIsObs] = useState(false);
  const [threshold, setThreshold] = useState(0.35);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [searchSettingChange, setSearchSettingChange] = useState("");
  const [searchSetting, setSearchSetting] = useState("");

  // Redux

  const { obs_paginationDetails, isDeleteVisible, selectionModel } =
    useSelector((state) => state.dataTableReducer);

  const {
    supported_entities,
    operators,
    operator_values,
    obfuscation_setting_details,
    obfuscator_details,
    obfuscated_fields,
  } = useSelector((state) => state.obfuscationReducer);

  const { original_field_details, get_mapping } = useSelector(
    (state) => state.mappingStep2Reducer
  );

  const cols = useMemo(() =>
    obfuscation_setting_details?.columns
      ? obfuscation_setting_details?.columns?.map((column) => {
          if (["entity", "operator"].includes(column)) {
            return {
              accessorKey: column,
              size: 40,
              header: obfuscation_setting_details?.display_map[column],
              Cell: ({ cell }) => (
                <Tooltip title={cell.getValue()}>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cell.getValue()}
                  </div>
                </Tooltip>
              ),
            };
          }
          return {
            accessorKey: column,
            header: obfuscation_setting_details?.display_map[column],
            Cell: ({ cell }) => (
              <Tooltip title={cell.getValue()}>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {cell.getValue()}
                </div>
              </Tooltip>
            ),
          };
        })
      : []
  );

  const rows = obfuscation_setting_details?.data
    ? obfuscation_setting_details?.data?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        obfuscation_setting_details?.columns?.forEach((column) => {
          if (column === "operator_value") {
            row[column] = JSON.stringify(dataItem[column]);
          } else {
            row[column] = dataItem[column];
          }
        });
        return row;
      })
    : [];
  // Functions

  const handleClose = () => {
    navigate(`/${localStorage.getItem("ci")}/mapping`);
  };
  const handleNextStep = () => {
    if (isObs) {
      let data = {
        mapping_uuid: params?.mapping_uuid,
        field_mapping_uuids: selectedFields.map((field) => field.uuid),
      };
      dispatch(obfuscateFields(data));
      dispatch(
        updateThreshold({ mapping_uuid: params?.mapping_uuid, threshold })
      );
    }
    navigate(`/${localStorage.getItem("ci")}/mapping`);
  };

  const handleCancel = () => {
    setIsFormView(false);
    setSelectedSetting(null);
    setEntity("");
    setOperator("");
    dispatch(updateOperatorValues({}));
  };
  const handleSave = () => {
    let data = {
      mapping_uuid: params.mapping_uuid,
      obfuscator_setting_uuid: selectedSetting?.id,
      entity,
      operator,
      operator_value:
        operator === "mask"
          ? {
              ...operator_values,
              chars_to_mask: parseInt(operator_values.chars_to_mask),
            }
          : operator_values,
      page_no: parseInt(obs_paginationDetails.page) + 1,
      page_size: obs_paginationDetails.pageSize,
      search: "",
    };
    if (!selectedSetting) dispatch(createObfuscatorSettings(data));
    else dispatch(updateObfuscatorSettings(data));
    setIsDisabled(false);

    setIsFormView(false);
    setEntity("");
    setOperator("");
    dispatch(updateOperatorValues({}));
    setSelectedSetting(null);
  };

  const handleDelete = () => {
    let data = {
      mapping_uuid: params?.mapping_uuid,
      page_no: parseInt(obs_paginationDetails.page) + 1,
      page_size: obs_paginationDetails.pageSize,
      search: "",
      obfuscator_setting_uuids: Object.keys(selectionModel),
    };
    dispatch(deleteObfuscatorSettings(data));
    dispatch(updateIsDelete(false));
    setIsDisabled(false);
  };
  const handleSearchSettingChange = (event) => {
    const value = event.target.value;
    setSearchSettingChange(value);
    setSearchSetting(value);
  };
  const debounceOnChange = debounce(handleSearchSettingChange, 1000);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchSetting(searchSettingChange);
    }
  };

  useEffect(() => {
    dispatch(getObfuscator(params?.mapping_uuid));
    dispatch(getObfuscatedFields(params?.mapping_uuid));
    dispatch(getOperators(params?.mapping_uuid));
    dispatch(getSupportedEntities(params?.mapping_uuid));
    dispatch(getFieldMapping(params.mapping_uuid));
    dispatch(getMapping(params.mapping_uuid));
  }, []);

  useEffect(() => {
    if (
      (operator === "replace" && !operator_values?.new_value) ||
      (operator === "mask" &&
        !operator_values?.masking_char &&
        !operator_values?.chars_to_mask)
    )
      dispatch(
        getOperatorValues({ mapping_uuid: params?.mapping_uuid, operator })
      );
  }, [operator]);

  useEffect(() => {
    let data = {
      mapping_uuid: params?.mapping_uuid,
      page_no: parseInt(obs_paginationDetails.page) + 1,
      page_size: obs_paginationDetails.pageSize,
      search: searchSetting,
    };
    dispatch(getObfuscatorSettings(data));
  }, [
    obs_paginationDetails.page,
    obs_paginationDetails.pageSize,
    searchSetting,
  ]);

  useEffect(() => {
    if (obfuscator_details) {
      setIsObs(true);
      setThreshold(obfuscator_details?.threshold);
    } else {
      setIsObs(false);
      setThreshold(0.35);
    }
  }, [obfuscator_details]);

  useEffect(() => {
    if (original_field_details && obfuscated_fields) {
      let obfuscatedFields = original_field_details
        ?.filter(
          (field) =>
            obfuscated_fields?.find(
              (item) => item.field_mapping_uuid === field.uuid
            )?.is_active
        )
        .map((item) => ({ ...item, id: item.uuid, label: item.ascendo_field }));
      setSelectedFields(obfuscatedFields);
    }
  }, [original_field_details, obfuscated_fields]);

  useEffect(() => {
    if (selectedSetting) {
      let op_value = JSON.parse(selectedSetting.operator_value);
      setEntity(selectedSetting.entity);
      setOperator(selectedSetting.operator);
      if (selectedSetting.operator === "mask") {
        dispatch(
          updateOperatorValues({
            ...op_value,
            chars_to_mask: `${op_value.chars_to_mask}`,
          })
        );
      } else dispatch(updateOperatorValues(op_value));
    }
  }, [selectedSetting]);

  useEffect(() => {
    dispatch(getMapping(params.mapping_uuid));
  }, []);

  const originalTime = get_mapping?.created_at;
  const formattedTime = moment(originalTime).format("MMM DD YYYY HH:mm");

  const moduleDetails = JSON.parse(localStorage.getItem("md"));

  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          zIndex: 1300,
          position: "absolute",
          top: 0,
          left: 0,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Sidebar */}
        <Grid
          width="232px"
          sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
        ></Grid>

        {/* Main Content */}
        <Grid
          width="calc(100% - 232px)"
          backgroundColor="white"
          position="relative"
          sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
        >
          <Grid
            display="flex"
            justifyContent="space-between"
            marginRight="40px"
          >
            <Typography
              margin="16px 0px 5px 24px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Mapping Details{" "}
            </Typography>

            <Grid marginTop="20px">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography
            margin="-14px 0px 10px 24px"
            color={colors.ascendo.darkblue}
            sx={{ fontSize: "14px", lineHeight: "17px" }}
          >
            {" "}
            View or update mapping here.
          </Typography>
          <Grid variant="stepperFixedtop1">
            <Grid marginX="46px" display="flex" flex="1 1 auto">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                component="div"
                borderRadius="4px"
                bgcolor="rgba(205, 205, 205, 0.12)"
                height="100px"
                paddingX="46px"
                width="100%"
              >
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Mapping Name
                  </Typography>
                  <Tooltip title={get_mapping?.name}>
                  <Typography fontWeight="600"  className="roundText mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.name}
                  </Typography>
                 </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Module
                  </Typography>
                  <Typography fontWeight="600"  className="roundText mapDesc" color={colors.ascendo.blue}>
                    {
                      moduleDetails?.find(
                        (item) => item?.uuid === get_mapping?.module_uuid
                      )?.name
                    }
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Business Unit
                  </Typography>
                  <Typography fontWeight="600"  className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.business_unit.name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Data Source
                  </Typography>
                  
                  <Tooltip title={get_mapping?.sub_datasource.name}>
                  <Typography fontWeight="600"  className="roundText mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.sub_datasource.name}
                  </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created By
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {get_mapping?.created_by_user.username}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography fontSize="14px" color={colors.grey.border}>
                    Created At
                  </Typography>
                  <Typography fontWeight="600" className="mapDesc" color={colors.ascendo.blue}>
                    {formattedTime}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <div className="tabWrapper">
            <input
              className="radio"
              id="one"
              name="group"
              type="radio"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem("ci")}/mapping/${
                    params.mapping_uuid
                  }/edit/fields-files`
                )
              }
            />
            <input className="radio" id="obs" name="group" type="radio" />
            <input
              className="radio"
              id="two"
              name="group"
              type="radio"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem("ci")}/mapping/${
                    params.mapping_uuid
                  }/edit/view-mapping`
                )
              }
            />
            <input
              className="radio"
              id="three"
              name="group"
              type="radio"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem("ci")}/mapping/${
                    params.mapping_uuid
                  }/all-requests`
                )
              }
            />
            <div className="tabs">
              <label className="tab" id="one-tab" for="one">
                {get_mapping?.sub_datasource?.datasource?.name === "pdf"
                  ? "Files Uploaded"
                  : "Field Mapping"}
              </label>
              <label className="tab active" id="obs-tab" for="obs">
                Obfuscation
              </label>
              <label className="tab " id="two-tab" for="two">
                View Mapping
              </label>
              <label className="tab " id="three-tab" for="three">
                All Requests
              </label>
            </div>
            <div className="panels">
              <div className="panel" id="one-panel">
                <Grid variant="stepperScroller">
                  <div className="innerField">
                    <div>
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "18px",
                          margin: "26px 0px 0px 46px",
                        }}
                      >
                        <Typography
                          fontWeight="500"
                          lineHeight="17px"
                          color={colors.ascendo.darkblue}
                        >
                          Obfuscation
                        </Typography>
                        <Switch
                          size="small"
                          checked={isObs}
                          onChange={(e) => {
                            if (e.target.checked) {
                              let data = {
                                mapping_uuid: params?.mapping_uuid,
                                page_no:
                                  parseInt(obs_paginationDetails.page) + 1,
                                page_size: obs_paginationDetails.pageSize,
                                search: "",
                                threshold,
                                is_active: true,
                              };
                              dispatch(updateObfuscator(data));
                              setIsObs(true);
                              setIsDisabled(false);
                            } else {
                              setDialogOpen(true);
                            }
                          }}
                        />
                      </Grid>

                      <Grid marginLeft="46px" marginBottom="26px">
                        <Grid
                          display="flex"
                          alignItems={"center"}
                          gap="18px"
                          mt="8px"
                        >
                          {isObs && (
                            <Grid>
                              <Grid
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "18px",
                                }}
                              >
                                {" "}
                                <Typography
                                  sx={{
                                    colors: colors.ascendo.darkblue,
                                    fontSize: "13px",
                                    width: "100px",
                                  }}
                                >{`Threshold : ${threshold}`}</Typography>
                                <Slider
                                  sx={{ width: "170px" }}
                                  step={0.01}
                                  min={0.0}
                                  max={1.0}
                                  size="small"
                                  valueLabelDisplay="auto"
                                  onChange={(e) => {
                                    setThreshold(e.target.value);
                                    setIsDisabled(false);
                                  }}
                                  value={threshold}
                                />
                              </Grid>
                              <Typography sx={{fontSize:"11px",color:"grey"}}>
                                Mimimum Confidence value above which entities
                                will be obfuscated
                              </Typography>
                            </Grid>
                          )}
                          {isObs && (
                            <Grid
                              width="420px"
                              mt="8px"
                              ml="26px"
                              px="16px"
                              sx={{
                                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                              }}
                            >
                              <MyMultiSelect
                                options={
                                  original_field_details?.map((item) => ({
                                    ...item,
                                    id: item.uuid,
                                    label: item.ascendo_field,
                                  })) ?? []
                                }
                                label="Fields To Obfuscate"
                                error={false}
                                helperText={""}
                                selectedValues={selectedFields}
                                handleChange={(newValue) => {
                                  setSelectedFields(newValue);
                                  setIsDisabled(false);
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                      <Grid margin="26px 46px 18px 46px">
                        <Typography
                          margin="26px 0px 18px 0px"
                          fontWeight="500"
                          lineHeight="17px"
                          color={colors.ascendo.darkblue}
                        >
                          Obfuscation Settings
                        </Typography>
                        {isObs ? (
                          <Grid>
                            {isFormView ? (
                              <Grid
                                sx={{
                                  display: "flex",
                                  gap: "18px",
                                  border: "1px solid rgba(0, 0, 0, 0.12)",
                                  px: "20px",
                                  py: "20px",
                                  borderRadius: "6px",
                                }}
                              >
                                <Grid sx={{ flex: "1 1 0" }}>
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      gap: "18px",
                                    }}
                                  >
                                    <Autocomplete
                                      disableClearable
                                      size="small"
                                      sx={{
                                        width: "230px",
                                        height: "40px",
                                        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
                                          {
                                            height: "40px",
                                          },

                                        "& .MuiInputLabel-root": {
                                          maxWidth: "calc(100% - 62px)",

                                          fontSize: "14px",
                                        },
                                      }}
                                      value={entity}
                                      options={supported_entities}
                                      onChange={(e, newValue) =>
                                        setEntity(newValue)
                                      }
                                      getOptionLabel={(option) => option}
                                      isOptionEqualToValue={(option, value) =>
                                        option === value
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} label="Entity" />
                                      )}
                                    />
                                    <Autocomplete
                                      disableClearable
                                      size="small"
                                      sx={{
                                        width: "230px",

                                        height: "40px",
                                        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
                                          {
                                            height: "40px",
                                          },

                                        "& .MuiInputLabel-root": {
                                          maxWidth: "calc(100% - 62px)",

                                          fontSize: "14px",
                                        },
                                      }}
                                      options={operators}
                                      value={operator}
                                      onChange={(e, newValue) => {
                                        setOperator(newValue);
                                        dispatch(updateOperatorValues({}));
                                      }}
                                      getOptionLabel={(option) => option}
                                      isOptionEqualToValue={(option, value) =>
                                        option === value
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Operator"
                                        />
                                      )}
                                    />

                                    {operator === "replace" && (
                                      <TextField
                                        sx={{ width: "230px" }}
                                        label="Replace With"
                                        size="small"
                                        value={operator_values?.new_value ?? ""}
                                        onChange={(e) =>
                                          dispatch(
                                            updateOperatorValues({
                                              new_value: e.target.value,
                                            })
                                          )
                                        }
                                      />
                                    )}
                                    {operator === "mask" && (
                                      <>
                                        <Grid
                                          sx={{ display: "flex", gap: "18px" }}
                                        >
                                          <Autocomplete
                                            disableClearable
                                            size="small"
                                            sx={{
                                              width: "230px",

                                              height: "40px",
                                              "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
                                                {
                                                  height: "40px",
                                                },

                                              "& .MuiInputLabel-root": {
                                                maxWidth: "calc(100% - 62px)",

                                                fontSize: "14px",
                                              },
                                            }}
                                            options={["*", ".", "-", "x"]}
                                            isOptionEqualToValue={(option) =>
                                              option
                                            }
                                            value={
                                              operator_values?.masking_char ??
                                              ""
                                            }
                                            onChange={(e, newValue) =>
                                              dispatch(
                                                updateOperatorValues({
                                                  ...operator_values,
                                                  masking_char: newValue,
                                                })
                                              )
                                            }
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Masking character"
                                              />
                                            )}
                                          />

                                          <TextField
                                            sx={{ width: "230px" }}
                                            size="small"
                                            type="number"
                                            label="Number of characters to mask"
                                            value={
                                              operator_values?.chars_to_mask ??
                                              ""
                                            }
                                            onChange={(e) =>
                                              dispatch(
                                                updateOperatorValues({
                                                  ...operator_values,
                                                  chars_to_mask: e.target.value,
                                                })
                                              )
                                            }
                                          />
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "6px",
                                      marginTop: "20px",
                                      position: "relative",
                                      height: "35px",
                                    }}
                                  >
                                    {operator === "mask" && (
                                      <Grid
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          position: "absolute",
                                          left: "0",
                                          gap: "6px",
                                        }}
                                      >
                                        <Checkbox
                                          size="small"
                                          checked={
                                            operator_values?.from_end ?? true
                                          }
                                          onChange={(e) =>
                                            dispatch(
                                              updateOperatorValues({
                                                ...operator_values,
                                                from_end: e.target.checked,
                                              })
                                            )
                                          }
                                        />
                                        <Typography sx={{ fontSize: "13px" }}>
                                          Mask characters from the end
                                        </Typography>
                                      </Grid>
                                    )}
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "18px",
                                        position: "absolute",
                                        right: "0px",
                                      }}
                                    >
                                      <Button
                                        variant="ascendo-light"
                                        onClick={handleCancel}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="ascendo"
                                        onClick={handleSave}
                                        disabled={
                                          !(
                                            entity.length > 0 &&
                                            operator.length > 0 &&
                                            (operator === "replace"
                                              ? operator_values?.new_value
                                                  ?.length > 0
                                              : operator === "mask"
                                              ? operator_values?.masking_char
                                                  ?.length > 0 &&
                                                operator_values?.chars_to_mask
                                                  ?.length > 0
                                              : true)
                                          )
                                        }
                                      >
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid>
                                <Grid
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "18px",
                                    my: "18px",
                                  }}
                                >
                                  <TextField
                                    size="small"
                                    type="search"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchNormalIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    fullWidth
                                    sx={{ flex: "1 1 0" }}
                                    placeholder="Search for settings here"
                                    onChange={debounceOnChange}
                                    onKeyDown={handleKeyDown}
                                  />
                                  {isDeleteVisible && (
                                    <Button
                                      variant="ascendo-light"
                                      startIcon={<DeleteOutline />}
                                      onClick={handleDelete}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                  <Button
                                    variant="ascendo"
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => setIsFormView(true)}
                                  >
                                    Add Entity
                                  </Button>
                                </Grid>
                                <Grid>
                                  <ResizableTable
                                    data={rows}
                                    columns={cols}
                                    paginationDetails={obs_paginationDetails}
                                    onTablePaginationChange={(value) =>
                                      dispatch(updateObsPagination(value))
                                    }
                                    onTableRowClick={(params) => {
                                      setIsFormView(true);
                                      setSelectedSetting(params.original);
                                    }}
                                    rowCount={
                                      obfuscation_setting_details?.total ?? 0
                                    }
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        ) : (
                          <Grid>
                            <Card
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "200px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: colors.ascendo.darkblue,
                                  fontSize: "13px",
                                }}
                              >
                                Enable Obfuscation to edit settings.
                              </Typography>
                            </Card>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </div>
            </div>
          </div>

          {/* Continue Button */}
          <Grid
            variant="stepperFixedbottombtn"
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You want to disable obfuscation for this mapping?
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ pr: 3, pb: 2.5 }}>
                <Button
                  variant="ascendo"
                  width="75px"
                  sx={{
                    color: "white",
                    backgroundColor: "red",
                    "&:hover": {
                      color: "rgba(7, 25, 66)",
                    },
                  }}
                  onClick={() => {
                    setDialogOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="ascendo"
                  width="75px"
                  onClick={() => {
                    setDialogOpen(false);
                    setIsObs(false);
                    let data = {
                      mapping_uuid: params?.mapping_uuid,
                      page_no: parseInt(obs_paginationDetails.page) + 1,
                      page_size: obs_paginationDetails.pageSize,
                      search: "",
                      threshold,
                      is_active: false,
                    };
                    dispatch(updateObfuscator(data));
                    setIsDisabled(false);
                  }}
                  autoFocus
                >
                  Disable
                </Button>
              </DialogActions>
            </Dialog>
            <Grid item width="100px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  backgroundColor: colors.ascendo.yellow,
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                onClick={handleNextStep}
                disabled={
                  (!isObs
                    ? false
                    : !(
                        isObs &&
                        selectedFields?.length > 0 
                      )) || isDisabled
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EditObfuscation;
