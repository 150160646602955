import React from 'react'

function SlackIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5401 1.87988C13.3101 1.87988 12.3101 2.87986 12.3101 4.10986V8.74988C12.3101 9.97988 13.3101 10.9799 14.5401 10.9799C15.7701 10.9799 16.7701 9.97988 16.7701 8.74988V4.10986C16.7701 2.88986 15.7701 1.87988 14.5401 1.87988Z" fill="#292D32"/>
    <path d="M20.1802 7.11035C19.1102 7.11035 18.2402 7.98035 18.2402 9.05035V10.6204C18.2402 10.8304 18.4102 11.0004 18.6202 11.0004H20.1902C21.2602 11.0004 22.1302 10.1304 22.1302 9.06036C22.1302 7.99036 21.2502 7.11035 20.1802 7.11035Z" fill="#292D32"/>
    <path d="M8.49988 6.99023H3.85988C2.62988 6.99023 1.62988 7.99025 1.62988 9.22025C1.62988 10.4502 2.62988 11.4503 3.85988 11.4503H8.49988C9.72988 11.4503 10.7299 10.4502 10.7299 9.22025C10.7299 7.99025 9.72988 6.99023 8.49988 6.99023Z" fill="#292D32"/>
    <path d="M8.79008 1.62012C7.72008 1.62012 6.8501 2.49012 6.8501 3.56012C6.8501 4.63012 7.72008 5.50012 8.79008 5.50012H10.3601C10.5701 5.50012 10.7401 5.33012 10.7401 5.12012V3.55011C10.7301 2.50011 9.86008 1.62012 8.79008 1.62012Z" fill="#292D32"/>
    <path d="M9.43019 13.0303C8.20019 13.0303 7.2002 14.0303 7.2002 15.2603V19.9003C7.2002 21.1303 8.20019 22.1303 9.43019 22.1303C10.6602 22.1303 11.6602 21.1303 11.6602 19.9003V15.2603C11.6602 14.0303 10.6602 13.0303 9.43019 13.0303Z" fill="#292D32"/>
    <path d="M5.36009 13.0303H3.79008C2.72008 13.0303 1.8501 13.9003 1.8501 14.9703C1.8501 16.0403 2.72008 16.9103 3.79008 16.9103C4.86008 16.9103 5.73009 16.0403 5.73009 14.9703V13.4003C5.73009 13.2003 5.56009 13.0303 5.36009 13.0303Z" fill="#292D32"/>
    <path d="M20.1102 12.5703H15.4702C14.2402 12.5703 13.2402 13.5703 13.2402 14.8003C13.2402 16.0303 14.2402 17.0303 15.4702 17.0303H20.1102C21.3402 17.0303 22.3402 16.0303 22.3402 14.8003C22.3402 13.5703 21.3402 12.5703 20.1102 12.5703Z" fill="#292D32"/>
    <path d="M15.18 18.4697H13.61C13.4 18.4697 13.23 18.6397 13.23 18.8497V20.4197C13.23 21.4897 14.1 22.3597 15.17 22.3597C16.24 22.3597 17.11 21.4897 17.11 20.4197C17.12 19.3397 16.25 18.4697 15.18 18.4697Z" fill="#292D32"/>
    </svg>
  )
}

export default SlackIcon