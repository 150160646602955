import { configureStore } from "@reduxjs/toolkit";
import leaderboardSlice from "../slices/leaderboardSlice";
import authenticationSlice from "../slices/authenticationSlice";
import insightsSlice from "../slices/insightsSlice";
import airesolveSlice from "../slices/airesolveSlice";
import dataOptionsReducer from "../slices/dataOptionsSlice";
import mappingStep2Reducer from "../slices/mapping/mappingStep2Slice";
import accordionReducer from "../slices/accordionSlice";
import businessunitsReducer from "../slices/businessunitsSlice";
import comboboxReducer from "../slices/comboboxSlice";
import dataTableReducer from "../slices/dataTableSlice";
import datasourceReducer from "../slices/datasourceSlice";
import listMappingReducer from "../slices/mapping/listMappingSlice";
import mappingStep1Reducer from "../slices/mapping/mappingStep1Slice";
import getmappingsummaryReducer from "../slices/mapping/mappingsummarySlice";
import supportChannelsSlice from "../slices/supportChannels/supportChannelsSlice";
import inboxSlice from "../slices/inboxSlice";
import sharedComponentsSlice from "../slices/sharedComponents/sharedComponentsSlice";
import zendeskSlice from "../slices/zendeskSlice";
import obsSlice from "../slices/obfuscation/obsSlice";


const store = configureStore({
    reducer:{
        authenticationReducer:authenticationSlice,
        leaderboardReducer:leaderboardSlice,
        insightsReducer:insightsSlice,
        airesolveReducer:airesolveSlice,
        businessunitsReducer: businessunitsReducer,
        datasourceReducer: datasourceReducer,
        mappingStep1Reducer: mappingStep1Reducer,
        listMappingReducer: listMappingReducer,
        getmappingsummaryReducer: getmappingsummaryReducer,
        dataTableReducer: dataTableReducer,
        accordionReducer: accordionReducer,
        comboboxReducer: comboboxReducer,
        dataOptionsReducer: dataOptionsReducer,
        mappingStep2Reducer: mappingStep2Reducer,
        supportChannelReducer:supportChannelsSlice,
        inboxReducer:inboxSlice,
        sharedComponentReducer:sharedComponentsSlice,
        zendeskReducer:zendeskSlice,
        obfuscationReducer:obsSlice
    }
})

export default store