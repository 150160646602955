import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../assets/EditIcon";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import loadingAnimation from "../../assets/animations/loading-animation.json";

import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
  Snackbar,
  Tooltip,
  Box,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ArrowLeftIcon from "../../assets/ArrowLeftIcon";
import ArrowRightIcon from "../../assets/ArrowRightIcon";
import DotIcon from "../../assets/DotIcon";
import ComboBox from "../../components/autocomplete/comboBox";
import CustomStepper from "../../components/stepper/customStepper";
import {
  getAllUploadedFiles,
  getFieldMapping,
 
  getMapping,
} from "../../redux/slices/mapping/mappingStep2Slice";
import ResizableTable from "../../components/dataTable/simpleTable";
import { updateFilesPagination } from "../../redux/slices/dataTableSlice";
import moment from "moment/moment";
import { resetStatus } from "../../redux/slices/mapping/mappingStep1Slice";
import FailedIcon from "../../assets/tableIcons/FailedIcon";
import DangerIcon from "../../assets/tableIcons/DangerIcon";
import CompletedIcon from "../../assets/tableIcons/CompletedIcon";
import Lottie from "lottie-react";

function FilesTable() {
  const dispatch = useDispatch();

  const { filesTableDetails } = useSelector(
    (state) => state.mappingStep2Reducer
  );
  const { files_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );


  const cols = useMemo(() =>
    filesTableDetails?.columns
      ? filesTableDetails?.columns?.filter((col)=>col!=="action")?.map((column) => {
        if (column === "parsing_status") {
          return {
            accessorKey: column,
            header: filesTableDetails?.display_map[column],
            Cell: ({ cell }) => {
              const status = cell.getValue();
              let icon = null;

              switch (status) {
                case "Completed":
                  icon = <CompletedIcon />;
                  break;
                case "Pending due to error":
                  icon = <DangerIcon />;
                  break;
                case "NO DATA":
                  icon = <DangerIcon />;
                  break;
                case "Failed":
                  icon = <FailedIcon />;
                  break;
                case "Validation":
                  icon = (
                    <Box width="60px" marginRight="-16px" marginLeft="-24px">
                      <Lottie animationData={loadingAnimation} />
                    </Box>
                  );
                  break;
                case "Processing":
                  icon = (
                    <Box width="60px" marginRight="-16px" marginLeft="-24px">
                      <Lottie animationData={loadingAnimation} />
                    </Box>
                  );
                  break;
                case "Learning Data":
                  icon = (
                    <Box width="60px" marginRight="-16px" marginLeft="-24px">
                      <Lottie animationData={loadingAnimation} />
                    </Box>
                  );
                  break;
                case "Parsing Data":
                  icon = (
                    <Box width="60px" marginRight="-16px" marginLeft="-24px">
                      <Lottie animationData={loadingAnimation} />
                    </Box>
                  );
                  break;
              }

              return (
                <Tooltip title={cell.getValue()}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {icon}
                    </div>
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {status}
                    </div>
                  </div>
                </Tooltip>
              );
            },
          };
        }
          if (column === "created_at") {

            return {
              accessorKey: column,
              header: filesTableDetails?.display_map[column],
              Cell: ({ cell }) => {
                const timestamp = cell.getValue();
                const formattedDate =
                  moment(timestamp).format("MMM DD YYYY HH:mm");

                return (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cell.getValue()?formattedDate:""}
                  </div>
                );
              },
            };
          }
          return {
      
            accessorKey: column,
            header: filesTableDetails?.display_map[column],
            Cell: ({ cell }) => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {cell.getValue()}
              </div>
            ),
         
          };
          
        })
      : []
  );

  const rows = filesTableDetails?.data
    ? filesTableDetails?.data?.map((dataItem) => {
        const row = { id: dataItem.uuid };

        filesTableDetails?.columns?.filter((col)=>col!=="action")?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  const handlePaginationModelChange = (newPaginationModel) => {
    dispatch(updateFilesPagination(newPaginationModel));
  };
  const handleRowClick = (params) => {
   {}
  };

  return (
    <>
      <>
        <div
          className="listmappingWrapper"
          style={{ overflow: "scroll", height: "calc(100vh - 170px)" }}
        >
          <Grid className="listMappingWrap">
            <ResizableTable
              data={rows}
              columns={cols}
              paginationDetails={files_paginationDetails}
              onTablePaginationChange={handlePaginationModelChange}
              onTableRowClick={handleRowClick}
              rowCount={filesTableDetails?.total??0}
            />
          </Grid>
        </div>
      </>
    </>
  );
}

function PdfStepper() {
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const mapping_uuid = params.mapping_uuid;
  const customer_uuid = localStorage.getItem("ci");
  const { files_paginationDetails } = useSelector(
    (state) => state.dataTableReducer
  );

  // State Value
  

  // Redux

  const {
   
    get_mapping,
  } = useSelector((state) => state.mappingStep2Reducer);

  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const action = (
    <Button color="secondary" size="small" onClick={handleSnackBarClose}>
      Okay
    </Button>
  );


  const handleClose = () => {
    navigate(`/${customer_uuid}/mapping`);
  };

  const handlePrevStep = () => {
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/select-datasource`);
  };

  const handleNextStep = () => {
    navigate(`/${customer_uuid}/mapping/${mapping_uuid}/obfuscation`);
  };
  const steps = [
    "Select Data Source",
    "Files Uploaded",
    "Obfuscation",
    "View Mapping",
    "Summary",
  ];



 

 

  useEffect(() => {
    dispatch(getMapping(mapping_uuid));
  }, [mapping_uuid]);

  useEffect(() => {
    let by_filters_and_pagination = {
      mapping_uuid:params.mapping_uuid,
      search:null,
      page_no: parseInt(files_paginationDetails.page) + 1,
      page_size: files_paginationDetails.pageSize,
    };
    dispatch(getAllUploadedFiles(by_filters_and_pagination));
  }, [
  
    files_paginationDetails,
  ]);

  useEffect(()=>{
    dispatch(resetStatus())
  },[ ])
  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          zIndex: 9999,
          position: "absolute",
          top: 0,
          left: 0,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Sidebar */}
        <Grid
          width="232px"
          sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
        ></Grid>

        {/* Main Content */}
        <Grid
          width="calc(100% - 232px)"
          backgroundColor="white"
          position="relative"
          sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
        >
          <Grid
            display="flex"
            justifyContent="space-between"
            marginRight="40px"
          >
            <Typography
              margin="40px 0px 0px 46px"
              color={colors.ascendo.darkblue}
              sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
            >
              Create Mapping
            </Typography>
            <Grid marginTop="20px">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography
            margin="8px 0px 20px 46px"
            color={colors.ascendo.darkblue}
            sx={{ fontSize: "14px", lineHeight: "17px" }}
          >
            Create a mapping that can help discover issue trends.
          </Typography>

          <Grid variant="stepperFixedtop">
            <Divider variant="middle" />
            <CustomStepper steps={steps} activeStep={1} />
          </Grid>

          <Grid variant="stepperScroller">
            <div>
              <Divider variant="middle" />
              <Grid marginX="46px" marginY="24px" marginTop="26px">
                <Typography fontWeight="500" color={colors.ascendo.darkblue}>
                  Mapping
                </Typography>
                <Grid display="flex" marginTop="20px">
                  <Grid display="flex">
                    <Typography fontSize="14px" color={colors.grey.normal}>
                      {get_mapping?.business_unit.name}
                    </Typography>
                    <Grid marginX="16px">
                      <DotIcon />
                    </Grid>
                  </Grid>
                  <Grid display="flex">
                    <Typography fontSize="14px" color={colors.grey.normal}>
                      {get_mapping?.sub_datasource.name}
                    </Typography>
                    <Grid marginX="16px">
                      <DotIcon />
                    </Grid>
                  </Grid>
                  <Grid display="flex">
                    <Typography fontSize="14px" color={colors.ascendo.blue}>
                      Files Uploaded
                    </Typography>
                    <Grid marginX="16px">
                      <DotIcon />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider variant="middle" sx={{ marginTop: "26px" }} />
             
              <FilesTable />

            
            </div>
          </Grid>

          <Divider variant="middle" />

          {/* Continue Button */}
          <Grid
            variant="stepperFixedbottombtn"
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              message={"Field name cannot be empty or duplicate"}
              action={action}
              onClose={handleSnackBarClose}
            />
            <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                startIcon={<ArrowLeftIcon />}
                onClick={handlePrevStep}
              >
                Back
              </Button>
            </Grid>

            <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  backgroundColor: colors.ascendo.yellow,
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                endIcon={
                  <ArrowRightIcon
                    opacity={
                     1
                    }
                  />
                }
                onClick={handleNextStep}
                
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PdfStepper;
