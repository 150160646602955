import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);

function DonutCharts() {
  useEffect(() => {
    Highcharts.chart('donut', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '%',
      },
      
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: [
            {
              enabled: true,
              distance: 20,
            },
            {
              enabled: true,
              distance: -40,
              format: '{point.percentage:.1f}%',
              style: {
                fontSize: '1.2em',
                textOutline: 'none',
                opacity: 0.7,
              },
              filter: {
                operator: '>',
                property: 'percentage',
                value: 10,
              },
            },
          ],
        },
      },
      series: [
        {
          name: 'Percentage',
          colorByPoint: true,
          data: [
            {
              name: '',
              sliced: true,
              selected: true,
              y: 23,
            },
            {
              name: '',
              y: 50,
            },
            
            {
              name: '',
              sliced: true,
              selected: false,
              y: 34,
            },
          ],
        },
      ],
    });
  }, []); ;

  return <div style={{height: "300px", width: "500px"}} id="donut" />;
}

export default DonutCharts;
