import axios from "../../../utils/axios"

export const GetGenAiSolution = async (selected_text,options) => {
    let response = await axios(
      process.env.REACT_APP_DATA_INTEGRATION + `gen_ai/${localStorage.getItem('ci')}/solution`,
      {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data:{
            selected_text,
            options,
            page:0
        }
      }
    );
    return { data: response.data, status: response.status };
  };
  export const AddToKnowledgeBase = async (business_unit_uuid,title,description,product) => {
    let response = await axios(
      process.env.REACT_APP_DATA_INTEGRATION + `gen_ai/${localStorage.getItem('ci')}/${business_unit_uuid}/add_to_knowledge_base`,
      {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        data:{
          title,
          description,
          product
        }
      }
    );
    return { data: response.data, status: response.status };
  };