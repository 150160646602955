import axios from "../../../utils/axios";

export const GetFieldMapping = async (mapping_uuid) => {
  let url =   process.env.REACT_APP_DATA_INTEGRATION +`mapping/${localStorage.getItem(
    "ci"
  )}/${mapping_uuid}/field_mapping`;

  // Check if the condition is true
  if (
    window.location.pathname.includes("fields-files") ||
    window.location.pathname.includes("field-mapping")||
    window.location.pathname.includes("all-requests")
  ) {
    // Add the query parameter if the condition is true
    url += "?is_fieldmapping=true";
  }

  let response = await axios(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
  });
  return { data: response.data, status: response.status };
};

export const UpdateCustomFieldMapping = async (
  field_mapping_uuid,
  custom_field
) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `field_mapping/${localStorage.getItem(
        "ci"
      )}/${field_mapping_uuid}/name?custom_field=${custom_field}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const CreateCustomField = async (mapping_uuid, custom_field) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `field_mapping/${localStorage.getItem("ci")}`,

    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        mapping_uuid: mapping_uuid,
        custom_field: custom_field,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const DeleteCustomFieldMapping = async (field_mapping_uuids) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `field_mapping/${localStorage.getItem(
        "ci"
      )}?field_mapping_uuids=${field_mapping_uuids}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const UpdateFieldMapping = async (
  field_mapping_uuid,
  source_field_uuid
) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `field_mapping/${localStorage.getItem(
        "ci"
      )}/${field_mapping_uuid}?source_field_uuid=${source_field_uuid}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const CreateMapping = async (
  sub_datasource_uuid,
  business_unit_uuid,
  name,
  module_uuid
) => {
  const response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `mapping/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        module_uuid: module_uuid,
        sub_datasource_uuid: sub_datasource_uuid,
        business_unit_uuid: business_unit_uuid,
        name: name,
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const UpdateMapping = async (
  sub_datasource_uuid,
  business_unit_uuid,
  name,

  mapping_uuid,
  module_uuid
) => {
  const response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `mapping/${localStorage.getItem("ci")}/${mapping_uuid}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
      data: {
        module_uuid: module_uuid,

        sub_datasource_uuid: sub_datasource_uuid,
        business_unit_uuid: business_unit_uuid,
        name: name,
      },
    }
  );

  return { data: response.data, status: response.status };
};

export const GetMapping = async (mapping_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `mapping/${localStorage.getItem("ci")}/${mapping_uuid}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const DeleteMapping = async (customer_uuid, mapping_uuids) => {
  let url =
    process.env.REACT_APP_DATA_INTEGRATION +
    `mapping/${customer_uuid}`;

  for (let i = 0; i < mapping_uuids.length; i++) {
    url += `${i===0?"?":"&"}mapping_uuids=${mapping_uuids[i]}`;
  }
  let response = await axios(url, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      accept: "application/json,text/plain",
      "Content-Type": "application/json",
    },
  });
  return { data: response.data, status: response.status };
};

export const UpdateSummaryForSubDatasource = async (sub_datasource_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `sub_datasource/${localStorage.getItem(
        "ci"
      )}/summary/${sub_datasource_uuid}`,

    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const UpdateSuggestionForSubDatasource = async (sub_datasource_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `sub_datasource/${localStorage.getItem(
        "ci"
      )}/intent_group/${sub_datasource_uuid}`,

    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};
