import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Select from "@mui/material/Select";
import StatusAvailable from "../../assets/images/status_available.svg";
import StatusBusy from "../../assets/images/status_busy.png";
import StatusAway from "../../assets/images/status_away.svg";
import StatusDND from "../../assets/images/status_do_not_disturb.png";
import StatusOffline from "../../assets/images/status_appear_offline.svg";
import MenudotIcon from "../../assets/menudotIcon";


import "./sidenav.scss";
import ProfileIcon from "../../assets/profileIcon";
import VocIcon from "../../assets/vocIcon";
import DummyIcon from "../../assets/dummyIcon";
import InboxIcon from "../../assets/inboxIcon";

import SettingsIcon from "@mui/icons-material/Settings";
import {
  Analytics,
  Close,
  DeleteOutline,
  LegendToggle,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import InsightsIcon from "../../assets/insightsIcon";
import AiresolveIcon from "../../assets/airesolveIcon";
import SparesIcon from "../../assets/sparesIcon";
import AscendoIcon from "../../assets/AscendoIcon";
import SupportchannelIcon from "../../assets/supportchannelIcon";
import MappingIcon from "../../assets/MappingIcon copy";
import { useDispatch } from "react-redux";
import { getLogInfo } from "../../redux/slices/authenticationSlice";
import Snackbar from "@mui/material/Snackbar";

import Popover from "@mui/material/Popover";

import {
  SwipeableDrawer,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  FormControl,
  IconButton,
  Typography,
  Avatar,
  hexToRgb,
} from "@mui/material";
import { useState, useEffect } from "react";
import { getAllModuleByUserUUID } from "../../redux/slices/leaderboardSlice";
import { useSelector } from "react-redux";
import AibotIcon from "../../assets/AibotIcon";
import SettingIcon from "../../assets/SettingIcon";
import SlackIcon from "../../assets/SlackIcon";
import BackArrowIcon from "../../assets/BackArrowIcon";
import {
  deleteCustomer,
  getAllAvailability,
} from "../../redux/slices/sharedComponents/sharedComponentsSlice";
import HelpIcon from "../../assets/HelpIcon";
import LogoutIcon from "../../assets/LogoutIcon";
import AgentRoutingIcon from "../../assets/AgentRoutingIcon";
import {
  getAllBellNotification,
  updateUserAvailability,
} from "../../redux/actions/notification/notificationActions";
import NotificationsIcon from "../../assets/NotificationBingIcon";
import { color } from "highcharts";
import { updateSelectedTab, updateTabs } from "../../redux/slices/inboxSlice";

const drawerWidth = 200;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function transform(value) {
  if (value) {
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    if (seconds < 29) {
      return "Just now";
    }

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let counter;
    for (const i in intervals) {
      counter = Math.floor(seconds / intervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          return counter + " " + i + " ago"; // singular (1 day ago)
        } else {
          return counter + " " + i + "s ago"; // plural (2 days ago)
        }
      }
    }
  }
  return value;
}
function stringAvatar(name) {
  let initials = name ? name.split(" ")[0][0] : ""; // First initial

  // Add second initial if it exists
  if (name && name.split(" ")[1]) {
    initials += name.split(" ")[1][0];
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 32,
      height: 32,
      fontSize: 14,
    },
    children: initials,
  };
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    width: `calc(100% - 0)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MiniDrawer() {
  const theme = useTheme();
  const colors = theme.palette;
  const [open, setOpen] = useState(true);
  const { availabilities, notifications } = useSelector(
    (state) => state.sharedComponentReducer
  );
  const { allTabs } = useSelector((state) => state.inboxReducer);
  const [iframeOpen, setIframeOpen] = useState(false);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const currentUrl = window.location.href;
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  useEffect(() => {
    dispatch(getLogInfo("log"));
  }, []);

  // Split the URL by '/'
  const urlParts = currentUrl.split("/");

  const currentPage = urlParts[4];
  const currentSubPage = urlParts[5]; // Adjust the index as needed

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleChange = (event) => {
    setAvailability(event.target.value);
    dispatch(updateUserAvailability(event.target.value));
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userDetails = JSON.parse(localStorage.getItem("ud"));
  const moduleDetails = JSON.parse(localStorage.getItem("md"))?.map(
    (item) => item.name
  );
  const customer_uuid = localStorage.getItem("ci");
  const user_uuid = localStorage.getItem("ui");
  const [availability, setAvailability] = useState(
    userDetails?.availability ?? ""
  );
  const handleOpenInteractionTab = (tab) => {
    if (!allTabs.some((item) => item.uuid === tab.interaction_uuid))
      dispatch(
        updateTabs([
          ...allTabs,
          {
            ...tab,
            uuid: tab.interaction_uuid,
            name: tab.description.split(":")[0],
          },
        ])
      );
    dispatch(updateSelectedTab(tab.interaction_uuid));
    navigate(`/${localStorage.getItem("ci")}/inbox`);
    setNotificationOpen(false);
  };

  const getAgentStatusImage = (value) => {
    if (value === "available") {
      return StatusAvailable;
    } else if (value === "busy") {
      return StatusBusy;
    } else if (value === "do_not_disturb") {
      return StatusDND;
    } else if (["appear_away", "be_right_back"].includes(value)) {
      return StatusAway;
    } else if (value === "appear_offline") {
      return StatusOffline;
    } else {
      return "";
    }
  };

  const [anchorEle, setAnchorEle] = React.useState(null);

  const handleClickpop = (event) => {
    setAnchorEle(event.currentTarget);
  };

  const handleClosepop = () => {
    setAnchorEle(null);
  };

  const openpop = Boolean(anchorEle);
  const id = openpop ? "simple-popover" : undefined;

  const { userdetails } = useSelector((state) => state.authenticationReducer);

  useEffect(() => {
    if (customer_uuid && user_uuid) {
      dispatch(getAllModuleByUserUUID({ customer_uuid, user_uuid }));
    }
    dispatch(getAllAvailability());
  }, [customer_uuid, user_uuid]);

  useEffect(() => {
    if (userdetails?.availability) {
      setAvailability(userdetails?.availability);
    }
  }, [userdetails?.availability]);

  useEffect(() => {
    if (customer_uuid && user_uuid) {
      dispatch(getAllBellNotification());
    }
  }, [customer_uuid, user_uuid]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <div className="headerWrap">
          <div className="headerLeftwrap">
            <Toolbar sx={{ display: "flex", flexDirection: "row" }}>

              <div className="menuDots">
                {/* <Button aria-describedby={id} variant="contained" onClick={handleClickpop}>
                  <MenudotIcon />
                </Button> */}
                <Popover
                  id={id}
                  open={openpop}
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: 60, left: 0 }}
                  anchorEle={anchorEle}
                  onClose={handleClosepop}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >

                  <div className="subMenu">

                    <Grid container className="subGrid">
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="centerFlex">
                          <DummyIcon /> Support Channel
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Popover>
              </div>

              <AscendoIcon />
            </Toolbar>
          </div>
          <div className="headerRight">
            {userDetails?.availability && (
              <Grid width={"200px"} mr="16px">
                <FormControl fullWidth>
                  <Select
                    value={availability}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      "& .MuiSvgIcon-root": { fill: "white", marginTop: "4px" },
                      color: "white",
                      "& .MuiOutlinedInput-input": {
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      },
                    }}
                  >
                    {availabilities.map((item) => (
                      <MenuItem
                        value={item.uuid}
                        key={item.uuid}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          height={"14px"}
                          width={"14px"}
                          src={getAgentStatusImage(item.value)}
                        ></img>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {userDetails?.permissions?.includes("rp_help") && (
              <Tooltip title="Help">
                {" "}
                <div onClick={() => setIframeOpen(true)}>
                  <HelpIcon />
                </div>
              </Tooltip>
            )}
            {/* <div onClick={() => setNotificationOpen(true)}>
              <NotificationsIcon />
            </div> */}

            <SettingsIcon onClick={handleClick} size="small" sx={{ ml: 2 }} />
          </div>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open1}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              className="menuWrap"
              onClick={() => {
                window.open(
                  process.env.REACT_APP_ADMIN +
                    `${localStorage.getItem(
                      "ci"
                    )}/validate/${localStorage.getItem(
                      "ui"
                    )}/${localStorage.getItem("at")}/${localStorage.getItem(
                      "rt"
                    )}`
                );
                handleClose();
              }}
            >
              <div className="settingWrap">
                <div className="imageWrap">
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                </div>

                <div className="textWrap">
                  <p> Administration</p>
                  <span className="smallText">
                    {" "}
                    Add users, groups, roles and manage access requests.{" "}
                  </span>
                </div>
              </div>
            </MenuItem>
            {userDetails?.permissions?.includes("super_admin") && (
              <MenuItem
                className="menuWrap"
                onClick={() => {
                  setDialogOpen(true);
                  handleClose();
                }}
              >
                <div className="settingWrap">
                  <div className="imageWrap">
                    <ListItemIcon>
                      <DeleteOutline fontSize="small" />
                    </ListItemIcon>
                  </div>

                  <div className="textWrap">
                    <p> Delete Customer</p>
                    <span className="smallText">
                      {" "}
                      Deleting this customer will erase all the data and remove
                      accounts added.{" "}
                    </span>
                  </div>
                </div>
              </MenuItem>
            )}
          </Menu>
        </div>
        <div className="headerRightwrap">
          <Box sx={{ width: "5%" }}></Box>
        </div>
      </AppBar>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the customer?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 2.5 }}>
          <Button
            variant="ascendo"
            width="75px"
            sx={{
              color: "white",
              backgroundColor: "red",
              "&:hover": {
                color: "rgba(7, 25, 66)",
              },
            }}
            onClick={() => setDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="ascendo"
            width="75px"
            onClick={() => {
              setDialogOpen(false);
              dispatch(deleteCustomer());
              window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${process.env.REACT_APP_WP}`;
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className="outerDrawer">
        <div
          className="arrows"
          onClick={handleDrawerToggle}
          sx={{ marginRight: 5, ...(open && { display: "block" }) }}
        >
          {" "}
          <Tooltip title={open ? "Collapse" : "Expand"}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Tooltip>
        </div>
        <Drawer variant="permanent" open={open} className="menuWrap">
          <DrawerHeader></DrawerHeader>

          <Divider />
          <List>
            <mat-card class="mat-card mat-focus-indicator user-details-card w-100">
              <mat-card-header class="mat-card-header ng-star-inserted">
                <div mat-card-avatar="" class="mat-card-avatar header-image">
                  <ProfileIcon />
                </div>

                <div class="mat-card-header-text">
                  <mat-card-title
                    class="mat-tooltip-trigger mat-card-title username"
                    aria-describedby="cdk-describedby-message-13"
                    cdk-describedby-host=""
                  >
                    {userDetails?.username ?? userdetails?.username}
                  </mat-card-title>
                  <mat-card-subtitle class="mat-card-subtitle orgname">
                    {userDetails?.customer?.name ?? userdetails?.customer?.name}
                  </mat-card-subtitle>
                </div>
              </mat-card-header>
            </mat-card>
          </List>
          {currentPage !== "support-channel" && (
            <List>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigate(`/${localStorage.getItem("ci")}/home`)}
              >
                <ListItemButton
                  className="menuList"
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 2,
                    color: colors.ascendo.darkblue,
                    backgroundColor:
                      currentPage === "home"
                        ? colors.ascendo.yellow
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        currentPage === "home"
                          ? colors.ascendo.yellow
                          : "transparent",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <DummyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Home"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              {userDetails?.permissions?.includes("insights_v2") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(`/${localStorage.getItem("ci")}/insights`)
                  }
                >
                  <ListItemButton
                    className="menuList"
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2,
                      color: colors.ascendo.darkblue,
                      backgroundColor:
                        currentPage === "insights"
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentPage === "insights"
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Insights"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {userDetails?.permissions?.includes(
                "resolution_prediction_v2"
              ) && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(`/${localStorage.getItem("ci")}/ai-resolve`)
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        currentPage === "ai-resolve" ||
                        currentPage?.includes("resolution-prediction-v2")
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentPage === "ai-resolve" ||
                          currentPage?.includes("resolution-prediction-v2")
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AiresolveIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"AI Resolve"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {userDetails?.permissions?.includes("auto_categorization") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    window.open(
                      `https://${userDetails?.customer?.name}.${
                        process.env.REACT_APP_VOC
                      }/${localStorage.getItem(
                        "ci"
                      )}/trending/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    )
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <VocIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Voice of the Customer"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {userDetails?.permissions?.includes("root_cause_analysis") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    window.open(
                      `https://${userDetails?.customer?.name}.${
                        process.env.REACT_APP_RCA
                      }/${localStorage.getItem(
                        "ci"
                      )}/rca/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    )
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Analytics sx={{ color: "#071942", opacity: "0.8" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Root Cause Analysis"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {userDetails?.permissions?.includes("inbox_v2") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(`/${localStorage.getItem("ci")}/inbox`)
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        currentPage === "inbox"
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentPage === "inbox"
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Inbox"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {userDetails?.permissions?.includes("mapping") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(`/${localStorage.getItem("ci")}/mapping`)
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        currentPage === "mapping"
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentPage === "mapping"
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <MappingIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Mapping"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {userDetails?.permissions?.includes("next_gen_spares") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    window.open(
                      `https://${userDetails?.customer?.name}.${
                        process.env.REACT_APP_SPARES
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    )
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <SparesIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Cognitive Spares"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {userDetails?.modules?.find(
                (item) => item.name === "Agent Routing"
              )?.is_active && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    window.open(
                      `https://${userDetails?.customer?.name}.${
                        process.env.REACT_APP_AGENT_ROUTING
                      }/${localStorage.getItem(
                        "ci"
                      )}/authentication/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    )
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AgentRoutingIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Agent Routing"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {moduleDetails?.includes("log") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    window.open(
                      `${
                        process.env.REACT_APP_LOG
                      }validate-user/${localStorage.getItem(
                        "ci"
                      )}/${localStorage.getItem("li")}/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    )
                  }
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      color: colors.ascendo.darkblue,

                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <LegendToggle sx={{ color: colors.ascendo.darkblue }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Log Analysis"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {userDetails?.permissions?.includes("support_channel") &&
                (userDetails?.permissions?.includes("ai_bot") ||
                  userDetails?.permissions?.includes("slack")) && (
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      localStorage.setItem(
                        "lastVisited",
                        window.location.pathname
                      );
                      navigate(
                        `/${localStorage.getItem("ci")}/support-channel/aibots`
                      );
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        color: colors.ascendo.darkblue,

                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SupportchannelIcon
                          sx={{ color: colors.ascendo.darkblue }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Support Channel"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
            </List>
          )}
          {currentPage === "support-channel" && (
            <List>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() =>
                  navigate(`${localStorage.getItem("lastVisited")}`)
                }
              >
                <ListItemButton
                  className="menuList"
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 2,
                    color: colors.ascendo.darkblue,
                    backgroundColor:
                      currentPage === "home"
                        ? colors.ascendo.yellow
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        currentPage === "home"
                          ? colors.ascendo.yellow
                          : "transparent",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <BackArrowIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Back to Menu"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              {userDetails?.permissions?.includes("ai_bot") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(
                      `/${localStorage.getItem("ci")}/support-channel/aibots`
                    )
                  }
                >
                  <ListItemButton
                    className="menuList"
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2,
                      color: colors.ascendo.darkblue,
                      backgroundColor:
                        currentSubPage === "aibots"
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentSubPage === "aibots"
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AibotIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"AI Bot"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {userDetails?.permissions?.includes("slack") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(
                      `/${localStorage.getItem("ci")}/support-channel/slackbot`
                    )
                  }
                >
                  <ListItemButton
                    className="menuList"
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2,
                      color: colors.ascendo.darkblue,
                      backgroundColor:
                        currentSubPage === "slackbot"
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentSubPage === "slackbot"
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <SlackIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Slack Bot"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {userDetails?.permissions?.includes("ai_bot") && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(
                      `/${localStorage.getItem("ci")}/support-channel/settings`
                    )
                  }
                >
                  <ListItemButton
                    className="menuList"
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2,
                      color: colors.ascendo.darkblue,
                      backgroundColor:
                        currentSubPage === "settings"
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentSubPage === "settings"
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <SettingIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Settings"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          )}

          <List sx={{ height: "100vh" }}>
            {["Logout"].map((text, index) => (
              <ListItem
                onClick={() => navigate("/logout")}
                key={text}
                disablePadding
                sx={{ display: "block", position: "absolute", bottom: "10px" }}
              >
                <Divider className="borderBottom" />
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index % 2 === 0 ? <LogoutIcon /> : <LogoutIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {iframeOpen && (
          <div className="helpAibot">
            <SwipeableDrawer
              anchor={"right"}
              open={iframeOpen}
              sx={{ zIndex: "9999" }}
              onClose={() => setIframeOpen(false)}
            >
              <div className="aibotClose" onClick={() => setIframeOpen(false)}>
                <Close />
              </div>
              <iframe
                style={{ height: "100vh", width: "465px" }}
                src={`https://support.aibot.ascendo.ai/?api_key=7fa76852-1c8f-4c57-b9d7-28f3cfc6307f&user_email=${userDetails?.email}&username=${userDetails?.username}`}
              ></iframe>
            </SwipeableDrawer>
          </div>
        )}
        {notificationOpen && (
          <div className="helpAibot">
            <SwipeableDrawer
              anchor={"right"}
              sx={{ width: "400px" }}
              open={notificationOpen}
              sx={{
                zIndex: "9999",
                "& .MuiPaper-root.MuiDrawer-paper ": {
                  borderRadius: "8px 0px 0px 0px",
                },
              }}
              onClose={() => setNotificationOpen(false)}
            >
              <div
                className="notificationClose"
                onClick={() => setNotificationOpen(false)}
              >
                <IconButton size="small">
                  <Close fontSize="14px" />
                </IconButton>
              </div>
              <div style={{ height: "100vh", width: "580px" }}>
                <Grid p="18px">
                  <Typography
                    color={colors.ascendo.darkblue}
                    fontWeight={600}
                    fontSize={"18px"}
                  >
                    Notifications
                  </Typography>
                  <Grid
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap="16px"
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: colors.ascendo.blue,
                        cursor: "pointer",
                      }}
                    >
                      Clear All
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: colors.ascendo.blue,
                        cursor: "pointer",
                      }}
                    >
                      Mark All As Seen
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      color={colors.ascendo.darkblue}
                      fontWeight={600}
                      fontSize={"15px"}
                    >
                      TODAY
                    </Typography>
                    {notifications
                      .filter((item) => {
                        const createdAtDate = new Date(item.created_at);
                        const today = new Date();
                        return (
                          createdAtDate.toDateString() === today.toDateString()
                        );
                      })
                      .map((nt) => (
                        <Grid
                          key={nt.uuid}
                          onClick={() => handleOpenInteractionTab(nt)}
                          sx={{
                            mt: "8px",
                            p: "12px",
                            display: "flex",
                            gap: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <Avatar
                            {...stringAvatar(nt.sender_username.toUpperCase())}
                          />
                          <Grid sx={{ flex: "1 1 0" }}>
                            <Grid
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: "4px",
                                mt: "2px",
                              }}
                            >
                              <Grid
                                sx={{
                                  color: colors.ascendo.darkblue,
                                  fontSize: "12px",
                                  p: "2px 4px",
                                  backgroundColor: "rgb(86, 199, 250)",
                                  borderRadius: "6px",
                                }}
                              >
                                {nt.module}
                              </Grid>
                              <Typography
                                sx={{ color: "#949394", fontSize: "11px" }}
                              >
                                {transform(nt.created_at)}
                              </Typography>
                            </Grid>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: colors.ascendo.darkblue,
                              }}
                            >
                              {nt.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: colors.ascendo.darkblue,
                              }}
                            >
                              {nt.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    <Typography
                      color={colors.ascendo.darkblue}
                      fontWeight={600}
                      fontSize={"15px"}
                    >
                      EARLIER
                    </Typography>
                    {notifications
                      .filter((item) => {
                        const createdAtDate = new Date(item.created_at);
                        const today = new Date();
                        return (
                          createdAtDate.toDateString() !== today.toDateString()
                        );
                      })
                      .map((nt) => (
                        <Grid
                          key={nt.uuid}
                          onClick={() => handleOpenInteractionTab(nt)}
                          sx={{
                            mt: "8px",
                            p: "12px",
                            backgroundColor: "#eaeaea",
                            display: "flex",
                            gap: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <Avatar
                            {...stringAvatar(nt.sender_username.toUpperCase())}
                          />
                          <Grid sx={{ flex: "1 1 0" }}>
                            <Grid
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: "4px",
                                mt: "2px",
                              }}
                            >
                              <Grid
                                sx={{
                                  color: colors.ascendo.darkblue,
                                  fontSize: "12px",
                                  p: "2px 4px",
                                  backgroundColor: "rgb(86, 199, 250)",
                                  borderRadius: "6px",
                                }}
                              >
                                {nt.module}
                              </Grid>
                              <Typography
                                sx={{ color: "#949394", fontSize: "11px" }}
                              >
                                {transform(nt.created_at)}
                              </Typography>
                            </Grid>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: colors.ascendo.darkblue,
                              }}
                            >
                              {nt.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: colors.ascendo.darkblue,
                              }}
                            >
                              {nt.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </div>
            </SwipeableDrawer>
          </div>
        )}
      </div>
    </Box>
  );
}
