import React, { useEffect, useMemo, useRef } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  Card,
  Divider,
  TextField,
  Grid,
  Tabs,
  Tab,
  Autocomplete,
  Checkbox,
  IconButton,
  InputAdornment,
  CircularProgress,
  Box,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
//new
import "./inboxPredictionView.scss";

import EastIcon from "@mui/icons-material/East";
import { useState } from "react";
import DummyIcon from "../../assets/dummyIcon";
import BuIcon from "../../assets/buIcon";
import UpvoteIcon from "../../assets/UpvoteIcon";
import DownvoteIcon from "../../assets/DownvoteIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelGenerateAnswer,
  editTab,
  generateAnswer,
  getAllBuHavingPgUnderUser,
  getAllPgInBuForUser,
  getDocument,
  getSubDatasourceByBu,
  predictDocument,
  toggleDocumentVote,
  updateDetail,
  updateDetailedDocument,
  updatePredictedDocuments,
  updateQuickView,
  updateSelectedTab,
  updateTabs,
} from "../../redux/slices/airesolveSlice";
import {
  ChevronRight,
  Close,
  CloseSharp,
  ExpandLess,
  ExpandMore,
  Flag,
  Search,
  ViewQuilt,
} from "@mui/icons-material";
import MyMultiSelect from "../../components/dropdown/myMultiSelect";
import { GetAllPgInBuForUser } from "../../redux/actions/identity/organizationActions";
import Avatar from "@mui/material/Avatar";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTheme } from "@emotion/react";
import SearchNormalIcon from "../../assets/SearchNormalIcon";
import { color } from "highcharts";
import Typewriter from "typewriter-effect";
import PreviewIcon from "../../assets/previewIcon";
import GuideIcon from "../../assets/guideIcon";
import GenAiIcon from "../../assets/GenAiIcon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Sebastin from "../../assets/images/sebastin.jpeg"
import Morgan from "../../assets/images/morgan.jpeg"
import Jethrow from "../../assets/images/jethrow.jpeg"
import Shaun from "../../assets/images/shaun.jpeg"

const avatars = [Sebastin,Morgan,Jethrow,Shaun]

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  let initials = name ? name.split(" ")[0][0] : ""; // First initial

  // Add second initial if it exists
  if (name && name.split(" ")[1]) {
    initials += name.split(" ")[1][0];
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 24,
      height: 24,
      fontSize: 12,
    },
    children: initials,
  };
}

function MappedCard({ label, value, cardId, index }) {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      // Handle the case where value is an array
      return value.map((val, index) =>
        Object.entries(val).map(([key, value]) => (
          <div>
            {value !== null && (
              <p key="index">
                <span class="text-tab">
                  <span class="fav-img"></span>
                  {key}
                </span>

                <span
                  class="answer-tab"
                  dangerouslySetInnerHTML={{
                    __html: value,
                  }}
                />
              </p>
            )}
          </div>
        ))
      );
    }
    // If it's neither an array nor an object, just return the value
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      ></p>
    );
  };
  return (
    <Draggable draggableId={cardId} index={index}>
      {(provided) => (
        <div
          className="listEdit"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span class="text-tab">
              <span class="fav-img"></span>
              {label}
            </span>

            <span class="answer-tab">{renderValue(value)}</span>
          </p>
        </div>
      )}
    </Draggable>
  );
}
function CustomCard({ label, value, cardId, index }) {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      // Handle the case where value is an array
      return value.map((val, index) =>
        Object.entries(val).map(([key, value]) => (
          <div>
            {value !== null && (
              <p key="index">
                <span class="text-tab">
                  <span class="fav-img"></span>
                  {key}
                </span>

                <span
                  class="answer-tab"
                  dangerouslySetInnerHTML={{
                    __html: value,
                  }}
                />
              </p>
            )}
          </div>
        ))
      );
    }
    // If it's neither an array nor an object, just return the value
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      ></p>
    );
  };
  return (
    <Draggable draggableId={cardId} index={index}>
      {(provided) => (
        <div
          className="listEdit"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <p>
            <span class="text-tab">
              <span class="fav-img"></span>
              {label}
            </span>

            <span class="answer-tab">{renderValue(value)}</span>
          </p>
        </div>
      )}
    </Draggable>
  );
}
function MappedList({ cards, listId }) {
  return (
    <Droppable droppableId={listId} type="CARD">
      {(provided) => (
        <ul
          className="cardsEdit"
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "300px",
          }}
        >
          {cards.map((card, cardIndex) => (
            <MappedCard
              key={card.key}
              label={card.key}
              value={card.value}
              cardId={card.key}
              index={cardIndex}
            />
          ))}

          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
}
function CustomList({ cards, listId }) {
  return (
    <Droppable droppableId={listId} type="CARD">
      {(provided) => (
        <ul
          className="cardsEdit"
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "300px",
          }}
        >
          {cards
            .filter((card) => card?.key !== "Part")
            .map((card, cardIndex) => (
              <CustomCard
                key={card.key}
                label={card.key}
                value={card.value}
                cardId={card.key}
                index={cardIndex}
              />
            ))}

          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
}
function InboxPredictions({ props, handleOpenGenAi }) {
  const dispatch = useDispatch();
  const [previewDocument, setPreviewDocument] = useState();
  const [error, setError] = useState(false);
  const [editLayout, setEditLayout] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("ud"));

  const { business_units, allTabs, selectedTab, detail } = useSelector(
    (state) => state.airesolveReducer
  );

  const theme = useTheme();
  const colors = theme.palette;
  const activeTab = allTabs.find((tab) => tab.uuid === selectedTab);
  const predicted_documents = activeTab.predicted_documents;
  const detailed_document = activeTab.detailed_document;

  const [animationComplete, setAnimationComplete] = useState(false);
  const scrollRef = useRef();
  useEffect(() => {
    dispatch(getAllBuHavingPgUnderUser());
  }, []);

  useEffect(() => {
    if (business_units.some((bu) => bu.uuid === selectedTab)) {
      if (
        allTabs.find((tab) => tab.uuid === selectedTab).datasources?.length ===
        0
      )
        dispatch(getSubDatasourceByBu(selectedTab));
      if (
        allTabs.find((tab) => tab.uuid === selectedTab).product_groups
          ?.length === 0
      )
        dispatch(getAllPgInBuForUser(selectedTab));
    } else if (selectedTab !== "home28082001" && !activeTab.detailed_document) {
      let data = {
        business_unit_uuid: activeTab?.business_unit_uuid,
        sub_datasource_uuid: activeTab?.sub_datasource_uuid,
        document_id: activeTab?.document_id,
        query: activeTab?.query,
        product_group: activeTab?.product_group?.name,
      };
      dispatch(getDocument(data));
    }
    setPreviewDocument(null);
  }, [selectedTab]);

  const handlePredict = (api_trigger, guide_filter) => {
    setPreviewDocument(null);
    dispatch(updateDetail({ api_trigger, guide_filter }));
    let data = {
      business_unit_uuid: activeTab.uuid,
      bu_name:activeTab?.name,
      product_group: activeTab.selected_pg.name,
      query: activeTab.query,
      
      sub_datasource_uuids: activeTab.selected_ds.map((ds) => ds?.uuid),
      api_trigger: api_trigger,
      guide_filter: guide_filter ?? {},
      request_channel: "Inbox",
    };
    dispatch(predictDocument(data));
  };

  useEffect(() => {
    if (props) {
      const bu = business_units.find((bu) => bu.name === props?.business_unit);
      if (bu) {
        if (!allTabs.some((tab) => tab.uuid === bu?.uuid)) {
          dispatch(
            updateTabs([
              ...allTabs,
              {
                ...bu,
                query: props?.query,
                req_query: props?.query,
                datasources: [],
                selected_ds: [],
                product_groups: [],
                selected_pg: null,
                default_pg: props?.product_group,
                is_guides_open: false,
                guides: {},
                selected_guides: {},
                is_preview_open: false,
                type: "bu",
                callPredict: true,
                read_documents: props?.read_documents,
              },
            ])
          );
        } else {
          const selectedPG = allTabs
            .find((tab) => tab.uuid === bu.uuid)
            .product_groups.find((pg) => pg.name === props?.product_group);
          dispatch(
            editTab({
              tab: bu.uuid,
              data: {
                query: props?.query,
                req_query: props?.query,

                selected_pg: {
                  ...selectedPG,
                  label: selectedPG?.name,
                  id: selectedPG?.uuid,
                },
                default_pg: props?.product_group,
                is_guides_open: false,
                guides: {},
                selected_guides: {},
                is_preview_open: false,
                type: "bu",
                callPredict: true,
                read_documents: props?.read_documents,
              },
            })
          );
        }
        dispatch(updateSelectedTab(bu?.uuid));
      }
    }
  }, [business_units]);
  useEffect(() => {
    if (
      activeTab?.selected_ds?.length > 0 &&
      activeTab?.selected_pg &&
      activeTab?.callPredict
    ) {
      handlePredict("search");
    }
  }, [activeTab?.selected_ds, activeTab?.selected_pg, activeTab?.callPredict]);

  useEffect(() => {
    if (
      activeTab?.call_onfly &&
      JSON?.parse(localStorage.getItem("ud"))?.permissions?.includes(
        "generate_answer"
      )
    ) {
      let data = {
        business_unit_uuid: activeTab.uuid,
        product_group: activeTab.selected_pg.name,
        query: activeTab.req_query ?? activeTab.query,

        sub_datasource_uuids: activeTab.selected_ds.map((ds) => ds?.uuid),
        api_trigger: detail.api_trigger,
        guide_filter: detail.guide_filter ?? {},
        request_channel: "Inbox",
      };
      setAnimationComplete(false);
      dispatch(generateAnswer(data));
    }
  }, [activeTab?.call_onfly]);

  const handleToggleVote = (
    document_id,
    sub_datasource_uuid,
    vote_direction
  ) => {
    let vote_details = {
      business_unit_uuid: activeTab.uuid,
      sub_datasource_uuid: sub_datasource_uuid,
      document_id: document_id,
      query: activeTab.query,
      product_group: activeTab.selected_pg.name,
      vote_direction: vote_direction,
    };
    const updatedDocument = predicted_documents?.map((tab) => {
      if (tab._id === document_id) {
        return {
          ...tab,
          user_vote_direction:
            vote_direction === tab.user_vote_direction ? null : vote_direction,
          up_vote_count:
            vote_direction === "UP"
              ? tab.user_vote_direction === "UP"
                ? tab.up_vote_count - 1
                : tab.up_vote_count + 1
              : vote_direction === "DOWN"
              ? tab.user_vote_direction === "UP"
                ? tab.up_vote_count - 1
                : tab.up_vote_count
              : tab.up_vote_count,
          down_vote_count:
            vote_direction === "DOWN"
              ? tab.user_vote_direction === "DOWN"
                ? tab.down_vote_count - 1
                : tab.down_vote_count + 1
              : vote_direction === "UP"
              ? tab.user_vote_direction === "DOWN"
                ? tab.down_vote_count - 1
                : tab.down_vote_count
              : tab.down_vote_count,
        };
      } else return tab;
    });

    const updatedTabs = allTabs.map((tab) => {
      if (tab.uuid === selectedTab) {
        return {
          ...tab,
          predicted_documents: updatedDocument,
        };
      }
      if (tab.document_id === document_id) {
        return {
          ...tab,
          detailed_document: {
            ...tab.detailed_document,
            user_vote_direction:
              vote_direction === tab.detailed_document.user_vote_direction
                ? null
                : vote_direction,
            up_vote_count:
              vote_direction === "UP"
                ? tab.detailed_document.user_vote_direction === "UP"
                  ? tab.detailed_document.up_vote_count - 1
                  : tab.detailed_document.up_vote_count + 1
                : vote_direction === "DOWN"
                ? tab.detailed_document.user_vote_direction === "UP"
                  ? tab.detailed_document.up_vote_count - 1
                  : tab.detailed_document.up_vote_count
                : tab.detailed_document.up_vote_count,
            down_vote_count:
              vote_direction === "DOWN"
                ? tab.detailed_document.user_vote_direction === "DOWN"
                  ? tab.detailed_document.down_vote_count - 1
                  : tab.detailed_document.down_vote_count + 1
                : vote_direction === "UP"
                ? tab.detailed_document.user_vote_direction === "DOWN"
                  ? tab.detailed_document.down_vote_count - 1
                  : tab.detailed_document.down_vote_count
                : tab.detailed_document.down_vote_count,
          },
        };
      } else return tab;
    });

    dispatch(updateTabs(updatedTabs));

    dispatch(toggleDocumentVote(vote_details));
  };

  const handleDetailedToggleVote = (
    document_id,
    sub_datasource_uuid,
    vote_direction
  ) => {
    let vote_details = {
      business_unit_uuid: activeTab.business_unit_uuid,
      sub_datasource_uuid: sub_datasource_uuid,
      document_id: document_id,
      query: activeTab.query,
      product_group: activeTab.product_group.name,
      vote_direction: vote_direction,
    };
    const updatedDocument = {
      ...detailed_document,
      user_vote_direction:
        vote_direction === detailed_document.user_vote_direction
          ? null
          : vote_direction,
      up_vote_count:
        vote_direction === "UP"
          ? detailed_document.user_vote_direction === "UP"
            ? detailed_document.up_vote_count - 1
            : detailed_document.up_vote_count + 1
          : vote_direction === "DOWN"
          ? detailed_document.user_vote_direction === "UP"
            ? detailed_document.up_vote_count - 1
            : detailed_document.up_vote_count
          : detailed_document.up_vote_count,
      down_vote_count:
        vote_direction === "DOWN"
          ? detailed_document.user_vote_direction === "DOWN"
            ? detailed_document.down_vote_count - 1
            : detailed_document.down_vote_count + 1
          : vote_direction === "UP"
          ? detailed_document.user_vote_direction === "DOWN"
            ? detailed_document.down_vote_count - 1
            : detailed_document.down_vote_count
          : detailed_document.down_vote_count,
    };

    const updatedTabs = allTabs.map((tab) => {
      if (tab.document_id === document_id) {
        return {
          ...tab,
          detailed_document: updatedDocument,
        };
      }
      if (tab.uuid === activeTab.business_unit_uuid) {
        return {
          ...tab,
          predicted_documents: tab.predicted_documents?.map((pd) => {
            if (pd._id === document_id) {
              return {
                ...pd,
                user_vote_direction:
                  vote_direction === pd.user_vote_direction
                    ? null
                    : vote_direction,
                up_vote_count:
                  vote_direction === "UP"
                    ? pd.user_vote_direction === "UP"
                      ? pd.up_vote_count - 1
                      : pd.up_vote_count + 1
                    : vote_direction === "DOWN"
                    ? pd.user_vote_direction === "UP"
                      ? pd.up_vote_count - 1
                      : pd.up_vote_count
                    : pd.up_vote_count,
                down_vote_count:
                  vote_direction === "DOWN"
                    ? pd.user_vote_direction === "DOWN"
                      ? pd.down_vote_count - 1
                      : pd.down_vote_count + 1
                    : vote_direction === "UP"
                    ? pd.user_vote_direction === "DOWN"
                      ? pd.down_vote_count - 1
                      : pd.down_vote_count
                    : pd.down_vote_count,
              };
            } else return pd;
          }),
        };
      } else return tab;
    });

    dispatch(updateTabs(updatedTabs));
    dispatch(toggleDocumentVote(vote_details));
  };

  useEffect(() => {
    setTimeout(() => {
      if (activeTab?.scrollPosition) {
        scrollRef.current.scrollTop = activeTab?.scrollPosition;
      }
    }, 10);
  }, [activeTab.uuid]);

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      // Handle the case where value is an array
      return value.map((val, index) =>
        Object.entries(val).map(([key, value]) => (
          <div>
            {value !== null && (
              <p key="index">
                <span class="text-tab">
                  <span class="fav-img"></span>
                  {key}
                </span>

                <span
                  class="answer-tab"
                  dangerouslySetInnerHTML={{
                    __html: value,
                  }}
                />
              </p>
            )}
          </div>
        ))
      );
    }
    // If it's neither an array nor an object, just return the value
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      ></p>
    );
  };

  const [board, setBoard] = useState({
    mapped: detailed_document?.quick_view ?? [],
    custom: detailed_document?.custom ?? [],
  });

  const deepCopyBoard = (board) => {
    return {
      mapped: board.mapped.map((item) => ({ ...item })),
      custom: board.custom.map((item) => ({ ...item })),
    };
  };
  useEffect(() => {
    setBoard(
      deepCopyBoard({
        mapped: detailed_document?.quick_view ?? [],
        custom: detailed_document?.custom ?? [],
      })
    );
  }, [detailed_document]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // If there is no destination or the item was dropped back into its original position
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newBoard = deepCopyBoard(board);

    const sourceList = newBoard[source.droppableId];
    const destinationList = newBoard[destination.droppableId];

    // Remove the item from the source list
    const [removed] = sourceList.splice(source.index, 1);

    // Add the item to the destination list
    destinationList.splice(destination.index, 0, removed);

    setBoard(newBoard);
  };

  return (
    <div
      className="airesolveWrap-inbox"
      style={{
        // overflow: "scroll",
        height: "calc(100vh - 70px)",
        marginTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              AI Resolve
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Get solutions and guidance to solve customer issues easily.
            </Typography>
          </div>
        </div>

        <Grid>
          <div sx={{ margin: "10px" }}>
            <Tabs value={selectedTab} centered className="tabmenuWrap">
              {allTabs.map((tab, index) => (
                <Tab
                  className="scrollable"
                  label={
                    <Grid display={"flex"} alignItems={"center"} gap="5px">
                      {tab.uuid !== "home28082001" &&
                        !tab?.logo_uri?.length > 0 && (
                          <Avatar {...stringAvatar(`${tab.name}`)} />
                        )}
                      {tab.uuid !== "home28082001" &&
                        tab.logo_uri?.length > 0 && (
                          <div className="buIcon">
                            <img src={tab.logo_uri} height={24}></img>
                          </div>
                        )}
                      <Typography
                        fontWeight={selectedTab === tab.uuid ? 600 : 400}
                      >
                        {tab.name}
                      </Typography>
                    </Grid>
                  }
                  value={tab.uuid}
                  onClick={() => dispatch(updateSelectedTab(tab.uuid))}
                  icon={
                    tab.uuid !== "home28082001" ? (
                      <Close
                        fontSize="small"
                        onClick={(e) => {
                          const nextIndex =
                            index === allTabs?.length - 1
                              ? index - 1
                              : index + 1;
                          e.stopPropagation();
                          dispatch(updateSelectedTab(allTabs[nextIndex].uuid));

                          dispatch(
                            updateTabs(
                              allTabs.filter((item) => item.uuid !== tab.uuid)
                            )
                          );
                        }}
                      />
                    ) : null
                  }
                  iconPosition="end"
                />
              ))}
            </Tabs>
          </div>
        </Grid>
        <Grid container spacing={2}>
          {selectedTab === "home28082001" &&
            business_units?.map((bu) => (
              <Grid
                item
                key={bu.uuid}
                xs={12}
                md={3}
                sm={3}
                className="cardGrid"
              >
                <Card className="commonCard highlightWrap">
                  <div className="innerWrap">
                    <div className="buWrap">
                      {bu?.logo_uri ? (
                        <div className="buIcon">
                          <img src={bu.logo_uri} height={24}></img>
                        </div>
                      ) : (
                        <div className="buIcon">
                          <BuIcon />
                        </div>
                      )}
                      <div className="buText">{bu.name}</div>
                      {bu.description.length > 0 && (
                        <Tooltip title={bu.description} placement="top">
                          <p className="buDesc">{bu.description}</p>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="buttonWrap">
                    <Button
                      endIcon={<EastIcon />}
                      onClick={() => {
                        if (!allTabs.some((tab) => tab.uuid === bu.uuid)) {
                          dispatch(
                            updateTabs([
                              ...allTabs,
                              {
                                ...bu,
                                query: "",
                                datasources: [],
                                selected_ds: [],
                                product_groups: [],
                                selected_pg: null,
                                is_guides_open: false,
                                guides: {},
                                selected_guides: {},
                                is_preview_open: false,
                                type: "bu",
                              },
                            ])
                          );
                        }
                        dispatch(updateSelectedTab(bu.uuid));
                      }}
                    >
                      Reveal
                    </Button>
                  </div>
                </Card>
              </Grid>
            ))}
          {selectedTab !== "home28082001" &&
            business_units.some((bu) => bu.uuid === selectedTab) &&
            !activeTab.datasources?.length > 0 && (
              <Grid item xs={12} md={12} lg={12}>
                <Card className="commonCard bg-lightbg" elevation={0}>
                  <div className="iconCenter">
                    <DummyIcon />
                    <Typography>
                      No datasource available for prediction. Please complete the
                      setup.
                    </Typography>
                  </div>
                </Card>
              </Grid>
            )}
          {selectedTab !== "home28082001" &&
            business_units.some((bu) => bu.uuid === selectedTab) &&
            activeTab.datasources?.length > 0 && (
              <Grid item xs={12} md={12} lg={12}>
                <Card className="commonCard bg-lightbg" elevation={0}>
                  <div className="searchWrap">
                    <TextField
                      error={error}
                      helperText={error ? "* Query cannot be empty" : ""}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <Grid px="6px" pt="6px">
                            <SearchNormalIcon />
                          </Grid>
                        ),
                        endAdornment: activeTab.query?.length > 0 && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                const updatedTabs = allTabs.map((tab) => {
                                  if (tab.uuid === selectedTab) {
                                    return {
                                      ...tab,
                                      query: "",
                                    };
                                  } else return tab;
                                });
                                dispatch(updateTabs(updatedTabs));
                              }}
                              size="small"
                              sx={{ cursor: "pointer" }}
                            >
                              <CloseSharp
                                fontSize="small"
                                sx={{ color: "grey" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        width: "55%",
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          padding: 0,
                        },
                      }}
                      type="text"
                      placeholder="Describe what are you looking for.."
                      value={activeTab.query}
                      onChange={(e) => {
                        const updatedTabs = allTabs.map((tab) => {
                          if (tab.uuid === selectedTab) {
                            return {
                              ...tab,
                              query: e.target.value,
                            };
                          } else return tab;
                        });
                        dispatch(updateTabs(updatedTabs));
                        if (e.target.value?.length > 0) setError(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (
                            activeTab.query?.length > 0 &&
                            activeTab.selected_ds?.length > 0
                          ) {
                            const updatedTabs = allTabs.map((tab) => {
                              if (tab.uuid === selectedTab) {
                                return {
                                  ...tab,
                                  req_query: e.target.value,
                                  selected_guides: {},
                                };
                              } else return tab;
                            });
                            dispatch(updateTabs(updatedTabs));

                            handlePredict("search");
                          } else setError(true);
                        }
                      }}
                    ></TextField>

                    <Grid width="280px">
                      <MyMultiSelect
                        options={activeTab.datasources.map((item) => ({
                          ...item,
                          id: item.uuid,
                          label: item.name,
                        }))}
                        label="Datasources"
                        error={activeTab.selected_ds?.length === 0}
                        helperText={
                          activeTab.selected_ds?.length === 0
                            ? "* Please select atleast one datasource"
                            : ""
                        }
                        selectedValues={activeTab.selected_ds}
                        handleChange={(newValue) => {
                          const updatedTabs = allTabs.map((tab) => {
                            if (tab.uuid === selectedTab) {
                              return { ...tab, selected_ds: newValue };
                            } else return tab;
                          });
                          dispatch(updateTabs(updatedTabs));
                        }}
                      />
                    </Grid>
                    <Grid width="175px">
                      <Autocomplete
                        size="small"
                        disableClearable
                        options={activeTab.product_groups.map((item) => ({
                          ...item,
                          id: item.uuid,
                          label: item.name,
                        }))}
                        value={activeTab.selected_pg}
                        onChange={(event, newValue) => {
                          const updatedTabs = allTabs.map((tab) => {
                            if (tab.uuid === selectedTab) {
                              return { ...tab, selected_pg: newValue };
                            } else return tab;
                          });
                          dispatch(updateTabs(updatedTabs));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Product Groups" />
                        )}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        opacity:
                          activeTab.query?.length > 0 &&
                          activeTab.selected_ds?.length > 0
                            ? 1
                            : 0.5,
                      }}
                    >
                      <Tooltip title="Click here to predict solutions for the query here!">
                        <button
                          className="predict"
                          disabled={
                            !(
                              activeTab.query?.length > 0 &&
                              activeTab.selected_ds?.length > 0
                            )
                          }
                          onClick={() => {
                            const updatedTabs = allTabs.map((tab) => {
                              if (tab.uuid === selectedTab) {
                                return {
                                  ...tab,

                                  req_query: tab.query,
                                  selected_guides: {},
                                };
                              } else return tab;
                            });
                            dispatch(updateTabs(updatedTabs));
                            handlePredict("search");
                          }}
                        >
                          Predict
                        </button>
                      </Tooltip>
                    </Grid>
                    <Tooltip
                      title={
                        activeTab?.is_preview_open
                          ? "Close Preview"
                          : "You can open the preview pane to view selected prediction details here!"
                      }
                    >
                      <button
                        className="openbtn preview-sel"
                        style={{
                          backgroundColor: activeTab?.is_preview_open
                            ? "#fac51b"
                            : "#fff",
                        }}
                        onClick={() => {
                          const updatedTabs = allTabs.map((tab) => {
                            if (tab.uuid === selectedTab) {
                              return {
                                ...tab,
                                is_preview_open: !tab.is_preview_open,
                              };
                            } else return tab;
                          });
                          dispatch(updateTabs(updatedTabs));
                          setPreviewDocument(null);
                        }}
                      >
                        <PreviewIcon />{" "}
                      </button>
                    </Tooltip>

                    <Tooltip
                      title={
                        activeTab?.is_guides_open
                          ? "Close Guides & Filters"
                          : "You view guide for the prediction results here!"
                      }
                    >
                      <button
                        className="openbtn previewguide-sel"
                        style={{
                          backgroundColor: activeTab?.is_guides_open
                            ? "#fac51b"
                            : "#fff",
                        }}
                        onClick={() => {
                          const updatedTabs = allTabs.map((tab) => {
                            if (tab.uuid === selectedTab) {
                              return {
                                ...tab,
                                is_guides_open: !tab.is_guides_open,
                              };
                            } else return tab;
                          });
                          dispatch(updateTabs(updatedTabs));
                        }}
                      >
                        <GuideIcon />{" "}
                      </button>
                    </Tooltip>
                  </div>
                  {!predicted_documents && !activeTab?.isPredicting && (
                    <div className="iconCenter">
                      <DummyIcon /> <Typography>Start Predicting</Typography>
                    </div>
                  )}

                  <div className="resultWrap">
                    {predicted_documents &&
                      predicted_documents?.length > 0 &&
                      !activeTab?.isPredicting && (
                        <Typography className="totDoc">{`Total Results: ${predicted_documents?.length}`}</Typography>
                      )}
                    <ul className="cards">
                      {predicted_documents &&
                        !predicted_documents?.length > 0 &&
                        !activeTab?.isPredicting && (
                          <li className="question-desc">
                            <Grid
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              height={"400px"}
                            >
                              <DummyIcon />{" "}
                              <Typography sx={{ fontSize: "1.5rem" }}>
                                No results found
                              </Typography>
                            </Grid>
                          </li>
                        )}
                      {activeTab?.isPredicting && (
                        <li className="question-desc">
                          <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            height={"400px"}
                          >
                            <CircularProgress color="inherit" />
                          </Grid>
                        </li>
                      )}

                      {predicted_documents &&
                        predicted_documents?.length > 0 &&
                        !activeTab?.isPredicting && (
                          <li
                            className="question-desc"
                            ref={scrollRef}
                            onScroll={(e) => {
                              const updatedTabs = allTabs.map((tab) => {
                                if (tab.uuid === selectedTab)
                                  return {
                                    ...tab,
                                    scrollPosition: e.target?.scrollTop,
                                  };
                                else return tab;
                              });
                              dispatch(updateTabs(updatedTabs));
                            }}
                          >
                            {(activeTab?.isGenerating ||
                              (!activeTab.isGenerating &&
                                (activeTab?.onfly?.answer?.length > 0 ||
                                  activeTab?.onfly?.urls?.length > 0))) && (
                              <div className="onfly">
                                <div className="genaiWrap">
                                  <div className="genaiIcon">
                                    <GenAiIcon />
                                  </div>
                                  <Typography
                                    fontSize={"12px"}
                                    color={"primary"}
                                    fontWeight={600}
                                  >
                                    {activeTab.isGenerating
                                      ? "Generating Solution"
                                      : "Suggested Solution"}
                                  </Typography>
                                </div>
                                <div className="inner-onfly">
                                  {activeTab.predicted_documents?.length >
                                    0 && (
                                    <div>
                                      {activeTab?.isGenerating ? (
                                        <Box sx={{ display: "flex" }} p="10px">
                                          <CircularProgress
                                            color="primary"
                                            size={20}
                                          />
                                        </Box>
                                      ) : (
                                        <div>
                                          {(activeTab?.onfly?.urls?.length >
                                            0 ||
                                            activeTab?.onfly?.answer?.length >
                                              0) && (
                                            <div>
                                              {!animationComplete ? (
                                                <Typewriter
                                                  options={{
                                                    delay: 15,
                                                  }}
                                                  onInit={(typewriter) => {
                                                    typewriter
                                                      .typeString(
                                                        activeTab?.onfly?.answer
                                                      )
                                                      .callFunction(() =>
                                                        setAnimationComplete(
                                                          true
                                                        )
                                                      )
                                                      .start();
                                                  }}
                                                />
                                              ) : (
                                                <Typography
                                                  sx={{
                                                    fontSize: "12px",
                                                    lineHeight: "2",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      activeTab?.onfly?.answer,
                                                  }}
                                                />
                                              )}

                                              {animationComplete &&
                                                activeTab?.onfly?.urls?.length >
                                                  0 && (
                                                  <Typography
                                                    fontSize={"12px"}
                                                    fontWeight={"600"}
                                                  >
                                                    References
                                                  </Typography>
                                                )}
                                              {animationComplete &&
                                                activeTab?.more &&
                                                activeTab?.onfly?.urls?.map(
                                                  (url) => (
                                                    <a
                                                      href={url}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {url}
                                                    </a>
                                                  )
                                                )}
                                              {animationComplete &&
                                                !activeTab?.more &&
                                                activeTab?.onfly?.urls
                                                  ?.filter(
                                                    (item, index) => index < 2
                                                  )
                                                  .map((url) => (
                                                    <a
                                                      href={url}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {url}
                                                    </a>
                                                  ))}
                                              {animationComplete &&
                                                activeTab?.onfly?.urls?.length >
                                                  2 && (
                                                  <Grid
                                                    sx={{
                                                      cursor: "pointer",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginLeft: "-4px",
                                                    }}
                                                    onClick={() => {
                                                      const updatedTabs =
                                                        allTabs.map((tab) => {
                                                          if (
                                                            tab.uuid ===
                                                            selectedTab
                                                          )
                                                            return {
                                                              ...tab,
                                                              more: !tab?.more,
                                                            };
                                                          else return tab;
                                                        });
                                                      dispatch(
                                                        updateTabs(updatedTabs)
                                                      );
                                                    }}
                                                  >
                                                    {!activeTab?.more ? (
                                                      <ExpandMore
                                                        sx={{
                                                          color:
                                                            colors.ascendo.blue,
                                                        }}
                                                      />
                                                    ) : (
                                                      <ExpandLess
                                                        sx={{
                                                          color:
                                                            colors.ascendo.blue,
                                                        }}
                                                      />
                                                    )}
                                                    <Typography
                                                      fontSize={"11px"}
                                                      color={
                                                        colors.ascendo.blue
                                                      }
                                                    >
                                                      {!activeTab?.more
                                                        ? "Show more..."
                                                        : "Show less"}
                                                    </Typography>
                                                  </Grid>
                                                )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {predicted_documents.map((pd) => (
                              <div
                                className="inner-card"
                                onMouseEnter={() => setPreviewDocument(pd)}
                                onClick={() => {
                                  if (
                                    !allTabs.some(
                                      (tab) => tab.uuid === pd["Unique Number"]
                                    )
                                  ) {
                                    const updatedTabs = allTabs.map((tab) => {
                                      if (tab.uuid === selectedTab)
                                        return {
                                          ...tab,
                                          last_selected_document: pd._id,
                                          predicted_documents:
                                            tab.predicted_documents.map(
                                              (item) => {
                                                if (item._id === pd._id)
                                                  return {
                                                    ...item,
                                                    view_session_data: {
                                                      ...item.view_session_data,
                                                      is_read: true,
                                                    },
                                                  };
                                                else return item;
                                              }
                                            ),
                                        };
                                      else return tab;
                                    });

                                    dispatch(
                                      updateTabs([
                                        ...updatedTabs,
                                        {
                                          name: `#${pd["Unique Number"]}`,
                                          uuid: pd["Unique Number"],
                                          business_unit_uuid: activeTab.uuid,
                                          sub_datasource_uuid:
                                            pd.sub_datasource.uuid,
                                          document_id: pd._id,
                                          query: activeTab.query,
                                          product_group: activeTab.selected_pg,
                                          logo_uri: activeTab.logo_uri,
                                          type: "un",
                                          read_documents:props?.read_documents
                                        },
                                      ])
                                    );
                                  }
                                  dispatch(
                                    updateSelectedTab(pd["Unique Number"])
                                  );
                                }}
                              >
                                <div className="card-content">
                                  <div
                                    className="cards-link"
                                    style={{
                                      backgroundColor:
                                        pd._id ===
                                        activeTab.last_selected_document
                                          ? "rgba(7, 25, 66, 0.05)"
                                          : "transparent",
                                      ":hover": {
                                        backgroundColor:
                                          "rgba(7, 25, 66, 0.05)",
                                      },
                                    }}
                                  >
                                    <div className="card-text">
                                      <Tooltip title={pd.sub_datasource.name}>
                                        <span
                                          className="colored-dropdown"
                                          style={{
                                            backgroundColor:
                                              pd.sub_datasource.color,
                                          }}
                                        >
                                          {pd.sub_datasource.name[0]}
                                        </span>
                                      </Tooltip>{" "}
                                      <Tooltip title="Title of the Solution">
                                        <b
                                          style={
                                            pd.view_session_data.is_read
                                              ? { color: "purple" }
                                              : {}
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: pd["Title"],
                                          }}
                                        />
                                      </Tooltip>
                                      <div className="floatInfo"> 
                                      {activeTab?.read_documents?.includes(pd?._id)&&
                               
                                     <span className="customer-used">Customer Used</span>
}                                      {pd?.is_most_recent&&
                                      <span className="most-recent">
                                      Most Recent
                                      </span>
}
                                      <span
                                        className="count"
                                        style={{
                                          backgroundColor:
                                            pd.probability > 70
                                              ? "#32C49E"
                                              : pd.probability > 30
                                              ? "#EF9E42"
                                              : "#E25757",
                                        }}
                                      >
                                        <Tooltip title="Relevance Score">
                                          {" "}
                                          {pd["probability"]}
                                        </Tooltip>
                                      </span>
                                     </div>
                                      <p className="desc-id">
                                        <Tooltip title="Unique Number">
                                          {" "}
                                          <span className="alink">
                                            {pd["Unique Number"]}
                                          </span>
                                        </Tooltip>
                                        {/* <span className="dot">●</span>
                                  <span className="update-date">
                                    Updated at 10 Jun, 2023
                                  </span> */}
                                      </p>
                                      <p
                                        className="desc-answer"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            pd["summary"] ?? pd["Description"],
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="icon-list">
                                    <ul>
                                      <li
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleToggleVote(
                                            pd._id,
                                            pd?.sub_datasource?.uuid,
                                            "UP"
                                          );
                                        }}
                                      >
                                        <Tooltip title="# of times this solution has been upvoted">
                                          <span>
                                            <UpvoteIcon
                                              fill={
                                                pd.user_vote_direction === "UP"
                                                  ? "#071942"
                                                  : "none"
                                              }
                                            />
                                            {pd.up_vote_count}
                                          </span>
                                        </Tooltip>
                                      </li>
                                      <li>
                                        <Tooltip title="# of times this solution has been downvoted">
                                          {" "}
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleToggleVote(
                                                pd._id,
                                                pd?.sub_datasource?.uuid,
                                                "DOWN"
                                              );
                                            }}
                                          >
                                            <DownvoteIcon
                                              fill={
                                                pd.user_vote_direction ===
                                                "DOWN"
                                                  ? "#071942"
                                                  : "none"
                                              }
                                            />{" "}
                                            {pd.down_vote_count}
                                          </span>
                                        </Tooltip>
                                      </li>
                                      <li>
                                        <Tooltip title="# of times this solution has been viewed">
                                          <span className="links">
                                            <img src="https://storage.googleapis.com/exteneral_logo/sap/eye-view.svg" />
                                            {pd.view_data.count}
                                            <b>Views</b>
                                          </span>
                                        </Tooltip>
                                        <span className="dot-li">●</span>
                                      </li>
                                      <li>
                                        <Tooltip
                                          title="# of times a top technician has recommended this

solution!"
                                        >
                                          {" "}
                                          <span className="links">
                                            <img src="https://storage.googleapis.com/exteneral_logo/sap/expert.svg" />
                                            {pd.expert_up_vote_count}
                                            <b>Top Technician</b>
                                          </span>
                                        </Tooltip>
                                        <span className="dot-li">●</span>
                                      </li>
                                      <li>
                                        <Tooltip
                                          title="# of times a super expert has recommended this

solution!"
                                        >
                                          {" "}
                                          <span className="links">
                                            <img src="https://storage.googleapis.com/exteneral_logo/sap/super-expert.svg" />
                                            {pd.super_expert_up_vote_count}
                                            <b>Super Expert</b>
                                          </span>
                                        </Tooltip>
                                      </li>
                                      {/* <li
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleOpenGenAi({
                                            openGenAi: true,
                                            title: pd["Title"],
                                            description: pd["Description"],
                                            sub_datasource_uuids:
                                              pd?.sub_datasource.uuid,
                                          });
                                        }}
                                      >
                                        <span>
                                          <GenAiIcon />
                                        </span>
                                      </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </li>
                        )}

                      {predicted_documents &&
                        predicted_documents?.length > 0 &&
                        activeTab.is_preview_open && (
                          <li
                            className="cards-item preview-wrap sidepanel"
                            id="previewSidepanel"
                            style={{
                              width: "55%",
                              overflow: "scroll",
                              minWidth: "350px",
                              maxWidth: "450px",
                              paddingLeft: "0rem",
                              display: "none;",
                            }}
                          >
                            <div className="inner-card height100">
                              <div className="card-content">
                                <p className="card-head">Preview</p>
                                {previewDocument && (
                                  <p
                                    className="desc-id"
                                    style={{ marginBottom: "2px" }}
                                  >
                                    <a href="#">
                                      {`#${previewDocument["Unique Number"]}`}
                                    </a>
                                  </p>
                                )}
                                {previewDocument?.preview.map((pv) => (
                                  <div style={{ marginBottom: "2px" }}>
                                    {pv.value?.length > 0 &&
                                      pv.value != null && (
                                        <div className="card-text">
                                          <b
                                            style={{ fontSize: "14px" }}
                                          >{`${pv.label} :`}</b>
                                          <p>
                                            {" "}
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: pv.value,
                                              }}
                                            />
                                          </p>
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </li>
                        )}

                      {activeTab.is_guides_open && (
                        <li
                          className="cards-item filter-wrap sidepanel"
                          id="filterSidepanel"
                          style={{
                            width: "55%",
                            height: "calc(100vh - 300px)",
                            overflow: "scroll",
                            minWidth: "290px",
                            maxWidth: "350px",
                            paddingLeft: "0.5rem",
                            display: "flex;",
                          }}
                        >
                          <div className="inner-card height100">
                            <div className="card-content">
                              <div className="tabs-bar tabs-black">
                                <button className="tabs-bar-item tabs-button tablink active-tab">
                                  Guides
                                </button>
                              </div>

                              <div
                                id="guides1"
                                className="tabs-container tabs-display-container"
                              >
                                 {activeTab.selected_ds?.length > 0 && (
                                  <div className="radio-btn">
                                    {activeTab?.guides?.top_technicians?.total >
                                      0 && (
                                      <Grid>
                                        <h5>{`Top Technician `}</h5>
                                        {activeTab?.guides?.top_technicians?.options.map(
                                          (option, index) => (
                                            <Grid
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "4px",
                                                pt: "4px",
                                              }}
                                            >
                                              <Avatar
                                                alt={option?.label}
                                                src={avatars[index]}
                                                sx={{
                                                  width: "36px",
                                                  height: "36px",
                                                }}
                                              />

                                              <Typography className="topLabel">
                                                {option?.label}
                                              </Typography>
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    )}
                                    {activeTab?.guides?.categories?.total >
                                      0 && (
                                      <Grid>
                                        <Grid
                                          display={"flex"}
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                          <h5>{`Categories`}</h5>

                                          <Typography
                                            fontSize={"12px"}
                                            color={
                                              activeTab?.selected_guides
                                                ?.category?.length > 0
                                                ? "primary"
                                                : "transparent"
                                            }
                                            sx={{
                                              cursor:
                                                activeTab?.selected_guides
                                                  ?.category?.length > 0
                                                  ? "pointer"
                                                  : "default",
                                            }}
                                            onClick={() => {
                                              const updatedTabs = allTabs.map(
                                                (tab) => {
                                                  if (tab.uuid === selectedTab)
                                                    return {
                                                      ...tab,
                                                      selected_guides: {
                                                        category: "",
                                                        sub_category: "",
                                                        solutions: [],
                                                      },
                                                    };
                                                  else return tab;
                                                }
                                              );
                                              dispatch(updateTabs(updatedTabs));
                                              handlePredict("guide", {
                                                category: "",
                                                sub_category: "",
                                                solutions: [],
                                              });
                                            }}
                                          >
                                            {" "}
                                            x Clear
                                          </Typography>
                                        </Grid>

                                        <FormControl className="form-control">
                                          <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={
                                              activeTab?.selected_guides
                                                ?.category ?? ""
                                            }
                                            onChange={(e) => {
                                              const updatedTabs = allTabs.map(
                                                (tab) => {
                                                  if (tab.uuid === selectedTab)
                                                    return {
                                                      ...tab,
                                                      selected_guides: {
                                                        ...tab.selected_guides,

                                                        category:
                                                          e.target.value,
                                                        sub_category: "",
                                                        solutions: [],
                                                      },
                                                    };
                                                  else return tab;
                                                }
                                              );
                                              dispatch(updateTabs(updatedTabs));
                                              handlePredict("guide", {
                                                category: e.target.value,
                                                sub_category: "",
                                                solutions: [],
                                              });
                                            }}
                                          >
                                            {activeTab?.guides?.categories?.options?.map(
                                              (category) => (
                                                <label className="form-control">
                                                  <FormControlLabel
                                                    className="labelWidth"
                                                    value={category.label ?? ""}
                                                    control={
                                                      <Radio size="small" />
                                                    }
                                                    label={category.label}
                                                  />
                                                  <span className="percent">
                                                    {`${category.score}%`}
                                                  </span>
                                                </label>
                                              )
                                            )}
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                    )}

                                    {activeTab?.guides?.sub_categories?.total >
                                      0 && (
                                      <Grid>
                                        <Grid
                                          display={"flex"}
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                          <h5>{`Sub-Categories`}</h5>
                                          <Typography
                                            fontSize={"12px"}
                                            color={
                                              activeTab?.selected_guides
                                                ?.sub_category?.length > 0
                                                ? "primary"
                                                : "transparent"
                                            }
                                            sx={{
                                              cursor:
                                                activeTab?.selected_guides
                                                  ?.sub_category?.length > 0
                                                  ? "pointer"
                                                  : "default",
                                            }}
                                            onClick={() => {
                                              const updatedTabs = allTabs.map(
                                                (tab) => {
                                                  if (tab.uuid === selectedTab)
                                                    return {
                                                      ...tab,
                                                      selected_guides: {
                                                        ...tab.selected_guides,
                                                        sub_category: "",
                                                        solution: [],
                                                      },
                                                    };
                                                  else return tab;
                                                }
                                              );
                                              dispatch(updateTabs(updatedTabs));
                                              handlePredict("guide", {
                                                category:
                                                  activeTab?.selected_guides
                                                    .category,
                                                sub_category: "",
                                                solutions: [],
                                              });
                                            }}
                                          >
                                            {" "}
                                            x Clear
                                          </Typography>
                                        </Grid>

                                        <FormControl className="form-control">
                                          <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={
                                              activeTab?.selected_guides
                                                ?.sub_category ?? ""
                                            }
                                            onChange={(e) => {
                                              const updatedTabs = allTabs.map(
                                                (tab) => {
                                                  if (tab.uuid === selectedTab)
                                                    return {
                                                      ...tab,
                                                      selected_guides: {
                                                        ...tab.selected_guides,

                                                        sub_category:
                                                          e.target.value,
                                                        solutions: [],
                                                      },
                                                    };
                                                  else return tab;
                                                }
                                              );
                                              dispatch(updateTabs(updatedTabs));
                                              handlePredict("guide", {
                                                category:
                                                  activeTab?.selected_guides
                                                    .category,
                                                sub_category: e.target.value,
                                                solutions: [],
                                              });
                                            }}
                                          >
                                            {activeTab?.guides?.sub_categories?.options.map(
                                              (category) => (
                                                <label className="form-control">
                                                  <FormControlLabel
                                                    className="labelWidth"
                                                    value={category.label ?? ""}
                                                    control={
                                                      <Radio size="small" />
                                                    }
                                                    label={category.label}
                                                  />
                                                  <span className="percent">
                                                    {`${category.score}%`}
                                                  </span>
                                                </label>
                                              )
                                            )}
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                    )}
                                    {activeTab?.guides?.solutions?.total >
                                      0 && (
                                      <Grid>
                                        <Grid
                                          display={"flex"}
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                          <h5>{`Solutions`}</h5>

                                          <Typography
                                            fontSize={"12px"}
                                            color={
                                              activeTab?.selected_guides
                                                ?.solutions?.length > 0
                                                ? "primary"
                                                : "transparent"
                                            }
                                            sx={{
                                              cursor:
                                                activeTab?.selected_guides
                                                  ?.solutions?.length > 0
                                                  ? "pointer"
                                                  : "default",
                                            }}
                                            onClick={() => {
                                              const updatedTabs = allTabs.map(
                                                (tab) => {
                                                  if (tab.uuid === selectedTab)
                                                    return {
                                                      ...tab,
                                                      selected_guides: {
                                                        ...tab.selected_guides,

                                                        solutions: [],
                                                      },
                                                    };
                                                  else return tab;
                                                }
                                              );
                                              dispatch(updateTabs(updatedTabs));
                                              handlePredict("guide", {
                                                category:
                                                  activeTab?.selected_guides
                                                    ?.category,
                                                sub_category:
                                                  activeTab?.selected_guides
                                                    ?.sub_category,
                                                solutions: [],
                                              });
                                            }}
                                          >
                                            {" "}
                                            x Clear
                                          </Typography>
                                        </Grid>

                                        {activeTab?.guides?.solutions?.options.map(
                                          (solution) => (
                                            <Grid
                                              className="form-control mb-0"
                                              mt="5px"
                                              display="flex"
                                              gap="5px"
                                              alignItems="center"
                                              sx={{ cursor: "pointer" }}
                                              onChange={() => {
                                                const updatedSolutions =
                                                  activeTab?.selected_guides
                                                    ?.solutions
                                                    ? activeTab?.selected_guides.solutions.some(
                                                        (item) =>
                                                          item.label ===
                                                            solution.label &&
                                                          item.score ===
                                                            solution.score
                                                      )
                                                      ? activeTab?.selected_guides.solutions.filter(
                                                          (item) =>
                                                            item.label !==
                                                              solution.label &&
                                                            item.score !==
                                                              solution.score
                                                        )
                                                      : [
                                                          ...activeTab
                                                            ?.selected_guides
                                                            ?.solutions,
                                                          solution,
                                                        ]
                                                    : [solution];
                                                const updatedTabs = allTabs.map(
                                                  (tab) => {
                                                    if (
                                                      tab.uuid === selectedTab
                                                    )
                                                      return {
                                                        ...tab,
                                                        selected_guides: {
                                                          ...tab.selected_guides,
                                                          solutions:
                                                            updatedSolutions,
                                                        },
                                                      };
                                                    else return tab;
                                                  }
                                                );
                                                dispatch(
                                                  updateTabs(updatedTabs)
                                                );
                                                handlePredict("guide", {
                                                  category:
                                                    activeTab?.selected_guides
                                                      .category,
                                                  sub_category:
                                                    activeTab?.selected_guides
                                                      .sub_category,
                                                  solutions:
                                                    updatedSolutions.map(
                                                      (up) => up.label
                                                    ),
                                                });
                                              }}
                                            >
                                              <Checkbox
                                                size="small"
                                                disableRipple
                                                sx={{ padding: 0 }}
                                                checked={activeTab?.selected_guides?.solutions?.some(
                                                  (selected) =>
                                                    selected.label ===
                                                      solution.label &&
                                                    selected.score ===
                                                      solution.score
                                                )}
                                              />{" "}
                                              <Typography
                                                className="labelWidth"
                                                color={colors.ascendo.darkblue}
                                                fontSize="14px"
                                              >
                                                {solution.label}
                                              </Typography>
                                              <span className="percent">
                                                {`${solution.score}%`}
                                              </span>
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    )}
                                    {activeTab?.guides?.parts?.total > 0 && (
                                      <Grid>
                                        <h5>{`Parts`}</h5>
                                        {activeTab?.guides?.parts?.options.map(
                                          (option) => (
                                            <Grid
                                              className="form-control mb-0"
                                              display={"flex"}
                                              justifyContent={"space-between"}
                                            >
                                              <Typography className="topLabel">
                                                {option?.label}
                                              </Typography>
                                              <span className="percent">
                                                {`${option.score}%`}
                                              </span>
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </Card>
              </Grid>
            )}
          {selectedTab !== "home28082001" &&
            !business_units.some((bu) => bu.uuid === selectedTab) &&
            !detailed_document && (
              <Grid item xs={12} md={12} lg={12}>
                <Card className="commonCard bg-lightbg" elevation={0}>
                  <div class="moredetail-wrap">
                    <Grid
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={"400px"}
                    >
                      <CircularProgress color="inherit" />
                    </Grid>
                  </div>
                </Card>
              </Grid>
            )}

          {selectedTab !== "home28082001" &&
            !business_units.some((bu) => bu.uuid === selectedTab) &&
            detailed_document && (
              <Grid item xs={12} md={12} lg={12}>
                <Card className="commonCard bg-lightbg" elevation={0}>
                  <div class="moredetail-wrap">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable
                        droppableId="board"
                        direction="horizontal"
                        type="COLUMN"
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="moreContent"
                          >
                            <div
                              class="desc-content"
                              style={
                                editLayout
                                  ? { height: "calc(100vh - 280px)" }
                                  : {}
                              }
                            >
                              <div class="heading">
                                <Tooltip title="Title of the Solution">
                                  <b
                                    class="desc-bold"
                                    dangerouslySetInnerHTML={{
                                      __html: detailed_document["Title"],
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <div class="more-contentdata">
                                <b class="desc-heading">Description</b>
                                {detailed_document.is_most_recent && (
                                  <span class="most-recent">Most Recent</span>
                                )}
                                {activeTab?.read_documents?.includes(detailed_document?._id)&&
                                <span className="customerused-Detail">Customer Used</span>  
}
                                <span
                                  className="count"
                                  style={{
                                    backgroundColor:
                                      detailed_document.probability > 70
                                        ? "#32C49E"
                                        : detailed_document.probability > 30
                                        ? "#EF9E42"
                                        : "#E25757",
                                  }}
                                >
                                  <Tooltip title="Relevance Score">
                                    {detailed_document["probability"]}
                                  </Tooltip>
                                </span>{" "}
                                <p
                                  class="para-content mar-top25"
                                  dangerouslySetInnerHTML={{
                                    __html: detailed_document["Description"],
                                  }}
                                />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: detailed_document["Part"],
                                  }}
                                />
                              </div>
                              {(detailed_document.quick_view?.length > 0 ||
                                editLayout) && (
                                <div class="quickTitle">
                                  <button>Quick View</button>
                                </div>
                              )}
                              <div class="righttab quickView width100">
                                <div class="card height100">
                                  <div class="card-content">
                                    {!editLayout &&
                                      detailed_document.quick_view.map((i) => {
                                        if (i["key"] !== "Part")
                                          return (
                                            <div
                                              id="filter"
                                              class="tabs-container tabs-display-container tabshead"
                                            >
                                              {i.value !== null && (
                                                <p>
                                                  <span class="text-tab">
                                                    <span class="fav-img"></span>
                                                    {i["key"]}
                                                  </span>

                                                  <span class="answer-tab">
                                                    {renderValue(i.value)}
                                                  </span>
                                                </p>
                                              )}
                                            </div>
                                          );
                                      })}
                                    {editLayout && (
                                      <MappedList
                                        cards={board.mapped}
                                        listId={"mapped"}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="icon-list">
                              <ul>
                                <li>
                                  <Tooltip title="# of times this solution has been upvoted">
                                    <span
                                      onClick={() =>
                                        handleDetailedToggleVote(
                                          detailed_document._id,
                                          detailed_document?.sub_datasource
                                            ?.uuid,
                                          "UP"
                                        )
                                      }
                                    >
                                      <UpvoteIcon
                                        fill={
                                          detailed_document.user_vote_direction ===
                                          "UP"
                                            ? "#071942"
                                            : "none"
                                        }
                                      />
                                      {detailed_document.up_vote_count}
                                    </span>{" "}
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip title="# of times this solution has been downvoted">
                                    {" "}
                                    <span
                                      onClick={() =>
                                        handleDetailedToggleVote(
                                          detailed_document._id,
                                          detailed_document?.sub_datasource
                                            ?.uuid,
                                          "DOWN"
                                        )
                                      }
                                    >
                                      <DownvoteIcon
                                        fill={
                                          detailed_document.user_vote_direction ===
                                          "DOWN"
                                            ? "#071942"
                                            : "none"
                                        }
                                      />
                                      {detailed_document.down_vote_count}
                                    </span>
                                  </Tooltip>
                                  <span class="dot-li">●</span>
                                </li>
                                <li>
                                  <Tooltip title="# of times this solution has been viewed">
                                    {" "}
                                    <span>
                                      <img src="https://storage.googleapis.com/exteneral_logo/sap/eye-view.svg" />
                                      {detailed_document.view_data.count}
                                    </span>
                                  </Tooltip>
                                  <span class="dot-li">●</span>
                                </li>
                                <li>
                                  <Tooltip
                                    title="# of times a top technician has recommended this

solution!"
                                  >
                                    {" "}
                                    <span className="links">
                                      <img src="https://storage.googleapis.com/exteneral_logo/sap/expert.svg" />
                                      {detailed_document.expert_up_vote_count}
                                    </span>
                                  </Tooltip>
                                  <span className="dot-li">●</span>
                                </li>
                                <li>
                                  <Tooltip
                                    title="# of times a super expert has recommended this

solution!"
                                  >
                                    {" "}
                                    <span className="links">
                                      <img src="https://storage.googleapis.com/exteneral_logo/sap/super-expert.svg" />
                                      {
                                        detailed_document.super_expert_up_vote_count
                                      }
                                    </span>
                                  </Tooltip>
                                </li>
                                {/* <li>
                                  <span>
                                    <GenAiIcon />
                                  </span>
                                </li> */}
                                {userDetails?.permissions?.includes(
                                  "rp_edit_layout"
                                ) && (
                                  <li
                                    className="editlayoutIcon"
                                    onClick={() => {
                                      setEditLayout(!editLayout);
                                    }}
                                  >
                                    <Tooltip title="Edit layout">
                                      <ViewQuilt
                                        sx={{ color: colors.ascendo.darkblue }}
                                      />
                                    </Tooltip>
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div
                              class="righttab"
                              style={
                                editLayout
                                  ? { height: "calc(100vh - 300px)" }
                                  : {}
                              }
                            >
                              <div class="card height100">
                                <div class="card-content">
                                  <div class="tabs-bar tabs-black">
                                    <button
                                      class="tabs-bar-item tabs-button tablink"
                                      id="fil-tab"
                                    >
                                      Others
                                    </button>
                                  </div>

                                  <div
                                    id="filter"
                                    class="tabs-container tabs-display-container tabshead"
                                  >
                                    <p>
                                      <span class="text-tab">
                                        <span class="fav-img"></span>
                                        Unique Number
                                      </span>
                                      <span class="answer-tab">
                                        {detailed_document["Unique Number"]}
                                      </span>
                                    </p>
                                    <p>
                                      <span class="text-tab">
                                        <span class="fav-img"></span>
                                        Product Group
                                      </span>
                                      <span class="answer-tab">
                                        {detailed_document["Product Group"]}
                                      </span>
                                    </p>
                                    <p>
                                      <span class="text-tab">
                                        <span class="fav-img"></span>
                                        URL
                                      </span>

                                      <a
                                        href={detailed_document["URL"]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {detailed_document["URL"]}
                                      </a>
                                    </p>

                                    {!editLayout &&
                                      detailed_document.custom.map((i) => {
                                        if (i["key"] !== "Part")
                                          return (
                                            <div>
                                              {
                                                i.value !== null && (
                                                  <p>
                                                    <span class="text-tab">
                                                      <span class="fav-img"></span>
                                                      {i["key"]}
                                                    </span>

                                                    <span class="answer-tab">
                                                      {renderValue(i.value)}
                                                    </span>
                                                  </p>
                                                )}
                                            </div>
                                          );
                                      })}
                                    {editLayout && (
                                      <CustomList
                                        listId={"custom"}
                                        cards={board.custom}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {/* <div className="bottomFixedbtn1">
<Button>Cancel</Button>
<Button className="primary">Save</Button>
                    </div> */}
                    {editLayout && (
                      <Grid
                        variant="stepperFixedbottombtn"
                        display="flex"
                        justifyContent="flex-end"
                        marginBottom="16px"
                        backgroundColor="#f5f5f5"
                        width="auto"
                        marginRight="20px"
                        gap={1}
                      >
                        <Grid item width="120px" margin="26px 0px 0px 0px">
                          <Button
                            fullWidth
                            onClick={() => {
                              setEditLayout(false);
                            }}
                            sx={{
                              color: colors.ascendo.darkblue,
                              textTransform: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>

                        <Grid item width="120px" margin="26px 0px 0px 0px">
                          <Button
                            fullWidth
                            onClick={() => {
                              let data = {
                                business_unit_uuid:
                                  activeTab.business_unit_uuid,
                                sub_datasource_uuid:
                                  activeTab.sub_datasource_uuid,
                                document_id: activeTab.document_id,
                                query: activeTab.query,
                                custom_fields: board.custom
                                  .filter((item) => item.key !== "Part")
                                  .map((item) => item.key),
                                quick_view_fields: board.mapped.map(
                                  (item) => item.key
                                ),
                              };
                              dispatch(updateQuickView(data));
                              setEditLayout(false);
                            }}
                            sx={{
                              backgroundColor: colors.ascendo.yellow,
                              color: colors.ascendo.darkblue,
                              textTransform: "none",
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Card>
              </Grid>
            )}

          {/* <Grid xs={12} md={6} lg={4}>
        <Card className='commonCard'> <DonutCharts/></Card> 
         </Grid> */}
        </Grid>
      </Container>
    </div>
  );
}

export default InboxPredictions;
