import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateExpanded0,
  updateExpanded1,
  updateExpanded2,
  updateExpanded3,
} from "../../redux/slices/accordionSlice";

function Accordion1({ accordionSummary, accordionDetails, accKey }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = theme.palette;
  const { expanded0, expanded1, expanded2, expanded3 } = useSelector(
    (state) => state.accordionReducer
  );
  const handleOnChange = () => {
    if (accKey === "0") dispatch(updateExpanded0(!expanded0));
    if (accKey === "1") dispatch(updateExpanded1(!expanded1));
    if (accKey === "2") dispatch(updateExpanded2(!expanded2));
    if (accKey === "3") dispatch(updateExpanded3(!expanded3));
  };

  return (
    <div>
      <Accordion
        square
        disableGutters
        expanded={
          accKey === "0"
            ? expanded0
            : accKey === "1"
            ? expanded1
            : accKey === "2"
            ? expanded2
            : accKey === "3"
            ? expanded3
            : false
        }
        onChange={handleOnChange}
        sx={{
          margin: "0px",
          boxShadow: "none",
          "&.MuiAccordion-root:before, &.MuiAccordion-root:after": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          sx={{
            color: colors.ascendo.darkblue,
            padding: "0px",
            alignItems: "center",
            "& .Mui-expanded": {
              color: colors.ascendo.blue,
            },
          }}
          expandIcon={
            <ArrowDropDownRoundedIcon
              fontSize="large"
              sx={{
                color:
                  (accKey === "0" && expanded0) ||
                  (accKey === "1" && expanded1) ||
                  (accKey === "2" && expanded2) ||
                  (accKey === "3" && expanded3)
                    ? colors.ascendo.blue
                    : colors.ascendo.darkblue,
              }}
            />
          }
        >
          {accordionSummary}
        </AccordionSummary>

        <AccordionDetails sx={{ padding: "0px" }}>
          {accordionDetails}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Accordion1;
