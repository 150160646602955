import React from "react";

function ImageIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        transform="matrix(-1 0 0 1 32 0)"
        fill="#071942"
        fillOpacity="0.12"
      />
      <path
        d="M13.8271 14.9842C14.9224 14.9842 15.8104 14.0963 15.8104 13.0009C15.8104 11.9055 14.9224 11.0176 13.8271 11.0176C12.7317 11.0176 11.8438 11.9055 11.8438 13.0009C11.8438 14.0963 12.7317 14.9842 13.8271 14.9842Z"
        fill="#B0B5C3"
      />
      <path
        d="M19.825 8H12.8417C9.80833 8 8 9.80833 8 12.8417V19.825C8 20.7333 8.15833 21.525 8.46667 22.1917C9.18333 23.775 10.7167 24.6667 12.8417 24.6667H19.825C22.8583 24.6667 24.6667 22.8583 24.6667 19.825V17.9167V12.8417C24.6667 9.80833 22.8583 8 19.825 8ZM23.3083 16.75C22.6583 16.1917 21.6083 16.1917 20.9583 16.75L17.4917 19.725C16.8417 20.2833 15.7917 20.2833 15.1417 19.725L14.8583 19.4917C14.2667 18.975 13.325 18.925 12.6583 19.375L9.54167 21.4667C9.35833 21 9.25 20.4583 9.25 19.825V12.8417C9.25 10.4917 10.4917 9.25 12.8417 9.25H19.825C22.175 9.25 23.4167 10.4917 23.4167 12.8417V16.8417L23.3083 16.75Z"
        fill="#B0B5C3"
      />
    </svg>
  );
}

export default ImageIcon;
