import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import './myMultiSelect.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function MyMultiSelect({
  options,
  defaultSelectAll,
  isOptionEqualToValue,
  label,
  placeholder,
  handleChange,
  selectedValues,
  error,
  helperText,
}) {
  const theme = useTheme();
  const colors = theme.palette;
  const [checkedOptions, setCheckedOptions] = useState([]);
  //--------------------,--------------------------------------------------------

  //----------------------------------------------------------------------------

  const handleClear = () => {
    setCheckedOptions([]);
    handleChange([]);
  };
  const handleSelected = (id) => {
    let updatedCheckedOptions = checkedOptions;
    if (id === "selectall28082001") {
      if (checkedOptions.includes(id)) updatedCheckedOptions = [];
      else {
        
        updatedCheckedOptions = [id, ...options.map((i) => i.id)];
      }
    } else if (checkedOptions.includes(id)) {
      updatedCheckedOptions = checkedOptions.filter(
        (prevOption) => ![id, "selectall28082001"].includes(prevOption)
      );
    } else if (checkedOptions.length === options.length - 1) {
      updatedCheckedOptions = [...checkedOptions, id, "selectall28082001"];
    } else {
      updatedCheckedOptions = [...checkedOptions, id];
    }

    setCheckedOptions(updatedCheckedOptions);
    handleChange(
      options.filter((option) => updatedCheckedOptions.includes(option.id))
    );
  };

  useEffect(() => {
    setCheckedOptions(
      selectedValues
        ? selectedValues.length === options.length
          ? [...options?.map((i) => i.id), "selectall28082001"]
          : selectedValues?.map((i) => i.id)
        : defaultSelectAll
        ? [...options?.map((i) => i.id), "selectall28082001"]
        : []
    );
  }, [options]);

  return (
    <Autocomplete
      defaultValue={selectedValues}
      multiple
      sx={{
        height: "40px",
        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
          height: "40px",
        },
        "& .MuiAutocomplete-input": {
          fontSize: "14px",
          color: colors.ascendo.darkblue,
        },
        "& .MuiChip-deleteIcon": {
          display: "none",
        },
        "& .MuiInputLabel-root": {
          maxWidth: "calc(100% - 62px)",
          fontSize: "14px",
        },
        "& .MuiInputLabel-shrink": {},
      }}
      limitTags={1}
      slotProps={{ clearIndicator: { onClick: handleClear } }}
      size="small"
      value={options.filter((option) => checkedOptions.includes(option.id))}
      options={[{ label: "Select All", id: "selectall28082001" }, ...options]}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => {
        const isSelected =
          option.id !== "selectall28082001"
            ? checkedOptions?.includes(option.id)
            : checkedOptions.length === options.length+1
            ? true
            : false;
        return (
          <Tooltip title={option.label}>
            <li
              {...props}
              style={{ height: "40px", marginLeft: "-20px" }}
              onClick={() => handleSelected(option.id)}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={isSelected}
              />
              <span className="lineClamp2"
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {option.label}
              </span>
            </li>
          </Tooltip>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={error ?? false}
          helperText={helperText ?? ""}
        />
      )}
    />
  );
}

export default React.memo(MyMultiSelect);
