import React from "react";

function AddCircle({opacity}) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      opacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.941992 10.8901C0.941992 15.8607 5.00395 19.8998 10.0003 19.8998C14.9967 19.8998 19.0587 15.8607 19.0587 10.8901C19.0587 5.91956 14.9967 1.88047 10.0003 1.88047C5.00395 1.88047 0.941992 5.91956 0.941992 10.8901ZM2.39199 10.8901C2.39199 6.71896 5.80504 3.32368 10.0003 3.32368C14.1956 3.32368 17.6087 6.71896 17.6087 10.8901C17.6087 15.0613 14.1956 18.4566 10.0003 18.4566C5.80504 18.4566 2.39199 15.0613 2.39199 10.8901Z"
        fill="#071942"
        stroke="#071942"
        strokeWidth="0.2"
      />
      <path
        d="M6.66699 11.6127H13.3337C13.73 11.6127 14.0587 11.2867 14.0587 10.8911C14.0587 10.4956 13.73 10.1695 13.3337 10.1695H6.66699C6.27061 10.1695 5.94199 10.4956 5.94199 10.8911C5.94199 11.2867 6.27061 11.6127 6.66699 11.6127Z"
        fill="#071942"
        stroke="#071942"
        strokeWidth="0.2"
      />
      <path
        d="M9.275 14.2071C9.275 14.6027 9.60362 14.9287 10 14.9287C10.3964 14.9287 10.725 14.6027 10.725 14.2071V7.57668C10.725 7.18113 10.3964 6.85508 10 6.85508C9.60362 6.85508 9.275 7.18113 9.275 7.57668V14.2071Z"
        fill="#071942"
        stroke="#071942"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export default AddCircle;
