import React from 'react'

function UrlIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3041 19.0121L14.6968 19.5956C17.9297 20.9502 21.6528 19.4257 23.0074 16.1928C24.3619 12.9598 22.8375 9.23674 19.6045 7.88219L18.221 7.30253C17.7819 7.11855 17.2881 7.32975 17.1086 7.75803C16.9288 8.18712 17.135 8.69069 17.5641 8.87047L18.9476 9.45013C21.3131 10.4412 22.4305 13.1703 21.4394 15.5358C20.4483 17.9014 17.7193 19.0188 15.3537 18.0277L13.961 17.4441C13.5319 17.2644 13.0284 17.4706 12.8486 17.8996C12.6688 18.3287 12.875 18.8323 13.3041 19.0121Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    <path d="M6.39586 16.1177L7.77934 16.6974C8.20842 16.8771 8.712 16.671 8.89178 16.2419C9.07156 15.8128 8.86537 15.3092 8.43628 15.1294L7.05281 14.5498C4.68728 13.5586 3.56984 10.8296 4.56097 8.46407C5.55209 6.09854 8.28114 4.98111 10.6467 5.97223L12.0301 6.55189C12.4592 6.73167 12.9628 6.52548 13.1426 6.09639C13.3224 5.6673 13.1162 5.16373 12.6871 4.98395L11.3036 4.4043C8.07068 3.04975 4.34758 4.5742 2.99303 7.80713C1.63848 11.0401 3.16294 14.7632 6.39586 16.1177Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    <path d="M8.98213 11.2382L16.3607 14.3297C16.7897 14.5095 17.2933 14.3033 17.4731 13.8742C17.6529 13.4451 17.4467 12.9415 17.0176 12.7617L9.63907 9.67025C9.20998 9.49047 8.70641 9.69666 8.52663 10.1257C8.34685 10.5548 8.55304 11.0584 8.98213 11.2382Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    </svg>
  )
}

export default UrlIcon