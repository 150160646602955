import { create } from "@mui/material/styles/createTransitions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetActiveChannels } from "../actions/dataIntegration/channelActions";
import { GetAllBuHavingPgUnderUser } from "../actions/identity/organizationActions";
import { GetHighlevelStats } from "../actions/analytics/usageAnalyticsActions";
import { GetCustomerEffortChart } from "../actions/analytics/customerEffortActions";
import { GetCustomerFeedbackChart } from "../actions/analytics/customerFeedbackActions";
import { GetAllModulesByUserUUID } from "../actions/identity/moduleActions";


export const getActiveChannels = createAsyncThunk("getActiveChannels",async(thunkAPI)=>{
    const response = await GetActiveChannels()
    return response
})
export const getAllModuleByUserUUID = createAsyncThunk("getAllModulesByUserUUID",async(data,thunkAPI)=>{
    const response = await GetAllModulesByUserUUID(data.customer_uuid,data.user_uuid)
    return response
})

export const getAllBuHavingPgUnderUser = createAsyncThunk("getAllBuHavingPgUnderUser",async(thunkAPI)=>{
    const response = await GetAllBuHavingPgUnderUser()
    return response
})

export const getHighlevelStats = createAsyncThunk("getHighlevelStats",async(data,thunkAPI)=>{
    const response = await GetHighlevelStats(data.business_unit_uuid,data.end_date,data.start_date,data.support_channel)
    return response
})

export const getCustomerFeedbackChart = createAsyncThunk("getCustomerFeedbackChart",async(data,thunkAPI)=>{
    const response = await GetCustomerFeedbackChart(data.business_unit_uuid,data.end_date,data.start_date,data.support_channel)
    return response
})

export const getCustomerEffortChart = createAsyncThunk("getCustomerEffortChart",async(data,thunkAPI)=>{
    const response = await GetCustomerEffortChart(data.business_unit_uuid,data.end_date,data.start_date,data.support_channel)
    return response
})

const leaderboardSlice = createSlice({
    name:"leaderboardSlice",
    initialState:{
        active_channels:[],
        business_units:[],
        
        customer_effort_details:null,
        usagestats_details:null,

    },
    reducers:{

    },
    extraReducers:(builder)=>{
    builder
    .addCase(getActiveChannels.fulfilled,(state,action)=>{
        if(action.payload.status===200)
        state.active_channels=action.payload.data
    })
    .addCase(getAllModuleByUserUUID.fulfilled,(state,action)=>{
        if(action.payload.status===200)
        if(action.payload.data?.data?.length>0){
        localStorage.setItem("md",JSON.stringify(action.payload.data.data))
        }
    })
    .addCase(getAllBuHavingPgUnderUser.fulfilled,(state,action)=>{
        if(action.payload.status===200)
        state.business_units=action.payload.data
    })
    .addCase(getCustomerEffortChart.fulfilled,(state,action)=>{
        if(action.payload.status===200)
        state.customer_effort_details=action.payload.data
    })
    .addCase(getHighlevelStats.fulfilled,(state,action)=>{
        if(action.payload.status===200)
        state.usagestats_details=action.payload.data
    })
    }
})

export default leaderboardSlice.reducer