import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

function Linecharts() {
  useEffect(() => {
    Highcharts.chart('linechart', {

      title: {
       text: '',
       text: '',
        align: 'left',
        margin: 10
      },
      credits: {
        enabled: false
   },

      yAxis: {
        title: {
          text: 'Number of Employees'
        }
      },

      xAxis: {
        accessibility: {
          rangeDescription: 'Range: 2010 to 2020'
        }
      },

      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom' 
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 2010
        }
      },

      series: [{
        name: 'Installation & Developers1',
        data: [439, 486, 651, 818, 112, 143,
          171, 174, 155, 161, 154]
      }, {
        name: 'Manufacturing',
        data: [216, 341, 392, 391, 320, 302,
          381, 366, 336, 342, 650]
      }, {
        name: 'Sales & Distribution',
        data: [114, 300, 165, 171, 201, 247,
          347, 309, 293, 992, 263]
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }

    });
  }, []);

  return <div id="linechart" style={{height: "300px"}} />;
}

export default Linecharts;
