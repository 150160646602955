import React from 'react'

function ImplementationGuideIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3997 11.6007C12.5695 11.7704 12.7853 11.85 13.0004 11.85C13.2155 11.85 13.4314 11.7704 13.6011 11.6007L21.8011 3.4007C22.1302 3.07165 22.1302 2.52833 21.8011 2.19928C21.4721 1.87023 20.9288 1.87023 20.5997 2.19928L12.3997 10.3993C12.0707 10.7283 12.0707 11.2716 12.3997 11.6007Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    <path d="M21.1502 6.8C21.1502 7.26523 21.535 7.65 22.0002 7.65C22.4654 7.65 22.8502 7.26523 22.8502 6.8V2C22.8502 1.53477 22.4654 1.15 22.0002 1.15H17.2002C16.735 1.15 16.3502 1.53477 16.3502 2C16.3502 2.46523 16.735 2.85 17.2002 2.85H21.1502V6.8Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    <path d="M9 22.85H15C17.7265 22.85 19.696 22.2679 20.982 20.982C22.2679 19.696 22.85 17.7265 22.85 15V13C22.85 12.5348 22.4652 12.15 22 12.15C21.5348 12.15 21.15 12.5348 21.15 13V15C21.15 17.2979 20.7402 18.8259 19.783 19.783C18.8259 20.7402 17.2979 21.15 15 21.15H9C6.70206 21.15 5.17414 20.7402 4.21696 19.783C3.25979 18.8259 2.85 17.2979 2.85 15V9C2.85 6.70206 3.25979 5.17414 4.21696 4.21696C5.17414 3.25979 6.70206 2.85 9 2.85H11C11.4652 2.85 11.85 2.46523 11.85 2C11.85 1.53477 11.4652 1.15 11 1.15H9C6.27352 1.15 4.30402 1.73206 3.01804 3.01804C1.73206 4.30402 1.15 6.27352 1.15 9V15C1.15 17.7265 1.73206 19.696 3.01804 20.982C4.30402 22.2679 6.27352 22.85 9 22.85Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    </svg>
  )
}

export default ImplementationGuideIcon