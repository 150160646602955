import React from 'react'

function MagicWand() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16706 18.2251C3.55039 18.2251 2.94206 17.9917 2.47539 17.5251C2.02539 17.0751 1.77539 16.4751 1.77539 15.8334C1.77539 15.1917 2.02539 14.5917 2.47539 14.1417L13.3087 3.3084C14.2421 2.37507 15.7587 2.37507 16.6921 3.3084C17.1421 3.7584 17.3921 4.3584 17.3921 5.00007C17.3921 5.64173 17.1421 6.24173 16.6921 6.69173L5.85872 17.5251C5.39206 17.9917 4.78372 18.2251 4.16706 18.2251ZM15.0004 3.8584C14.7087 3.8584 14.4171 3.96673 14.1921 4.19173L3.35872 15.0251C3.14206 15.2417 3.02539 15.5251 3.02539 15.8334C3.02539 16.1417 3.14206 16.4251 3.35872 16.6417C3.80039 17.0834 4.53372 17.0834 4.97539 16.6417L15.8087 5.8084C16.0254 5.59173 16.1421 5.3084 16.1421 5.00007C16.1421 4.69173 16.0254 4.4084 15.8087 4.19173C15.5837 3.96673 15.2921 3.8584 15.0004 3.8584Z" fill="#071942"/>
    <path d="M15.0087 8.11706C14.8503 8.11706 14.692 8.05872 14.567 7.93372L12.067 5.43372C11.8253 5.19206 11.8253 4.79206 12.067 4.55039C12.3087 4.30872 12.7087 4.30872 12.9503 4.55039L15.4503 7.05039C15.692 7.29206 15.692 7.69206 15.4503 7.93372C15.3253 8.05872 15.167 8.11706 15.0087 8.11706Z" fill="#071942"/>
    <path d="M8.33386 4.58354C8.2922 4.58354 8.25886 4.57521 8.2172 4.56687L7.08386 4.23354L5.95053 4.56687C5.80053 4.60854 5.6422 4.56687 5.5422 4.45854C5.43386 4.35021 5.3922 4.20021 5.43386 4.05021L5.7672 2.91687L5.43386 1.78354C5.3922 1.63354 5.43386 1.47521 5.5422 1.37521C5.65053 1.26687 5.80886 1.22521 5.95053 1.26687L7.08386 1.60021L8.2172 1.26687C8.3672 1.22521 8.5172 1.26687 8.62553 1.37521C8.73386 1.48354 8.77553 1.64187 8.73386 1.78354L8.40053 2.91687L8.73386 4.05021C8.77553 4.20021 8.73386 4.35854 8.62553 4.45854C8.55053 4.54187 8.4422 4.58354 8.33386 4.58354ZM7.08386 3.38354C7.12553 3.38354 7.15886 3.39187 7.20053 3.40021L7.7172 3.55021L7.5672 3.03354C7.5422 2.95854 7.5422 2.87521 7.5672 2.80021L7.7172 2.28354L7.20053 2.43354C7.12553 2.45854 7.0422 2.45854 6.9672 2.43354L6.45053 2.28354L6.60053 2.80021C6.62553 2.87521 6.62553 2.95854 6.60053 3.03354L6.45053 3.55021L6.9672 3.40021C7.00886 3.39187 7.0422 3.38354 7.08386 3.38354Z" fill="#071942"/>
    <path d="M4.99988 9.58354C4.95821 9.58354 4.92488 9.57521 4.88321 9.56687L3.74988 9.23354L2.61655 9.56687C2.46655 9.60854 2.31655 9.56687 2.20821 9.45854C2.09988 9.35021 2.05821 9.19187 2.09988 9.05021L2.43321 7.91687L2.09988 6.78354C2.05821 6.63354 2.09988 6.47521 2.20821 6.37521C2.31655 6.27521 2.47488 6.22521 2.61655 6.26687L3.74988 6.60021L4.88321 6.26687C5.02488 6.22521 5.18321 6.26687 5.29155 6.37521C5.39988 6.48354 5.44155 6.64187 5.39988 6.78354L5.06655 7.91687L5.39988 9.05021C5.44155 9.20021 5.39988 9.35854 5.29155 9.45854C5.21655 9.54187 5.10821 9.58354 4.99988 9.58354ZM3.74988 8.38354C3.79155 8.38354 3.82488 8.39187 3.86655 8.40021L4.38321 8.55021L4.23321 8.03354C4.20821 7.95854 4.20821 7.87521 4.23321 7.80021L4.38321 7.28354L3.86655 7.43354C3.79155 7.45854 3.70821 7.45854 3.63321 7.43354L3.11655 7.28354L3.26655 7.80021C3.29155 7.87521 3.29155 7.95854 3.26655 8.03354L3.11655 8.55021L3.63321 8.40021C3.67488 8.39187 3.70821 8.38354 3.74988 8.38354Z" fill="#071942"/>
    <path d="M17.4999 13.7505C17.4582 13.7505 17.4249 13.7422 17.3832 13.7339L16.2499 13.4005L15.1165 13.7339C14.9665 13.7755 14.8165 13.7339 14.7082 13.6255C14.5999 13.5172 14.5582 13.3589 14.5999 13.2172L14.9332 12.0839L14.5999 10.9505C14.5582 10.8005 14.5999 10.6422 14.7082 10.5422C14.8165 10.4422 14.9749 10.3922 15.1165 10.4339L16.2499 10.7672L17.3832 10.4339C17.5249 10.3922 17.6832 10.4339 17.7915 10.5422C17.8999 10.6505 17.9415 10.8089 17.8999 10.9505L17.5665 12.0839L17.8999 13.2172C17.9415 13.3672 17.8999 13.5255 17.7915 13.6255C17.7165 13.7089 17.6082 13.7505 17.4999 13.7505ZM16.2499 12.5505C16.2915 12.5505 16.3249 12.5589 16.3665 12.5672L16.8832 12.7172L16.7332 12.2005C16.7082 12.1255 16.7082 12.0422 16.7332 11.9672L16.8832 11.4505L16.3665 11.6005C16.2915 11.6255 16.2082 11.6255 16.1332 11.6005L15.6165 11.4505L15.7665 11.9672C15.7915 12.0422 15.7915 12.1255 15.7665 12.2005L15.6165 12.7172L16.1332 12.5672C16.1749 12.5589 16.2082 12.5505 16.2499 12.5505Z" fill="#071942"/>
    </svg>
  )
}

export default MagicWand