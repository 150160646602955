import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getMapping } from '../../../redux/slices/mapping/mappingStep2Slice'
import { useSelector } from 'react-redux'
import EditFieldMapping from '../editFieldMapping/editFieldMapping'
import UploadedFiles from '../uploadedFiles/uploadedFiles'

function FieldsAndFiles() {
    const params = useParams()
    const dispatch = useDispatch()
const {get_mapping}=useSelector((state)=>state.mappingStep2Reducer)

    useEffect(()=>{
dispatch(getMapping(params.mapping_uuid))
    },[])
  return (
    <div>
        {get_mapping?.sub_datasource?.datasource?.name==="pdf"?
        <UploadedFiles />:<EditFieldMapping/>}
    </div>
  )
}

export default FieldsAndFiles