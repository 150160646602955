import axios from "../../../utils/axios";

export const UpdateQuickView = async (
  business_unit_uuid,
 sub_datasource_uuid,
 quick_view_fields,
 custom_fields
) => {

  let response = await axios(
    process.env.REACT_APP_PREDICTION_SERVICE +
      `quick_view_setting/${localStorage.getItem("ci")}/${business_unit_uuid}/${sub_datasource_uuid}`,
    {
      method: "PUT",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        quick_view_fields,custom_fields
        ,
      },
    }
  );
  return { data: response.data, status: response.status };
};