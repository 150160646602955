import React from 'react'

function SparesIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.28125 2.87778C6.71563 2.87778 6.48438 2.47778 6.76563 1.98716C6.92813 1.70278 6.83125 1.34028 6.54688 1.17778L6.00625 0.868407C5.75938 0.721532 5.44062 0.809032 5.29375 1.05591L5.25938 1.11528C4.97813 1.60591 4.51563 1.60591 4.23125 1.11528L4.19687 1.05591C4.05625 0.809032 3.7375 0.721532 3.49062 0.868407L2.95 1.17778C2.66562 1.34028 2.56875 1.70591 2.73125 1.99028C3.01562 2.47778 2.78438 2.87778 2.21875 2.87778C1.89375 2.87778 1.625 3.14341 1.625 3.47153V4.02153C1.625 4.34653 1.89063 4.61528 2.21875 4.61528C2.78438 4.61528 3.01562 5.01528 2.73125 5.50591C2.56875 5.79028 2.66562 6.15278 2.95 6.31528L3.49062 6.62466C3.7375 6.77153 4.05625 6.68403 4.20312 6.43716L4.2375 6.37778C4.51875 5.88716 4.98125 5.88716 5.26562 6.37778L5.3 6.43716C5.44688 6.68403 5.76563 6.77153 6.0125 6.62466L6.55313 6.31528C6.8375 6.15278 6.93437 5.78716 6.77187 5.50591C6.4875 5.01528 6.71875 4.61528 7.28438 4.61528C7.60938 4.61528 7.87813 4.34966 7.87813 4.02153V3.47153C7.875 3.14653 7.60938 2.87778 7.28125 2.87778ZM4.75 4.76216C4.19063 4.76216 3.73438 4.30591 3.73438 3.74653C3.73438 3.18716 4.19063 2.73091 4.75 2.73091C5.30937 2.73091 5.76562 3.18716 5.76562 3.74653C5.76562 4.30591 5.30937 4.76216 4.75 4.76216Z" fill="#071942" fill-opacity="0.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.89465 8.22271L2.65039 8.07454V15.1667C2.65039 17.3758 4.44125 19.1667 6.65039 19.1667H15.3171C17.5262 19.1667 19.3171 17.3758 19.3171 15.1667V6.5C19.3171 4.29086 17.5262 2.5 15.3171 2.5H8.84355C9.12773 2.65302 9.32319 2.96629 9.32643 3.32321V4.17783C9.32643 4.68769 8.93246 5.10043 8.45603 5.10043C7.62687 5.10043 7.28787 5.72197 7.70474 6.48433C7.94296 6.92135 7.80095 7.48948 7.38407 7.74198L6.59155 8.22271C6.22965 8.45093 5.76238 8.31497 5.54707 7.93136L5.49668 7.8391C5.07981 7.07674 4.40181 7.07674 3.98952 7.8391L3.93913 7.93136C3.72382 8.31497 3.25655 8.45093 2.89465 8.22271ZM7.27898 9.16719H8.01972C8.27435 9.16719 8.48268 9.37434 8.48268 9.638V13.6964C8.48268 13.9553 8.27435 14.1672 8.01972 14.1672H7.27898C7.02435 14.1672 6.81602 13.9553 6.81602 13.6964V9.638C6.81602 9.37905 7.02435 9.16719 7.27898 9.16719ZM11.3541 7.5H10.6134C10.3587 7.5 10.1504 7.71723 10.1504 7.98274V13.6839C10.1504 13.9494 10.3587 14.1667 10.6134 14.1667H11.3541C11.6087 14.1667 11.8171 13.9494 11.8171 13.6839V7.98274C11.8171 7.71723 11.6087 7.5 11.3541 7.5ZM13.9477 6.66719H14.6885C14.9431 6.66719 15.1514 6.86572 15.1514 7.10836V13.726C15.1514 13.9687 14.9431 14.1672 14.6885 14.1672H13.9477C13.6931 14.1672 13.4848 13.9687 13.4848 13.726V7.10836C13.4848 6.86572 13.6931 6.66719 13.9477 6.66719Z" fill="#071942" fill-opacity="0.8"/>
    </svg>
  )
}

export default SparesIcon