import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

function Cardline({ value }) {
  useEffect(() => {
    Highcharts.chart('container', {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          minWidth: 600,
          scrollPositionX: 1
        }
      },
      title: {
        text: '',
        align: 'left'
      }, credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: {
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [{ // Strong Gale
          from: 20.5,
          to: 24,
          color: '#6633B0',
          label: {
            text: 'Strong gale',
            style: {
              color: '#606060'
            }
          }
        }]
      },
      plotOptions: {
        spline: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          marker: {
            enabled: false
          },
          pointInterval: 360000, // one hour
          pointStart: Date.UTC(2020, 3, 15, 0, 0, 0)
        }
      },
      series: [{
        name: '',
        data: [
          0.2, 0.1, 0.1, 0.5, 0.3, 0.2, 0.1,
          0.1, 0.1, 0.1, 0.2, 1.1, 1.3,
          2.0, 1.5, 1.5, 1.5, 1.4, 1.7,
          2.0, 2.9, 2.1, 2.1, 3.5, 2.9
        ]
      }],
      navigation: {
        menuItemStyle: {
          fontSize: '10px'
        }
      }
    });
  }, []); // Empty dependency array ensures this runs only once

  return <div id="container" style={{ height: '200px' }} />;
}

export default Cardline;
