import { Grid, ThemeProvider } from "@mui/material";
import Sidenav from "./components/sidenav/sidenav";
import { theme } from "./theme";

import { Provider } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Authentication from "./authentication/authentication";
import store from "./redux/store/configure-store";
import Airesolveview from "./views/airesolveview/airesolveview";
import ExtAiresolveview from "./views/extAiresolveview/extAiresolve";
import ZendeskAiresolve from "./views/extAiresolveview/zendeskAiresolve";
import HomeView from "./views/homeview/homeview";
import Inboxview from "./views/inboxView/inboxview";
import Insightsviews from "./views/insightviews/insightviews";
import Leaderboardview from "./views/leaderboardview/leaderboardview";
import Logout from "./views/logoutview/logout";
import AllRequests from "./views/mappingview/allRequest/allRequest";
import FieldsAndFiles from "./views/mappingview/editField&fileUploads/FieldsAndFiles";
import EditViewMapping from "./views/mappingview/editViewMapping/editViewMapping";
import ListMappings from "./views/mappingview/listMappings/listMappings";
import ObfuscationStep from "./views/mappingview/obfuscationStep";
import PdfStepper from "./views/mappingview/pdfStepper";
import SelectDataSource from "./views/mappingview/selectDataSource";
import FieldMapping from "./views/mappingview/fieldMapping";
import MapSummary from "./views/mappingview/mappingSummary";
import ViewMapping from "./views/mappingview/viewMapping/viewMapping";
import SlackAuthView from "./views/slackAuthView/slackAuthView";
import AiBotView from "./views/supportChannelview/aibotView/aibotView";
import ListBots from "./views/supportChannelview/multipleAIBotView /ListBots";
import BasicDetails from "./views/supportChannelview/multipleAIBotView /stepper1";
import MappingSelection from "./views/supportChannelview/multipleAIBotView /stepper2";
import MultipleAibotSummary from "./views/supportChannelview/multipleAIBotView /stepper3";
import ViewAiBot from "./views/supportChannelview/multipleAIBotView /ViewAiBot";
import SettingsView from "./views/supportChannelview/settingsView/settingsView";
import SlackBotView from "./views/supportChannelview/slackbotView/slackbotView";
import ZendeskDetailedView from "./views/zendeskDetailedView/zendeskDetailedView";
import EditObfuscation from "./views/mappingview/editObfuscation/editObfuscation";
import NewInsights from "./views/newInsights/NewInsights";

function App() {
  const location = useLocation();
  const shouldShowNavbarAndSidebar =
    !["/logout", "/slack/auth"].includes(location.pathname) &&
    !location.pathname.includes("/detailed-doc");
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Grid display="flex">
          {shouldShowNavbarAndSidebar && <Sidenav />}
          <Grid
            mt={shouldShowNavbarAndSidebar ? "70px" : null}
            sx={shouldShowNavbarAndSidebar && { width: "85%", flex: "1 1" }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate to={`/${localStorage.getItem("ci")}/home`} />
                }
              />
              <Route path="/slack/auth" element={<SlackAuthView />} />
              <Route
                path="/:customer_uuid/authentication/:access_token/:refresh_token"
                element={<Authentication />}
              />
              <Route
                path="/:customer_uuid/detailed-doc/:business_unit_uuid/:sub_datasource_uuid/:document_id/:query/:access_token/:refresh_token"
                element={<ZendeskDetailedView />}
              />

              <Route path="/:customer_uuid/home" element={<HomeView />} />
              <Route
                path="/:customer_uuid/leaderboard"
                element={<Leaderboardview />}
              />
              <Route
                path="/:customer_uuid/insights"
                element={<Insightsviews />}
              />
            <Route
                path="/:customer_uuid/new-insights"
                element={<NewInsights/>}
              />
              <Route
                path="/:customer_uuid/ai-resolve"
                element={<Airesolveview />}
              />
              <Route
                path="/:customer_uuid/resolution-prediction-v2"
                element={<ExtAiresolveview />}
              />
              <Route
                path="/:customer_uuid/resolution-prediction-zen"
                element={<ZendeskAiresolve />}
              />

              <Route path="/:customer_uuid/inbox" element={<Inboxview />} />

              <Route
                path="/:customer_uuid/mapping"
                element={<ListMappings />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/edit/fields-files"
                element={<FieldsAndFiles />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/all-requests"
                element={<AllRequests />}
              />
              <Route
                path="/:customer_uuid/mapping/select-datasource"
                element={<SelectDataSource />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/select-datasource"
                element={<SelectDataSource />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/obfuscation"
                element={<ObfuscationStep />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/edit/obfuscation"
                element={<EditObfuscation />}
              />
                <Route
                path="/:customer_uuid/mapping/:mapping_uuid/files-uploaded"
                element={<PdfStepper />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/field-mapping"
                element={<FieldMapping />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/summary"
                element={<MapSummary />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/view-mapping"
                element={<ViewMapping />}
              />
              <Route
                path="/:customer_uuid/mapping/:mapping_uuid/edit/view-mapping"
                element={<EditViewMapping />}
              />
              <Route
                path="/:customer_uuid/support-channel/aibot"
                element={<AiBotView />}
              />
                  <Route
                path="/:customer_uuid/support-channel/aibots"
                element={<ListBots />}
              />
                <Route
                path="/:customer_uuid/support-channel/aibots/step-1"
                element={<BasicDetails />}
              />
                <Route
                path="/:customer_uuid/support-channel/aibots/:bot_uuid/view"
                element={<ViewAiBot />}
              />
                  <Route
                path="/:customer_uuid/support-channel/aibots/:bot_uuid/step-1"
                element={<BasicDetails />}
              />
                   <Route
                path="/:customer_uuid/support-channel/aibots/:bot_uuid/step-2"
                element={<MappingSelection />}
              />
                  <Route
                path="/:customer_uuid/support-channel/aibots/:bot_uuid/step-3"
                element={<MultipleAibotSummary />}
              />
              <Route
                path="/:customer_uuid/support-channel/slackbot"
                element={<SlackBotView />}
              />

              <Route
                path="/:customer_uuid/support-channel/settings"
                element={<SettingsView />}
              />

              <Route path="/home" element={<HomeView />} />

              <Route path="/logout" element={<Logout />} />
            </Routes>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
