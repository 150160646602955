import { create } from "lodash";
import axios from "../../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSapActivityStatus = createAsyncThunk(
  "getSapActivityStatus",
  async (thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_DATA_INTEGRATION +
        `sap_fsm/${localStorage.getItem("ci")}/activity_status`,
      {
        method: "GET",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return { status: response.status, data: response.data };
  }
);

export const getSapActivityType = createAsyncThunk(
    "getSapActivityType",
    async (thunkAPI) => {
      const response = await axios(
        process.env.REACT_APP_DATA_INTEGRATION +
          `sap_fsm/${localStorage.getItem("ci")}/activity_type`,
        {
          method: "GET",
          headers: {
            accept: "application/json,text/plain",
            "Content-Type": "application/json",
          },
        }
      );
      return { status: response.status, data: response.data };
    }
  );