import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import ArrowLeftIcon from "../../../assets/ArrowLeftIcon";
import ArrowRightIcon from "../../../assets/ArrowRightIcon";

import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomStepper from "../../../components/stepper/customStepper";
import { getmappingSummary } from "../../../redux/slices/mapping/mappingsummarySlice";
import { requestData } from "../../../redux/slices/mapping/listMappingSlice";
import { getAibot, getBotVersions } from "../../../redux/slices/supportChannels/supportChannelsSlice";

function ViewAiBot() {
  const steps = ["Basic Details", "Mapping Selection", "Summary"];

  const navigate = useNavigate();
  const [customer_uuid, setCustomer_uuid] = useState(
    localStorage.getItem("ci")
  );
  const params = useParams();
  const selectedMaps= [{ds:"Tickets",name:"Zendesk Tickets"},{ds:"Converge OS",name:"Converge OS Mapping"}]
  const {bot_settings,bot_versions}=useSelector((state)=>state.supportChannelReducer)
  const handleClose = () => {
    navigate(`/${customer_uuid}/support-channel/aibots`);
  };
  const handlePrevStep = () => {
    navigate(`/${customer_uuid}/support-channel/aibots/${params.bot_uuid}/step-2`);
  };

  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();

  const handleFinish = () => {
    navigate(`/${customer_uuid}/support-channel/aibots`);
   
  };


  useEffect(() => {
  dispatch(getAibot(params.bot_uuid))
  dispatch(getBotVersions())
  }, []);

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        zIndex: 9999,
        position: "absolute",
        top: 0,
        left: 0,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {/* Sidebar */}
      <Grid
        width="232px"
        sx={{ backgroundColor: colors.ascendo.darkblue, opacity: 0.6 }}
      ></Grid>

      {/* Main Content */}
      <Grid
        width="calc(100% - 232px)"
        backgroundColor="white"
        position="relative"
        sx={{ opacity: 1, borderTopLeftRadius: "6px" }}
      >
        <Grid display="flex" justifyContent="space-between" marginRight="40px">
          <Typography
            margin="40px 0px 0px 46px"
            color={colors.ascendo.darkblue}
            sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
          >
            AI Bot Details
          </Typography>
          <Grid marginTop="20px">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Typography
          margin="8px 0px 20px 46px"
          color={colors.ascendo.darkblue}
          sx={{ fontSize: "14px", lineHeight: "17px" }}
        >
              View AI Bot details here.
              </Typography>
        <Grid  >
          <Divider variant="middle" />
          <Grid  sx={{gap:"24px",backgroundColor:"rgba(205, 205, 205, 0.12)",height:"80px",px:"46px",m:"20px",alignItems:"center",display:"flex",borderRadios:"8px"}}>
              
                <Grid >
                  <Grid>
                    <Typography
                      fontSize="12px"
                      lineHeight="17px"
                      color={colors.grey.border}
                    >
                      Bot Name
                    </Typography>

                    <Typography
                      marginTop="6px"
                      fontSize="14px"
                      lineHeight="19px"
                      fontWeight={600}
                      color={colors.ascendo.blue}
                    >
                      {bot_settings?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid >
                  <Grid>
                    <Typography
                      fontSize="12px"
                      lineHeight="17px"
                      color={colors.grey.border}
                    >
                      Version
                    </Typography>

                    <Typography
                      marginTop="6px"
                      fontSize="14px"
                      fontWeight={600}
                      lineHeight="19px"
                      color={colors.ascendo.blue}
                    >
                      {bot_settings?.version?.label}
                    </Typography>
                  </Grid>
                </Grid>
                
              </Grid>
        </Grid>

        <Grid variant="stepperScroller">
          <div>
            
            <Grid marginTop="26px" marginX="46px" height="412px">
              <Typography
                fontSize="16px"
                fontWeight="500"
                color={colors.ascendo.darkblue}
              >
                Active Data Mappings
              </Typography>
              <Grid marginTop="12px" container spacing={2}>
                {bot_settings?.mappings?.map((mapping, index) => (
                  <Grid item md={4}>
                    <Box
                      component="div"
                      border="1px solid"
                      borderRadius="6px"
                      borderColor={colors.grey.light}
                    >
                      <Grid container alignItems="center" height="80px">
                        <Grid
                          item
                          md={3}
                          justifyContent="center"
                          display="flex"
                        >
                          <Typography
                            fontSize="16px"
                            fontWeight="500"
                            color={colors.ascendo.darkblue}
                          >
                            {index + 1}
                          </Typography>
                        </Grid>
                        <Grid item md={9}>
                          <Typography
                            fontSize="12px"
                            fontWeight="400"
                            color={colors.grey.border}
                          >
                            {mapping.sub_datasource.name}
                          </Typography>
                          <Typography
                            fontSize="14px"
                            fontWeight="400"
                            color={colors.ascendo.darkblue}
                          >
                            {mapping.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Divider variant="middle" />

        {/* Continue Button */}
        <Grid variant="stepperFixedbottombtn">
          <Grid
            display="flex"
            justifyContent="flex-end"
            marginBottom="32px"
            marginRight="40px"
            gap={2}
          >
            {/* <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                sx={{
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
                startIcon={<ArrowLeftIcon />}
                onClick={handlePrevStep}
              >
                Back
              </Button>
            </Grid> */}

            <Grid item width="120px" margin="26px 0px 0px 0px">
              <Button
                fullWidth
                onClick={handleFinish}
                sx={{
                  backgroundColor: colors.ascendo.yellow,
                  color: colors.ascendo.darkblue,
                  textTransform: "none",
                }}
              >
                {"Close"}
                
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ViewAiBot;
