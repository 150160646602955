import React from 'react'

function GuideIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3801 6.49816L12.3803 6.49831L12.3846 6.49395C12.7945 6.08404 13.2126 5.28793 13.2126 4.55659V3.22837C13.2126 2.13212 12.3243 1.24375 11.228 1.24375H3.24079C2.12325 1.24375 1.2562 2.15348 1.2562 3.2912V4.68221C1.2562 4.97285 1.33675 5.3028 1.46965 5.61135C1.60239 5.91954 1.79035 6.21337 2.01188 6.43061L4.27003 8.80525C4.35474 8.90683 4.43433 9.05038 4.49288 9.19834C4.55231 9.34853 4.58571 9.49225 4.58571 9.59119V12.795C4.58571 13.5136 4.96448 14.1567 5.59635 14.5152C5.90815 14.6947 6.24842 14.7797 6.58827 14.7797C6.95746 14.7797 7.32536 14.6754 7.66345 14.4688L7.66347 14.4689L7.66593 14.4673L8.49005 13.9298C8.75483 13.7705 8.98316 13.5092 9.1452 13.2107C9.30773 12.9114 9.40747 12.5675 9.40747 12.2386V9.63608C9.40747 9.50002 9.44604 9.33386 9.51072 9.18014C9.57588 9.02527 9.66218 8.89568 9.74812 8.82469L9.74816 8.82473L9.75066 8.82252L12.3801 6.49816ZM3.24079 2.79889H3.34079V2.7899H11.228C11.4689 2.7899 11.6664 2.98744 11.6664 3.22837V4.55659C11.6664 4.67972 11.6189 4.84643 11.5472 5.0072C11.4763 5.16632 11.3891 5.3031 11.3208 5.37229L8.73926 7.65805C8.22221 8.10033 7.86132 8.9093 7.86132 9.64503V12.2476C7.86132 12.3019 7.83712 12.3868 7.79559 12.4699C7.77551 12.51 7.75304 12.5464 7.7308 12.5751C7.7076 12.605 7.6888 12.6214 7.67759 12.6279L7.67754 12.6278L7.67376 12.6302L6.83968 13.1684C6.83958 13.1684 6.83949 13.1685 6.83939 13.1686C6.68319 13.2678 6.49141 13.235 6.36807 13.1656L6.36813 13.1655L6.36377 13.1633C6.27566 13.1192 6.14079 13.0127 6.14079 12.795V9.59119C6.14079 8.94211 5.7958 8.22957 5.44548 7.79627L5.44573 7.79606L5.44014 7.79019L3.1248 5.35815L3.12482 5.35813L3.12308 5.35639C3.03624 5.26955 2.95476 5.13575 2.89458 5.00143C2.83311 4.86422 2.80235 4.74556 2.80235 4.6912V3.30018C2.80235 3.00375 3.00096 2.79889 3.24079 2.79889Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
<path d="M18.0352 2.1542L18.0351 2.15418C17.5603 1.88419 16.7457 1.66686 15.5702 1.47838C15.1551 1.40616 14.7551 1.69612 14.6833 2.11659C14.6112 2.53891 14.8992 2.94171 15.3232 3.00409C16.6818 3.21864 17.1438 3.43153 17.2724 3.50382C18.5974 4.2574 19.2049 5.71689 19.2049 8.30778V13.6924C19.2049 15.7539 18.8372 17.1216 17.9812 17.9776C17.1251 18.8336 15.7575 19.2014 13.6959 19.2014H8.31133C6.24979 19.2014 4.88213 18.8336 4.02611 17.9776C3.17009 17.1216 2.80235 15.7539 2.80235 13.6924V11.0001C2.80235 10.5769 2.45246 10.227 2.02928 10.227C1.6061 10.227 1.2562 10.5769 1.2562 11.0001V13.6924C1.2562 16.1404 1.77878 17.9112 2.93565 19.0681C4.09252 20.2249 5.86331 20.7475 8.31133 20.7475H13.6959C16.144 20.7475 17.9147 20.2249 19.0716 19.0681C20.2285 17.9112 20.7511 16.1404 20.7511 13.6924V8.30778C20.7511 5.18564 19.8928 3.21175 18.0352 2.1542Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
<path d="M11.9011 12.6698H16.3883C16.8115 12.6698 17.1614 12.3199 17.1614 11.8967C17.1614 11.4735 16.8115 11.1236 16.3883 11.1236H11.9011C11.4779 11.1236 11.128 11.4735 11.128 11.8967C11.128 12.3199 11.4779 12.6698 11.9011 12.6698Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
<path d="M10.1062 16.2596H16.3882C16.8114 16.2596 17.1613 15.9097 17.1613 15.4866C17.1613 15.0634 16.8114 14.7135 16.3882 14.7135H10.1062C9.68301 14.7135 9.33311 15.0634 9.33311 15.4866C9.33311 15.9097 9.68301 16.2596 10.1062 16.2596Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
</svg>

  )
}

export default GuideIcon