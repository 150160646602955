import axios from "../../../utils/axios"
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getAnswersForInsights = createAsyncThunk(
  "getAnswersForInsights",
  async (input_query, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_NLP +
        `nlp2sql/${localStorage.getItem("ci")}/nlp_to_sql?input_query=${input_query}
    `,
      {
        method: "POST",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
        data:{}
      }
    );
    return { data: response.data, status: response.status };
  }
);
