import axios from "../../../utils/axios";

export const GetSlackWorkspaces = async () => {
  let response = await axios(
    process.env.REACT_APP_SLACK+
    `workspace/${localStorage.getItem("ci")}/status?is_active=true`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};