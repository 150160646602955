import axios from "../../../utils/axios";

export const GetSubDataSourceFields = async (sub_datasource_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `sub_datasource_field/${localStorage.getItem(
        "ci"
      )}?sub_datasource_uuid=${sub_datasource_uuid}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};
