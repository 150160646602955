import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

function SemiPie() {
  useEffect(() => {
    Highcharts.chart('semipie', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false
   },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white',
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%',
        },
      },
      series: [{
        type: 'pie',
        name: 'Browser share',
        innerSize: '50%',
        data: [
          ['', 33.86],
          ['', 33.97],
          ['', 33.52]
        ],
      }],
    });
  }, []); 

  return <div style={{height: "300px", width: "500px"}} id="semipie" />;
}

export default SemiPie;
