import React from 'react'

function Uniquesearch() {
  return (
    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 25.9375C7.025 25.9375 1.5625 20.475 1.5625 13.75C1.5625 7.025 7.025 1.5625 13.75 1.5625C14.2625 1.5625 14.6875 1.9875 14.6875 2.5C14.6875 3.0125 14.2625 3.4375 13.75 3.4375C8.0625 3.4375 3.4375 8.0625 3.4375 13.75C3.4375 19.4375 8.0625 24.0625 13.75 24.0625C19.4375 24.0625 24.0625 19.4375 24.0625 13.75C24.0625 13.2375 24.4875 12.8125 25 12.8125C25.5125 12.8125 25.9375 13.2375 25.9375 13.75C25.9375 20.475 20.475 25.9375 13.75 25.9375Z" fill="#071942"/>
    <path d="M25.1994 28.4875C25.0994 28.4875 24.9994 28.475 24.9119 28.4625C24.3244 28.3875 23.2619 27.9875 22.6619 26.2C22.3494 25.2625 22.4619 24.325 22.9744 23.6125C23.4869 22.9 24.3494 22.5 25.3369 22.5C26.6119 22.5 27.6119 22.9875 28.0619 23.85C28.5119 24.7125 28.3869 25.8125 27.6744 26.875C26.7869 28.2125 25.8244 28.4875 25.1994 28.4875ZM24.4494 25.6125C24.6619 26.2625 24.9619 26.5875 25.1619 26.6125C25.3619 26.6375 25.7369 26.4 26.1244 25.8375C26.4869 25.3 26.5119 24.9125 26.4244 24.7375C26.3369 24.5625 25.9869 24.375 25.3369 24.375C24.9494 24.375 24.6619 24.5 24.4994 24.7125C24.3494 24.925 24.3244 25.25 24.4494 25.6125Z" fill="#071942"/>
    <path d="M22.3078 10.8359C21.9903 10.8359 21.6118 10.75 21.16 10.4801L20 9.78071L18.84 10.4678C17.7777 11.1059 17.0817 10.7378 16.8253 10.5537C16.5689 10.3697 16.0194 9.81753 16.3002 8.60281L16.5933 7.33901L15.6165 6.43101C15.0792 5.89113 14.8838 5.24085 15.067 4.6519C15.2502 4.06294 15.7874 3.64575 16.5445 3.52305L17.851 3.3022L18.4737 1.92798C18.8278 1.22859 19.3773 0.835938 20 0.835938C20.6227 0.835938 21.1844 1.24087 21.5263 1.94026L22.2467 3.3881L23.4555 3.53533C24.2004 3.65803 24.7376 4.07519 24.933 4.66414C25.1162 5.2531 24.9208 5.90342 24.3835 6.44329L23.3701 7.46171L23.6875 8.60281C23.9684 9.81753 23.4189 10.3697 23.1625 10.5537C23.0282 10.6641 22.7351 10.8359 22.3078 10.8359ZM17.0817 5.31448L17.9242 6.16107C18.315 6.55371 18.5103 7.2163 18.3882 7.75618L18.1562 8.73776L19.1331 8.16107C19.6581 7.85432 20.3663 7.85432 20.8791 8.16107L21.856 8.73776L21.6362 7.75618C21.5141 7.20403 21.6972 6.55371 22.088 6.16107L22.9305 5.31448L21.8682 5.1304C21.3554 5.04451 20.8425 4.66417 20.6105 4.19792L20 2.99545L19.3895 4.22244C19.1697 4.67643 18.6569 5.06908 18.144 5.15497L17.0817 5.31448Z" fill="#071942"/>
    </svg>
    
  )
}

export default Uniquesearch