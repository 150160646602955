

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetUserInfoAPI } from '../actions/identity/authActions';
import { GetModuleByModuleName } from '../actions/identity/moduleActions';

export const getUserInfo = createAsyncThunk('userinfo',
    async (thunkAPI) => {
        const response = await GetUserInfoAPI()
        return response
    }
)

export const getModuleInfo = createAsyncThunk('moduleinfo',
    async (module_name,thunkAPI) => {
        const response = await GetModuleByModuleName(module_name)
        return response
    }
)

export const getLogInfo = createAsyncThunk('loginfo',
    async (module_name,thunkAPI) => {
        const response = await GetModuleByModuleName(module_name)
        return response
    }
)

const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    initialState: {
        userdetails: null,
        status: null,

    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserInfo.pending, (state, action) => {
            state.status = false
        })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                if(action.payload.status===200){
                state.userdetails = action.payload.data
                localStorage.setItem("ud", JSON.stringify(action.payload.data));
                localStorage.setItem('ui',action.payload.data.uuid)

                state.status = true
                }
                else{
                    state.status=false
                }

            })
            .addCase(getModuleInfo.fulfilled, (state, action) => {
                if(action.payload.status===200){
                localStorage.setItem("mi",action.payload.data.data.uuid);

                state.status = true
                }
                else{
                    state.status=false
                }

            })
            .addCase(getLogInfo.fulfilled, (state, action) => {
                if(action.payload.status===200){
                localStorage.setItem("li",action.payload.data.data.uuid);

                }
                

            })
            .addCase(getUserInfo.rejected, (state, action) => {
                state.status = false
            })
    },
})

export default authenticationSlice.reducer