import React from "react";

function MappingIcon({ opacity }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6ZM14.2487 12.0373V7.62438C14.2487 6.86438 13.6337 6.24938 12.8737 6.24938H11.4168L12.1162 5.66803C12.2762 5.53303 12.3013 5.29803 12.1663 5.13803C12.0313 4.97803 11.7963 4.95303 11.6363 5.08803L10.1363 6.33803C10.0513 6.41303 10.0013 6.51303 10.0013 6.62803C10.0013 6.74303 10.0513 6.84803 10.1363 6.91803L11.6363 8.16803C11.7063 8.22303 11.7913 8.25303 11.8763 8.25303C11.9813 8.25303 12.0913 8.20803 12.1663 8.11803C12.2963 7.95803 12.2762 7.72303 12.1162 7.58803L11.408 6.99938H12.8737C13.2187 6.99938 13.4987 7.27938 13.4987 7.62438V12.0378C12.6439 12.2122 12 12.9689 12 13.875C12 14.91 12.84 15.75 13.875 15.75C14.91 15.75 15.75 14.91 15.75 13.875C15.75 12.968 15.1049 12.2107 14.2487 12.0373ZM8.74875 13.875C8.74875 12.9684 8.10426 12.2114 7.24875 12.0376V8.98517C8.23565 8.86224 8.99875 8.02096 8.99875 7.00062C8.99875 5.89563 8.10375 5.00063 6.99875 5.00063C5.89375 5.00063 4.99875 5.89563 4.99875 7.00062C4.99875 7.93296 5.6359 8.71579 6.49875 8.93768V12.0376C5.64324 12.2114 4.99875 12.9684 4.99875 13.875C4.99875 14.91 5.83875 15.75 6.87375 15.75C7.90875 15.75 8.74875 14.91 8.74875 13.875ZM5.74875 7.00062C5.74875 6.31063 6.30875 5.75063 6.99875 5.75063C7.68875 5.75063 8.24875 6.31063 8.24875 7.00062C8.24875 7.69062 7.68875 8.25062 6.99875 8.25062C6.30875 8.25062 5.74875 7.69062 5.74875 7.00062ZM6.87375 12.75C6.25375 12.75 5.74875 13.255 5.74875 13.875C5.74875 14.495 6.25375 15 6.87375 15C7.49375 15 7.99875 14.495 7.99875 13.875C7.99875 13.255 7.49375 12.75 6.87375 12.75ZM12.75 13.875C12.75 13.255 13.255 12.75 13.875 12.75C14.495 12.75 15 13.255 15 13.875C15 14.495 14.495 15 13.875 15C13.255 15 12.75 14.495 12.75 13.875Z"
        fill="#071942"
        fillOpacity={0.8}
      />
    </svg>
  );
}

export default MappingIcon;
