import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);

function Topproduct({ value }) {
  useEffect(() => {
    // Calculate the total value
    const total = value.data.reduce((sum, product) => sum + product.y, 0);

    Highcharts.chart('topProduct', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },

      legend: {
        width: 479,
      height: 180,
      layout: 'horizontal',
      y: 10,
        align: 'left',
       x: -100, // = marginLeft - default spacingLeft
       itemWidth: 80,
        borderWidth: 0,
      
    },
      credits: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '%',
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true
        },
      },
      series: [
        {
          colorByPoint: true,
          data: value.data.map(product => ({
            name: product.name,
            y: +((product.y / total) * 100).toFixed(2), // Convert to percentage with 2 decimal places
          })),
        },
      ],
    });
  }, [value]); 

  return <div id="topProduct" style={{ height: '300px' }} />;
}

export default Topproduct;
