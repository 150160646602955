import axios from "../../../utils/axios";

export const CreateChannelMapping = async (data) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `channel_mapping/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: data,
    }
  );
  return { data: response.data, status: response.status };
};
export const CreateChannelMappingForAibot = async (bot_uuid,mapping_uuids) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `channel_mapping/${localStorage.getItem("ci")}/ai_bot/${bot_uuid}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data:{mapping_uuids},
    }
  );
  return { data: response.data, status: response.status };
};

export const DeleteChannelMapping = async (data) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `channel_mapping/${localStorage.getItem("ci")}/delete`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: data,
    }
  );
  return { data: response.data, status: response.status };
};

export const GetAllChannelMappings = async (mapping_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION +
      `channel_mapping/${localStorage.getItem("ci")}/list`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        filter: {
          mapping_uuids: [mapping_uuid],
        },
      },
    }
  );
  return { data: response.data, status: response.status };
};



export const GetChannelMappingTable = async (

  search,
  page_no,
  page_size,
  bot_uuid
) => {
  let url =
    process.env.REACT_APP_DATA_INTEGRATION +
    `channel_mapping/${localStorage.getItem(
      "ci"
    )}/table/ai_bot?page_no=${page_no}&page_size=${page_size}`;
  if (search !== null) {
    url += `&search=${search}`;
  }
  let response = await axios(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      "Content-Type": "application/json",
    },
    
    data: {
      filter: {
       bot_uuid
      },
    },
  });
  return { data: response.data, status: response.status };
};

export const GetChannelMappingTableForSlack = async (

  
  page_no,
  page_size,
  workspace_uuid
) => {
  let url =
    process.env.REACT_APP_DATA_INTEGRATION +
    `channel_mapping/${localStorage.getItem(
      "ci"
    )}/table/slack?page_no=${page_no}&page_size=${page_size}`;
  
  let response = await axios(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("at"),
      "Content-Type": "application/json",
    },
    
    data: {
      filter: {
        workspace_uuid
      },
    },
  });
  return { data: response.data, status: response.status };
};

export const UpdateChannelMappingStatus = async (channel_mapping_uuid,bot_uuid,is_active) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `channel_mapping/${localStorage.getItem("ci")}/${channel_mapping_uuid}/ai_bot/${bot_uuid}?is_active=${is_active}`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};

export const UpdateChannelMappingStatusForSlack = async (channel_mapping_uuid,workspace_uuid,is_active) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `channel_mapping/${localStorage.getItem("ci")}/${channel_mapping_uuid}/slack/${workspace_uuid}?is_active=${is_active}`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};



