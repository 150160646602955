import React from 'react'

function NlpIcon() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="24px" height="24px" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <path d="M2100 5104 c-186 -40 -339 -139 -445 -289 -69 -98 -114 -204 -130
   -312 -4 -24 -10 -43 -14 -43 -5 0 -38 -27 -74 -60 -314 -288 -314 -782 0
   -1070 36 -33 69 -60 74 -60 4 0 10 -19 14 -42 35 -228 189 -437 397 -540 113
   -56 185 -72 323 -72 121 0 167 8 261 45 l50 20 95 -32 c84 -29 106 -33 214
   -33 71 -1 142 5 175 13 277 69 485 281 545 556 13 57 24 84 39 95 51 35 130
   119 170 179 201 303 155 692 -109 940 -36 33 -70 61 -74 61 -5 0 -12 19 -16
   43 -53 349 -366 617 -720 617 -79 0 -202 -24 -267 -52 l-48 -20 -47 20 c-115
   49 -285 64 -413 36z m223 -395 l37 -10 0 -834 0 -835 -27 -7 c-16 -5 -59 -8
   -98 -8 -120 1 -232 72 -287 183 -20 42 -23 61 -23 179 l0 132 -91 41 c-107 48
   -165 100 -201 178 -49 105 -39 224 26 323 37 55 92 95 192 138 l76 33 -5 112
   c-5 129 3 166 54 243 75 110 223 167 347 132z m689 -22 c66 -30 128 -90 160
   -155 20 -42 23 -61 23 -179 l0 -132 75 -32 c140 -61 204 -124 237 -238 48
   -166 -39 -321 -227 -404 l-85 -38 0 -132 c0 -119 -2 -137 -24 -179 -55 -112
   -166 -182 -286 -183 -38 0 -82 3 -97 8 l-28 7 0 835 0 834 33 10 c54 17 158 7
   219 -22z"/>
   <path d="M0 2435 l0 -1635 934 0 934 0 342 -395 c188 -217 345 -395 350 -395
   5 0 162 178 350 395 l342 395 934 0 934 0 0 1635 0 1635 -400 0 -400 0 0 -200
   0 -200 200 0 200 0 0 -1235 0 -1235 -827 -1 -828 0 -249 -290 c-137 -159 -252
   -289 -256 -289 -4 0 -119 130 -256 289 l-249 290 -827 0 -828 1 0 1235 0 1235
   200 0 200 0 0 200 0 200 -400 0 -400 0 0 -1635z"/>
   <path d="M1560 1800 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z"/>
   <path d="M2360 1800 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z"/>
   <path d="M3160 1800 l0 -200 200 0 200 0 0 200 0 200 -200 0 -200 0 0 -200z"/>
   </g>
   </svg>
  )
}

export default NlpIcon