import React from 'react'

function InboxFilter() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.90936 18.6624L7.91012 18.6628C8.29113 18.8706 8.69921 18.975 9.11634 18.975C9.55964 18.975 10.003 18.8533 10.3942 18.6099L10.3955 18.609L11.5442 17.8682C11.8546 17.6815 12.1277 17.3644 12.3231 17.0027C12.5189 16.6401 12.6413 16.2238 12.6413 15.8333V12.25C12.6413 12.0079 12.7062 11.7318 12.8144 11.4801C12.9231 11.2277 13.0713 11.0094 13.2304 10.8768L13.2304 10.8769L13.2326 10.8749L16.8409 7.68324L16.8411 7.68338L16.8454 7.67904C17.101 7.4234 17.3615 7.0422 17.5582 6.61472C17.7549 6.18732 17.8913 5.70615 17.8913 5.25V3.41667C17.8913 2.09477 16.8216 1.025 15.4997 1.025H4.49967C3.15787 1.025 2.10801 2.11474 2.10801 3.5V5.41667C2.10801 5.77201 2.21275 6.17976 2.38216 6.56332C2.55156 6.94687 2.78887 7.31363 3.06127 7.58635L6.17444 10.8826C6.29506 11.0313 6.42283 11.2561 6.52061 11.4961C6.6194 11.7385 6.68301 11.9851 6.68301 12.175V16.5917C6.68301 17.4619 7.14488 18.2367 7.90936 18.6624ZM4.09717 6.57279L4.09719 6.57276L4.09538 6.57096C3.94841 6.42398 3.81285 6.20638 3.71387 5.9852C3.6141 5.76224 3.55801 5.55026 3.55801 5.41667V3.5C3.55801 2.91471 3.97849 2.475 4.49967 2.475H15.508C16.0278 2.475 16.4497 2.8969 16.4497 3.41667V5.24167C16.4497 5.46751 16.3703 5.74431 16.2524 6.00338C16.1353 6.26084 15.9861 6.48803 15.856 6.61887L12.2927 9.77428C11.6433 10.3204 11.1997 11.3324 11.1997 12.25V15.8333C11.1997 15.9615 11.1484 16.1327 11.0652 16.2923C10.9813 16.453 10.8752 16.5824 10.7811 16.6395L10.7811 16.6395L10.7791 16.6408L9.62226 17.3815C9.62202 17.3817 9.62177 17.3818 9.62153 17.382C9.32977 17.5611 8.94366 17.563 8.62271 17.3873L8.62125 17.3865C8.47732 17.3108 8.14134 17.0709 8.14134 16.5833V12.1667C8.14134 11.3538 7.71204 10.4573 7.2774 9.92041L7.27764 9.92021L7.27217 9.91445L4.09717 6.57279Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    <path d="M4.61351 8.95139L4.61339 8.95158L4.61931 8.95477C4.73853 9.01896 4.8631 9.05839 5.00006 9.05839C5.24326 9.05839 5.4754 8.93192 5.61019 8.71086C5.61028 8.7107 5.61038 8.71054 5.61048 8.71038L9.71805 2.12827C9.71807 2.12825 9.71809 2.12822 9.71811 2.12819C9.93141 1.78875 9.82397 1.34411 9.48661 1.13206C9.14715 0.918687 8.70247 1.02612 8.49039 1.36351L8.49022 1.36378L4.38206 7.94684C4.38203 7.94689 4.382 7.94694 4.38197 7.94699C4.16901 8.28596 4.2757 8.73905 4.61351 8.95139Z" fill="#071942" stroke="#071942" stroke-width="0.2"/>
    </svg>
  )
}

export default InboxFilter