import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAllBuHavingPgUnderUser,
  GetAllPgInBuForUser,
} from "../actions/identity/organizationActions";
import { GetAllAccessableRegionForGroup } from "../actions/identity/regionActions";
import { create } from "@mui/material/styles/createTransitions";
import {
  ExportInsights,
  GetInsightsDashboard,
  GetEmailsForExclusion,
  AddEmailForExclusion,
  RemoveEmailForExclusion,
  ValidateEmailForExclusion,
  GetPredictionTable,
  GetUniquePredictionTable,
  GetViewsTable,
  GetVotesTable,
  GetInsightsRequestChannels,
} from "../actions/predictionService/insightsActions";
import {
  AddEmailForNotification,
  GetEmailsForNotification,
  RemoveEmailForNotification,
  ValidateEmailForNotification,
} from "../actions/predictionService/insightsEmailSettingsActions";
import { act } from "react-dom/test-utils";
import { getAnswersForInsights } from "../actions/nlpsql/nlpsqlActions";

const downloadFile = async (blob, filename) => {
  // Create a temporary link element
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;

  // Trigger the download
  downloadLink.click();

  // Cleanup the temporary link after download
  URL.revokeObjectURL(downloadLink.href);
};

export const getAllBuHavingPgUnderUser = createAsyncThunk(
  "getAllBuHavingPgUnderUser",
  async (thunkAPI) => {
    const response = await GetAllBuHavingPgUnderUser();
    return response;
  }
);

export const getAllPgInBuForUserInsights = createAsyncThunk(
  "getAllPgInBuForUserInsights",
  async (organization_uuid, thunkAPI) => {
    const response = await GetAllPgInBuForUser(organization_uuid);
    return response;
  }
);

export const getAllAccessableRegionForGroup = createAsyncThunk(
  "getAllAccessableRegionForGroup",
  async (thunkAPI) => {
    const response = await GetAllAccessableRegionForGroup();
    return response;
  }
);

export const getInsightsDashboard = createAsyncThunk(
  "getInsightsDashboard",
  async (data, thunkAPI) => {
    const response = await GetInsightsDashboard(
      data.business_unit_uuids,
      data.end_date,
      data.start_date,
      data.product_groups,
      data.regions,
      data.request_channels
    );
    return response;
  }
);
export const getPredictionTable = createAsyncThunk(
  "getPredictionTable",
  async (data, thunkAPI) => {
    const response = await GetPredictionTable(
      data.business_unit_uuids,
      data.end_date,
      data.start_date,
      data.product_groups,
      data.regions,
      data.page_no,
      data.page_size,
      data.search,
      data.request_channels
    );
    return response;
  }
);
export const getUniquePredictionTable = createAsyncThunk(
  "getUniquePredictionTable",
  async (data, thunkAPI) => {
    const response = await GetUniquePredictionTable(
      data.business_unit_uuids,
      data.end_date,
      data.start_date,
      data.product_groups,
      data.regions,
      data.page_no,
      data.page_size,
      data.search,
      data.request_channels
    );
    return response;
  }
);
export const getViewsTable = createAsyncThunk(
  "getViewsTable",
  async (data, thunkAPI) => {
    const response = await GetViewsTable(
      data.business_unit_uuids,
      data.end_date,
      data.start_date,
      data.product_groups,
      data.regions,
      data.page_no,
      data.page_size,
      data.search,
      data.request_channels
    );
    return response;
  }
);
export const getVotesTable = createAsyncThunk(
  "getVotesTable",
  async (data, thunkAPI) => {
    const response = await GetVotesTable(
      data.business_unit_uuids,
      data.end_date,
      data.start_date,
      data.product_groups,
      data.regions,
      data.page_no,
      data.page_size,
      data.search,
      data.request_channels
    );
    return response;
  }
);


export const exportInsights = createAsyncThunk(
  "exportInsights",
  async (data, thunkAPI) => {
    const responses = await ExportInsights(
      data.business_unit_uuids,
      data.end_date,
      data.start_date,
      data.product_groups,
      data.regions,
      data.request_channels
    );
    downloadFile(responses.response.data, responses.filename);
  }
);

export const getEmailsForNotification = createAsyncThunk(
  "getEmailsForNotification",
  async (thunkAPI) => {
    const response = await GetEmailsForNotification();
    return response;
  }
);

export const getInsightsRequestChannels = createAsyncThunk(
  "getInsightsRequestChannels",
  async (thunkAPI) => {
    const response = await GetInsightsRequestChannels();
    return response;
  }
);

export const validateEmailForNotification = createAsyncThunk(
  "validateEmailForNotification",
  async (email, thunkAPI) => {
    const response = await ValidateEmailForNotification(email);

    return { response, email };
  }
);

export const addEmailForNotification = createAsyncThunk(
  "addEmailForNotification",
  async (email_address, thunkAPI) => {
    await AddEmailForNotification(email_address);
    const response = await GetEmailsForNotification();

    return response;
  }
);

export const removeEmailForNotification = createAsyncThunk(
  "removeEmailForNotification",
  async (email_address, thunkAPI) => {
    await RemoveEmailForNotification(email_address);
    const response = await GetEmailsForNotification();

    return response;
  }
);

export const getEmailsForExclusion = createAsyncThunk(
  "getEmailsForExclusion",
  async (thunkAPI) => {
    const response = await GetEmailsForExclusion();
    return response;
  }
);

export const validateEmailForExclusion = createAsyncThunk(
  "validateEmailForExclusion",
  async (email, thunkAPI) => {
    const response = await ValidateEmailForExclusion(email);

    return { response, email };
  }
);

export const addEmailForExclusion = createAsyncThunk(
  "addEmailForExclusion",
  async (email_address, thunkAPI) => {
    await AddEmailForExclusion(email_address);
    const response = await GetEmailsForExclusion();

    return response;
  }
);

export const removeEmailForExclusion = createAsyncThunk(
  "removeEmailForExclusion",
  async (email_address, thunkAPI) => {
    await RemoveEmailForExclusion(email_address);
    const response = await GetEmailsForExclusion();

    return response;
  }
);

const insightsSlice = createSlice({
  name: "insightsSlice",
  initialState: {
    business_units: [],
    product_groups: [],
    regions: [],
    dashboard_details: null,
    included_emails: [],
    excluded_emails: [],
    add_emails: [],
    remove_emails: [],
    is_add_email: true,
    is_remove_email: true,
    answer_for_insights: "",
    is_answer_loading:false,
    prediction_table_details:null,
    unique_prediction_table_details:null,
    views_table_details:null,
    votes_table_details:null,
    req_channels:[]

  },
  reducers: {
    updateAddEmails: (state, action) => {
      state.add_emails = action.payload;
    },
    updateIsAddEmail: (state, action) => {
      state.is_add_email = action.payload;
    },
    updateRemoveEmails: (state, action) => {
      state.remove_emails = action.payload;
    },
    updateIsRemoveEmail: (state, action) => {
      state.is_remove_email = action.payload;
    },
    updateAnswer:(state,action)=>{
      state.answer_for_insights=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBuHavingPgUnderUser.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.business_units = action.payload.data;
      })
      .addCase(getAllPgInBuForUserInsights.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.product_groups = action.payload.data;
      })
      .addCase(getAllAccessableRegionForGroup.fulfilled, (state, action) => {
        if (action.payload.status === 200) state.regions = action.payload.data;
      })
      .addCase(getInsightsDashboard.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.dashboard_details = action.payload.data;
      })
      .addCase(getEmailsForNotification.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.included_emails = action.payload.data;
      })
      .addCase(addEmailForNotification.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.included_emails = action.payload.data;
        state.add_emails = [];
      })
      .addCase(removeEmailForNotification.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.included_emails = action.payload.data;
      })
      .addCase(validateEmailForNotification.fulfilled, (state, action) => {
        state.add_emails = state.add_emails.map((email) => {
          if (email.value === action.payload.email) {
            return {
              ...email,
              isApiValidatedEmail: action.payload.response.data,
            };
          } else {
            return email;
          }
        });
        if (action.payload.response.data) {
          state.is_add_email = true;
        }
      })
      .addCase(getEmailsForExclusion.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.excluded_emails = action.payload.data;
      })
      .addCase(addEmailForExclusion.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.excluded_emails = action.payload.data;
        state.remove_emails = [];
      })
      .addCase(removeEmailForExclusion.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.excluded_emails = action.payload.data;
      })
      .addCase(validateEmailForExclusion.fulfilled, (state, action) => {
        state.remove_emails = state.remove_emails.map((email) => {
          if (email.value === action.payload.email) {
            return {
              ...email,
              isApiValidatedEmail: action.payload.response.data,
            };
          } else {
            return email;
          }
        });
        if (action.payload.response.data) {
          state.is_remove_email = true;
        }
      })
      .addCase(getAnswersForInsights.pending, (state, action) => {
       
        state.is_answer_loading=true
      })
      .addCase(getAnswersForInsights.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.answer_for_insights = action.payload.data.data;
        }
        state.is_answer_loading=false
      }).addCase(getPredictionTable.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.prediction_table_details=action.payload.data
        }
      }).addCase(getUniquePredictionTable.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.unique_prediction_table_details=action.payload.data
        }
      })
      .addCase(getViewsTable.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.views_table_details=action.payload.data
        }
      })
      .addCase(getVotesTable.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.votes_table_details=action.payload.data
        }
      })
      .addCase(getInsightsRequestChannels.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.req_channels=action.payload.data.data
        }
      })
  },
});
export const {
  updateAddEmails,
  updateIsAddEmail,
  updateIsRemoveEmail,
  updateRemoveEmails,
  updateAnswer
} = insightsSlice.actions;
export default insightsSlice.reducer;
