import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Box, PaginationItem, Typography, useTheme } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import { styled } from '@mui/material/styles';
import {
  DataGrid, GridPagination
} from "@mui/x-data-grid";

import NoRecordsFound from "../../assets/NoRecordsFound";
import { updatePagination, updateRowCount, updateSelectionModelChange, updateTableRows } from "../../redux/slices/dataTableSlice";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function Pagination({ page, onPageChange, className }) {

  const { paginationDetails, rowCount } = useSelector((state) => state.dataTableReducer)

  const pageCount = Math.ceil(rowCount / paginationDetails.pageSize);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}

      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: ArrowLeftRoundedIcon,
            next: ArrowRightRoundedIcon,
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}
function NoRowsOverlay() {
  const theme = useTheme()
  const colors= theme.palette
  return (
    <StyledGridOverlay>
      <NoRecordsFound />
      <Typography fontSize="14px" color={colors.grey.border}>No Records Found</Typography>
    </StyledGridOverlay>
  );
}

const DataTable = ({ rows, columns, source, rowCount,disableCheckBox })=> {

  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = theme.palette;


  // Redux
  const { paginationDetails, tableRows, selectionModel } = useSelector((state) => state.dataTableReducer)

  // State Value
  const [paginationModel, setPaginationModel] = useState({
    pageSize: paginationDetails.pageSize,
    page: paginationDetails.page
  })

  useEffect(() => {
    dispatch(updateRowCount(rowCount))
    dispatch(updateTableRows(rows))
  }, [])
  const navigate = useNavigate()
  const customer_uuid = localStorage.getItem("ci");

  const handleEdit = (params) => {
    if (source === "mapping") {
      const mapping_uuid = params.row.id

      navigate(`/${customer_uuid}/mapping/${mapping_uuid}/edit`)

    }
    if (source === "process") {
      const process_uuid = params.row.id
      navigate(`/${customer_uuid}/process/${process_uuid}/edit`)
    }
    if (source === "review") {
      const review_uuid = params.row.id
      navigate(`/${customer_uuid}/review-update/${review_uuid}/step-1`)
    }
  }

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    dispatch(updatePagination(newPaginationModel))
  }

  const handleRowSelectionModelChange = (newSelectionModel) => {
    dispatch(updateSelectionModelChange(newSelectionModel))

  }
  const updatedColumns = columns.map((column) => ({
    ...column,
    sortable: false,
  }));

  return (
    <div style={{ height: tableRows?.length === 0 ? 300 : "auto" }} width="100%">
      <DataGrid

        rowHeight={48}
        columnHeaderHeight={42}
             sx={{
          border: "none",
              "& .MuiButtonBase-root.MuiPaginationItem-root":{
                
               height:"20px",
               minWidth:"20px"
                
                
              },
            
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(7, 25, 66, 0.12)",
            color: colors.ascendo.darkblue,
            fontSize: "15px"
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "18px",
          },
          "& .MuiDataGrid-row": { height: "42px" },
          "& .MuiDataGrid-cell": {
            color: colors.ascendo.darkblue,
            lineHeight: "17px",
          },
          "& .MuiCheckbox-root": {
            color: colors.grey.normal,
            "& .MuiChecked": {
              color: colors.ascendo.blue,
            },
          },

        }}
        disableRowSelectionOnClick
        slotProps={{ baseCheckbox: { size: "small" } }}
        rows={tableRows}
        columns={updatedColumns}
        
        pagination
        disableColumnMenu
        slots={{ pagination: CustomPagination, noRowsOverlay: NoRowsOverlay, noResultsOverlay: NoRowsOverlay }}
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}

        onRowClick={handleEdit}
        getRowId={(rows) => rows.id}
        pageSizeOptions={[5, 10]}
        checkboxSelection={disableCheckBox?false:true}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={selectionModel}
      />
    </div>
  );
}

export default DataTable;
