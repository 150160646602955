import { createSlice } from "@reduxjs/toolkit";

const accordionSlice = createSlice({
  name: "accordion",
  initialState: {
    expanded0:true,
    expanded1:false,
    expanded2:false,
    expanded3:false,

  },
  reducers: {
    updateExpanded0:(state,action)=>{
      state.expanded0=action.payload
  },
    updateExpanded1:(state,action)=>{
        state.expanded1=action.payload
    },
    updateExpanded2:(state,action)=>{
        state.expanded2=action.payload
    },
    updateExpanded3:(state,action)=>{
      state.expanded3=action.payload
    },
    resetAccordion:(state,action)=>{
      state.expanded0=true

      state.expanded1=false
      state.expanded2=false
      state.expanded3=false
    }
  },
});

export const { updateExpanded0,updateExpanded1,updateExpanded2,updateExpanded3,resetAccordion } = accordionSlice.actions;
export default accordionSlice.reducer;
