import { createSlice } from "@reduxjs/toolkit";

const comboboxSlice = createSlice({
  name: "combobox",
  initialState: {
    allFilled:false,
    filtered_bu:null,
    filtered_ds:null,

  },
  reducers: {
    updateAllFilled:(state,action)=>{
        state.allFilled=action.payload
    },  updateBusinessUnitFilters:(state,action)=>{
      state.filtered_bu=action.payload
    },
    updateDataSourceFilters:(state,action)=>{
      state.filtered_ds=action.payload
    }
  },
});

export const { updateAllFilled,updateBusinessUnitFilters,updateDataSourceFilters} = comboboxSlice.actions;
export default comboboxSlice.reducer;
