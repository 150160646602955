import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';


function Predictionproduct({ value }) {
  useEffect(() => {
    Highcharts.chart('predictionProduct', {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        align: 'left',
        text: '',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        labels: {
          //step: 2,
          formatter: function() {
            var ret = this.value,
                len = ret.length;
            if( len > 10 ) {
              ret = ret.slice(0,10);
            }
            
            if( len > 15 ) {
              ret = ret.slice(0,15);
            }
            
            return ret;
          }
        },
        type: 'category',
        gridLineWidth: 0,
        categories: value.x_axis.map(timestamp => moment(timestamp).format("DD MMM YY")), // Use x_axis from value prop
        title: {
          text: value.x_title, // Use x_title from value prop
        },
      },
      yAxis: {
        title: {
          text: value.y_title,
          gridLineWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
        },
      },
      series: value.y_axis.map(product => ({
        name: product.name,
        colorByPoint: true,
        data: product.data,
      })),
    });
  }, [value]); // Add value as a dependency to useEffect

  return <div id="predictionProduct" style={{ height: '300px' }} />;
}

export default Predictionproduct;
