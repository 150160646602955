import React from 'react'

function DownvoteIcon({fill}) {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M7.62741 14.4561L7.62741 14.4561C7.3014 14.848 6.6986 14.848 6.37259 14.4561L6.94849 13.977L6.37259 14.4561L1.93801 9.1249C1.46814 8.56003 1.92067 7.79213 2.56543 7.79213H4.63664L4.63664 2.06336C4.63664 1.5929 5.02324 1.25 5.45194 1.25H8.54806C8.97678 1.25 9.36336 1.59292 9.36336 2.06336L9.36336 7.79213H11.4346C12.0793 7.79213 12.5319 8.56004 12.062 9.1249M7.62741 14.4561L12.062 9.1249M7.62741 14.4561L12.062 9.1249M7.62741 14.4561L12.062 9.1249" stroke="#071942" stroke-width="1.5"/>
    </svg>
    
    
  )
}

export default DownvoteIcon