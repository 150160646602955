import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';

function Predictionsovertime({ value }) {
  useEffect(() => {
    Highcharts.chart('predictionsOvertime', {
      chart: {
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      title: {
        align: 'left',
        text: '',
      },
      accessibility: {
        announceNewData: {
          
          enabled: true,
        },
        
      },
      xAxis: {
        labels: {
          //step: 2,
          formatter: function() {
            var ret = this.value,
                len = ret.length;
            if( len > 10 ) {
              ret = ret.slice(0,10);
            }
            
            if( len > 15 ) {
              ret = ret.slice(0,15);
            }
            
            return ret;
          }
        },
        type: 'category',
        gridLineWidth: 0,
        categories: value.x_axis.map(timestamp => moment(timestamp).format("DD MMM YY")), // Use x_axis from value prop
        title: {
          text: value.x_title, // Use x_title from value prop
        },
      },
      yAxis: {
        title: {
          text: value.y_title,
          gridLineWidth: 0,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
        },
      },
      series: [
        {
          name: '',
          colorByPoint: true,
          data: value.y_axis.map(yValue => ({
            y: yValue,
            marker: {
              enabled: yValue !== 0, // Disables the marker for 0 values
            }
          })),
        },
      ],
      drilldown: {},
    });
  }, [value]); // Add value as a dependency to useEffect

  return <div id="predictionsOvertime" style={{ height: '300px' }} />;
}

export default Predictionsovertime;
