import axios from "../../../utils/axios"



export const GetRoomByInteraction = async (interaction_id) => {
    let response = await axios(
      process.env.REACT_APP_IS + `room/${localStorage.getItem('ci')}/interaction_id/${interaction_id}`,
      {
        method: "GET",
        headers: {
            accept: "application/json,text/plain",
            "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, status: response.status };
  };